<template>
  <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12">
    <div class="table-cont" style="height: 100%">
      <div class="table-header pa-3 d-flex">
        <div class="table-property-label">Job History</div>
      </div>
      <v-data-table
        :headers="headers"
        :items="history"
        :footer-props="{
          'disable-items-per-page': true,
        }"
      >
        <template v-slot:item.id="{ item }">
          <v-btn @click.stop="goToJob(item.id)" color="primary">{{
            item.id
          }}</v-btn>
        </template>
        <template v-slot:item.delDate="{ item }">
          <span v-if="item.delDate"> {{ item.delDate | YMD_to_DMY }}</span>
          <span v-else>-</span>
        </template>

        <!-- <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :disable-items-per-page="true"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          />
        </template> -->
      </v-data-table>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "JobHistory",
  // props: ["job"],
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Serial", value: "serial", sortable: false },
        { text: "Date Delivered", value: "delDate", sortable: false },
      ],
    };
  },
  methods: {
    goToJob(job_id) {
      if (job_id) {
        // this.$store.commit("SetSnackbarData", {
        //   message: "Not Implemented Yet - Go To Job",
        //   type: "warning",
        // });

        console.log(job_id);
        this.$router.push("/job/" + job_id);
      }
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    history() {
      return this.$store.getters.getJobHistory;
    },
  },
};
</script>

<style>
</style>