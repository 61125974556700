<template>
  <div class="d-flex flex-column pa-4 ma-0">
    <CompaniesTable />
  </div>
</template>

<script>
import CompaniesTable from "../../components/management/companies/CompaniesTable.vue";
export default {
  name: "Suppliers",
  components: {
    CompaniesTable,
  },
  mounted() {
    this.$store.dispatch("fetchSuppliersList");
  },
  beforeMount() {
    this.$store.commit("setCompanyList", []);
  },
};
</script>

<style>
</style>