<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col
        align-self="center"
        class="d-flex flex-column justify-center align-center"
      >
        <v-progress-circular
          indeterminate
          :size="70"
          :width="6"
          color="primary"
        ></v-progress-circular>
        <p v-if="this.message">{{ this.message }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: {
    message: String,
  },
};
</script>

<style>
</style>