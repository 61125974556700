<template>
  <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12">
    <div class="table-cont">
      <div class="table-header pa-3 d-flex">
        <div class="table-property-label">Parts</div>
      </div>
      <div class="table-row">
        <div class="table-property-label">Parts cost</div>
        <div class="table-property-value">
          {{ this.CalculateParts | toCurrency }}
        </div>
      </div>
      <div class="table-row">
        <div class="table-property-label">Parts Markup %</div>
        <div class="table-property-value">{{ this.job.engPartsmarkup }}</div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Parts Quotation</div>
        <div class="table-property-value">
          {{ CalculatePartsWithMarkup | toCurrency }}
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "JobEngPartSummary",

  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    CalculateParts() {
      if (
        (isNaN(parseFloat(this.job.engParts)) ||
          parseFloat(this.job.engParts) == 0) &&
        this.job.parts &&
        this.job.parts.length > 0
      ) {
        var total = 0;

        this.job.parts.forEach((part) => {
          total += parseFloat(part.packs_cost) || 0;
        });
        return total;
      }
      return this.job.engParts;
    },
    CalculatePartsWithMarkup() {
      if (!this.job) return 0;

      return (
        parseFloat(this.CalculateParts) *
          (1 + parseFloat(this.job.engPartsmarkup) / 100) || 0
      );
    },
  },
};
</script>

<style>
</style>