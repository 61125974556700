<template>
  <v-row class="ma-0 pa-6">
    <div class="flex-grow-1 d-flex justify-space-between mb-6">
      <EditComponentForm
        v-model="editFormVisible"
        :currentEditItem="CurrentSingleComponent"
        @closeModal="CloseModal()"
      />
      <h1>Components to Order</h1>
      <!-- <v-btn
        color="success"
        @click="DownloadPDF('go_ahead/pdf')"
        :loading="IsFetchingPDF"
        :disabled="IsFetchingPDF"
        >Download PDF</v-btn
      > -->
    </div>
    <v-data-table
      :headers="headers"
      :items="LowStockComponentList"
      :items-per-page="-1"
      class="elevation-1"
      style="width: 100%"
      :loading="is_loading"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn @click="EditItem(item)" color="warning">Edit</v-btn>
      </template>
      <!--
      <template v-slot:item.appDate="{ item }">
        {{ item.appDate | YMD_to_DMY }}
      </template> -->

      <!-- <template v-slot:body.append>
        <tr>
          <td colspan="5">Total: {{ CalculateJobsAssignedTotal }}</td>
        </tr>
      </template> -->
    </v-data-table></v-row
  >
</template>


<script>
import EditComponentForm from "../../components/stock/components/EditComponentForm.vue";
export default {
  name: "LowStockComponents",
  components: { EditComponentForm },
  data() {
    return {
      is_loading: false,
      IsFetchingPDF: false,
      editFormVisible: false,

      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          width: 75,
        },
        { text: "Part Code", value: "part_code" },
        { text: "Description", value: "description", width: 250 },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Package", value: "package" },
        { text: "Marking Code", value: "marking_code" },
        { text: "location", value: "location" },
        { text: "Supplier", value: "company.name" },
        { text: "Order Code", value: "order_code" },
        { text: "Unit Price", value: "unit_price" },
        { text: "Qty In Stock", value: "qty_in_stock" },
        { text: "Restock Threshold", value: "restock_threshold" },
        { text: "Restock Qty", value: "restock_qty" },
        { text: "Actions", value: "actions" },
      ],
      // components: [],
    };
  },
  methods: {
    // DownloadPDF(endpoint) {
    //   this.IsFetchingPDF = true;
    //   return this.$store.dispatch("DownloadReportPDF", endpoint).finally(() => {
    //     this.IsFetchingPDF = false;
    //   });
    // },
    EditItem(component) {
      console.log(component);
      this.$store.commit("SetCurrentSingleComponent", component);
      this.editFormVisible = true;
    },
    CloseModal() {
      this.editFormVisible = false;
      this.$store.commit("SetCurrentSingleComponent", null);
    },
  },
  computed: {
    CurrentSingleComponent() {
      return this.$store.getters.GetCurrentSingleComponent;
    },
    LowStockComponentList() {
      return this.$store.getters.GetComponentList;
    },
  },
  mounted() {
    this.is_loading = true;
    this.$store
      .dispatch("getLowStockComponents")
      .then(() => {
        // this.components = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
};
</script>

<style>
</style>