<template>
  <div class="d-flex flex-column pa-4 ma-0">
    <ContactsTable />
  </div>
</template>

<script>
import ContactsTable from "../../components/management/contacts/ContactsTable.vue";
export default {
  name: "Contacts",
  components: {
    ContactsTable,
  },
  mounted() {
    this.$store.dispatch("fetchCompanyContactList");
  },
  beforeMount() {
    this.$store.commit("setCompanyContactList", []);
  },
};
</script>

<style>
</style>