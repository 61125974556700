<template>
  <div>
    <v-btn @click="EditUserModal()" color="success">Add User</v-btn>

    <v-dialog
      ref="editUserDialog"
      v-model="editModal"
      :persistent="isSubmitting"
      width="400px"
    >
      <v-card>
        <v-form ref="EditUserForm" v-model="valid" @submit.prevent="Submit">
          <v-row class="pa-4 ma-0">
            <v-col cols="12">
              <h4 class="mb-2">Name</h4>

              <v-text-field
                solo
                v-model="formFields.name"
                name="name"
                :rules="[rules.required]"
              ></v-text-field>

              <h4 class="mb-2">Username</h4>

              <v-text-field
                solo
                v-model="formFields.username"
                name="name"
              ></v-text-field>

              <h4 class="mb-2">Email</h4>

              <v-text-field
                solo
                v-model="formFields.email"
                name="email"
                :rules="[rules.required, rules.emailPattern]"
              ></v-text-field>

              <h4 class="mb-2">Monthly Target</h4>

              <v-text-field
                solo
                v-model="formFields.monthly_target"
                name="monthly_target"
                type="number"
                prefix="£"
                @wheel="$event.target.blur()"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Sage Ref</h4>

              <v-text-field
                solo
                v-model="formFields.sage_ref"
                name="sage_ref"
                type="number"
                @wheel="$event.target.blur()"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Password</h4>
              <v-text-field
                v-model="formFields.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="password"
                solo
                hint="At least 8 characters"
                counter
                @click:append="show1 = !show1"
              ></v-text-field>

              <h4 class="mb-2">Confirm Password</h4>
              <v-text-field
                v-model="confirm_password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, rules.confirmPasswordRules]"
                :type="show1 ? 'text' : 'password'"
                name="confim_password"
                filled
                hint="At least 8 characters"
                counter
                @click:append="show2 = !show2"
              ></v-text-field>

              <h4 class="mb-2">Roles</h4>
              <v-autocomplete
                v-model="formFields.selectedRoles"
                :disabled="isUpdating"
                :items="rolesList"
                solo
                chips
                multiple
                color="blue-grey lighten-2"
                label="Select"
                item-text="name"
                item-value="id"
                hide-no-data
                :rules="[rules.required, rules.isArr, rules.ArrLength]"
                hide-selected
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>

                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <div class="mx-0 mt-3 d-flex justify-space-between">
                <v-btn
                  :disabled="isSubmitting"
                  color="error"
                  text
                  @click="editModal = false"
                  >Cancel</v-btn
                >

                <v-btn
                  :disabled="!valid || isSubmitting"
                  class="success"
                  type="submit"
                  :loading="isSubmitting"
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AddUserForm",
  data() {
    return {
      valid: false,
      isUpdating: false,
      editModal: false,
      show1: false,
      show2: false,
      rolesList: [],
      isSubmitting: false,
      confirm_password: "",
      formFields: {
        name: "",
        username: "",
        email: "",
        sage_ref: "",
        password: "",
        selectedRoles: [],
        monthly_target: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        isArr: (v) => Array.isArray(v) == true || "Wrong Value",
        confirmPasswordRules: (v) =>
          v == this.formFields.password || "Passwords don't match",
        ArrLength: (v) =>
          (Array.isArray(v) == true && v.length > 0) || "This can't be empty",
        emailMatch: () => `The email and password you entered don't match`,
        emailPattern: (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          "E-mail must be valid",
      },
    };
  },
  methods: {
    remove(item) {
      const index = this.formFields.selectedRoles.findIndex(
        (role_id) => role_id == item.id
      );
      if (index >= 0) this.formFields.selectedRoles.splice(index, 1);
    },
    EditUserModal() {
      this.editModal = true;
    },
    Submit() {
      if (this.validate()) {
        //this.formFields.user_id = this.CurrentSingleUser.id;
        this.isSubmitting = true;
        console.log("Editing User");
        console.log(this.formFields);
        console.log(this.validate());
        const payload = this.formFields;
        this.$store
          .dispatch("AddSingleUser", payload)
          .then((user) => {
            if (user && user.id) {
              this.$store.commit("SetSnackbarData", {
                message: "User created successfully.",
              });
              this.$store.commit("PushUserToEnd", user);

              this.editModal = false;
              this.ResetForm();
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.EditUserForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  computed: {
    CurrentSingleUser() {
      return this.$store.getters.GetCurrentSingleUser;
    },
  },
  mounted() {
    this.isUpdating = true;
    this.$store
      .dispatch("fetchAllRoles")
      .then((roles) => {
        this.rolesList = roles;
      })
      .finally(() => {
        this.isUpdating = false;
      });
  },
};
</script>

<style>
</style>