<template>
  <v-container fill-height fluid v-if="!this.job.id">
    <LoadingScreen />
  </v-container>
  <v-container fill-height fluid style="align-items: flex-start" v-else>
    <v-row>
      <EditJobForm />
    </v-row>
  </v-container>
</template>

<script>
import EditJobForm from "@/components/jobs/forms/EditJobForm";
import LoadingScreen from "@/components/global/LoadingScreen";

export default {
  name: "EditJob",
  components: {
    EditJobForm,
    LoadingScreen,
  },
  computed: {
    job_id() {
      return this.$route.params.job_id;
    },
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
  },
  mounted() {
    console.log("Single Job View " + this.job_id);
    if (this.job_id != "") this.$store.dispatch("fetchSingleJob", this.job_id);
  },
  created() {
    console.log("Edit Job View - created");
  },
  beforeDestroy() {
    this.$store.commit("setCurrentSingleJob", {});
    this.$store.commit("setCompanyList", []);
  },
};
</script>

<style>
</style>