<template>
  <v-row class="mx-0 pa-4">
    <v-col>
      <div class="d-flex align-center mb-4">
        <span class="mr-2">Viewing User:</span>
        <h3>{{ this.CurrentSingleUser.name }}</h3>
        <EditUserForm
          class="ml-auto"
          v-if="this.checkPermission('can_edit_users')"
        />
      </div>
      <!-- <v-btn @click="EditUserModal()">Edit</v-btn> -->

      <div
        class="table-cont mb-4"
        v-if="this.CurrentSingleUser && this.CurrentSingleUser.id"
      >
        <div class="table-header pa-3">
          <div class="table-property-label">
            User ID: {{ this.CurrentSingleUser.id }}
          </div>
        </div>

        <div class="table-row">
          <div class="table-property-label">Name</div>
          <div class="table-property-value" style="text-transform: capitalize">
            {{ this.CurrentSingleUser.name }}
          </div>
        </div>

        <div class="table-row" v-if="this.CurrentSingleUser.Username">
          <div class="table-property-label">Username</div>
          <div class="table-property-value">
            {{ this.CurrentSingleUser.Username }}
          </div>
        </div>

        <div class="table-row">
          <div class="table-property-label">Email</div>
          <div class="table-property-value">
            {{ this.CurrentSingleUser.email }}
          </div>
        </div>

        <div class="table-row">
          <div class="table-property-label">Sage Ref</div>
          <div class="table-property-value">
            {{ this.CurrentSingleUser.sage_ref }}
          </div>
        </div>

        <div class="table-row">
          <div class="table-property-label">Assigned Roles</div>
          <div class="table-property-value">
            <div
              class="d-flex align-center"
              v-if="
                this.CurrentSingleUser.permissions &&
                this.CurrentSingleUser.permissions.length > 0
              "
            >
              <div
                class="header-role-chip mx-1 px-4 py-1"
                v-for="role in this.CurrentSingleUser.permissions"
                :key="role.id"
              >
                {{ role.name }}
              </div>
            </div>
            <div v-else>-None-</div>
          </div>
        </div>
      </div>
    </v-col>
    <v-col></v-col>
    <v-col>
      <CompoundedPermissionsTable />
    </v-col>
  </v-row>
</template>

<script>
import EditUserForm from "./EditUserForm.vue";
import CompoundedPermissionsTable from "./CompoundedPermissionsTable.vue";
export default {
  name: "SingleUserInfo",
  components: {
    EditUserForm,
    CompoundedPermissionsTable,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    CurrentSingleUser() {
      return this.$store.getters.GetCurrentSingleUser;
    },
  },
  beforeDestroy() {
    this.$store.commit("SetCurrentSingleUser", null);
  },
};
</script>

<style scoped>
.header-role-chip {
  background: #1976d2;
}
</style>