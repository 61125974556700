
<template>
  <v-row class="ma-0">
    <v-data-table
      style="width: 100%"
      :headers="headers"
      :items="notes"
      :items-per-page="100"
      calculate-widths
      hide-default-footer
      class="elevation-1"
      mobile-breakpoint="500"
    >
    </v-data-table>
  </v-row>
</template>

<script>
export default {
  name: "NotesTable",
  props: ["notes"],
  data() {
    return {
      headers: [
        { text: "Date", value: "created", width: 150 },
        { text: "User", value: "user.name", width: 200 },
        { text: "Note", value: "note" },
      ],
      table_headers: {
        // ID: "ID",
        Date: "Date",
        User: "User",
        Note: "Note",
      },
    };
  },
  methods: {},
};
</script>

<style>
</style>