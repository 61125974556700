<template>
  <v-dialog v-model="show" width="400">
    <v-card color="white " class="pa-6">
      <v-form
        ref="MutiJobDeliveryForm"
        v-model="valid"
        @submit.prevent="Submit"
      >
        <h4 class="mb-2">Delivery Date</h4>

        <v-text-field
          solo
          name="delDate"
          @click="show_datepicker()"
          readonly
          :value="formFields.delDate | YMD_to_DMY"
        ></v-text-field>

        <v-dialog
          ref="datepicker_dialog"
          v-model="datepicker_modal"
          persistent
          :return-value.sync="temp_date"
          width="290px"
        >
          <v-date-picker v-model="temp_date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="hide_datepicker()">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="save_datepicker()"> OK </v-btn>
          </v-date-picker>
        </v-dialog>

        <h4 class="mb-2">Shipment Ref</h4>

        <v-text-field
          solo
          v-model="formFields.shipment_ref"
          name="shipment_ref"
        ></v-text-field>

        <div class="columns-2">
          <div>
            <h4 class="mb-2">First Name</h4>

            <v-text-field
              solo
              v-model="formFields.deliveryFirstName"
              name="deliveryFirstName"
              :rules="[rules.required, rules.trimWhiteSpace]"
            ></v-text-field>
          </div>

          <div>
            <h4 class="mb-2">Last Name</h4>

            <v-text-field
              solo
              v-model="formFields.deliveryLastName"
              name="deliveryLastName"
              :rules="[rules.required, rules.trimWhiteSpace]"
            ></v-text-field>
          </div>
        </div>

        <v-btn @click="signature_dialog = true">Add Signature</v-btn>

        <v-text-field
          solo
          style="display: none !important"
          readonly
          :value="formFields.svg_signature"
          name="svg_signature"
          :rules="[rules.required]"
        ></v-text-field>

        <v-btn
          :disabled="!valid || isSubmitting"
          class="success mr-0 mt-3 ml-auto d-flex"
          type="submit"
          :loading="valid && isSubmitting"
          >Advance Job</v-btn
        >
      </v-form>

      <v-dialog
        ref="signature_dialog"
        v-model="signature_dialog"
        persistent
        width="400px"
      >
        <div style="background: white; width: 100%" class="pa-4">
          <Signature_pad
            :SignatureDialogValue="signature_dialog"
            :svg_sig="this.formFields.svg_signature"
            @SignatureModalClosed="signature_dialog = false"
            @SignatureConfirmed="set_signature"
          />
        </div>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import Signature_pad from "../singleJob/job-advancing/Signature_pad.vue";
export default {
  name: "MutiJobDeliveryForm",
  components: {
    Signature_pad,
  },
  props: {
    value: Boolean,
    job_IDs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isUpdating: false,
      isSubmitting: false,
      datepicker_modal: false,
      signature_dialog: false,
      temp_date: "",
      valid: false,
      formFields: {
        deliveryFirstName: "",
        deliveryLastName: "",
        shipment_ref: null,
        // print_signature: null,
        delDate: new Date().toISOString().substr(0, 10),
        svg_signature: null,
      },

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
        trimWhiteSpace: (v) => v.toString().trim().length > 0,
      },
    };
  },

  methods: {
    set_signature(sig) {
      this.formFields.svg_signature = sig;
    },
    show_datepicker() {
      this.datepicker_modal = true;
      this.temp_date = "";
    },
    hide_datepicker() {
      this.datepicker_modal = false;
      this.temp_date = "";
    },
    save_datepicker() {
      if (this.temp_date) {
        this.formFields.delDate = this.temp_date;
      }
      this.hide_datepicker();
    },
    Submit() {
      console.log("MutiJobDeliveryForm - submitted");
      console.log(this.formFields);
      console.log(this.validate());

      // if (
      //   this.formFields.deliveryFirstName.trim().length > 0 &&
      //   this.formFields.deliveryLastName.trim().length > 0
      // )
      //   this.formFields.print_signature =
      //     this.formFields.deliveryFirstName.trim() +
      //     " " +
      //     this.formFields.deliveryLastName.trim();
      // else return;

      this.isSubmitting = true;

      const payload = {
        job_IDs: this.job_IDs,
        data: this.formFields,
      };
      this.$store
        .dispatch("MultiDeliveryFormSubmit", payload)
        .then(() => {
          this.$emit("delivered", true);
          this.$refs.MutiJobDeliveryForm.reset();
        })
        .finally(() => {
          this.isSubmitting = false;

          // this.$router.push("/job/" + this.job_id);
        });
    },
    reset_form() {},
    validate() {
      return this.$refs.MutiJobDeliveryForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  mounted() {
    // this.formFields.shipment_ref = this.job.shipment_ref || "";
    // this.formFields.print_signature = this.job.print_signature || "";
    // if (this.job && this.job.svg_signature) {
    //   var encodedData = window.btoa(this.job.svg_signature);
    //   this.formFields.svg_signature =
    //     "data:image/svg+xml;base64," + encodedData;
    // }
  },
  computed: {
    // job() {
    //   return this.$store.getters.getCurrentSingleJob;
    // },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>