<template>
  <div class="d-flex flex-column pa-4 ma-0">
    <EngineerGraphs />
  </div>
</template>

<script>
import EngineerGraphs from "../../components/management/graphs/EngineerGraphs.vue";
export default {
  name: "EngineerGraphsPage",
  components: {
    EngineerGraphs,
  },
  mounted() {},
  beforeMount() {
    // this.$store.commit("setCompanyList", []);
  },
};
</script>

<style>
</style>