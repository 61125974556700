<template>
  <div class="full-width">
    <h2 class="mb-4">Components Search</h2>

    <!-- grid -->

    <div class="comp-filtesr-search-wrapper">
      <!-- start of filters -->

      <div class="full-width">
        <v-form
          ref="ComponentSearchForm"
          @submit.prevent="SearchComponents"
          class="comp-filters-wrapper"
        >
          <div>
            <h4>Filter by Part Code</h4>
            <v-text-field solo v-model="formFields.part_code"></v-text-field>
          </div>

          <div>
            <h4>Filter by Description</h4>
            <v-text-field
              solo
              v-model="formFields.description"
              placeholder="Filter by description"
            ></v-text-field>
          </div>

          <div>
            <h4>Filter by Package</h4>
            <v-text-field solo v-model="formFields.package"></v-text-field>
          </div>

          <div>
            <h4>Filter by Marking Code</h4>
            <v-text-field
              solo
              v-model="formFields.marking_code"
              placeholder="Filter by Marking Code"
            ></v-text-field>
          </div>

          <div>
            <h4>Filter by Order Code</h4>
            <v-text-field solo v-model="formFields.order_code"></v-text-field>
          </div>

          <div>
            <h4>Filter All</h4>
            <v-text-field solo v-model="formFields.text"></v-text-field>
          </div>

          <v-btn
            color="success"
            @click="SearchComponents"
            :loading="isSearchingComponents"
            :disabled="isSearchingComponents"
            type="submit"
            >Search</v-btn
          >
        </v-form>
      </div>
      <!-- end of filters -->

      <v-row>
        <v-col :cols="12" :md="12">
          <div>
            <v-data-table
              :headers="headers"
              :items="filteredComponentList"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              calculate-widths
              hide-default-footer
              show-select
              v-model="selectedComponents"
              :loading="this.isLoadingComponents || this.isSearchingComponents"
              loading-text="Loading... Please wait"
              class="elevation-1"
              @page-count="pageCount = $event"
            >
              <!-- <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" style="background: #eaeaea">
                  <div class="flex-column pa-2">
                    <span class="mb-1"
                      ><strong>Manufacturer:</strong>
                      {{ item.manufacturer }}</span
                    >
                    <span class="mb-1"
                      ><strong>Supplier:</strong>
                      {{
                        item.company && item.company.name
                          ? item.company.name
                          : "-"
                      }}</span
                    >
                    <span
                      ><strong>Manufacturer:</strong>
                      {{ item.manufacturer }}</span
                    >
                  </div>
                </td>
              </template> -->

              <template v-slot:item.data-table-select="{ isSelected, select, item }">
                <v-simple-checkbox
                  :value="isSelected"
                  @input="select($event)"
                  v-ripple
                  :disabled="!item.qty_in_stock || !item.qty_in_stock > 0"
                ></v-simple-checkbox>
              </template>

              <template v-slot:item.datasheet="{ item }">
                <a target="_blank" :href="item.datasheet" v-if="item.datasheet">
                  <v-img :src="require('../../../../assets/pdf.svg')" contain width="36"
                /></a>
                <div v-else>-</div>
              </template>

              <template v-slot:item.qty_in_stock="{ item }">
                <v-btn
                  @click.stop="
                    component_qty_modal = true;
                    component_qty_item = item;
                  "
                  x-small
                  fab
                  v-if="
                    checkPermission('can_advance_job') ||
                      checkPermission('show_in_engineer_dropdown') ||
                      checkPermission('show_in_sales_rep_dropdown')
                  "
                  icon
                  color="orange"
                  ><v-icon>mdi-pen</v-icon></v-btn
                >
                {{ item.qty_in_stock }}
              </template>
            </v-data-table>
            <EditComponentQTY
              v-model="component_qty_modal"
              :currentEditItem="this.component_qty_item"
              @closeModal="
                component_qty_modal = false;
                component_qty_item = null;
              "
            />
            <div class="text-center pt-2" v-if="pageCount > 1">
              <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
            </div>
            <v-btn
              class="mt-5"
              color="primary"
              @click="AddComponentsToJob"
              :loading="isAddingComponents"
              :disabled="
                isAddingComponents || !selectedComponents || !selectedComponents.length > 0
              "
              >Add to Job</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import EditComponentQTY from '../../../stock/components/EditComponentQTYForm.vue';
export default {
  components: {
    EditComponentQTY,
  },
  data() {
    return {
      component_list: null,
      component_qty_modal: false,
      component_qty_item: null,
      isLoadingComponents: false,
      isAddingComponents: false,
      selectedComponents: [],
      mappedSelectedComponents: [],
      itemsPerPage: 20,
      page: 1,
      pageCount: 0,
      formFields: {
        text: '',
        part_code: '',
        order_code: '',
        company_id: '',
      },
      headers: [
        // {
        //   text: "ID",
        //   align: "start",
        //   sortable: false,
        //   value: "id",
        //   width: 75,
        // },
        { text: 'Part Code', value: 'part_code' },
        { text: 'Description', value: 'description', width: 250 },
        { text: 'Manufacturer', value: 'manufacturer' },
        { text: 'Package', value: 'package' },
        { text: 'Marking Code', value: 'marking_code' },
        { text: 'Datasheet', value: 'datasheet' },
        { text: 'Location', value: 'location' },
        { text: 'Supplier', value: 'company.name' },

        { text: 'Order Code', value: 'order_code' },
        { text: 'Unit Price', value: 'unit_price' },
        { text: 'Qty In Stock', value: 'qty_in_stock' },
      ],
    };
  },

  methods: {
    // mapComponentSelection(item) {
    //   console.log(item);
    //   let itemPresentInMapping = this.mappedSelectedComponents.find((comp) => {
    //     return comp.id == item.item.id;
    //   });

    //   if (!itemPresentInMapping) this.mappedSelectedComponents.push(item.item);
    //   else
    //     this.mappedSelectedComponents.splice(
    //       this.mappedSelectedComponents.findIndex(function (comp) {
    //         return comp.id === item.item.id;
    //       }),
    //       1
    //     );

    //   console.log(this.mappedSelectedComponents);
    // },
    SearchComponents(show_snackbar = true) {
      // this.isSearchingComponents = true;

      return this.$store
        .dispatch('SearchComponents', this.formFields)
        .then((components) => {
          console.log(components);
          this.component_list = components;
          // this.isSearchingComponents = false;
          if (show_snackbar)
            this.$store.commit('AddSnackbar', {
              message: 'Searched',
            });
        })
        .finally(() => {
          this.$store.commit('setIsSearchingComponents', false);
        });
    },
    AddComponentsToJob() {
      let components = this.selectedComponents;
      if (!components || !components.length > 0 || !this.job.id) return;

      this.isAddingComponents = true;
      return axios
        .post(
          process.env.VUE_APP_API_PROTOCOL +
            '://' +
            process.env.VUE_APP_API_HOST +
            ':' +
            process.env.VUE_APP_API_PORT +
            '/api/job_components/bulk',
          { components: this.selectedComponents, job_id: this.job.id }
        )
        .then((response) => {
          console.log(response.data);

          let job = response.data;
          return this.SearchComponents(false).finally(() => {
            this.isAddingComponents = false;
            this.$store.commit('setCurrentSingleJob', {});
            this.$store.commit('setCurrentSingleJob', job);
            this.$store.commit('AddSnackbar', {
              message: 'Added',
            });
            this.selectedComponents = [];
          });
        })
        .catch(function(err) {
          console.log(err);
        });

      // this.$emit("addItem")
    },
  },
  computed: {
    CompanyList() {
      return this.$store.getters.getCompanyList;
    },
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    isSearchingComponents() {
      return this.$store.getters.getIsSearchingComponents;
    },
    FullComponentList() {
      return this.$store.getters.GetComponentList;
    },
    filteredComponentList() {
      let items = [];
      if (this.component_list !== null) items = this.component_list;
      else items = this.FullComponentList;

      return items;
      // let filter = this.formFields.text;
      // if (!filter) return items;
      // return items.filter((component) => {
      //   return (
      //     component["manufacturer"].toString().toLowerCase().includes(filter) ||
      //     component["package"].toString().toLowerCase().includes(filter) ||
      //     component["marking_code"]
      //       .toString()
      //       .toLowerCase()
      //       .includes(filter) ||
      //     component["order_code"].toString().toLowerCase().includes(filter) ||
      //     component["part_code"]
      //       .toString()
      //       .toLowerCase()
      //       .includes(filter.toLowerCase()) ||
      //     component["description"]
      //       .toString()
      //       .toLowerCase()
      //       .includes(filter.toLowerCase()) ||
      //     (component["company"] &&
      //       component["company"]["name"]
      //         .toString()
      //         .toLowerCase()
      //         .includes(filter.toLowerCase()))
      //   );
      //   // str.includes("world");
      // });
      // return this.component_list;
    },
  },
  mounted() {
    this.isLoadingComponents = true;
    this.$store.dispatch('getComponents').finally(() => {
      this.isLoadingComponents = false;
    });
  },
};
</script>

<style scoped>
.comp-filter-search-wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 36px;
}

@media only screen and (max-width: 991px) {
  .comp-filter-search-wrapper {
    grid-template-columns: 1fr;
  }
}

.comp-filters-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 30px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 991px) {
  .comp-filters-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .comp-filters-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 370px) {
  .comp-filters-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
}

>>> .v-data-table__selected {
  background: #e8f5e9 !important;
}
</style>
