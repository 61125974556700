<template>
  <div style="width: 100%" class="mechanical-data-tab-content pb-6">
    <v-row class="px-2 ma-0">
      <v-col cols="12" md="12">
        <h2>Mechanical Data</h2>
      </v-col>
      <!-- General Motor Info -->
      <v-col cols="12" md="2">
        <h3 class="mb-2">General Motor Info</h3>

        <h4 class="mb-2">Motor Power</h4>

        <v-text-field
          solo
          v-model.number="formFields.motor_power"
          name="motor_power"
          class="hide-arrows"
          suffix="kW"
          type="number"
          @wheel="$event.target.blur()"
          @blur="ConvertIntsToFloats('motor_power', 1)"
        ></v-text-field>

        <h4 class="mb-2">Motor Speed</h4>

        <v-text-field
          solo
          v-model.number="formFields.motor_speed"
          name="motor_speed"
          class="hide-arrows"
          suffix="RPM"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Motor Voltage</h4>

        <v-text-field
          solo
          v-model.number="formFields.motor_voltage"
          name="motor_voltage"
          class="hide-arrows"
          suffix="V"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Motor Current</h4>

        <v-text-field
          solo
          v-model.number="formFields.motor_current"
          name="motor_current"
          class="hide-arrows"
          suffix="A"
          type="number"
          @wheel="$event.target.blur()"
          @blur="ConvertIntsToFloats('motor_current')"
        ></v-text-field>

        <h4 class="mb-2">Motor Frequency</h4>

        <v-text-field
          solo
          v-model.number="formFields.motor_freq"
          name="motor_freq"
          class="hide-arrows"
          suffix="Hz"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Motor Mounting</h4>

        <v-select
          v-model="formFields.motor_mounting"
          solo
          :items="['B3', 'B5', 'B14', 'B35', 'B34']"
        ></v-select>
      </v-col>

      <!-- Further Info -->
      <v-col cols="12" md="6">
        <h3 class="mb-8">Further Info</h3>

        <h4 class="mb-2">Reported Fault</h4>

        <div>{{ this.job.fault || "-" }}</div>

        <v-divider class="my-6"></v-divider>

        <h4 class="mb-2">Root Cause Analysis</h4>

        <div>{{ this.job.faultdetails || "-" }}</div>
        <v-divider class="my-6"></v-divider>

        <h4 class="mb-2">Repairs Carried Out</h4>

        <div>{{ this.job.repairs || "-" }}</div>
        <v-divider class="my-6"></v-divider>

        <h4 class="mb-2">Final Test</h4>

        <div class="mb-3">{{ this.job.finaltest || "-" }}</div>
        <JobReportEditModal />
      </v-col>

      <!-- Motor Photos -->
      <v-col cols="12" md="4">
        <MechanicalDataJobPhotos
          @InnerFormChanged="ImageDescUpdated($event)"
          v-if="this.job"
        />
      </v-col>
    </v-row>
    <div class="mech-data-group-spacing"></div>

    <div class="mechanical-data-cont">
      <v-row class="px-5">
        <!-- Shaft -->
        <v-col cols="12" md="12">
          <!-- Shaft -->
          <h3 class="mb-4">Shaft</h3>

          <h4 class="mb-2">Size</h4>
          <v-text-field
            solo
            v-model.number="formFields.shaft_size"
            name="shaft_size"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('shaft_size', 3)"
          ></v-text-field>

          <v-switch
            v-model="formFields.shaft_size_pass_fail"
            label="Shaft Size - Pass/Fail"
          ></v-switch>

          <h4 class="mb-2">Keyway</h4>
          <v-text-field
            solo
            v-model.number="formFields.shaft_keyway"
            name="shaft_keyway"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('shaft_keyway', 3)"
          ></v-text-field>

          <v-switch
            v-model="formFields.shaft_keyway_pass_fail"
            label="Shaft Keyway - Pass/Fail"
          ></v-switch>

          <h4 class="mb-2">Run-out</h4>
          <v-text-field
            solo
            v-model.number="formFields.shaft_runout"
            name="shaft_runout"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('shaft_runout', 3)"
          ></v-text-field>

          <v-switch
            v-model="formFields.shaft_runout_pass_fail"
            label="Shaft Run-out - Pass/Fail"
          ></v-switch>
        </v-col>
        <!-- <v-col cols="12" md="6"> </v-col> -->
        <v-col cols="12" md="12">
          <v-divider class="my-8"></v-divider>
        </v-col>

        <!-- NDE Journal -->
        <v-col cols="12" md="4">
          <!-- NDE Journal -->
          <h3 class="mb-4">NDE Journal</h3>

          <h4 class="mb-2">Bearing</h4>
          <v-textarea
            solo
            v-model="formFields.nde_journal_bearing"
            name="nde_journal_bearing"
          ></v-textarea>
          <h4 class="mb-2">Fit</h4>
          <v-textarea
            solo
            v-model="formFields.nde_journal_fit"
            name="nde_journal_fit"
          ></v-textarea>

          <h4 class="mb-2">Nominal</h4>

          <v-text-field
            solo
            v-model.number="formFields.nde_journal_nominal"
            name="nde_journal_nominal"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('nde_journal_nominal', 3)"
          ></v-text-field>

          <h4 class="mb-2">Upper Tolerance</h4>

          <v-text-field
            solo
            v-model.number="formFields.nde_journal_upper_tolerance"
            name="nde_journal_upper_tolerance"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('nde_journal_upper_tolerance', 3)"
          ></v-text-field>

          <h4 class="mb-2">Lower Tolerance</h4>

          <v-text-field
            solo
            v-model.number="formFields.nde_journal_lower_tolerance"
            name="nde_journal_lower_tolerance"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('nde_journal_lower_tolerance', 3)"
          ></v-text-field>

          <h4 class="mb-2">Actual Measurement</h4>

          <v-text-field
            solo
            v-model.number="formFields.nde_journal_actual_measurement"
            name="nde_journal_actual_measurement"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('nde_journal_actual_measurement', 3)"
          ></v-text-field>

          <v-switch
            v-model="formFields.nde_journal_pass_fail"
            label="Pass/Fail"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="4">
          <img
            src="@/assets/journal.jpg"
            transition="scale-transition"
            alt="Journal Illustration"
            class="mechanical-img"
          />
        </v-col>
        <!-- DE Journal -->
        <v-col cols="12" md="4">
          <!-- DE Journal -->
          <h3 class="mb-4">DE Journal</h3>

          <h4 class="mb-2">Bearing</h4>
          <v-textarea
            solo
            v-model="formFields.de_journal_bearing"
            name="nde_journal_bearing"
          ></v-textarea>
          <h4 class="mb-2">Fit</h4>
          <v-textarea
            solo
            v-model="formFields.de_journal_fit"
            name="nde_journal_fit"
          ></v-textarea>

          <h4 class="mb-2">Nominal</h4>

          <v-text-field
            solo
            v-model.number="formFields.de_journal_nominal"
            name="nde_journal_nominal"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('de_journal_nominal', 3)"
          ></v-text-field>

          <h4 class="mb-2">Upper Tolerance</h4>

          <v-text-field
            solo
            v-model.number="formFields.de_journal_upper_tolerance"
            name="nde_journal_upper_tolerance"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('de_journal_upper_tolerance', 3)"
          ></v-text-field>

          <h4 class="mb-2">Lower Tolerance</h4>

          <v-text-field
            solo
            v-model.number="formFields.de_journal_lower_tolerance"
            name="nde_journal_lower_tolerance"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('de_journal_lower_tolerance', 3)"
          ></v-text-field>

          <h4 class="mb-2">Actual Measurement</h4>

          <v-text-field
            solo
            v-model.number="formFields.de_journal_actual_measurement"
            name="nde_journal_actual_measurement"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('de_journal_actual_measurement', 3)"
          ></v-text-field>

          <v-switch
            v-model="formFields.de_journal_pass_fail"
            label="Pass/Fail"
          ></v-switch>
        </v-col>

        <!-- <div class="mech-data-group-spacing"></div> -->

        <v-col cols="12" md="12">
          <v-divider class="my-8"></v-divider>
        </v-col>

        <!-- NDE Housing -->
        <v-col cols="12" md="4">
          <!-- NDE Housing -->
          <h3 class="mb-4">NDE Housing</h3>

          <h4 class="mb-2">Nominal Size</h4>

          <v-text-field
            solo
            v-model.number="formFields.nde_housing_nominal"
            name="nde_housing_nominal"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('nde_housing_nominal', 3)"
          ></v-text-field>

          <h4 class="mb-2">Upper Tolerance</h4>

          <v-text-field
            solo
            v-model.number="formFields.nde_housing_upper_tolerance"
            name="nde_housing_upper_tolerance"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('nde_housing_upper_tolerance', 3)"
          ></v-text-field>

          <h4 class="mb-2">Lower Tolerance</h4>

          <v-text-field
            solo
            v-model.number="formFields.nde_housing_lower_tolerance"
            name="nde_housing_lower_tolerance"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('nde_housing_lower_tolerance', 3)"
          ></v-text-field>

          <h4 class="mb-2">Actual Measurement</h4>

          <v-text-field
            solo
            v-model.number="formFields.nde_housing_actual_measurement"
            name="nde_housing_actual_measurement"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('nde_housing_actual_measurement', 3)"
          ></v-text-field>

          <v-switch
            v-model="formFields.nde_housing_pass_fail"
            label="Pass/Fail"
          ></v-switch>
        </v-col>

        <v-col cols="12" md="4">
          <img
            src="@/assets/housing.jpg"
            transition="scale-transition"
            alt="housing Illustration"
            class="mechanical-img"
          />
        </v-col>

        <!-- DE Housing -->
        <v-col cols="12" md="4">
          <!-- DE Housing -->
          <h3 class="mb-4">DE Housing</h3>

          <h4 class="mb-2">Nominal Size</h4>

          <v-text-field
            solo
            v-model.number="formFields.de_housing_nominal"
            name="de_housing_nominal"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('de_housing_nominal', 3)"
          ></v-text-field>

          <h4 class="mb-2">Upper Tolerance</h4>

          <v-text-field
            solo
            v-model.number="formFields.de_housing_upper_tolerance"
            name="de_housing_upper_tolerance"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('de_housing_upper_tolerance', 3)"
          ></v-text-field>

          <h4 class="mb-2">Lower Tolerance</h4>

          <v-text-field
            solo
            v-model.number="formFields.de_housing_lower_tolerance"
            name="de_housing_lower_tolerance"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('de_housing_lower_tolerance', 3)"
          ></v-text-field>

          <h4 class="mb-2">Actual Measurement</h4>

          <v-text-field
            solo
            v-model.number="formFields.de_housing_actual_measurement"
            name="de_housing_actual_measurement"
            class="hide-arrows"
            suffix="mm"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('de_housing_actual_measurement', 3)"
          ></v-text-field>

          <v-switch
            v-model="formFields.de_housing_pass_fail"
            label="Pass/Fail"
          ></v-switch>
        </v-col>

        <!-- <div class="mech-data-group-spacing"></div> -->

        <v-col cols="12" md="12">
          <v-divider class="my-8"></v-divider>
        </v-col>

        <!-- Balance Grade -->
        <v-col cols="12" md="12">
          <h3 class="mb-4">Balance Grade</h3>
          <v-select
            solo
            :items="['G1.0', 'G2.5']"
            v-model="formFields.balance_grade"
          ></v-select>

          <h4 class="mb-2">Measuring Speed</h4>

          <v-text-field
            solo
            v-model.number="formFields.measuring_speed"
            name="measuring_speed"
            class="hide-arrows"
            suffix="RPM"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>
        </v-col>

        <!-- NDE Balance Data -->
        <v-col cols="12" md="6">
          <h3 class="mb-4">NDE Balance Data</h3>
          <h4 class="mb-2">Permitted Weight</h4>

          <v-text-field
            solo
            v-model.number="formFields.balance_nde_permitted"
            name="balance_nde_permitted"
            class="hide-arrows"
            suffix="grams"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('balance_nde_permitted')"
          ></v-text-field>

          <h4 class="mb-2">Actual Weight</h4>

          <v-text-field
            solo
            v-model.number="formFields.balance_nde_actual"
            name="balance_nde_actual"
            class="hide-arrows"
            suffix="grams"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('balance_nde_actual')"
          ></v-text-field>

          <h4 class="mb-2">Angle</h4>

          <v-text-field
            solo
            v-model.number="formFields.balance_nde_angle"
            name="balance_nde_angle"
            class="hide-arrows"
            suffix="degrees"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>
        </v-col>

        <!-- DE Balance Data -->
        <v-col cols="12" md="6">
          <h3 class="mb-4">DE Balance Data</h3>
          <h4 class="mb-2">Permitted Weight</h4>

          <v-text-field
            solo
            v-model.number="formFields.balance_de_permitted"
            name="balance_de_permitted"
            class="hide-arrows"
            suffix="grams"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('balance_de_permitted')"
          ></v-text-field>

          <h4 class="mb-2">Actual Weight</h4>

          <v-text-field
            solo
            v-model.number="formFields.balance_de_actual"
            name="balance_de_actual"
            class="hide-arrows"
            suffix="grams"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('balance_de_actual')"
          ></v-text-field>

          <h4 class="mb-2">Angle</h4>

          <v-text-field
            solo
            v-model.number="formFields.balance_de_angle"
            name="balance_de_angle"
            class="hide-arrows"
            suffix="degrees"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>
        </v-col>

        <!-- <div class="mech-data-group-spacing"></div> -->

        <v-col cols="12" md="12">
          <v-divider class="my-8"></v-divider>
        </v-col>

        <!-- Run Test Off Load -->
        <v-col cols="12" md="6">
          <h3 class="mb-4">Run Test - Off Load</h3>
          <h4 class="mb-2">Connection</h4>

          <v-select
            solo
            :items="['Star', 'Delta']"
            v-model="formFields.off_load_connection"
          ></v-select>

          <h4 class="mb-2">Test Voltage</h4>

          <v-text-field
            solo
            v-model.number="formFields.off_load_test_voltage"
            name="test_voltage"
            class="hide-arrows"
            suffix="V"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('off_load_test_voltage')"
          ></v-text-field>

          <h4 class="mb-2">Test Frequency</h4>

          <v-text-field
            solo
            v-model.number="formFields.off_load_test_frequency"
            name="test_frequency"
            class="hide-arrows"
            suffix="Hz"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('off_load_test_frequency')"
          ></v-text-field>

          <h4 class="mb-2">Motor Test Speed</h4>

          <v-text-field
            solo
            v-model.number="formFields.off_load_motor_test_speed"
            name="motor_test_speed"
            class="hide-arrows"
            suffix="RPM"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('off_load_motor_test_speed')"
          ></v-text-field>

          <h4 class="mb-2">Current L1</h4>

          <v-text-field
            solo
            v-model.number="formFields.off_load_current_l1"
            name="current_l1"
            class="hide-arrows"
            suffix="A"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('off_load_current_l1')"
          ></v-text-field>

          <h4 class="mb-2">Current L2</h4>

          <v-text-field
            solo
            v-model.number="formFields.off_load_current_l2"
            name="current_l2"
            class="hide-arrows"
            suffix="A"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('off_load_current_l2')"
          ></v-text-field>

          <h4 class="mb-2">Current L3</h4>

          <v-text-field
            solo
            v-model.number="formFields.off_load_current_l3"
            name="current_l3"
            class="hide-arrows"
            suffix="A"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('off_load_current_l3')"
          ></v-text-field>
        </v-col>

        <!-- Run Test On Load -->
        <v-col cols="12" md="6">
          <h3 class="mb-4">Run Test - On Load</h3>
          <h4 class="mb-2">Connection</h4>

          <v-select
            solo
            :items="['Star', 'Delta']"
            v-model="formFields.on_load_connection"
          ></v-select>

          <h4 class="mb-2">Test Voltage</h4>

          <v-text-field
            solo
            v-model.number="formFields.on_load_test_voltage"
            name="test_voltage"
            class="hide-arrows"
            suffix="V"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('on_load_test_voltage')"
          ></v-text-field>

          <h4 class="mb-2">Test Frequency</h4>

          <v-text-field
            solo
            v-model.number="formFields.on_load_test_frequency"
            name="test_frequency"
            class="hide-arrows"
            suffix="Hz"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('on_load_test_frequency')"
          ></v-text-field>

          <h4 class="mb-2">Motor Test Speed</h4>

          <v-text-field
            solo
            v-model.number="formFields.on_load_motor_test_speed"
            name="motor_test_speed"
            class="hide-arrows"
            suffix="RPM"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('on_load_motor_test_speed')"
          ></v-text-field>

          <h4 class="mb-2">Current L1</h4>

          <v-text-field
            solo
            v-model.number="formFields.on_load_current_l1"
            name="current_l1"
            class="hide-arrows"
            suffix="A"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('on_load_current_l1')"
          ></v-text-field>

          <h4 class="mb-2">Current L2</h4>

          <v-text-field
            solo
            v-model.number="formFields.on_load_current_l2"
            name="current_l2"
            class="hide-arrows"
            suffix="A"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('on_load_current_l2')"
          ></v-text-field>

          <h4 class="mb-2">Current L3</h4>

          <v-text-field
            solo
            v-model.number="formFields.on_load_current_l3"
            name="current_l3"
            class="hide-arrows"
            suffix="A"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('on_load_current_l3')"
          ></v-text-field>
        </v-col>

        <!-- Static Test Results -->
        <v-col cols="12" md="6">
          <h3 class="mb-4">Static Test Results</h3>
          <h4 class="mb-2">u1_v1</h4>

          <v-text-field
            solo
            v-model.number="formFields.u1_v1"
            name="u1_v1"
            class="hide-arrows"
            suffix="Ohms"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('u1_v1', 6)"
          ></v-text-field>

          <h4 class="mb-2">u1_w1</h4>

          <v-text-field
            solo
            v-model.number="formFields.u1_w1"
            name="u1_w1"
            class="hide-arrows"
            suffix="Ohms"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('u1_w1', 6)"
          ></v-text-field>

          <h4 class="mb-2">v1_w1</h4>

          <v-text-field
            solo
            v-model.number="formFields.v1_w1"
            name="v1_w1"
            class="hide-arrows"
            suffix="Ohms"
            type="number"
            @wheel="$event.target.blur()"
            @blur="ConvertIntsToFloats('v1_w1', 6)"
          ></v-text-field>

          <h4 class="mb-2">Insulation Resistance</h4>

          <v-text-field
            solo
            v-model.number="formFields.insulation_resistance"
            name="insulation_resistance"
            class="hide-arrows"
            suffix="MOhms"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>

          <h4 class="mb-2">Insulation Resistance Test Voltage</h4>

          <v-text-field
            solo
            v-model.number="formFields.insulation_voltage"
            name="insulation_voltage"
            class="hide-arrows"
            suffix="V"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <!-- there's an empty character here -->
          <h3 class="mb-4">­</h3>

          <h4 class="mb-2">Earth Leakage Current (Hi-Pot)</h4>

          <v-text-field
            solo
            v-model.number="formFields.hi_pot"
            name="hi_pot"
            class="hide-arrows"
            suffix="uA"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>

          <h4 class="mb-2">Hi-Pot Voltage</h4>

          <v-text-field
            solo
            v-model.number="formFields.hi_pot_voltage"
            name="hi_pot_voltage"
            class="hide-arrows"
            suffix="V"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>

          <h4 class="mb-2">Surge Test Voltage</h4>

          <v-text-field
            solo
            v-model.number="formFields.surge_test_voltage"
            name="surge_test_voltage"
            class="hide-arrows"
            suffix="V"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>

          <v-switch
            label="Surge Test Pass/Fail"
            v-model="formFields.surge_test"
          ></v-switch>
        </v-col>
      </v-row>

      <v-btn
        @click="Save()"
        :loading="isSubmitting"
        color="success"
        :disabled="isSubmitting"
        class="ml-5"
        >Save</v-btn
      >

      <div class="r-fab-btn">
        <v-btn
          fab
          @click="Save()"
          :loading="isSubmitting"
          color="success"
          :disabled="isSubmitting"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import MechanicalDataJobPhotos from "./MechanicalDataJobPhotos.vue";
import JobReportEditModal from "../tab6/JobReportEditModal.vue";

import axios from "axios";

export default {
  name: "MechanicalData",
  components: {
    MechanicalDataJobPhotos,
    JobReportEditModal,
  },
  data() {
    return {
      formFields: {
        motor_power: "",
        measuring_speed: "",
        motor_speed: "",
        motor_voltage: "",
        motor_current: "",
        motor_freq: "",
        motor_mounting: "B3",
        nameplate_photo: "",
        incoming_photo: "",
        root_cause_analysis_photo: "",
        nde_journal_bearing: "",
        nde_journal_fit: "",
        nde_journal_nominal: "",
        nde_journal_upper_tolerance: "",
        nde_journal_lower_tolerance: "",
        nde_journal_actual_measurement: "",
        nde_journal_pass_fail: false,
        de_journal_bearing: "",
        de_journal_fit: "",
        de_journal_nominal: "",
        de_journal_upper_tolerance: "",
        de_journal_lower_tolerance: "",
        de_journal_actual_measurement: "",
        de_journal_pass_fail: false,
        shaft_size: "",
        shaft_keyway: "",
        shaft_runout: "",
        shaft_size_pass_fail: false,
        shaft_keyway_pass_fail: false,
        shaft_runout_pass_fail: false,
        nde_housing_nominal: "",
        nde_housing_upper_tolerance: "",
        nde_housing_lower_tolerance: "",
        nde_housing_actual_measurement: "",
        nde_housing_pass_fail: false,
        de_housing_nominal: "",
        de_housing_upper_tolerance: "",
        de_housing_lower_tolerance: "",
        de_housing_actual_measurement: "",
        de_housing_pass_fail: false,
        balance_grade: "G1.0",
        balance_nde_permitted: "",
        balance_nde_actual: "",
        balance_nde_angle: "",
        balance_de_permitted: "",
        balance_de_actual: "",
        balance_de_angle: "",
        u1_v1: "",
        u1_w1: "",
        v1_w1: "",
        insulation_resistance: "",
        hi_pot: "",
        hi_pot_voltage: "",
        surge_test: false,
        surge_test_voltage: "",
        off_load_connection: "Star",
        off_load_test_voltage: "",
        off_load_test_frequency: "",
        off_load_motor_test_speed: "",
        off_load_current_l1: "",
        off_load_current_l2: "",
        off_load_current_l3: "",

        on_load_connection: "Star",
        on_load_test_voltage: "",
        on_load_test_frequency: "",
        on_load_motor_test_speed: "",
        on_load_current_l1: "",
        on_load_current_l2: "",
        on_load_current_l3: "",
        further_information_desc_1: "",
        further_information_desc_2: "",
      },
      isSubmitting: false,
    };
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
  },
  methods: {
    ImageDescUpdated(payload) {
      this.formFields.further_information_desc_1 =
        payload.further_information_desc_1;
      this.formFields.further_information_desc_2 =
        payload.further_information_desc_2;
    },
    Save() {
      console.log(this.formFields);

      this.isSubmitting = true;

      var payload = this.formFields;

      return axios
        .post(
          process.env.VUE_APP_API_PROTOCOL +
            "://" +
            process.env.VUE_APP_API_HOST +
            ":" +
            process.env.VUE_APP_API_PORT +
            "/api/jobs/" +
            this.job.id +
            "/mechanical_data",
          payload
        )
        .then((response) => {
          console.log(response.data);

          var job = response.data;
          if (job && job.id) {
            console.log("Valid Job");
            this.isSubmitting = false;
            this.$store.commit("AddSnackbar", {
              message: "Job updated successfully.",
            });
            this.$router.push("/job/" + this.job.id);
          } else console.log("Invalid Job");
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    ConvertIntsToFloats(property = null, decimal_places = 2) {
      if (property) {
        let value = this.formFields[property];
        value = +value.toFixed(decimal_places) || 0;
        this.formFields[property] = parseFloat(value);
      }
    },
  },
  mounted() {
    if (this.job.mechanical_data) this.formFields = this.job.mechanical_data;
  },
};
</script>

<style>
.mechanical-data-cont {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.mech-data-group-spacing {
  margin-bottom: 60px;
  flex: 0 0 100%;
}

.mechanical-data-tab-content {
  /* overflow: hidden;
  transform: translate3d(0, 0, 0); */
  position: relative;
}

.r-fab-btn {
  position: absolute;
  inset: 0;
  pointer-events: none;
  clip: rect(0, auto, auto, 0);
  clip-path: inset(0 0 0 0);
}

.r-fab-btn > button {
  position: fixed;
  bottom: 36px;
  right: 36px;
  pointer-events: all;
  pointer-events: auto;
}

.mechanical-img {
  width: 100%;
  max-width: 360px;
  display: flex;
  align-self: center;
  margin: 0 auto;
  object-fit: contain;
  object-position: center;
}

@media only screen and (max-width: 1200px) {
  .mechanical-data-scont {
    margin-top: 36px;
  }
}
</style>