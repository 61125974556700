import Axios from "axios"

const state = {
  LabourRates: null,
}
const mutations = {
  SetLabourRates(state, payload) {
    state.LabourRates = payload
  },

}
const actions = {




  fetchLabourRates(context) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/labour_rates"
      ).then((response) => {
        console.log(response.data);
        context.commit("SetLabourRates", response.data)
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },



  UpdateLabourRates(context, payload) {
    console.log("updating single user");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/labour_rates/" + payload.role_id, payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        // context.commit("UpdateRoleItem", response.data);
        // context.commit("SetCurrentSingleRole", response.data);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },





  fetchSingleLabourRate(context, tag_id) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/labour_rates/" + tag_id
      ).then((response) => {
        console.log(response.data);
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },

}
const getters = {
  GetLabourRates(state) {
    return state.LabourRates;
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}