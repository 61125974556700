<template>
  <div class="ma-0 pa-5">
    <div class="d-flex justify-space-between align-center mb-4">
      <h2 class="pa-4 mx-0">
        Viewing Contact: {{ this.CurrentSingleContact.name }}
      </h2>
      <EditContactForm v-if="this.checkPermission('can_edit_contacts')" />
    </div>

    <div
      class="table-cont mx-3"
      v-if="this.CurrentSingleContact && this.CurrentSingleContact.id"
    >
      <div class="table-header pa-3">
        <div class="table-property-label">
          Contact ID: {{ this.CurrentSingleContact.id }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Name</div>
        <div class="table-property-value" style="text-transform: capitalize">
          {{ this.CurrentSingleContact.name }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Company</div>
        <div class="table-property-value r-link" @click="gotoCompany()">
          {{
            this.CurrentSingleContact.company
              ? this.CurrentSingleContact.company.name
              : ""
          }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Phone</div>
        <div class="table-property-value">
          {{ this.CurrentSingleContact.phone }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Fax</div>
        <div class="table-property-value">
          {{ this.CurrentSingleContact.fax }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Mobile</div>
        <div class="table-property-value">
          {{ this.CurrentSingleContact.mobile }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Email</div>
        <div class="table-property-value">
          {{ this.CurrentSingleContact.email }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Notes</div>
        <div class="table-property-value">
          {{ this.CurrentSingleContact.notes }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Time Created</div>
        <div class="table-property-value">
          {{ this.CurrentSingleContact.created }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Time Last Modified</div>
        <div class="table-property-value">
          {{ this.CurrentSingleContact.modified }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditContactForm from "./EditContactForm.vue";
export default {
  name: "SingleContactInfo",
  components: {
    EditContactForm,
  },
  data() {
    return {};
  },
  methods: {
    gotoCompany() {
      var company = this.CurrentSingleContact.company;
      if (company && company.id)
        this.$router.push("/management/customers/" + company.id);
    },
  },
  computed: {
    CurrentSingleContact() {
      return this.$store.getters.GetCurrentSingleContact;
    },
  },
  beforeDestroy() {
    this.$store.commit("SetCurrentSingleContact", null);
  },
};
</script>

<style>
</style>