<template>
  <SingleCompanyInfo
    v-if="
      CurrentSingleCompany &&
      CurrentSingleCompany != null &&
      CurrentSingleCompany.id
    "
  />
</template>

<script>
import SingleCompanyInfo from "../../components/management/companies/SingleCompanyInfo.vue";
export default {
  name: "SingleCustomerPage",
  components: {
    SingleCompanyInfo,
  },
  computed: {
    CurrentSingleCompany() {
      return this.$store.getters.GetCurrentSingleCompany;
    },
  },
  mounted() {
    if (this.$store.getters.GetCurrentSingleCompany == null) {
      this.$store.dispatch("fetchSingleCompany", this.$route.params.company_id);
    }
  },

  beforeDestroy() {
    this.$store.commit("SetCurrentSingleCompany", null);
  },
};
</script>

<style>
</style>