var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-end mb-8"},[_c('div',{staticClass:"d-flex flex-column pa-4 mx-0"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Filter")]),_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"solo":"","hide-details":"","placeholder":"Type to filter...","clearable":"","disabled":!this.ContactList.length > 0 && !this.CompanyID},on:{"click:clear":function($event){_vm.filter = ''}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),(this.checkPermission('can_add_contacts'))?_c('AddContactForm',{attrs:{"company_id":_vm.CompanyID}}):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filtered_contact_list,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"calculate-widths":"","hide-default-footer":"","loading":!this.ContactList.length > 0 && !this.CompanyID,"loading-text":"Loading... Please wait"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.include_cc_quotation",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.include_cc_quotation),callback:function ($$v) {_vm.$set(item, "include_cc_quotation", $$v)},expression:"item.include_cc_quotation"}})]}},{key:"item.include_cc_delivery",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.include_cc_delivery),callback:function ($$v) {_vm.$set(item, "include_cc_delivery", $$v)},expression:"item.include_cc_delivery"}})]}},{key:"item.company_name",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.Company))])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.VisitItem(item)}}},[_vm._v(" mdi-eye ")]),(_vm.checkPermission('can_delete_contacts'))?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.DeleteModalActive(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),(this.checkPermission('can_delete_contacts'))?_c('ConfirmationDialog',{attrs:{"loader":_vm.is_deleting,"itemName":'contact'},on:{"cancelled":function($event){_vm.deleteModal = false;
      _vm.contact_to_delete = null;},"confirmed":function($event){return _vm.deleteContact()}},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }