<template>
  <!-- RIYAD START HERE, THESE ARE COPIED - MAKE THEM ENG. Quotation -->
  <v-col class="px-5" md="12" sm="12" cols="12">
    <div class="table-cont">
      <div class="table-header pa-3">
        <div class="table-property-label">Engineer Notes</div>
      </div>

      <div class="table-row d-flex" style="white-space: break-spaces">
        {{ this.notes ? this.notes : "No notes" }}
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "EngNotes",

  computed: {
    notes() {
      return this.$store.getters.getCurrentSingleJob.engNotes;
    },
  },

  methods: {},
};
</script>

<style>
</style>