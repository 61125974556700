<template>
  <v-dialog
    ref="confirmation_dialog"
    v-model="show"
    width="400px"
    :persistent="this.loader"
  >
    <v-card class="pa-4">
      <p class="mb-3">
        Are you sure you want to delete this {{ this.itemName }}?
      </p>
      <div class="d-flex justify-space-between align-center">
        <v-btn
          @click="$emit('cancelled', true)"
          text
          color="error"
          :disabled="this.loader"
          >Cancel</v-btn
        >
        <v-btn
          @click="$emit('confirmed')"
          color="error"
          :loading="this.loader"
          :disabled="this.loader"
          >Delete</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    value: Boolean,
    loader: Boolean,
    itemName: {
      type: String,
      default: "company",
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>