<template>
  <div class="d-flex flex-column pa-4 ma-0">
    <RolesTable />
  </div>
</template>

<script>
import RolesTable from "../../components/management/roles/RolesTable.vue";
export default {
  name: "Roles",
  components: {
    RolesTable,
  },
  mounted() {
    this.$store.dispatch("fetchDepartments");
  },
};
</script>

<style>
</style>