<template>
  <div class="pa-4">
    <div>can see col 1 - {{ this.compounded["col_1"] }}</div>
    <div>can see col 2 - {{ this.compounded["col_2"] }}</div>
    <div>can see col 3 - {{ this.compounded["col_3"] }}</div>
    <div>can see col 4 - {{ this.compounded["col_4"] }}</div>
    <div>can see col 5 - {{ this.compounded["col_5"] }}</div>
    <div>can see col 6 - {{ this.compounded["col_6"] }}</div>
  </div>
</template>

<script>
export default {
  name: "CompoundedPermissionsTable",
  data() {
    return {
      compounded: {
        col_1: false,
        col_2: false,
        col_3: false,
        col_4: false,
        col_5: false,
        col_6: false,
      },
    };
  },
  methods: {
    // get_permission_compounded($key) {},
    CompoundPerms() {
      var perms = this.CurrentSingleUser.permissions; //array = [[true, false, false], [false, false, false], [false, false, true]];
      var keys = Object.keys(this.compounded);
      keys.forEach((v) => (this.compounded[v] = false));
      if (perms.length > 0) {
        for (let i = 0; i < perms.length; i++) {
          //loop through perms, each perm is an obj of individual booleans
          var perm_obj = perms[i];
          console.log(i);
          console.log(perm_obj);
          keys.forEach((single_perm) => {
            if (!this.compounded[single_perm] && perm_obj[single_perm] == true)
              // if its false
              this.compounded[single_perm] = perm_obj[single_perm];
            // console.log(single_perm + " - " + perm_obj[single_perm]);
          });
        }
      }
    },
  },
  computed: {
    CurrentSingleUser() {
      return this.$store.getters.GetCurrentSingleUser;
    },

    CurrentSingleUserPerms() {
      return this.$store.getters.GetCurrentSingleUser.permissions;
    },
  },
  watch: {
    CurrentSingleUserPerms() {
      this.CompoundPerms();
    },
  },
};
</script>

<style>
</style>