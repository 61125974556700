<template>
  <!-- RIYAD START HERE, THESE ARE COPIED - MAKE THEM ENG. Quotation -->
  <v-col class="px-5" v-if="this.job" md="3" cols="12">
    <div class="table-cont">
      <div
        class="white--text pa-3 d-flex align-center justify-space-between"
        v-bind:class="{
          'normal-priority': job.priority_id <= 2,
          'high-priority': job.priority_id == 3,
          'emergency-priority': job.priority_id == 4,
        }"
      >
        <div class="table-property-label">Job: {{ this.job.id }}</div>
        <JobReportEditModal />
        <!-- <v-btn @click="JobReportModal = true">Edit</v-btn> -->
      </div>

      <div class="table-row long-text-row">
        <div class="table-property-label">Fault</div>
        <div class="table-property-value">
          {{ this.job.fault }}
        </div>
      </div>
      <div class="table-row long-text-row">
        <div class="table-property-label">Root Cause Analysis</div>
        <div class="table-property-value">{{ this.job.faultdetails }}</div>
      </div>

      <div class="table-row long-text-row">
        <div class="table-property-label">Repairs</div>
        <div class="table-property-value">{{ this.job.repairs }}</div>
      </div>

      <div class="table-row long-text-row">
        <div class="table-property-label">Final Test</div>
        <div class="table-property-value">{{ this.job.finaltest }}</div>
      </div>
    </div>
  </v-col>
</template>

<script>
import JobReportEditModal from "./JobReportEditModal.vue";
export default {
  name: "JobReportSummary",

  components: {
    JobReportEditModal,
  },

  data() {
    return {
      JobReportModal: false,
    };
  },

  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
  },

  methods: {},
};
</script>

<style>
</style>