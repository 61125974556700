<template>
  <div>
    <v-form
      ref="LabourRatesForm"
      v-model="valid"
      @submit.prevent="Submit"
      v-if="departments"
    >
      <v-row class="pa-4 ma-0">
        <v-col cols="12" sm="6" class="py-0 px-4">
          <div v-for="(labourRate, key) in formFields" :key="labourRate.tag_id">
            <!-- <h4 class="mb-2">{{ key }} Rate - {{ labourRate.tag_id }}</h4> -->
            <h4 class="mb-2" style="text-transform: capitalize">
              {{ key }} Rate
            </h4>
            <v-text-field
              solo
              v-model.number="formFields[key].rate"
              :name="labourRate.tag_id + '-' + key"
            ></v-text-field>
          </div> </v-col
      ></v-row>

      <v-row align="start" justify="end" class="mb-4 pa-6 pt-0">
        <v-btn
          :disabled="!departments || isFetchingData || isSubmitting"
          class="success ma-0 mr-6"
          type="submit"
          :loading="valid && isSubmitting"
          >Save Rates</v-btn
        >
      </v-row></v-form
    >
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      valid: false,
      isFetchingData: false,
      isSubmitting: false,
      formFields: {},
    };
  },

  methods: {
    validate() {
      return this.$refs.LabourRatesForm.validate();
    },
    Submit() {
      console.log(this.formFields);
      console.log(this.validate());
      this.isSubmitting = true;

      var payload = { ...this.formFields };

      return axios
        .post(
          process.env.VUE_APP_API_PROTOCOL +
            "://" +
            process.env.VUE_APP_API_HOST +
            ":" +
            process.env.VUE_APP_API_PORT +
            "/api/labour_rates",
          payload
        )
        .then((response) => {
          console.log(response.data);
          this.$store.commit("SetLabourRates", response.data);

          this.isSubmitting = false;

          this.$store.commit("AddSnackbar", {
            message: "Labour rates saved successfully.",
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    initFormFields() {
      let temp2 = {
        default: {
          tag_id: null,
          name: "default",
          rate: 60,
        },
      };

      let temp = {};
      for (let i = 0; i < this.departments.length; i++) {
        temp = {};

        const department = this.departments[i];
        const isObjectPresent = this.rates.find(
          (rate) => department.id === rate.tag_id
        );
        let value = 60;
        let tag_id = "";

        if (isObjectPresent) {
          value = isObjectPresent.rate;
        }
        tag_id = department.id;

        temp["tag_id"] = tag_id;
        temp["name"] = department.name;
        temp["rate"] = value;

        temp2[department.name.toLowerCase()] = temp;
      }

      // this.$set(this.formFields, temp);
      this.formFields = { ...temp2 };
    },
  },

  mounted() {
    this.isFetchingData = true;

    Promise.all([
      this.$store.dispatch("fetchLabourRates"),
      this.$store.dispatch("fetchDepartments"),
    ]).then(() => {
      this.isFetchingData = false;
    });
  },
  computed: {
    departments() {
      return this.$store.getters.getDepartmentList;
    },
    rates() {
      return this.$store.getters.GetLabourRates;
    },
  },

  watch: {
    departments() {
      this.initFormFields();
    },
  },
};
</script>

<style>
</style>