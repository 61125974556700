<template>
  <v-dialog v-model="show" width="500" :persistent="this.is_loading">
    <v-container class="pa-0">
      <v-card>
        <AddJobPartForm @closeModal="closeModal()" />
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import AddJobPartForm from "../../forms/AddJobPartForm";
export default {
  name: "AddPartModal",
  components: {
    AddJobPartForm,
  },
  props: {
    value: Boolean,
    bin: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      new_bin: "",
      is_loading: false,
    };
  },
  methods: {
    save_bin() {
      this.is_loading = true;
    },
    closeModal() {
      this.show = false;
    },
  },
};
</script>

<style>
</style>