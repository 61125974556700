
import Axios from "axios"

const state = {
  common_phrases: [],
  current_phrase: null,
}
const mutations = {
  SetCommonPhrases(state, payload) {
    state.common_phrases = payload
  },

  UpdateCommonPhrase(state, payload) {
    const index = state.common_phrases.findIndex(role => role.id == payload.id);
    if (index >= 0)
      state.common_phrases.splice(index, 1, payload);
  },

  SetCurrentPhrase(state, payload) {
    state.current_phrase = payload
  },

  AddCommonPhrase(state, payload) {
    state.common_phrases.push(payload)
  },

  RemovePhraseFromList(state, payload) {
    state.common_phrases.splice(state.common_phrases.findIndex(function (phrase) {
      return phrase.id === payload;
    }), 1);
  },
}
const actions = {




  fetchCommonPhrases(context) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/common_phrases"
      ).then((response) => {
        console.log(response.data);
        context.commit("SetCommonPhrases", response.data)
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },



  UpdateCommonPhrase(context, payload) {
    console.log("updating single user");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.put(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/common_phrases/" + payload.id, payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        context.commit("UpdateCommonPhrase", response.data);
        // context.commit("SetCurrentSingleRole", response.data);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },





  AddCommonPhrase(context, payload) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/common_phrases", payload
      ).then((response) => {
        console.log(response.data);

        context.commit("AddCommonPhrase", response.data)
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },




  DeleteCommonPhrase(context, payload) {
    console.log("Adding new department");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/common_phrases/" + payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        context.commit("RemovePhraseFromList", response.data);

        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },

}
const getters = {
  GetCommonPhrases(state) {
    return state.common_phrases;
  },

  GetCurrentCommonPhrase(state) {
    return state.current_phrase;
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}