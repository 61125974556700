<template>
  <v-row class="ma-0 pa-4">
    <v-col cols="12" sm="6" class="mb-3">
      <v-form
        ref="LoginFormRef"
        v-model="valid"
        lazy-validation
        @submit.prevent="validate"
      >
        <h4 class="mb-2">Email</h4>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
          solo
        ></v-text-field>

        <h4 class="mb-2">Password</h4>

        <v-text-field
          solo
          v-model="password"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="showPass ? 'text' : 'password'"
          name="password"
          label="Normal with hint text"
          hint="At least 8 characters"
          counter
          @click:append="showPass = !showPass"
        ></v-text-field>

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          type="submit"
          :loading="logging_in"
        >
          Sign In
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
// import Axios from "axios";
export default {
  name: "LoginForm",
  data() {
    return {
      password: "",
      email: "",
      showPass: false,
      valid: true,
      logging_in: false,

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },

  methods: {
    validate() {
      const validation = this.$refs.LoginFormRef.validate();
      if (validation) {
        this.logging_in = true;
        console.log("Valid login now");
        this.$store
          .dispatch("userLogin", {
            email: this.email,
            password: this.password,
          })
          .then((res) => {
            console.log(res);
            if (res.status && res.status.toLowerCase() == "failed") {
              this.$store.commit("SetSnackbarData", {
                message: res.message,
                type: "error",
              });
            }
          })
          .finally(() => {
            this.logging_in = false;
          });
      }
    },
    test() {
      this.$store.dispatch("getUserObjFromToken");
    },
    reset() {
      this.$refs.LoginFormRef.reset();
    },
    resetValidation() {
      this.$refs.LoginFormRef.resetValidation();
    },
  },
};
</script>

<style>
</style>