<template>
  <div>
    <v-btn @click="AddPhraseModal()" color="success">Add a Phrase</v-btn>

    <v-dialog
      ref="addPhraseDialog"
      v-model="addPhraseModal"
      :persistent="isSubmitting"
      width="400px"
    >
      <v-card>
        <v-form ref="AddPhraseForm" v-model="valid" @submit.prevent="Submit">
          <v-row class="pa-4 ma-0">
            <v-col cols="12">
              <h4 class="mb-2">Name</h4>
              <v-text-field
                solo
                :rules="[rules.required]"
                v-model="formFields.name"
                name="name"
              ></v-text-field>

              <h4 class="mb-2">Phrase</h4>

              <v-textarea
                solo
                @wheel="$event.target.blur()"
                v-model.number="formFields.phrase"
                name="phrase"
              ></v-textarea>

              <div class="mx-0 mt-3 d-flex justify-space-between">
                <v-btn
                  :disabled="isSubmitting"
                  color="error"
                  text
                  @click="addPhraseModal = false"
                  >Cancel</v-btn
                >

                <v-btn
                  :disabled="!valid || isSubmitting"
                  class="success"
                  type="submit"
                  :loading="isSubmitting"
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AddPhraseForm",
  data() {
    return {
      valid: false,
      isUpdating: false,
      addPhraseModal: false,
      isSubmitting: false,
      formFields: {
        name: "",
        phrase: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 4 || "Min 4 characters",
      },
    };
  },
  methods: {
    AddPhraseModal() {
      this.addPhraseModal = true;
    },
    ResetForm() {
      this.$refs.AddPhraseForm.reset();
      this.formFields.include_cc_quotation = false;
      this.formFields.include_cc_delivery = false;
    },
    Submit() {
      if (this.validate()) {
        this.isSubmitting = true;
        console.log("Editing Department");
        console.log(this.formFields);
        console.log(this.validate());
        this.$store
          .dispatch("AddCommonPhrase", this.formFields)
          .then((new_department) => {
            if (new_department && new_department.id) {
              this.$store.commit("SetSnackbarData", {
                message: "Phrase added successfully.",
              });

              this.addPhraseModal = false;
              this.ResetForm();
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.AddPhraseForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  computed: {},
  mounted() {
    // this.isUpdating = true;
  },
};
</script>

<style>
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.v-application--is-ltr
  .v-input--reverse
  .v-input__slot
  .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}
.v-application--is-rtl
  .v-input--reverse
  .v-input__slot
  .v-input--selection-controls__input {
  margin-left: 0;
  margin-right: 8px;
}

.v-input--expand .v-input__slot .v-label {
  display: block;
  flex: 1;
}
</style>