<template>
  <v-select
    v-if="this.UserObj"
    solo
    class="single-dashboard-filter"
    placeholder="Type user id to search.."
    label="Search by assigned user"
    v-model="assigned_user"
    :items="FilterUserList.concat(fetchedUsers)"
    :loading="fetchingUsers"
    item-text="name"
    item-value="id"
  ></v-select>
</template>

<script>
export default {
  name: "FilterByUserId",
  data() {
    return {
      localStorageKey: "filterByUser",
      FilterUserList: [
        {
          name: "Any",
          id: null,
        },
      ],
      assigned_user: null,
      fetchedUsers: [],
      fetchingUsers: false,
    };
  },
  methods: {
    DeleteLocalStorage() {
      window.localStorage.removeItem(this.localStorageKey);
    },
    GetLocalStorage() {
      return window.localStorage.getItem(this.localStorageKey);
    },
    SetLocalStorage(value) {
      window.localStorage.setItem(this.localStorageKey, value);
    },
  },
  computed: {
    // assigned_user: {
    //   get() {
    //     return this.value;
    //   },
    //   set(value) {
    //     this.DeleteLocalStorage();
    //     this.SetLocalStorage(value);
    //     this.$emit("input", value);
    //     console.log(value);
    //   },
    // },
    UserObj() {
      return this.$store.getters.getCurrentUserObj;
    },
  },
  watch: {
    assigned_user(newValue, oldValue) {
      if (newValue != oldValue) {
        //this.DeleteLocalStorage();
        this.SetLocalStorage(newValue);
        this.$emit("input", newValue);
        //console.log(newValue);
      }
    },
  },
  mounted() {
    this.fetchingUsers = true;
    this.$store
      .dispatch("getFilterUserList")
      .then((res) => {
        this.fetchedUsers = res;
        let localStorageVar = parseInt(this.GetLocalStorage()) || null;

        if (localStorageVar != null) {
          var found = res.findIndex((user) => {
            return user.id == localStorageVar;
          });
          if (found && found != -1) this.assigned_user = localStorageVar;
        } else this.assigned_user = localStorageVar;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.fetchingUsers = false;
      });
  },
};
</script>

<style>
</style>