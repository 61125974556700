<template>
  <JobAdvancing v-if="this.job.id" />
  <LoadingScreen v-else />
</template>

<script>
import LoadingScreen from "../../components/global/LoadingScreen";

import JobAdvancing from "../../components/jobs/singleJob/job-advancing/JobAdvancing.vue";
export default {
  name: "JobAdvancingPage",
  components: {
    JobAdvancing,
    LoadingScreen,
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_id() {
      return this.$route.params.job_id;
    },
  },
  mounted() {
    console.log("Advancing Job page mounted, fetching job info" + this.job_id);
    if (this.job_id && !this.job.id) {
      // this.$store.commit("setCurrentSingleJob", {});
      this.$store.dispatch("fetchSingleJob", this.job_id);
    }
  },
};
</script>

<style>
</style>