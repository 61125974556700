<template>
  <v-row class="pa-4 ma-0">
    <v-dialog v-model="filtersModal" width="400">
      <v-card class="pa-4">
        <SearchForm @submit="SearchJobs" :isSearching="isSearching" />
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-btn color="success" @click="filtersModal = true">Filters</v-btn>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Description</th>
              <th class="text-left">Company</th>
              <th class="text-left">Manufacturer</th>
              <th class="text-left">Model</th>
              <th class="text-left">Serial</th>
              <th class="text-left" v-if="checkPermission('can_view_sales_quotations')">
                Sales Quote
              </th>
              <th class="text-left">Booked</th>
              <th class="text-left">Status</th>
              <th class="text-left">Order Number</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="result in searchResults" :key="result.id">
              <td @click="gotoJob(result.id)" class="result-link">
                {{ result.id }}
              </td>
              <td>{{ result.description }}</td>
              <td>
                {{ result.company && result.company.name ? result.company.name : 'N/A' }}
              </td>
              <td>{{ result.manufacturer }}</td>
              <td>{{ result.machine_model }}</td>
              <td>{{ result.serial }}</td>
              <td v-if="checkPermission('can_view_sales_quotations')">
                {{ result.salQuote | toCurrency }}
              </td>
              <td style="white-space: nowrap">{{ result.date_booked }}</td>
              <td>{{ getJobStatusName(result.status_id) }}</td>
              <td>{{ result.order_number }}</td>
              <td>
                <v-btn @click="openNewTab(result.id)" color="primary">View</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import Axios from 'axios';

import SearchForm from '../../components/jobs/search/SearchForm.vue';
export default {
  name: 'JobSearch',
  data() {
    return {
      isSearching: false,
      searchResults: null,
      filtersModal: false,
    };
  },
  components: {
    SearchForm,
  },

  methods: {
    gotoJob(id) {
      // this.$store.commit("SetSnackbarData", {
      //   message: "Not Implemented Yet - Go To Job",
      //   type: "warning",
      // });

      // console.log(id);
      this.$router.push('/job/' + id);
    },
    openNewTab(id) {
      if (!id) return;
      window.open('/job/' + id, '_blank');
    },
    getJobStatusName(status_id) {
      return this.$store.getters.get_status(status_id);
    },
    SearchJobs(payload) {
      this.isSearching = true;
      console.log('search jobs store action with payload');
      console.log(payload);

      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/jobs/search',
        payload
      )
        .then((response) => {
          console.log(response.data);
          // context.commit("setJobList", response.data);
          this.searchResults = response.data;
          this.filtersModal = false;
        })
        .catch(function(err) {
          console.log(err);
        })
        .finally(() => {
          this.isSearching = false;
        });
    },
  },
};
</script>

<style>
.result-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.result-link {
  cursor: pointer;
  transition-property: auto !important;
  transition-property: all !important;
}

.result-link:hover {
  background: #cacaca;
}
</style>
