<template>
  <div style="width: 100%" class="winding-details-tab-content pb-6">
    <div class="winding-details-cont">
      <v-row class="px-5 ma-0">
        <v-col cols="12" md="12">
          <h2 class="text-center">Winding Details</h2>
        </v-col>

        <v-col cols="12" md="12">
          <v-divider class="my-8"></v-divider>
        </v-col>

        <v-col cols="12" md="12">
          <div class="r-columns-4">
            <div>
              <h4>Customer</h4>
              <span>{{ this.job.company.name || "-" }}</span>
            </div>
            <div>
              <h4>Job ID</h4>
              <span>{{ this.job.id }}</span>
            </div>
            <div>
              <h4>Manufacturer</h4>
              <span>{{ this.job.manufacturer || "-" }}</span>
            </div>

            <div>
              <h4>Serial Number</h4>
              <span>{{ this.job.serial || "-" }}</span>
            </div>

            <div>
              <h4>Poles</h4>
              <v-text-field
                solo
                v-model="formFields.poles"
                name="poles"
                @change="Save"
              ></v-text-field>
            </div>
          </div>

          <v-col cols="12" md="12">
            <v-divider class="my-8"></v-divider>
          </v-col>

          <!-- Titles -->
          <div class="r-columns-3 winding-details-row mb-6">
            <span></span>
            <h4 class="text-center">Existing/Stator</h4>
            <h4 class="text-center">New or Rotor</h4>
          </div>
          <!-- Fields -->
          <div
            class="r-columns-3 winding-details-row"
            v-for="(row, key) in fieldLoopData"
            :key="key"
          >
            <span>{{ key + ":" }}</span>
            <v-text-field
              solo
              v-model="formFields['existing_' + row.var_name]"
              :name="'existing_' + row.var_name"
              :suffix="row.suffix"
              :prefix="row.prefix"
              @change="Save"
            ></v-text-field>

            <v-text-field
              solo
              v-model="formFields['new_' + row.var_name]"
              :name="'new_' + row.var_name"
              :suffix="row.suffix"
              :prefix="row.prefix"
              @change="Save"
            ></v-text-field>
          </div>
        </v-col>
        <!-- <span>{{ formFields }}</span> -->
        <!-- <v-col cols="12" md="12">
          <v-divider class="my-8"></v-divider>
        </v-col> -->
      </v-row>

      <h3 class="mt-6">Current Images</h3>

      <div v-if="winding_images.length > 0" class="">
        <div class="winding-details-images-cont">
          <div
            v-for="img in winding_images"
            :key="img.id"
            class="d-flex flex-column"
          >
            <img
              :src="bases[0] + img.filename + '?rand=' + rand()"
              alt=""
              class="job-report-img"
            />
            <v-btn
              @click="DeleteImg(img.id)"
              :loading="isDeleting && isDeleting == img.id"
              color="error"
              :disabled="isDeleting && isDeleting == img.id"
              class="mx-auto mt-4"
              >Delete</v-btn
            >
          </div>
        </div>
      </div>
      <div v-else>No winding details images attached yet.</div>

      <ImageUploader ref="winding_uploader" class="mt-6" />

      <v-btn
        @click="Save()"
        :loading="isSubmitting"
        color="success"
        :disabled="isSubmitting"
        class=""
        >Save</v-btn
      >

      <div class="r-fab-btn">
        <v-btn
          fab
          @click="Save()"
          :loading="isSubmitting"
          color="success"
          :disabled="isSubmitting"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
// import MechanicalDataJobPhotos from "./MechanicalDataJobPhotos.vue";
// import JobReportEditModal from "../tab6/JobReportEditModal.vue";

import axios from "axios";
import ImageUploader from "./ImageUploader.vue";

export default {
  name: "WindingDetails",
  components: {
    // MechanicalDataJobPhotos,
    // JobReportEditModal,
    ImageUploader,
  },
  data() {
    return {
      bases: [
        //   "https://www.neutronic.biz/img/jobs/",
        process.env.VUE_APP_API_PROTOCOL +
          "://" +
          process.env.VUE_APP_API_HOST +
          ":" +
          process.env.VUE_APP_API_PORT +
          "/storage/images/",
      ],
      fieldLoopData: {
        "Core Length": { var_name: "core_length", suffix: "", prefix: "" },
        "Core Diameter": { var_name: "core_dia" },
        Slots: { var_name: "slots" },
        Coils: { var_name: "coils" },
        "Lead End is D.E / N.D.E": { var_name: "lead_end" },
        "Projection D.E": { var_name: "projection_de" },
        "Projection N.D.E": { var_name: "projection_nde" },
        "Type of Winding": { var_name: "winding_type" },
        "Lead Length": { var_name: "lead_length" },
        "Number of Leads out": { var_name: "leads_out" },
        "Number of Parallels": { var_name: "parallels" },
        Connections: { var_name: "connections" },
        "Sect/Coil": { var_name: "sect_per_coil" },
        "Winding Machine Measurement": {
          var_name: "winding_machine_measurement",
        },
        "Wire Size (main/run wndg)": { var_name: "wire_size" },
        "Coil Pitch": { var_name: "coil_pitch" },
        "Turns/Coil": { var_name: "turns_per_coil" },
        "Weight of Wire": { var_name: "wire_weight" },
        "Slots Per Pole Per Phase": { var_name: "slots_per_pole_per_phase" },
        "Coil Groups": { var_name: "coil_groups" },
        "Start Wndg Wire Size": { var_name: "start_winding_wire_size" },
        "Start Wndg Pitch": { var_name: "start_winding_pitch" },
        "Thermistor/Sensor Type": { var_name: "thermistor_type" },
        "Thermistor/Sensor Position": { var_name: "thermistor_position" },
        "Band details SWG": { var_name: "band_details_swg" },
      },
      formFields: {
        poles: "",
        existing_core_length: "",
        existing_core_dia: "",
        existing_slots: "",
        existing_coils: "",
        existing_lead_end: "",
        existing_projection_de: "",
        existing_projection_nde: "",
        existing_winding_type: "",
        existing_lead_length: "",
        existing_leads_out: "",
        existing_parallels: "",
        existing_connections: "",
        existing_sect_per_coil: "",
        existing_winding_machine_measurement: "",
        existing_wire_size: "",
        existing_coil_pitch: "",
        existing_turns_per_coil: "",
        existing_wire_weight: "",
        existing_slots_per_pole_per_phase: "",
        existing_coil_groups: "",
        existing_start_winding_wire_size: "",
        existing_start_winding_pitch: "",
        existing_thermistor_type: "",
        existing_thermistor_posiiton: "",
        existing_band_details_swg: "",
        new_core_length: "",
        new_core_dia: "",
        new_slots: "",
        new_coils: "",
        new_lead_end: "",
        new_projection_de: "",
        new_projection_nde: "",
        new_winding_type: "",
        new_lead_length: "",
        new_leads_out: "",
        new_parallels: "",
        new_connections: "",
        new_sect_per_coil: "",
        new_winding_machine_measurement: "",
        new_wire_size: "",
        new_coil_pitch: "",
        new_turns_per_coil: "",
        new_wire_weight: "",
        new_slots_per_pole_per_phase: "",
        new_coil_groups: "",
        new_start_winding_wire_size: "",
        new_start_winding_pitch: "",
        new_thermistor_type: "",
        new_thermistor_posiiton: "",
        new_band_details_swg: "",
      },
      isSubmitting: false,
      isDeleting: false,
    };
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    winding_images() {
      if (!this.job || !this.job.images) return [];
      return this.job.images.filter((img) => {
        return img.usage === "winding";
      });
    },
  },
  methods: {
    DeleteImg(img_id) {
      this.isDeleting = img_id;
      return this.$store
        .dispatch("deleteJobImage", {
          job_id: this.job.id,
          img_id: img_id,
        })
        .finally(() => {
          this.isDeleting = false;
        });
    },
    rand() {
      return Date.now();
    },
    ImageDescUpdated(payload) {
      this.formFields.further_information_desc_1 =
        payload.further_information_desc_1;
      this.formFields.further_information_desc_2 =
        payload.further_information_desc_2;
    },
    Save() {
      console.log(this.formFields);

      this.isSubmitting = true;

      var payload = this.formFields;

      return axios
        .post(
          process.env.VUE_APP_API_PROTOCOL +
            "://" +
            process.env.VUE_APP_API_HOST +
            ":" +
            process.env.VUE_APP_API_PORT +
            "/api/jobs/" +
            this.job.id +
            "/winding_details",
          payload
        )
        .then((response) => {
          console.log(response.data);

          var job = response.data;
          if (job && job.id) {
            console.log("Valid Job");

            this.$store.commit("AddSnackbar", {
              message: "Job updated successfully.",
            });
            this.$router.push("/job/" + this.job.id);
          } else console.log("Invalid Job");
        })
        .then(() => {
          return this.$refs.winding_uploader.upload2();
        })
        .then(() => {
          this.$store.dispatch("fetchSingleJob", this.job.id);
        })
        .finally(() => {
          this.isSubmitting = false;
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    // ConvertIntsToFloats(property = null, decimal_places = 2) {
    //   if (property) {
    //     let value = this.formFields[property];
    //     value = +value.toFixed(decimal_places) || 0;
    //     this.formFields[property] = parseFloat(value);
    //   }
    // },
  },
  mounted() {
    if (this.job.winding_details) this.formFields = this.job.winding_details;
  },
};
</script>

<style>
.winding-details-images-cont {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
}

.winding-details-images-cont img {
  width: 100%;
  height: 100%;
  max-height: 600px;
  /* aspect-ratio: 1; */
  object-fit: contain;
}

.winding-details-cont {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.mech-data-group-spacing {
  margin-bottom: 60px;
  flex: 0 0 100%;
}

.winding-details-tab-content {
  /* overflow: hidden;
  transform: translate3d(0, 0, 0); */
  position: relative;
}

.r-fab-btn {
  position: absolute;
  inset: 0;
  pointer-events: none;
  clip: rect(0, auto, auto, 0);
  clip-path: inset(0 0 0 0);
}

.r-fab-btn > button {
  position: fixed;
  bottom: 36px;
  right: 36px;
  pointer-events: all;
  pointer-events: auto;
}

.mechanical-img {
  width: 100%;
  max-width: 360px;
  display: flex;
  align-self: center;
  margin: 0 auto;
  object-fit: contain;
  object-position: center;
}

.r-columns-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.r-columns-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;
}

@media only screen and (max-width: 1200px) {
  .winding-details-scont {
    margin-top: 36px;
  }
}
</style>