var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0 pa-4"},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"3"}},[_c('div',{staticClass:"dates-container"},[_c('v-btn',{staticClass:"mb-3",staticStyle:{"grid-column":"span 2"},attrs:{"to":"/reports/monthly_sales/","exact":"","outlined":""}},[_vm._v("View All")]),_vm._l((_vm.datesNav),function(date_obj){return _c('v-btn',{key:date_obj.date,staticClass:"mb-3",attrs:{"to":'/reports/monthly_sales/' + date_obj.date,"outlined":""}},[_vm._v(_vm._s(date_obj.label))])})],2)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"9"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"\n          d-flex\n          justify-space-between\n          mb-9\n          flex-column flex-sm-row\n          align-center\n        "},[_c('h2',{staticClass:"page-header"},[_vm._v("Monthly Sales By Sales Rep")]),_c('v-btn',{staticClass:"mt-sm-0 mt-4",attrs:{"loading":this.is_loading,"disabled":this.is_loading},on:{"click":function($event){return _vm.DownloadPDF('monthly_sales_by_rep/pdf' + _vm.urlParamPdfEndpoint)}}},[_vm._v("Download as PDF")])],1),_c('div',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.jobs,"items-per-page":-1,"group-by":"sales_rep_name","loading":_vm.is_loading,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":'/job/' + item.job_id,"color":"primary"}},[_vm._v("View")])]}},{key:"group",fn:function(ref){
var items = ref.items;
return [_c('tr',{attrs:{"colspan":"5"}},[_c('td',{staticClass:"grey lighten-2",staticStyle:{"font-weight":"500"},attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(items[0] && items[0].sales_rep_name ? items[0].sales_rep_name : "House accounts")+" ")])]),_vm._l((items),function(item){return _c('tr',{key:item.company_id,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.gotoCompany(item.company_id)}}},_vm._l((_vm.headers),function(header){return _c('td',{key:item.company_id + ' - ' + header.value},[(
                    header.value != 'Total' &&
                    header.value != 'average_job_value' &&
                    header.value != 'number_of_jobs' &&
                    header.value != 'user'
                  )?[_vm._v(" "+_vm._s(header.value != "" && item[header.value] != "" ? item[header.value] : "-")+" ")]:_vm._e(),(header.value == 'number_of_jobs')?[_vm._v(" "+_vm._s(item[header.value])+" ")]:_vm._e(),(header.value == 'Total')?[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item[header.value]))+" ")]:_vm._e(),(header.value == 'average_job_value')?[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item["average_job_value"]))+" ")]:_vm._e(),(header.value == 'user')?[_vm._v(" "+_vm._s(item["user"] != null ? item["user"].name : "")+" ")]:_vm._e()],2)}),0)}),(items && items.length > 0)?_c('tr',{},[_c('td'),_c('td',{attrs:{"colspan":"2"}},[_c('p',{staticClass:"ma-0"},[_vm._v(" Total Jobs: "),_c('strong',[_vm._v(_vm._s(_vm.CalculateCountTotal(items)))])])]),_c('td',{attrs:{"colspan":"2"}},[_c('strong',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.CalculateJobsAssignedTotal(items))))])])]):_vm._e()]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',[_c('strong',[_vm._v("Total Count: "),_c('strong',[_vm._v(_vm._s(_vm.CalculateCountTotal(_vm.jobs)))])])]),_c('td'),_c('td',[_c('strong',[_vm._v("Total Spend")])]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.CalculateJobsAssignedTotal(_vm.jobs))))])]),_c('td')])])],2),_c('div')],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }