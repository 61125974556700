<template>
  <v-row class="ma-0 pa-4">
    <v-col cols="12" sm="3">
      <v-autocomplete
        v-model="selected_company"
        :disabled="is_loading"
        :items="companyList"
        solo
        color="blue-grey lighten-2"
        label="Select Company"
        item-text="name"
        item-value="id"
        hide-no-data
        hide-selected
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            @click="data.select"
          >
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="9" class="d-flex flex-column">
      <MutiJobDeliveryForm
        v-model="dialog"
        :job_IDs="this.job_IDs"
        @delivered="onDelivered()"
      />
      <v-btn
        class="ml-auto mb-5"
        :disabled="!selected_jobs.length > 0"
        @click="dialog = true"
        >Deliver Jobs</v-btn
      >
      <v-data-table
        v-model="selected_jobs"
        :headers="headers"
        :items="jobs"
        :items-per-page="-1"
        class="elevation-1"
        style="width: 100%"
        :loading="is_loading"
        show-select
      >
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import MutiJobDeliveryForm from "../../components/jobs/forms/MutiJobDeliveryForm.vue";
export default {
  name: "Deliveries",
  components: {
    MutiJobDeliveryForm,
  },
  data() {
    return {
      is_loading: false,
      dialog: false,
      selected_jobs: [],
      jobs: [],
      selected_company: "",
      headers: [
        {
          text: "Job",
          align: "start",
          sortable: false,
          value: "id",
        },
        //{ text: "Invoice Date", value: "invDate" },
        { text: "Contact", value: "contact.name" },
        { text: "Mobile", value: "contact.mobile" },
        { text: "Description", value: "description" },
        /*   { text: "Manufacturer", value: "manufacturer" },
        { text: "Model", value: "model" },*/
      ],
    };
  },
  methods: {
    onDelivered() {
      console.log("delivered");
      this.dialog = false;
      this.selected_jobs = [];
      this.GetCompanyJobs();
    },
    GetCompanyJobs() {
      this.is_loading = true;
      this.jobs = [];
      this.$store
        .dispatch("GetCompanyJobs", this.selected_company)
        .then((jobs) => {
          this.jobs = jobs;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
  computed: {
    companyList() {
      return this.$store.getters.getCompanyList;
    },
    job_IDs() {
      return this.selected_jobs.map((job) => job.id);
    },
  },
  mounted() {
    this.is_loading = true;
    this.jobs = [];
    this.$store.dispatch("fetchCompanyList").then(() => {
      this.is_loading = false;
    });
  },
  watch: {
    selected_company(newVal, oldVal) {
      if (newVal != oldVal && newVal != null) {
        //
        this.GetCompanyJobs();
      }
    },
  },
};
</script>

<style>
</style>