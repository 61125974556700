<template>
  <div style="width: 100%" class="d-flex flex-column">
    <VueSignaturePad
      width="100%"
      height="300px"
      id="signature_pad"
      ref="signaturePad"
      :options="{
        onBegin: () => {
          $refs.signaturePad.resizeCanvas();
        },
      }"
    />
    <div class="d-flex justify-space-around">
      <v-btn type="button" @click.stop.prevent="cancel" class="error--text"
        >Cancel</v-btn
      >
      <v-btn type="button" @click.stop.prevent="undo" class="warning--text"
        >Undo</v-btn
      >
      <v-btn type="button" @click.stop="save" class="success--text"
        >Confirm</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "MySignaturePad",
  props: ["svg_sig", "SignatureDialogValue"],

  computed: {},
  methods: {
    RenderPreviousSig() {
      const sig = this.svg_sig;
      console.log(sig);
      if (sig) this.$refs.signaturePad.fromDataURL(sig);
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    cancel() {
      // const { isEmpty, data } = this.$refs.signaturePad.saveSignature(
      //   "image/svg+xml"
      // );
      // console.log(data);
      // if (!isEmpty)
      this.$refs.signaturePad.clearSignature();
      this.$emit("SignatureModalClosed", true);
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature(
        "image/svg+xml"
      );
      if (!isEmpty) {
        // this.$store.dispatch("SaveSignature", {
        //   svg_signature: data,
        // });
        this.$emit("SignatureConfirmed", data);
        this.$emit("SignatureModalClosed", true);
      }

      console.log(isEmpty);
      console.log(data);
    },
  },
  mounted() {
    console.log("aaaaaa");
  },

  watch: {
    SignatureDialogValue: {
      handler(newVal, oldVal) {
        if (this.svg_sig && newVal == true && newVal != oldVal) {
          this.RenderPreviousSig();
          console.log("bbbbbbb");
        }
      },
    },
  },
};
</script>

<style>
</style>