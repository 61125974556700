import Axios from "axios"

const state = {

  isSearchingComponents: false,

}
const mutations = {
  setIsSearchingComponents(state, payload) {
    state.isSearchingComponents = payload
  }
}
const actions = {


  SearchComponents(context, payload) {
    console.log("search jobs store action with payload");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);
    context.commit("setIsSearchingComponents", true)
    if (token != "")
      return Axios
        .post(
          process.env.VUE_APP_API_PROTOCOL +
          "://" +
          process.env.VUE_APP_API_HOST +
          ":" +
          process.env.VUE_APP_API_PORT +
          "/api/components/search",
          payload
        )
        .then((response) => {
          console.log(response.data);


          return response.data;
        })

        .catch(function (err) {
          console.log(err);

          context.commit("SetSnackbarData", {
            message: "Error occured while searching for components",
            timeout: 300000,
            type: "error",
          });
          return Promise.reject(err);
        })

  },

}
const getters = {
  getIsSearchingComponents(state) {
    return state.isSearchingComponents;
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}