import Axios from "axios"

const state = {
  departments: [],

}
const mutations = {
  PushDepartmentToEnd(state, payload) {
    state.departments.push(payload)
  },
  setDepartmentsList(state, payload) {
    state.departments = payload
  },
  RemoveDepartmentFromList(state, payload) {
    state.departments.splice(state.departments.findIndex(function (department) {
      return department.id === payload;
    }), 1);
  },
}
const actions = {

  fetchDepartments(context) {
    console.log("getting company list");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/tags"
      ).then(function (response) {
        console.log(response.data);

        context.commit("setDepartmentsList", response.data);
        return response.data;
      })
        .catch(function (err) {
          console.log(err);
        });
  },


  AddSingleDepartment(context, payload) {
    console.log("Adding new department");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/tags", payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },




  UpdateSingleDepartment(context, payload) {
    console.log("Adding new department");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/tags/" + payload.id, payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        context.commit("setDepartmentsList", response.data);

        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },



  DeleteSingleDepartment(context, payload) {
    console.log("Adding new department");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/tags/" + payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        context.commit("RemoveDepartmentFromList", response.data);

        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },

}
const getters = {
  getDepartmentList(state) {
    return state.departments;
  }

}

export default {
  state,
  getters,
  mutations,
  actions
}