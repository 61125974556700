<template>
  <SingleUserInfo v-if="CurrentSingleUser != null && CurrentSingleUser.id" />
</template>

<script>
import SingleUserInfo from "../../components/management/users/SingleUserInfo.vue";
export default {
  name: "SingleUserPage",
  components: { SingleUserInfo },
  computed: {
    CurrentSingleUser() {
      return this.$store.getters.GetCurrentSingleUser;
    },
  },
  created() {
    if (this.$store.getters.GetCurrentSingleUser == null) {
      this.$store.dispatch("fetchSingleUser", this.$route.params.user_id);
    }
  },
};
</script>

<style>
</style>