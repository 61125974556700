<template>
  <div class="stat-card">
    <h3 class="mb-3" v-if="this.cardTitle">{{ this.cardTitle }}</h3>
    <div
      class="stat-card-content d-flex flex-row justify-space-between align-center"
    >
      <div v-if="this.label">{{ this.label }}</div>
      <div
        class="cursor-pointer stat-value"
        @click="clickStat()"
        v-if="this.value"
      >
        {{ this.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatCard",
  props: ["label", "value", "linkTo", "cardTitle"],
  methods: {
    clickStat() {
      if (this.linkTo) this.$router.push(this.linkTo);
    },
  },
};
</script>

<style>
.stat-card {
  border-radius: 5px;
  border: 1px solid lightgray;
}

.stat-card > * {
  padding: 14px;
}

.stat-card h3 {
  width: 100%;
  background: lightgray;
}

.stat-value {
  padding: 12px;
  font-size: 24px;
  border-radius: 7px;
  background-color: lightskyblue;
  color: white;
}
</style>