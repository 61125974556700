<template>
  <div>
    <apexchart
      type="bar"
      width="100%"
      :options="conversions.chartOptions"
      :series="conversions.series"
      v-if="conversions.series[0].data.length > 0"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "ConversionsChart",

  data() {
    return {
      conversions: {
        series: [
          {
            name: "",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          legend: {
            onItemClick: {
              toggleDataSeries: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: "top", // top, center, bottom
                hideOverflowingLabels: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              var total = 0;
              var dataPoint = opts.dataPointIndex;
              console.log(opts);
              if (opts.seriesIndex == 0) return;
              for (let i = 0; i < opts.w.config.series.length; i++) {
                if (opts.w.config.series[i])
                  total =
                    total + parseInt(opts.w.config.series[i].data[dataPoint]);
              }
              var percent = 100 - (100 * val) / total;
              return Math.round(percent * 10) / 10 + "%";
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },

          xaxis: {
            categories: [],
            position: "top",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val;
              },
            },
          },
          title: {
            text: "",
            floating: true,
            offsetY: 330,
            align: "center",
            style: {
              color: "#444",
            },
          },
        },
      },
    };
  },
  computed: {
    conversions_store_data() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats["jobConversions"]) return Stats["jobConversions"];
      else return [];
    },
  },

  mounted() {
    var conversions_data = {
      "x-axis": [],
      series: [
        {
          name: "No Go Ahead",
          data: [],
        },
        {
          name: "Go Ahead",
          data: [],
        },
      ],
    };

    for (let i = 0; i < this.conversions_store_data.length; i++) {
      conversions_data["x-axis"].push(
        this.conversions_store_data[i]["TheMonth"]
      );
      conversions_data.series[0].data.push(
        this.conversions_store_data[i]["Booked"]
      );
      conversions_data.series[1].data.push(
        this.conversions_store_data[i]["NotBooked"]
      );
    }
    this.conversions.chartOptions.xaxis.categories = conversions_data["x-axis"];
    this.conversions.series = conversions_data.series;
  },
};
</script>

<style>
</style>