<template>
  <div>
    <v-form ref="AdvanceToStage8" v-model="valid" @submit.prevent="Submit">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="4" class="mb-4 pa-6">
          <h4 class="mb-2">Order Number</h4>

          <v-text-field
            solo
            v-model="formFields.order_number"
            name="order_number"
          ></v-text-field>

          <h4 class="mb-2">Delivery Date</h4>

          <v-text-field
            solo
            name="delDate"
            @click="show_datepicker()"
            readonly
            :value="formFields.delDate | YMD_to_DMY"
          ></v-text-field>

          <v-dialog
            ref="datepicker_dialog"
            v-model="datepicker_modal"
            persistent
            :return-value.sync="temp_date"
            width="290px"
          >
            <v-date-picker v-model="temp_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="hide_datepicker()">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="save_datepicker()">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <h4 class="mb-2">Shipment Ref</h4>

          <v-text-field
            solo
            v-model="formFields.shipment_ref"
            name="shipment_ref"
          ></v-text-field>

          <div class="columns-2">
            <div>
              <h4 class="mb-2">First Name</h4>

              <v-text-field
                solo
                v-model="formFields.deliveryFirstName"
                name="deliveryFirstName"
                :rules="[rules.required, rules.trimWhiteSpace]"
              ></v-text-field>
            </div>

            <div>
              <h4 class="mb-2">Last Name</h4>

              <v-text-field
                solo
                v-model="formFields.deliveryLastName"
                name="deliveryLastName"
                :rules="[rules.required, rules.trimWhiteSpace]"
              ></v-text-field>
            </div>
          </div>

          <div v-if="this.job.svg_signature && this.job.print_signature">
            <h4>Already Signed as:</h4>
            <div v-html="this.job.svg_signature" class="mt-3 mb-3"></div>
            <v-btn @click="signature_dialog = true" color="warning"
              >Change Signature</v-btn
            >
          </div>
          <v-btn @click="signature_dialog = true" v-else>Add Signature</v-btn>

          <v-text-field
            solo
            style="display: none !important"
            readonly
            :value="formFields.svg_signature"
            name="svg_signature"
            :rules="[rules.required]"
          ></v-text-field>

          <v-btn
            :disabled="!valid || isSubmitting"
            class="success mr-0 mt-3 ml-auto d-flex"
            type="submit"
            :loading="valid && isSubmitting"
            >Advance Job</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-dialog
      ref="signature_dialog"
      v-model="signature_dialog"
      persistent
      width="400px"
    >
      <div style="background: white; width: 100%" class="pa-4">
        <Signature_pad
          :SignatureDialogValue="signature_dialog"
          :svg_sig="this.formFields.svg_signature"
          @SignatureModalClosed="signature_dialog = false"
          @SignatureConfirmed="set_signature"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Signature_pad from "./Signature_pad.vue";
export default {
  name: "toStage8",
  components: {
    Signature_pad,
  },

  data() {
    return {
      isUpdating: false,
      isSubmitting: false,
      datepicker_modal: false,
      signature_dialog: false,
      temp_date: "",
      valid: false,
      formFields: {
        deliveryFirstName: "",
        deliveryLastName: "",
        order_number: null,
        shipment_ref: null,
        // print_signature: null,
        delDate: new Date().toISOString().substr(0, 10),
        svg_signature: null,
      },

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
        trimWhiteSpace: (v) => v.toString().trim().length > 0,
      },
    };
  },

  methods: {
    set_signature(sig) {
      this.formFields.svg_signature = sig;
    },
    show_datepicker() {
      this.datepicker_modal = true;
      this.temp_date = "";
    },
    hide_datepicker() {
      this.datepicker_modal = false;
      this.temp_date = "";
    },
    save_datepicker() {
      if (this.temp_date) {
        this.formFields.delDate = this.temp_date;
      }
      this.hide_datepicker();
    },
    Submit() {
      console.log("Advancing to stage 8");
      console.log(this.formFields);
      console.log(this.validate());
      // if (
      //   this.formFields.deliveryFirstName.trim().length > 0 &&
      //   this.formFields.deliveryLastName.trim().length > 0
      // )
      //   this.formFields.print_signature =
      //     this.formFields.deliveryFirstName.trim() +
      //     " " +
      //     this.formFields.deliveryLastName.trim();
      // else return;
      this.isSubmitting = true;

      const payload = {
        job_id: this.job_id,
        data: this.formFields,
      };
      this.$store.dispatch("AdvanceToForInvoicing", payload).finally(() => {
        this.snackbar = true;
        this.isSubmitting = false;

        this.$router.push("/job/" + this.job_id);
      });
    },
    reset_form() {},
    validate() {
      return this.$refs.AdvanceToStage8.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  mounted() {
    this.formFields.order_number = this.job.order_number;
    this.formFields.shipment_ref = this.job.shipment_ref || "";
    this.formFields.print_signature = this.job.print_signature || "";
    this.formFields.deliveryFirstName = this.job.deliveryFirstName || "";
    this.formFields.deliveryLastName = this.job.deliveryLastName || "";

    if (this.job.delDate) {
      this.formFields.delDate = new Date(this.job.delDate)
        .toISOString()
        .substr(0, 10);
    }

    if (this.job && this.job.svg_signature) {
      var encodedData = window.btoa(this.job.svg_signature);

      this.formFields.svg_signature =
        "data:image/svg+xml;base64," + encodedData;
    }
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_id() {
      return this.$route.params.job_id;
    },
  },
};
</script>

<style>
</style>