import Vue from 'vue'
import Vuex from 'vuex'
// import Axios from "axios"
import JobModules from "./modules/jobs/JobModules"
import SingleJobModules from "./modules/jobs/SingleJobModules"
import JobSearch from "./modules/jobs/JobSearch"
import Deliveries from "./modules/jobs/Deliveries"
import JobAdvancing from "./modules/jobs/JobAdvancing"
import JobModulesRealtime from "./modules/jobs/JobModulesRealtime"
import UserAuth from "./modules/users/UserAuth"
import SnackBar from "./modules/misc/SnackBar"
import misc from "./modules/misc/misc"
import MiscJobDashboard from "./modules/misc/MiscJobDashboard"
import ContactsManagement from "./modules/management/contacts"
import UsersManagement from "./modules/management/users"
import CompaniesManagement from "./modules/management/companies"
import RolesManagement from "./modules/management/roles"
import DepartmentsManagement from "./modules/management/departments"
import labourRates from "./modules/management/labour_rates"
import commonPhrases from "./modules/management/common_phrases"
import Emails from "./modules/misc/Emails"
import Reports from "./modules/reports/reports"
import Settings from "./modules/Settings"
import Sage from "./modules/sage/sage"
import StatsDashboard from "./modules/misc/StatsDashboard"
import Components from "./modules/management/components"
import JobComponents from "./modules/jobs/JobComponents"
import ServoData from "./modules/jobs/ServoData"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    status_map: {
      1: "Collection",
      2: "Assessment",
      3: "Customer Quotation",
      4: "Awaiting Acceptance",
      5: "Go Ahead",
      6: "In Progress",
      7: "For Delivery",
      8: "For Invoicing",
      9: "Invoiced",
      10: "Complete",
      11: "Archived",
    },
    priority_map: {
      2: "normal priority",
      3: "high priority",
      4: "emergency",
    }
  },
  mutations: {




  },

  getters: {
    get_status: (state) => (statusId) => {

      var status_id = parseInt(statusId);
      if (!isNaN(status_id) && status_id <= 11) {
        return state.status_map[status_id.toString()];
      }
    },

    get_all_status(state) {
      return state.status_map;
    },
    get_priority_list(state) {
      return state.priority_map;

    }
    // get_priority: (state) => (priorityId) => {

    //   var priority_id = parseInt(priorityId);
    //   if (!isNaN(priority_id) && priority_id <= 3) {
    //     return state.priority_map[priority_id.toString()];
    //   }
    // },

  },
  actions: {



  },
  modules: {
    JobModules,
    SingleJobModules,
    UserAuth,
    JobSearch,
    Deliveries,
    JobModulesRealtime,
    SnackBar,
    JobAdvancing,
    MiscJobDashboard,
    ContactsManagement,
    UsersManagement,
    CompaniesManagement,
    RolesManagement,
    Emails,
    Reports,
    DepartmentsManagement,
    labourRates,
    commonPhrases,
    Settings,
    Sage,
    StatsDashboard,
    misc,
    Components,
    JobComponents,
    ServoData
  }
})
