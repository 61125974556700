import Axios from 'axios';

const state = {
  CurrentSingleComponent: null,
  ComponentList: [],
};
const mutations = {
  SetCurrentSingleComponent(state, payload) {
    state.CurrentSingleComponent = payload;
  },
  SetComponentList(state, payload) {
    state.ComponentList = payload;
  },
  PushComponentToEnd(state, payload) {
    state.ComponentList.push(payload);
  },
  UpdateComponent(state, payload) {
    const index = state.ComponentList.findIndex((component) => component.id == payload.id);
    if (index >= 0) state.ComponentList.splice(index, 1, payload);
  },

  RemoveComponentFromList(state, payload) {
    state.ComponentList.splice(
      state.ComponentList.findIndex(function(component) {
        return component.id === payload;
      }),
      1
    );
  },
};
const actions = {
  getComponents(context) {
    console.log('getUsersByTag store action with payload');
    var token = context.getters.getCurrentToken;
    console.log('token: ' + token);

    if (token != '')
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/components'
      )
        .then((response) => {
          console.log(response.data);
          context.commit('SetComponentList', response.data);
          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },

  getComponentsWithValue(context) {
    console.log('getUsersByTag store action with payload');
    var token = context.getters.getCurrentToken;
    console.log('token: ' + token);

    if (token != '')
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/components-with-total'
      )
        .then((response) => {
          console.log(response.data);
          context.commit('SetComponentList', response.data);
          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },

  getLowStockComponents(context) {
    console.log('getUsersByTag store action with payload');
    var token = context.getters.getCurrentToken;
    console.log('token: ' + token);

    if (token != '')
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/components/lowStock'
      )
        .then((response) => {
          console.log(response.data);
          context.commit('SetComponentList', response.data);
          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },

  DeleteJobComponent(context, payload) {
    console.log('RecieveJobPart');
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log('token: ' + token);

    if (token != '')
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/job_components/' +
          payload
      )
        .then(function(response) {
          console.log(response.data);

          // context.commit("setCurrentSingleJob", response.data);
          return response.data;
        })
        .catch(function(error) {
          console.log(error);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });
          return Promise.reject(error);
        });
  },

  UpdateComponent(context, payload) {
    console.log('updating single user');
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (!payload.id) return;

    if (token != '')
      return Axios.put(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/components/' +
          payload.id,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then(function(response) {
          console.log(response);
          context.commit('UpdateComponent', response.data);
          // context.commit("SetCurrentSingleComponent", response.data);
          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },

  UpdateComponentStockOnly(context, payload) {
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (!payload.id) return;

    if (token != '')
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/components/updateStockOnly/' +
          payload.id,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then(function(response) {
          console.log(response);
          context.commit('UpdateComponent', response.data);
          // context.commit("SetCurrentSingleComponent", response.data);
          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },

  AddComponent(context, payload) {
    console.log('updating single user');
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != '')
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/components',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then(function(response) {
          console.log(response);

          // context.commit("PushComponentToEnd", response.data);
          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },

  DeleteSingleComponent(context, payload) {
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (!payload) return;

    if (token != '')
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/components/' +
          payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then(function(response) {
          console.log(response);
          context.commit('RemoveComponentFromList', response.data);

          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },
};
const getters = {
  GetComponentList(state) {
    return state.ComponentList;
  },
  GetCurrentSingleComponent(state) {
    return state.CurrentSingleComponent;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
