<template>
  <div>
    <v-btn @click="AddCompanyModal()" color="success">Add Company</v-btn>

    <v-dialog
      ref="addCompanyDialog"
      v-model="addCompanyModal"
      :persistent="isSubmitting"
      width="400px"
    >
      <v-card>
        <v-form ref="AddCompanyForm" v-model="valid" @submit.prevent="Submit">
          <v-row class="pa-4 ma-0">
            <v-col cols="12">
              <h4 class="mb-2">Name</h4>
              <v-text-field
                solo
                :rules="[rules.required]"
                v-model="formFields.name"
                name="name"
              ></v-text-field>

              <h4 class="mb-2">Legal Entity</h4>
              <v-text-field
                solo
                v-model="formFields.legal_entity"
                name="legal_entity"
              ></v-text-field>

              <h4 class="mb-2">Address</h4>

              <v-textarea solo v-model="formFields.address" name="address"></v-textarea>

              <h4 class="mb-2">Region</h4>
              <v-select
                :items="this.regions"
                :disabled="this.isUpdating"
                label="Region"
                solo
                name="region"
                :rules="[rules.required]"
                v-model="formFields.region"
              ></v-select>

              <h4 class="mb-2">Email</h4>

              <v-text-field
                solo
                :rules="[rules.emailPattern]"
                v-model="formFields.email"
                name="email"
              ></v-text-field>

              <h4 class="mb-2">Phone</h4>

              <v-text-field
                v-model="formFields.phone"
                name="phone"
                :rules="[rules.required]"
                type="text"
                v-on:keypress="isNotLetter($event)"
                color="purple"
                filled
                rounded
                label="Phone"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Fax</h4>

              <v-text-field
                solo
                v-model="formFields.fax"
                name="fax"
                type="text"
                v-on:keypress="isNotLetter($event)"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Customer Code</h4>

              <v-text-field
                solo
                v-model="formFields.customer_code"
                name="customer_code"
              ></v-text-field>

              <h4 class="mb-2">Invoice Email</h4>

              <v-text-field
                solo
                v-model="formFields.invoice_email"
                name="invoice_email"
                :rules="[rules.required, rules.emailPattern]"
              ></v-text-field>

              <h4 class="mb-2">Invoice Address</h4>

              <v-textarea
                solo
                v-model="formFields.invoice_address"
                name="invoice_address"
              ></v-textarea>

              <h4 class="mb-2">Company Status</h4>
              <v-select
                :items="this.companyStatuses"
                :loading="this.companyStatuses.length == 0"
                :disabled="
                  this.isUpdating || !this.companyStatuses || !this.companyStatuses.length > 0
                "
                item-text="name"
                item-value="id"
                label="Company Status"
                solo
                name="company_status"
                v-model="formFields.company_status_id"
              ></v-select>

              <h4 class="mb-2">Sales Rep</h4>
              <v-select
                :items="this.SalesUsers"
                :loading="this.isUpdating"
                :disabled="this.isUpdating || !this.SalesUsers || !this.SalesUsers.length > 0"
                item-text="name"
                item-value="id"
                label="Sales Rep"
                solo
                clearable
                name="sales_rep_id"
                v-model="formFields.sales_rep_id"
              ></v-select>

              <h4 class="mb-2">Term</h4>
              <v-select
                :items="this.TermList"
                :loading="this.isUpdating"
                :disabled="this.isUpdating || !this.TermList || !this.TermList.length > 0"
                item-text="name"
                item-value="id"
                label="Term"
                solo
                clearable
                name="Term_id"
                v-model="formFields.term_id"
              ></v-select>

              <!-- add toggles for is customer and is supplier -->

              <!--
  can use
   hint="This label matches most of the examples in the Material Design documentation."
                persistent-hint
 -->
              <v-switch
                class="v-input--reverse v-input--expand"
                v-model="formFields.is_customer"
                color="orange darken-3"
              >
                <template #label> Is Customer? </template>
              </v-switch>

              <v-switch
                class="v-input--reverse v-input--expand"
                color="orange darken-3"
                v-model="formFields.is_supplier"
              >
                <template #label> Is Supplier? </template>
              </v-switch>

              <v-switch
                class="v-input--reverse v-input--expand"
                color="orange darken-3"
                v-model="formFields.is_subcontractor"
              >
                <template #label> Is Subcontractor? </template>
              </v-switch>

              <div class="mx-0 mt-3 d-flex justify-space-between">
                <v-btn :disabled="isSubmitting" color="error" text @click="addCompanyModal = false"
                  >Cancel</v-btn
                >

                <v-btn
                  :disabled="
                    !valid || isSubmitting || !(formFields.is_customer || formFields.is_supplier)
                  "
                  class="success"
                  type="submit"
                  :loading="isSubmitting"
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddCompanyForm',
  data() {
    return {
      valid: false,
      isUpdating: false,
      addCompanyModal: false,
      SalesUsers: [],
      isSubmitting: false,
      companyStatuses: [],
      formFields: {
        name: '',
        legal_entity:'',
        email: '',
        phone: '',
        fax: '',
        address: '',
        customer_code: null,
        company_status_id: null,
        is_customer: false,
        is_supplier: false,
        is_subcontractor: false,
        sales_rep_id: null,
        term_id: null,
        invoice_email: null,
        invoice_address: null,
        region: null,
      },
      regions: ['NW', 'NE', 'Mids', 'SE', 'SW', 'Scotland', 'Europe'],
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        isNaN: (v) => this.isNumeric(v) == true || 'Wrong Value',
        emailMatch: () => `The email and password you entered don't match`,
        emailPattern: (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          'E-mail must be valid',
      },
    };
  },
  methods: {
    test() {
      console.log(this.$refs.switch.$el);
    },
    AddCompanyModal() {
      this.addCompanyModal = true;
    },
    ResetForm() {
      this.$refs.AddCompanyForm.reset();
      // this.formFields.include_cc_quotation = false;
      // this.formFields.include_cc_delivery = false;
    },
    Submit() {
      if (this.validate()) {
        this.isSubmitting = true;
        console.log('Editing Company');
        console.log(this.formFields);
        console.log(this.validate());
        this.$store
          .dispatch('AddSingleCompany', this.formFields)
          .then((new_company) => {
            if (new_company && new_company.id) {
              this.$store.commit('SetSnackbarData', {
                message: 'Company added successfully.',
              });
              this.$store.commit('PushCompanyToEnd', new_company);
              this.addCompanyModal = false;
              this.ResetForm();
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.AddCompanyForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isNotLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      var input = e.target;
      var val = input.value;
      var end = input.selectionEnd;

      if (e.keyCode == 32 && (val[end - 1] == ' ' || val[end] == ' ')) {
        e.preventDefault();
        return false;
      } else if (/^[+0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },
  computed: {
    CurrentSingleCompany() {
      return this.$store.getters.GetCurrentSingleCompany;
    },
    companyList() {
      return this.$store.getters.getCompanyList;
    },
    TermList() {
      return this.$store.getters.GetTerms;
    },
  },
  mounted() {
    this.isUpdating = true;
    this.$store
      .dispatch('getUsersByPermission', 'show_in_sales_rep_dropdown')
      .then((users) => {
        this.SalesUsers = users;
      })
      .finally(() => {
        this.isUpdating = false;
      });
    this.$store.dispatch('fetchCompanyStatuses').then((statuses) => {
      this.companyStatuses = statuses;
    });
  },
};
</script>

<style>
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.v-application--is-ltr .v-input--reverse .v-input__slot .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}
.v-application--is-rtl .v-input--reverse .v-input__slot .v-input--selection-controls__input {
  margin-left: 0;
  margin-right: 8px;
}

.v-input--expand .v-input__slot .v-label {
  display: block;
  flex: 1;
}
</style>
