<template>
  <v-dialog
    ref="editComponentDialog"
    v-model="show"
    :persistent="isSubmitting"
    width="400px"
  >
    <v-card>
      <v-card-title class="text-h5 primary text-white darken-3 text-center">
        Add a Component
      </v-card-title>
      <v-form ref="editComponentForm" v-model="valid" @submit.prevent="Submit">
        <v-row class="pa-4 ma-0">
          <v-col cols="12">
            <h4 class="mb-2">part_code</h4>
            <v-text-field
              solo
              :rules="[rules.required]"
              v-model="formFields.part_code"
              name="part_code"
            ></v-text-field>

            <h4 class="mb-2">Description</h4>

            <v-textarea
              solo
              v-model="formFields.description"
              name="description"
            ></v-textarea>

            <h4 class="mb-2">Manufacturer</h4>
            <v-text-field
              solo
              :rules="[rules.required]"
              v-model="formFields.manufacturer"
              name="manufacturer"
            ></v-text-field>

            <h4 class="mb-2">Package</h4>
            <v-text-field
              solo
              v-model="formFields.package"
              name="package"
            ></v-text-field>

            <h4 class="mb-2">Marking Code</h4>
            <v-text-field
              solo
              v-model="formFields.marking_code"
              name="marking_code"
            ></v-text-field>

            <h4 class="mb-2">Datasheet URL</h4>
            <v-text-field
              solo
              v-model="formFields.datasheet"
              name="datasheet"
            ></v-text-field>

            <h4 class="mb-2">Location</h4>
            <v-text-field
              solo
              v-model="formFields.location"
              name="location"
            ></v-text-field>

            <h4 class="mb-2">Order Code</h4>
            <v-text-field
              solo
              v-model="formFields.order_code"
              name="order_code"
            ></v-text-field>

            <h4 class="mb-2">Unit Price</h4>
            <v-text-field
              v-model.number="formFields.unit_price"
              name="unit_price"
              :rules="[rules.isNaN]"
              type="number"
              color="purple"
              filled
              rounded
              prefix="£"
              label="Unit Price"
              class="hide-arrows"
            ></v-text-field>

            <h4 class="mb-2">Quantity In Stock</h4>
            <v-text-field
              v-model.number="formFields.qty_in_stock"
              name="qty_in_stock"
              :rules="[rules.isNaN]"
              type="number"
              color="purple"
              filled
              rounded
              @keypress="IntegerOnly(event)"
              label="Quantity In Stock"
              class="hide-arrows"
            ></v-text-field>

            <h4 class="mb-2">Re-Order Threshold</h4>
            <v-text-field
              v-model.number="formFields.restock_threshold"
              name="restock_threshold"
              :rules="[rules.isNaN]"
              type="number"
              color="purple"
              filled
              rounded
              @keypress="IntegerOnly(event)"
              label="Re-Order Threshold"
              class="hide-arrows"
            ></v-text-field>

            <h4 class="mb-2">Re-Order Quantity</h4>
            <v-text-field
              v-model.number="formFields.restock_qty"
              name="restock_qty"
              :rules="[rules.isNaN]"
              type="number"
              color="purple"
              filled
              rounded
              @keypress="IntegerOnly(event)"
              label="Re-Order Quantity"
              class="hide-arrows"
            ></v-text-field>

            <h4 class="mb-2">Supplier</h4>
            <v-autocomplete
              v-model="formFields.company_id"
              :disabled="isUpdating"
              :items="CompanyList"
              solo
              color="blue-grey lighten-2"
              label="Select"
              item-text="name"
              item-value="id"
              hide-no-data
              :rules="[rules.required, rules.isNaN]"
              hide-selected
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click="data.select"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <!-- <v-list-item-avatar>
                <img :src="data.item.avatar" />
              </v-list-item-avatar> -->
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                    <!-- <v-list-item-subtitle
                  v-html="data.item.group"
                ></v-list-item-subtitle> -->
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

            <div class="mx-0 mt-3 d-flex justify-space-between">
              <v-btn
                :disabled="isSubmitting"
                color="error"
                text
                @click="show = false"
                >Cancel</v-btn
              >

              <v-btn
                :disabled="!valid || isSubmitting"
                class="success"
                type="submit"
                :loading="isSubmitting"
                >Save</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditComponentForm",
  props: {
    value: Boolean,
    currentEditItem: Object,
  },
  data() {
    return {
      valid: false,
      isUpdating: false,
      addComponentModal: false,
      isSubmitting: false,
      formFields: {
        part_code: "",
        description: "",
        manufacturer: "",
        package: "",
        marking_code: "",
        datasheet: "",
        location: "",
        Company_id: null,
        order_code: "",
        unit_price: 0,
        qty_in_stock: 0,
        restock_threshold: null,
        restock_qty: 0,
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
      },
    };
  },
  methods: {
    IntegerOnly(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    AddComponentModal() {
      this.addComponentModal = true;
    },
    ResetForm() {
      this.$refs.editComponentForm.reset();
      // this.formFields.include_cc_quotation = false;
      // this.formFields.include_cc_delivery = false;
    },
    Submit() {
      if (this.validate()) {
        this.isSubmitting = true;
        console.log("Editing Component");
        console.log(this.formFields);
        console.log(this.validate());
        this.$store
          .dispatch("UpdateComponent", this.formFields)
          .then((new_component) => {
            if (new_component && new_component.id) {
              this.$store.commit("SetSnackbarData", {
                message: "Component updated successfully.",
              });
              this.show = false;
              this.ResetForm();
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.editComponentForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isNotLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      var input = e.target;
      var val = input.value;
      var end = input.selectionEnd;

      if (e.keyCode == 32 && (val[end - 1] == " " || val[end] == " ")) {
        e.preventDefault();
        return false;
      } else if (/^[+0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    CurrentSingleComponent() {
      return this.$store.getters.GetCurrentSingleComponent;
    },
    ComponentList() {
      return this.$store.getters.getComponentList;
    },
    CompanyList() {
      return this.$store.getters.getCompanyList;
    },
  },
  mounted() {
    this.isUpdating = true;

    this.$store.dispatch("fetchSuppliersList").finally(() => {
      this.isUpdating = false;
    });
  },
  watch: {
    show(newVal, old) {
      if (old != newVal && newVal == true) {
        // this.formFields.name = this.currentEditItem.name;
        // this.formFields.id = this.currentEditItem.id;
        // this.formFields.phrase = this.currentEditItem.phrase;
        this.formFields = { ...this.CurrentSingleComponent };
      } else if (old != newVal && old === true && newVal == false) {
        this.$emit("closeModal");
      }
    },
  },
};
</script>

<style>
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.v-application--is-ltr
  .v-input--reverse
  .v-input__slot
  .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}
.v-application--is-rtl
  .v-input--reverse
  .v-input__slot
  .v-input--selection-controls__input {
  margin-left: 0;
  margin-right: 8px;
}

.v-input--expand .v-input__slot .v-label {
  display: block;
  flex: 1;
}
</style>