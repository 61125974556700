<template>
  <div>
    <v-form ref="AddRoleForm" v-model="valid" @submit.prevent="Submit">
      <v-row class="pa-4 ma-0">
        <v-col cols="12">
          <h4 class="mb-2">Name</h4>

          <v-text-field
            solo
            :rules="[rules.required]"
            v-model="formFields.name"
            name="name"
          ></v-text-field>

          <h4 class="mb-2">Description</h4>

          <v-text-field
            solo
            v-model="formFields.description"
            name="name"
          ></v-text-field>

          <h4 class="mb-2">Related Department</h4>

          <v-select
            :items="Departments"
            item-value="id"
            item-text="name"
            v-model.number="formFields.tag_id"
          ></v-select>

          <v-divider class="mb-6" />

          <div class="role-groups-wrapper">
            <!-- single group here -->

            <div class="single-role-group">
              <div class="d-flex justify-space-between align-center">
                <h3 class="role-group-title mb-3 mr-auto">
                  <v-icon class="mr-2">mdi-eye</v-icon> General
                </h3>

                <v-btn color="error" text @click="UnselectAll(1)" small
                  >unselect all</v-btn
                >
                <v-btn color="primary" text @click="SelectAll(1)" small
                  >select all</v-btn
                >
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.show_in_engineer_dropdown"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>show_in_engineer_dropdown</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.show_in_sales_rep_dropdown"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>show_in_sales_rep_dropdown</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_access_search"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_access_search</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_access_reports"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_access_reports</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_book_collection"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_book_collection</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_create_job"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_create_job</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_edit_job"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_edit_job</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_delete_job"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_delete_job</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.read_jobs"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>read_jobs</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_view_stats_dashboard"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_view_stats_dashboard</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_access_sage_menu"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_access_sage_menu</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_create_sage_export"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_create_sage_export</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_send_sms"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_send_sms</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_access_full_management_menu"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_access_full_management_menu</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_advance_job"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_advance_job</span>
                  </template>
                </v-switch>
              </div>
            </div>

            <!-- single group 2 - Job Permissions here -->
            <div class="single-role-group">
              <div class="d-flex justify-space-between align-center">
                <h3 class="role-group-title mb-3 mr-auto">
                  <v-icon class="mr-2">mdi-eye</v-icon> Job Permissions
                </h3>

                <v-btn color="error" text @click="UnselectAll(2)" small
                  >unselect all</v-btn
                >
                <v-btn color="primary" text @click="SelectAll(2)" small
                  >select all</v-btn
                >
              </div>
              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_edit_bin"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_edit_bin</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_edit_internal_note"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_edit_internal_note</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_add_photos"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_add_photos</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.see_admin_docs"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>see_admin_docs</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_rnr"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_rnr</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_ber"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_ber</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_add_job_parts"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_add_job_parts</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_manually_deliver"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_manually_deliver</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_access_multi_deliveries"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_access_multi_deliveries</span>
                  </template>
                </v-switch>
              </div>
            </div>

            <!-- single group 3 - PDF Permissions here -->
            <div class="single-role-group">
              <div class="d-flex justify-space-between align-center">
                <h3 class="role-group-title mb-3 mr-auto">
                  <v-icon class="mr-2">mdi-eye</v-icon> PDF/Reports Permissions
                </h3>

                <v-btn color="error" text @click="UnselectAll(3)" small
                  >unselect all</v-btn
                >
                <v-btn color="primary" text @click="SelectAll(3)" small
                  >select all</v-btn
                >
              </div>
              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_view_job_report_pdf"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_view_job_report_pdf</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_email_job_report"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_email_job_report</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_view_invoice"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_view_invoice</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_view_delivery_note"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_view_delivery_note</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_view_sales_quotations"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_view_sales_quotations</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_email_sales_quotations"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_email_sales_quotations</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_email_invoice"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_email_invoice</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_view_job_label_pdf"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_view_job_label_pdf</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_view_signed_delivery_note_pdf"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_view_signed_delivery_note_pdf</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_email_signed_delivery_note_pdf"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_email_signed_delivery_note_pdf</span>
                  </template>
                </v-switch>
              </div>
            </div>

            <!-- single group 4 - Companies Permissions here -->
            <div class="single-role-group">
              <div class="d-flex justify-space-between align-center">
                <h3 class="role-group-title mb-3 mr-auto">
                  <v-icon class="mr-2">mdi-eye</v-icon> Companies Permissions
                </h3>

                <v-btn color="error" text @click="UnselectAll(4)" small
                  >unselect all</v-btn
                >
                <v-btn color="primary" text @click="SelectAll(4)" small
                  >select all</v-btn
                >
              </div>
              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_add_customers"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_add_customers</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_edit_customers"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_edit_customers</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_delete_customers"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_delete_customers</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_add_suppliers"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_add_suppliers</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_edit_suppliers"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_edit_suppliers</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_delete_suppliers"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_delete_suppliers</span>
                  </template>
                </v-switch>
              </div>
              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_add_contacts"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_add_contacts</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_edit_contacts"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_edit_contacts</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_delete_contacts"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_delete_contacts</span>
                  </template>
                </v-switch>
              </div>
            </div>

            <!-- single group 5 - Contacts Permissions here -->
            <div class="single-role-group">
              <div class="d-flex justify-space-between align-center">
                <h3 class="role-group-title mb-3 mr-auto">
                  <v-icon class="mr-2">mdi-eye</v-icon> Other
                </h3>

                <v-btn color="error" text @click="UnselectAll(5)" small
                  >unselect all</v-btn
                >
                <v-btn color="primary" text @click="SelectAll(5)" small
                  >select all</v-btn
                >
              </div>
              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.role_is_deletable"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>role_is_deletable</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_view_deleted_items"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_view_deleted_items</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_add_roles"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_add_roles</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_edit_roles"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_edit_roles</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_delete_roles"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_delete_roles</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_add_departments"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_add_departments</span>
                  </template>
                </v-switch>
              </div>
              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_edit_departments"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_edit_departments</span>
                  </template>
                </v-switch>
              </div>
              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_delete_departments"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_delete_departments</span>
                  </template>
                </v-switch>
              </div>
            </div>

            <!-- single group 6 - User Permissions here -->
            <div class="single-role-group">
              <div class="d-flex justify-space-between align-center">
                <h3 class="role-group-title mb-3 mr-auto">
                  <v-icon class="mr-2">mdi-eye</v-icon> Users Permissions
                </h3>

                <v-btn color="error" text @click="UnselectAll(6)" small
                  >unselect all</v-btn
                >
                <v-btn color="primary" text @click="SelectAll(6)" small
                  >select all</v-btn
                >
              </div>
              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_add_users"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_add_users</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_edit_users"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_edit_users</span>
                  </template>
                </v-switch>
              </div>

              <div class="single-role-item">
                <v-switch
                  dense
                  hide-details
                  class="v-input--reverse v-input--expand r-role-switch"
                  v-model="formFields.can_delete_users"
                  :color="switchSettings.color"
                  :rules="[rules.isBool]"
                  :ripple="false"
                >
                  <template #label>
                    <span>can_delete_users</span>
                  </template>
                </v-switch>
              </div>
            </div>
          </div>

          <div class="mx-0 mt-3 d-flex justify-end">
            <v-btn
              :disabled="isSubmitting"
              color="error"
              text
              @click="$emit('closeModal')"
              >Cancel</v-btn
            >

            <v-btn
              :disabled="!valid || isSubmitting"
              class="success ml-2"
              type="submit"
              :loading="isSubmitting"
              >Save</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "AddRoleForm",
  props: {
    value: Boolean,
  },
  data() {
    return {
      valid: false,
      isUpdating: false,
      editModal: false,
      switchSettings: {
        color: "orange darken-3",
      },
      permissionGroups: {
        1: [
          "can_access_search",
          "can_access_reports",
          "can_book_collection",
          "can_create_job",
          "can_edit_job",
          "read_jobs",
          "can_delete_job",
          "can_view_stats_dashboard",
          "can_access_sage_menu",
          "can_create_sage_export",
          "show_in_engineer_dropdown",
          "show_in_sales_rep_dropdown",
          "can_send_sms",
          "can_access_full_management_menu",
          "can_advance_job"
        ],
        2: [
          "can_edit_bin",
          "can_edit_internal_note",
          "can_add_photos",
          "can_ber",
          "see_admin_docs",
          "can_rnr",
          "can_add_job_parts",
          "can_manually_deliver",
          "can_access_multi_deliveries",
        ],

        3: [
          "can_view_job_report_pdf",
          "can_email_job_report",
          "can_view_sales_quotations",
          "can_email_sales_quotations",
          "can_email_invoice",
          "can_view_signed_delivery_note_pdf",
          "can_email_signed_delivery_note_pdf",
          "can_view_invoice",
          "can_view_delivery_note",
          "can_view_job_label_pdf",
        ],
        4: [
          "can_add_customers",
          "can_delete_customers",
          "can_edit_customers",
          "can_add_suppliers",
          "can_edit_suppliers",
          "can_delete_suppliers",
          "can_add_contacts",
          "can_delete_contacts",
          "can_edit_contacts",
        ],

        5: [
          "role_is_deletable",
          "can_view_deleted_items",
          "can_add_roles",
          "can_edit_roles",
          "can_delete_roles",
          "can_add_departments",
          "can_edit_departments",
          "can_delete_departments",
        ],

        6: ["can_add_users", "can_edit_users", "can_delete_users"],
      },
      // rolesList: [],
      isSubmitting: false,
      formFields: {
        name: "",
        description: "",
        can_ber: false,
        read_jobs: false,
        tag_id: null,
        see_admin_docs: false,
        can_access_search: false,
        can_access_reports: false,
        can_book_collection: false,
        can_create_job: false,
        can_edit_job: false,
        can_edit_bin: false,
        can_edit_internal_note: false,
        can_add_photos: false,
        can_view_job_report_pdf: false,
        can_email_job_report: false,
        can_view_sales_quotations: false,
        can_email_sales_quotations: false,
        can_view_stats_dashboard: false,
        can_add_users: false,
        can_edit_users: false,
        can_delete_users: false,
        can_access_sage_menu: false,
        can_create_sage_export: false,
        can_add_customers: false,
        can_delete_customers: false,
        can_edit_customers: false,
        can_add_contacts: false,
        can_delete_contacts: false,
        can_edit_contacts: false,
        can_add_suppliers: false,
        can_edit_suppliers: false,
        can_delete_suppliers: false,
        can_delete_job: false,
        can_email_invoice: false,
        can_rnr: false,
        can_manually_deliver: false,
        can_view_signed_delivery_note_pdf: false,
        can_email_signed_delivery_note_pdf: false,
        can_view_job_label_pdf: false,
        can_add_job_parts: false,
        role_is_deletable: false,
        can_view_invoice: false,
        can_view_deleted_items: false,
        can_add_roles: false,
        can_edit_roles: false,
        can_delete_roles: false,
        can_add_departments: false,
        can_edit_departments: false,
        can_delete_departments: false,
        can_access_multi_deliveries: false,
        can_view_delivery_note: false,
        show_in_engineer_dropdown: false,
        show_in_sales_rep_dropdown: false,
        can_send_sms: false,
        can_access_full_management_menu: false,
                can_advance_job:false

      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        isBool: (v) => v === true || v === false || "Wrong Value",
      },
    };
  },
  methods: {
    scrollToElement() {
      const el = this.$refs.AddRoleForm.$el;
      if (el) {
        console.log(el);
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },

    SelectAll(group_number) {
      const keys = this.permissionGroups[group_number.toString()];
      console.log(keys);
      keys.forEach((key) => {
        this.formFields[key.toString()] = true;
      });
    },

    UnselectAll(group_number) {
      const keys = this.permissionGroups[group_number.toString()];
      console.log(keys);
      keys.forEach((key) => {
        this.formFields[key.toString()] = false;
      });
    },
    Submit() {
      if (this.validate()) {
        //this.formFields.user_id = this.CurrentSingleUser.id;
        this.isSubmitting = true;
        console.log("adding role");
        console.log(this.formFields);
        console.log(this.validate());
        const payload = this.formFields;
        this.$store
          .dispatch("AddSingleRole", payload)
          .then(() => {
            this.$store.commit("SetSnackbarData", {
              message: "Role created successfully.",
            });
            this.$emit("closeModal");
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.AddRoleForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  computed: {
    CurrentSingleRole() {
      return this.$store.getters.GetCurrentSingleRole;
    },
    Departments() {
      return this.$store.getters.getDepartmentList;
    },
  },
  mounted() {
    this.scrollToElement();
  },
};
</script>
