<template>
  <v-form ref="userSettingsForm" v-model="valid" @submit.prevent="Submit">
    <v-row class="ma-0 pa-4">
      <v-col cols="12">
        <div class="d-flex justify-space-between mb-3">
          <h3 class="h3">Settings</h3>
          <SettingsChangePassword />
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <h4 class="mb-2">Name</h4>
        <v-text-field solo v-model="formFields.name"></v-text-field>

        <h4 class="mb-2">Email</h4>
        <v-text-field
          solo
          v-model="formFields.email"
          :rules="emailRules"
        ></v-text-field>

        <h4 class="mb-2">Sage Ref</h4>
        <v-text-field solo v-model.number="formFields.sage_ref"></v-text-field>
      </v-col>
      <v-col cols="1"> </v-col>

      <v-col cols="12" sm="4" md="3">
        <h3 class="mb-5">Visible Dashboard Columns</h3>

        <div class="single-role-item">
          <v-switch
            dense
            hide-details
            class="v-input--reverse v-input--expand r-role-switch"
            v-model="formFields.col_1"
            :color="switchSettings.color"
            :rules="[rules.isBool]"
            :ripple="false"
          >
            <template #label>
              <span>Collection</span>
            </template>
          </v-switch>
        </div>

        <div class="single-role-item">
          <v-switch
            dense
            hide-details
            class="v-input--reverse v-input--expand r-role-switch"
            v-model="formFields.col_2"
            :color="switchSettings.color"
            :rules="[rules.isBool]"
            :ripple="false"
          >
            <template #label>
              <span>Assessment</span>
            </template>
          </v-switch>
        </div>

        <div class="single-role-item">
          <v-switch
            dense
            hide-details
            class="v-input--reverse v-input--expand r-role-switch"
            v-model="formFields.col_3"
            :color="switchSettings.color"
            :rules="[rules.isBool]"
            :ripple="false"
          >
            <template #label>
              <span>Customer Quotation</span>
            </template>
          </v-switch>
        </div>

        <div class="single-role-item">
          <v-switch
            dense
            hide-details
            class="v-input--reverse v-input--expand r-role-switch"
            v-model="formFields.col_4"
            :color="switchSettings.color"
            :rules="[rules.isBool]"
            :ripple="false"
          >
            <template #label>
              <span>Awaiting Acceptance</span>
            </template>
          </v-switch>
        </div>

        <div class="single-role-item">
          <v-switch
            dense
            hide-details
            class="v-input--reverse v-input--expand r-role-switch"
            v-model="formFields.col_5"
            :color="switchSettings.color"
            :rules="[rules.isBool]"
            :ripple="false"
          >
            <template #label>
              <span>Go Ahead</span>
            </template>
          </v-switch>
        </div>

        <div class="single-role-item">
          <v-switch
            dense
            hide-details
            class="v-input--reverse v-input--expand r-role-switch"
            v-model="formFields.col_6"
            :color="switchSettings.color"
            :rules="[rules.isBool]"
            :ripple="false"
          >
            <template #label>
              <span> In Progress </span>
            </template>
          </v-switch>
        </div>

        <div class="single-role-item">
          <v-switch
            dense
            hide-details
            class="v-input--reverse v-input--expand r-role-switch"
            v-model="formFields.col_7"
            :color="switchSettings.color"
            :rules="[rules.isBool]"
            :ripple="false"
          >
            <template #label>
              <span>For Delivery</span>
            </template>
          </v-switch>
        </div>

        <div class="single-role-item">
          <v-switch
            dense
            hide-details
            class="v-input--reverse v-input--expand r-role-switch"
            v-model="formFields.col_8"
            :color="switchSettings.color"
            :rules="[rules.isBool]"
            :ripple="false"
          >
            <template #label>
              <span>For Invoicing</span>
            </template>
          </v-switch>
        </div>
      </v-col>
      <v-col cols="12">
        <v-btn
          :disabled="!valid || isSubmitting"
          class="success"
          type="submit"
          :loading="isSubmitting"
          >Save</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SettingsChangePassword from "../../components/management/SettingsChangePassword.vue";
export default {
  name: "UserSettings",
  components: {
    SettingsChangePassword,
  },
  data() {
    return {
      isSubmitting: false,
      switchSettings: {
        color: "orange darken-3",
      },
      formFields: {
        name: "",
        email: "",
        sage_ref: null,
        col_1: false,
        col_2: false,
        col_3: false,
        col_4: false,
        col_5: false,
        col_6: false,
        col_7: false,
        col_8: false,
      },

      valid: false,
      rules: {
        confirmPasswordRules: (v) =>
          v == this.formFields.new_password || "Passwords don't match",
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",

        isBool: (v) => v === true || v === false || "Wrong Value",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    Submit() {
      if (this.validate()) {
        this.isSubmitting = true;
        console.log("Editing Contact");
        console.log(this.formFields);
        console.log(this.validate());

        this.$store
          .dispatch("update_loggedin_user_data", this.formFields)
          .then(() => {
            this.$store.commit("SetSnackbarData", {
              message: "User details updated successfully.",
            });
          })

          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.userSettingsForm.validate();
    },
  },

  computed: {
    CurrentUser() {
      return this.$store.getters.getCurrentUserObj;
    },
  },
  mounted() {
    this.formFields = { ...this.CurrentUser };
  },
};
</script>

<style>
</style>