<template>
  <form
    @submit.prevent="goToJob"
    class="d-none d-sm-flex"
    :class="this.extraClasses"
  >
    <v-text-field
      solo
      light
      flat
      dense
      hide-details
      class="hide-arrows"
      type="number"
      @wheel="$event.target.blur()"
      placeholder="Job Number"
      color="white"
      v-model="job_id_quick_nav"
      name="description"
    ></v-text-field>
    <v-btn
      type="submit"
      class="ml-2"
      style="border-width: 2px"
      outlined
      icon
      width="36px"
      height="36px"
      :disabled="job_id_quick_nav && job_id_quick_nav.toString().length < 5"
    >
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </form>
</template>

<script>
export default {
  name: "MenuJobNumberNav",
  props: {
    extraClasses: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      job_id_quick_nav: this.$route.params.job_id || "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  methods: {
    goToJob() {
      var id = this.job_id_quick_nav.toString();
      id = id.replace(/\s/g, "");
      if (id && this.isNumeric(id) && id.length >= 5)
        this.$router.push("/job/" + id);
      else console.log("error");
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },

  watch: {
    $route() {
      this.job_id_quick_nav = this.$route.params.job_id;
    },
  },
};
</script>

<style scoped>
.on-row-2 {
  width: 100% !important;
}
</style>