<template>
  <v-dialog v-model="show" width="500" :persistent="this.is_loading">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Enter the new note
      </v-card-title>

      <v-card-text>
        <v-textarea
          solo
          name="note"
          placeholder="Enter a note"
          hide-details
          v-model="new_note"
        ></v-textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :loading="this.is_loading"
          @click="save_note"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditInternalNote",
  props: {
    value: Boolean,
    note: String,
    job_id: null,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      new_note: "",
      is_loading: false,
    };
  },
  mounted() {
    this.new_note = this.note || "";
  },

  methods: {
    save_note() {
      if (!this.job_id) return;
      var self = this;
      this.is_loading = true;
      this.$store
        .dispatch("updateSingleJob", {
          id: this.job_id,
          internalnote: this.new_note,
        })
        .then(() => {
          this.$store.commit("SetSnackbarData", {
            message: "Internal note updated successfully",
          });
        })
        .finally(function () {
          self.is_loading = false;
          self.show = false;
        });
    },
  },
};
</script>

<style>
</style>