<template>
  <!-- RIYAD START HERE, THESE ARE COPIED - MAKE THEM ENG. Quotation -->
  <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12">
    <div class="table-cont">
      <div
        class="table-header pa-3"
        v-bind:class="{
          'normal-priority': job.priority_id <= 2,
          'high-priority': job.priority_id == 3,
          'emergency-priority': job.priority_id == 4,
        }"
      >
        <div class="table-property-label">Delivery</div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Printed Name</div>
        <div class="table-property-value">
          {{ this.job.print_signature }}
        </div>
      </div>
      <div class="table-row">
        <div class="table-property-label">Date Time</div>
        <div class="table-property-value">
          {{ this.job.delDate | YMD_to_DMY }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Last Modified</div>
        <div class="table-property-value">
          {{ this.job.time_signature | YMDHis_to_DMYHis }}
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "JobBasicInfo",

  data() {
    return {};
  },

  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
  },

  methods: {},
};
</script>

<style>
</style>