<template>
  <v-row class="ma-0 pa-6">
    <div class="flex-grow-1 d-flex justify-space-between mb-6">
      <h1 class="h1">Jobs Awaiting Acceptance</h1>
      <v-btn
        color="success"
        @click="DownloadPDF('awaiting_acceptance/pdf')"
        :loading="IsFetchingPDF"
        :disabled="IsFetchingPDF"
        >Download PDF</v-btn
      >
    </div>
    <v-data-table
      :headers="headers"
      :items="jobs"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="elevation-1"
      style="width: 100%"
      group-by="company.sales_rep.name"
      :loading="is_loading"
    >
      <template v-slot:group="{ items }">
        <tr colspan="7">
          <td colspan="7" class="grey lighten-2" style="font-weight: 500">
            {{
              items[0].company && items[0].company.sales_rep
                ? items[0].company.sales_rep.name
                : "House Accounts"
            }}
          </td>
        </tr>
        <tr v-for="item in items" :key="item.id">
          <td v-for="header in headers" :key="item.id + ' - ' + header.value">
            <div
              v-if="header.value != 'NetTotal' && header.value != 'date_booked'"
            >
              {{
                header.value == "company"
                  ? item["company"]
                    ? item[header.value]["name"]
                    : ""
                  : item[header.value]
              }}
            </div>
            <v-btn
              v-if="header.value == 'actions'"
              :to="'/job/' + item.id"
              color="primary"
              >View</v-btn
            >
            <div v-if="header.value == 'company.name'">
              {{ item.company.name }}
            </div>

            <div v-if="header.value == 'Gross'">
              {{ ((item.NetTotal || 0) * 1.2) | toCurrency }}
            </div>
            <div v-if="header.value == 'date_booked'">
              {{ item.date_booked | YMD_to_DMY }}
            </div>

            <div v-if="header.value == 'NetTotal'">
              {{ ((item.NetTotal || 0) * 1) | toCurrency }}
            </div>
          </td>
        </tr>
        <tr v-if="items && items.length > 0" class="">
          <td>
            Total for
            {{
              items[0].company && items[0].company.sales_rep
                ? items[0].company.sales_rep.name
                : "None Assigned"
            }}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <strong>{{
              CalculateJobsAssignedTotal(items) | toCurrency
            }}</strong>
          </td>
          <td>
            <strong>{{
              (CalculateJobsAssignedTotal(items) * 1.2) | toCurrency
            }}</strong>
          </td>
          <td></td>
        </tr>
      </template>

      <template slot="body.append">
        <tr>
          <td><strong>Total</strong></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <strong>{{ NetTotalTotal | toCurrency }}</strong>
          </td>
          <td>
            <strong>{{ (NetTotalTotal * 1.2) | toCurrency }}</strong>
          </td>
        </tr>
      </template>

      <!-- <template v-slot:item.actions="{ item }">
        <v-btn :to="'/job/' + item.id" color="primary">View</v-btn>
      </template> -->

      <template v-slot:group.header="{ items }">
        <th colspan="7">
          {{
            items[0].company &&
            items[0].company.sales_rep &&
            items[0].company.sales_rep.name
              ? items[0].company.sales_rep.name
              : "No Sales Rep Assigned"
          }}
        </th>
      </template>

      <template v-slot:item.NetTotal="{ item }">
        {{ (item.NetTotal || 0) | toCurrency }}
      </template>

      <template v-slot:item.Gross="{ item }">
        {{ ((item.NetTotal || 0) * 1.2) | toCurrency }}
      </template>
    </v-data-table>
  </v-row>
</template>

<script>
export default {
  name: "Awaiting_Acceptance",
  data() {
    return {
      sortBy: "company.name",
      sortDesc: false,
      is_loading: false,
      IsFetchingPDF: false,
      headers: [
        {
          text: "Job",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Customer", value: "company.name" },
        { text: "Description", value: "description" },
        { text: "Date Booked", value: "date_booked" },
        { text: "£ Net", value: "NetTotal" },
        { text: "£ Gross", value: "Gross" },

        { text: "Actions", value: "actions" },
      ],
      jobs: [],
    };
  },
  methods: {
    DownloadPDF(endpoint) {
      this.IsFetchingPDF = true;
      return this.$store.dispatch("DownloadReportPDF", endpoint).finally(() => {
        this.IsFetchingPDF = false;
      });
    },
    CalculateJobsAssignedTotal(jobs) {
      var total = 0;
      var i = 0;
      for (i == 0; i < jobs.length; i++) {
        total += jobs[i].NetTotal || 0;
      }
      return total;
    },
  },
  mounted() {
    this.is_loading = true;
    this.$store
      .dispatch("get_report_data_for_tables", "awaiting_acceptance")
      .then((res) => {
        this.jobs = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
  computed: {
    NetTotalTotal() {
      var total = 0;
      for (let i = 0; i < this.jobs.length; i++) {
        total = total + this.jobs[i]["NetTotal"] || 0;
      }
      return total;
    },
  },
};
</script>

<style scoped>
>>> .v-row-group__header button {
  display: none;
}
</style>