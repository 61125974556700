<template>
  <v-dialog v-model="show" width="400">
    <v-card color="white " class="pa-6">
      <v-form ref="UnlinkJobImagesForm" @submit.prevent="Submit">
        <h3>Unlink Images</h3>
        <p>click on the image to select it.</p>
        <div class="columns-2">
          <div v-for="(value, name) in photos" :key="name">
            <div
              v-if="value"
              class="r-img-toggle r-transition"
              @click="ToggleImageStatus(name)"
              :class="[{ 'r-img-toggle-active': newPhotos[name] != value }]"
            >
              <img
                :src="base + value + '?rand=' + rand"
                :alt="value"
                style="max-width: 100%; width: 100%"
              />
            </div>
          </div>
        </div>

        <div class="mx-0 mt-3 d-flex justify-space-between">
          <v-btn
            :disabled="isSubmitting"
            color="error"
            text
            @click="show = false"
            >Cancel</v-btn
          >

          <v-btn
            :disabled="isSubmitting"
            class="error"
            type="submit"
            :loading="isSubmitting"
            >Unlink</v-btn
          >
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UnlinkJobImagesForm",
  props: {
    value: Boolean,
  },
  data() {
    return {
      rand: "",
      isSubmitting: false,
      base:
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/storage/images/",
      test: "https://api.neutronic.biz:443/storage/images/",
      photos: {
        photo1: null,
        photo2: null,
        photo3: null,
        photo4: null,
        photo5: null,
        photo6: null,
      },
      newPhotos: {
        photo1: null,
        photo2: null,
        photo3: null,
        photo4: null,
        photo5: null,
        photo6: null,
      },
    };
  },
  methods: {
    InitImages() {
      this.Rand();
      this.photos.photo1 = this.job.photo1;
      this.photos.photo2 = this.job.photo2;
      this.photos.photo3 = this.job.photo3;
      this.photos.photo4 = this.job.photo4;
      this.photos.photo5 = this.job.photo5;
      this.photos.photo6 = this.job.photo6;

      this.newPhotos.photo1 = this.job.photo1;
      this.newPhotos.photo2 = this.job.photo2;
      this.newPhotos.photo3 = this.job.photo3;
      this.newPhotos.photo4 = this.job.photo4;
      this.newPhotos.photo5 = this.job.photo5;
      this.newPhotos.photo6 = this.job.photo6;
    },
    ToggleImageStatus(name) {
      if (!name) return;

      if (this.newPhotos[name] != this.photos[name])
        this.newPhotos[name] = this.photos[name];
      else this.newPhotos[name] = null;
    },
    Submit() {
      this.isSubmitting = true;
      /* return axios
        .post(
          process.env.VUE_APP_API_PROTOCOL +
            "://" +
            process.env.VUE_APP_API_HOST +
            ":" +
            process.env.VUE_APP_API_PORT +
            "/api/jobs/" +
            this.job.id +
            "/edit",
          payload
        )
        .then((response) => {
          console.log(response.data);

          var job = response.data;
          if (job && job.id) {
            console.log("Valid Job");
            this.isSubmitting = false;
            this.$store.commit("AddSnackbar", {
              message: "Job updated successfully.",
            });
            this.$router.push("/job/" + this.job.id);
          } else console.log("Invalid Job");
        })
        .catch(function (err) {
          console.log(err);
          this.$store.commit("AddSnackbar", {
            message: err,
            type: "error",
          });
        });*/
      let payload = { ...this.newPhotos };
      payload.id = this.job.id;
      return this.$store
        .dispatch("removeImagesSingleJob", payload)
        .then(() => {
          this.$store.commit("SetSnackbarData", {
            message: "Job updated successfully.",
          });
          this.show = false;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    Rand() {
      this.rand = Date.now();
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  mounted() {
    this.InitImages();
  },
  watch: {
    show(newVal, old) {
      if (old != newVal && newVal == true) {
        this.InitImages();
      }
    },
  },
};
</script>

<style scoped>
.r-img-toggle {
  border-radius: 4px;
  overflow: hidden;
  padding: 2px;
  border: 4px solid transparent;
}
.r-img-toggle-active {
  border: 4px solid red;
}
</style>