import Axios from "axios"

const state = {
  statsData: {}
}
const mutations = {
  setStatsData(state, payload) {
    state.statsData = payload;
  }
}
const actions = {



  get_stats_data(context) {
    console.log("get_stats_data now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/stats",
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (res) {

        context.commit("setStatsData", res.data);
        return res.data;


      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
            redirect: "/dashboard",

          });

          return Promise.reject(error);
        });

  },

}
const getters = {
  getStatsData(state) {
    return state.statsData;
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}