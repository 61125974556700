var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"4","sm":"4","lg":"2"}},[_c('p',[_vm._v("Job Label")]),(this.checkPermission('can_view_job_label_pdf'))?_c('v-btn',{staticClass:"pa-5",attrs:{"loading":this.indexClicked == 'job_label',"disabled":this.indexClicked != null},on:{"click":function($event){return _vm.GetPDF('job_label')}}},[_vm._v("Job Label")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"4","sm":"4","lg":"2"}},[_c('p',[_vm._v("Function")]),(this.checkPermission('can_send_sms'))?_c('v-btn',{staticClass:"mb-4 pa-5",attrs:{"loading":this.indexClicked == 'send_text',"disabled":this.indexClicked != null},on:{"click":function($event){_vm.smsModal = true}}},[_vm._v("Send SMS")]):_vm._e(),(this.checkPermission('can_ber'))?_c('v-btn',{staticClass:"mb-4 pa-5",attrs:{"loading":this.indexClicked == 'ber',"disabled":this.indexClicked != null},on:{"click":function($event){return _vm.SetBER()}}},[_vm._v(_vm._s(!this.job.bBer ? "Set BER" : "Unset BER"))]):_vm._e(),(this.checkPermission('can_rnr'))?_c('v-btn',{staticClass:"mb-4 pa-5",attrs:{"loading":(this.indexClicked == 'rnr' && this.rnr_modal == true) ||
        (this.job.bRnr && this.indexClicked == 'rnr'),"disabled":(this.indexClicked != null && this.rnr_modal == true) ||
        (this.job.bRnr && this.indexClicked == 'rnr')},on:{"click":function($event){return _vm.SetRNR()}}},[_vm._v(_vm._s(!this.job.bRnr ? "Set RNR" : "Unset RNR"))]):_vm._e(),(this.checkPermission('can_edit_job'))?_c('v-btn',{staticClass:"mb-4 pa-5",on:{"click":function($event){return _vm.gotoEditJob()}}},[_vm._v("Edit")]):_vm._e(),(this.checkPermission('can_delete_job'))?_c('v-btn',{staticClass:"pa-5",attrs:{"color":"error"},on:{"click":function($event){_vm.deleteModal = true}}},[_vm._v("Delete")]):_vm._e(),(this.checkPermission('can_delete_job'))?_c('ConfirmationDialog',{attrs:{"loader":_vm.is_deleting,"itemName":'job'},on:{"cancelled":function($event){_vm.deleteModal = false},"confirmed":function($event){return _vm.deleteJob()}},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}}):_vm._e(),(this.checkPermission('can_rnr'))?_c('RnrDialog',{on:{"cancelled":function($event){return _vm.RNRcancelled()}},model:{value:(_vm.rnr_modal),callback:function ($$v) {_vm.rnr_modal=$$v},expression:"rnr_modal"}}):_vm._e(),(this.checkPermission('can_ber'))?_c('BerDialog',{on:{"cancelled":function($event){return _vm.BERcancelled()}},model:{value:(_vm.ber_modal),callback:function ($$v) {_vm.ber_modal=$$v},expression:"ber_modal"}}):_vm._e()],1),(
      this.job.status_id >= 4 &&
      (this.checkPermission('can_view_sales_quotations') ||
        this.checkPermission('can_email_sales_quotations'))
    )?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"4","sm":"4","lg":"2"}},[_c('p',[_vm._v("Quotation")]),(this.checkPermission('can_view_sales_quotations'))?_c('v-btn',{staticClass:"mb-4 pa-5",attrs:{"loading":this.indexClicked == 'sales_quotation',"disabled":this.indexClicked != null},on:{"click":function($event){return _vm.GetPDF('sales_quotation')}}},[_vm._v("View Sales Quotation")]):_vm._e(),(this.checkPermission('can_email_sales_quotations'))?_c('v-btn',{staticClass:"pa-5",attrs:{"loading":this.indexClicked == 'email'},on:{"click":function($event){_vm.EmailSalesQVisible = true}}},[_vm._v("Email Sales Quotation")]):_vm._e(),(this.checkPermission('can_email_sales_quotations'))?_c('EmailSalesQuotationModal',{on:{"confirmed":_vm.EmailPDF},model:{value:(_vm.EmailSalesQVisible),callback:function ($$v) {_vm.EmailSalesQVisible=$$v},expression:"EmailSalesQVisible"}}):_vm._e()],1):_vm._e(),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"4","sm":"4","lg":"2"}},[_c('p',[_vm._v("Job Report")]),(this.checkPermission('can_add_photos'))?_c('v-btn',{staticClass:"mb-4 pa-5",on:{"click":function($event){return _vm.ShowUploadImagesForm()}}},[_vm._v("Add Photos")]):_vm._e(),(
        this.checkPermission('can_add_photos') &&
        (this.job.photo1 ||
          this.job.photo2 ||
          this.job.photo3 ||
          this.job.photo4 ||
          this.job.photo5 ||
          this.job.photo6)
      )?_c('v-btn',{staticClass:"mb-4 pa-5",on:{"click":function($event){_vm.unlink_images_form_visible = true}}},[_vm._v("Remove Photos")]):_vm._e(),(this.checkPermission('can_view_job_report_pdf'))?_c('v-btn',{staticClass:"mb-4 pa-5",attrs:{"loading":this.indexClicked == 'job_report',"disabled":this.indexClicked != null},on:{"click":function($event){return _vm.GetPDF('job_report')}}},[_vm._v("View Job Report")]):_vm._e(),(this.checkPermission('can_email_job_report'))?_c('v-btn',{staticClass:"pa-5",attrs:{"loading":this.indexClicked == 'email',"disabled":this.indexClicked != null},on:{"click":function($event){_vm.EmailJobReportVisible = true}}},[_vm._v("Email Job Report")]):_vm._e(),_c('EmailJobReportModal',{on:{"confirmed":_vm.EmailPDF},model:{value:(_vm.EmailJobReportVisible),callback:function ($$v) {_vm.EmailJobReportVisible=$$v},expression:"EmailJobReportVisible"}})],1),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"4","sm":"4","lg":"2"}},[_c('p',[_vm._v("Delivery")]),(this.checkPermission('can_manually_deliver'))?_c('v-btn',{staticClass:"mb-4 pa-5",on:{"click":function($event){return _vm.ManualDelivery()}}},[_vm._v("Manual Delivery")]):_vm._e(),_c('v-btn',{staticClass:"mb-4 pa-5",attrs:{"loading":this.indexClicked == 'delivery_note',"disabled":this.indexClicked != null},on:{"click":function($event){return _vm.GetPDF('delivery_note')}}},[_vm._v("View Delivery Note")]),(
        this.job.time_signature &&
        this.job.svg_signature &&
        this.checkPermission('can_view_signed_delivery_note_pdf')
      )?_c('v-btn',{staticClass:"mb-4 pa-5",attrs:{"loading":this.indexClicked == 'signed_delivery_note',"disabled":this.indexClicked != null},on:{"click":function($event){return _vm.GetPDF('signed_delivery_note')}}},[_vm._v("View Signed Delivery Note")]):_vm._e(),(
        this.job.status_id >= 8 &&
        this.checkPermission('can_email_signed_delivery_note_pdf')
      )?_c('v-btn',{staticClass:"mb-4 pa-5",attrs:{"loading":this.indexClicked == 'signed_delivery_note',"disabled":this.indexClicked != null},on:{"click":function($event){_vm.EmailSignedDNVisible = true}}},[_vm._v("Email Signed Delivery Note")]):_vm._e(),_c('EmailSignedDeliveryNoteModal',{on:{"confirmed":_vm.EmailPDF},model:{value:(_vm.EmailSignedDNVisible),callback:function ($$v) {_vm.EmailSignedDNVisible=$$v},expression:"EmailSignedDNVisible"}}),(this.job.status_id >= 9)?_c('v-btn',{staticClass:"mb-4 pa-5",attrs:{"loading":this.indexClicked == 'invoice',"disabled":this.indexClicked != null},on:{"click":function($event){return _vm.GetPDF('invoice')}}},[_vm._v("View Invoice")]):_vm._e(),(
        this.job.status_id >= 9 && this.checkPermission('can_email_invoice')
      )?_c('v-btn',{staticClass:"pa-5",attrs:{"loading":this.indexClicked == 'email',"disabled":this.indexClicked != null},on:{"click":function($event){_vm.EmailInvoiceVisible = true}}},[_vm._v("Email Invoice")]):_vm._e(),_c('EmailInvoiceModal',{on:{"confirmed":_vm.EmailPDF},model:{value:(_vm.EmailInvoiceVisible),callback:function ($$v) {_vm.EmailInvoiceVisible=$$v},expression:"EmailInvoiceVisible"}})],1),(this.job.status_id < 8 && this.checkPermission('can_advance_job'))?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"4","sm":"4","lg":"2"}},[_c('p',[_vm._v("Advance Job")]),_c('v-btn',{staticClass:"pa-5",attrs:{"color":"success"},on:{"click":function($event){return _vm.gotoAdvanceJob()}}},[_vm._v(_vm._s(this.advance_button_text))])],1):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.upload_images_form_visible),callback:function ($$v) {_vm.upload_images_form_visible=$$v},expression:"upload_images_form_visible"}},[_c('v-card',{staticClass:"pa-4",attrs:{"color":"white"}},[_c('UploadJobImagesForm')],1)],1),(
      this.job.photo1 ||
      this.job.photo2 ||
      this.job.photo3 ||
      this.job.photo4 ||
      this.job.photo5 ||
      this.job.photo6
    )?_c('UnlinkJobImagesForm',{model:{value:(_vm.unlink_images_form_visible),callback:function ($$v) {_vm.unlink_images_form_visible=$$v},expression:"unlink_images_form_visible"}}):_vm._e(),_c('ManualDeliver',{model:{value:(_vm.manualDeliveryModal),callback:function ($$v) {_vm.manualDeliveryModal=$$v},expression:"manualDeliveryModal"}}),(this.job)?_c('SMSModal',{model:{value:(_vm.smsModal),callback:function ($$v) {_vm.smsModal=$$v},expression:"smsModal"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }