<template>
  <v-tab-item class="pa-3">
    <v-row>
      <v-col class="px-5" md="3" sm="6" cols="12">
        <JobBasicInfo />
      </v-col>
      <JobTimeInfo />
      <JobEngPartSummary />
      <JobEngQuotationTotal />
      <EngNotes />
    </v-row>
  </v-tab-item>
</template>

<script>
import JobBasicInfo from "./JobBasicInfo";
import JobTimeInfo from "./JobTimeInfo";
import JobEngPartSummary from "./JobEngPartSummary";
import JobEngQuotationTotal from "./JobEngQuotationTotal";
import EngNotes from "./JobEngNotes.vue";

export default {
  name: "EngQuotationTab",
  components: {
    JobBasicInfo,
    JobTimeInfo,
    JobEngPartSummary,
    JobEngQuotationTotal,
    EngNotes,
  },
};
</script>

<style>
</style>