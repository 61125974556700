<template>
  <div>
    <v-btn @click="dialogActive = true" color="success">Edit</v-btn>
    <v-dialog
      v-model="dialogActive"
      ref="JobReportEditModal"
      :persistent="isSubmitting"
      width="400px"
    >
      <v-card>
        <v-form
          ref="JobReportEditForm"
          v-model="valid"
          @submit.prevent="Submit"
        >
          <v-row class="pa-4 ma-0">
            <v-col cols="12">
              <h4 class="mb-2">Fault</h4>

              <v-textarea
                solo
                v-model="formFields.fault"
                name="fault"
              ></v-textarea>
              <h4 class="mb-2">Root Cause Analysis</h4>

              <v-textarea
                solo
                v-model="formFields.faultdetails"
                name="faultdetails"
              ></v-textarea>
              <h4 class="mb-2">Repairs</h4>

              <v-textarea
                solo
                v-model="formFields.repairs"
                name="repairs"
              ></v-textarea>
              <h4 class="mb-2">Final Test</h4>

              <v-textarea
                solo
                v-model="formFields.finaltest"
                name="finaltest"
              ></v-textarea>

              <div class="mx-0 mt-3 d-flex justify-space-between">
                <v-btn
                  :disabled="isSubmitting"
                  color="error"
                  text
                  @click="dialogActive = false"
                  >Cancel</v-btn
                >

                <v-btn
                  :disabled="!valid || isSubmitting"
                  class="success"
                  type="submit"
                  :loading="isSubmitting"
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "JobReportEditModal",
  data() {
    return {
      dialogActive: false,
      valid: false,
      formFields: {
        fault: "",
        faultdetails: "",
        repairs: "",
        finaltest: "",
      },
      isSubmitting: false,
    };
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
  },
  mounted() {
    this.formFields.fault = this.job.fault;
    this.formFields.faultdetails = this.job.faultdetails;
    this.formFields.repairs = this.job.repairs;
    this.formFields.finaltest = this.job.finaltest;
  },
  methods: {
    validate() {
      return this.$refs.JobReportEditForm.validate();
    },
    Submit() {
      if (this.validate()) {
        // this.formFields.contact_id = this.CurrentSingleContact.id;
        this.isSubmitting = true;
        console.log(this.formFields);
        console.log(this.validate());
        var payload = {
          id: this.job.id,
          ...this.formFields,
        };
        this.$store
          .dispatch("updateSingleJob", payload)
          .then(() => {
            this.$store.commit("SetSnackbarData", {
              message: "Job updated successfully.",
            });
            this.dialogActive = false;
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>

<style>
</style>