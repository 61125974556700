<template>
  <v-row class="documents-table">
    <v-simple-table v-if="Documents.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-left"
              v-for="(value, key) in table_headers"
              :key="key"
            >
              {{ key }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="doc in Documents">
            <tr :key="doc.id" v-if="is_admin_condition(doc.is_admin)">
              <!-- <td>{{ doc.name }}</td> -->
              <td>{{ doc.original }}</td>
              <td>{{ doc.user ? doc.user.name : "" }}</td>
              <td>{{ doc.created }}</td>
              <td>{{ doc.modified }}</td>
              <td>
                <v-btn
                  v-if="isExtensionAllowedToBeViewedInNewTab(doc.original)"
                  @click="viewDoc(doc.id)"
                  :disabled="is_deleting"
                  class="mx-2"
                  color="primary"
                >
                  <v-icon dense color="white">mdi-open-in-new</v-icon>
                </v-btn>

                <v-btn
                  @click="downloadDoc(doc.id, doc.original)"
                  :disabled="is_deleting"
                  class="mx-2"
                  color="success"
                  ><v-icon dense color="white">
                    mdi-cloud-download
                  </v-icon></v-btn
                >
                <v-btn
                  @click="deleteDoc(doc.id, doc.job_id)"
                  :disabled="is_deleting"
                  class="ml-2"
                  color="error"
                  ><v-icon dense color="white"> mdi-delete </v-icon></v-btn
                >
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <div
      class="no-docs-found d-flex flex-column justify-center align-center mx-auto my-8"
      v-else
    >
      No Documents Found, Upload Some?
    </div>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "DocumentsTable",
  data() {
    return {
      is_deleting: false,
      disable_table: false,
      extensions_with_view_action_in_new_tab: [
        "zip",
        "rar",
        "7zip",
        "7z",
        "tar",
      ],
      table_headers: {
        // Document: "Document",
        Uploaded: "Uploaded",
        "Uploaded By": "Uploaded By",
        Comments: "Comments",
        Pause: "Pause",
        actions: "Actions",
      },
    };
  },
  methods: {
    isExtensionAllowedToBeViewedInNewTab(filename) {
      // Get the file extension by splitting the filename at the last dot
      const fileExtension = filename.split(".").pop().toLowerCase();

      // Check if the file extension is in the list of allowed extensions
      return !this.extensions_with_view_action_in_new_tab.includes(
        fileExtension
      );
    },
    is_admin_condition(is_admin) {
      if (!is_admin) return true;

      if (is_admin && this.checkPermission("see_admin_docs")) return true;

      return false;
    },
    deleteDoc($doc_id, $job_id) {
      this.is_deleting = true;
      return axios
        .delete(
          process.env.VUE_APP_API_PROTOCOL +
            "://" +
            process.env.VUE_APP_API_HOST +
            ":" +
            process.env.VUE_APP_API_PORT +
            "/api/documents/" +
            $doc_id
        )
        .then(() => {
          console.log("job id is:" + $job_id);

          if ($job_id != "") {
            this.disable_table = true;
            this.$store.dispatch("fetchSingleJob", $job_id).finally(() => {
              this.disable_table = false;
            });
          }
        })
        .catch((error) => {
          if (error.response.data.message == "File not found in storage.")
            console.log("ddd"); //remove the doc from the array here.

          var job = this.$store.getters.getCurrentSingleJob;
          var removeIndex = job.documents
            .map(function (doc) {
              return doc.id;
            })
            .indexOf($doc_id);
          job.documents.splice(removeIndex, 1);

          this.$store.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.is_deleting = false;
        });
    },
    downloadDoc($doc_id, $doc_name) {
      this.is_deleting = true;
      return axios
        .get(
          process.env.VUE_APP_API_PROTOCOL +
            "://" +
            process.env.VUE_APP_API_HOST +
            ":" +
            process.env.VUE_APP_API_PORT +
            "/api/documents/" +
            $doc_id,
          { responseType: "blob" } // important
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", $doc_name);
          document.body.appendChild(link);

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.$store.commit("SetSnackbarData", {
            message: error,
            type: "error",
          });
        })
        .finally(() => {
          this.is_deleting = false;
        });
    },

    viewDoc($doc_id) {
      this.is_deleting = true;
      return axios
        .get(
          process.env.VUE_APP_API_PROTOCOL +
            "://" +
            process.env.VUE_APP_API_HOST +
            ":" +
            process.env.VUE_APP_API_PORT +
            "/api/documents/" +
            $doc_id,
          { responseType: "blob" } // important
        )
        .then((response) => {
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement("a");
          // link.href = url;

          var blob = new Blob([response.data], { type: "application/pdf" });
          var blobURL = URL.createObjectURL(blob);
          window.open(blobURL);
          // link.setAttribute("download", $doc_name);
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
        })

        .catch((error) => {
          //cuz its a blob request the error isn't read with a message
          this.$store.commit("SetSnackbarData", {
            message: error,
            type: "error",
          });
        })

        .finally(() => {
          this.is_deleting = false;
        });
    },
  },
  computed: {
    Documents() {
      return this.$store.getters.getCurrentSingleJob.documents;
    },
  },
};
</script>

<style>
</style>