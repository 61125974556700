<template>
  <v-dialog
    ref="EditDepartmentDialog"
    v-model="show"
    :persistent="isSubmitting"
    width="400px"
  >
    <v-card>
      <v-form ref="EditPhraseForm" v-model="valid" @submit.prevent="Submit">
        <v-row class="pa-4 ma-0">
          <v-col cols="12">
            <h4 class="mb-2">Department Name</h4>
            <v-text-field
              solo
              :rules="[rules.required]"
              v-model="formFields.name"
              name="name"
            ></v-text-field>

            <h4 class="mb-2">Phrase</h4>

            <v-textarea
              solo
              @wheel="$event.target.blur()"
              v-model.number="formFields.phrase"
              name="phrase"
            ></v-textarea>

            <div class="mx-0 mt-3 d-flex justify-space-between">
              <v-btn
                :disabled="isSubmitting"
                color="error"
                text
                @click="show = false"
                >Cancel</v-btn
              >

              <v-btn
                :disabled="!valid || isSubmitting"
                class="success"
                type="submit"
                :loading="isSubmitting"
                >Save</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditPhraseForm",
  props: {
    value: Boolean,
    currentEditItem: Object,
  },
  data() {
    return {
      valid: false,
      isUpdating: false,
      isSubmitting: false,
      formFields: {
        id: "",
        name: "",
        phrase: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
        emailPattern: (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          "E-mail must be valid",
      },
    };
  },
  methods: {
    ResetForm() {
      this.$refs.EditPhraseForm.reset();
    },
    Submit() {
      if (this.validate()) {
        this.isSubmitting = true;
        console.log("Editing Department");
        console.log(this.formFields);
        console.log(this.validate());
        this.$store
          .dispatch("UpdateCommonPhrase", this.formFields)
          .then((new_department) => {
            if (new_department) {
              this.$store.commit("SetSnackbarData", {
                message: "Phrase updated successfully.",
              });
              this.show = false;
              this.ResetForm();
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.EditPhraseForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    // this.formFields.name = this.currentEditItem.name;
    // this.formFields.id = this.currentEditItem.id;
    // this.formFields.nominal_code = this.currentEditItem.nominal_code;
  },
  watch: {
    show(newVal, old) {
      if (old != newVal && newVal == true) {
        this.formFields.name = this.currentEditItem.name;
        this.formFields.id = this.currentEditItem.id;
        this.formFields.phrase = this.currentEditItem.phrase;
      }
    },
  },
};
</script>

<style>
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.v-application--is-ltr
  .v-input--reverse
  .v-input__slot
  .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}
.v-application--is-rtl
  .v-input--reverse
  .v-input__slot
  .v-input--selection-controls__input {
  margin-left: 0;
  margin-right: 8px;
}

.v-input--expand .v-input__slot .v-label {
  display: block;
  flex: 1;
}
</style>