<template>
  <v-dialog v-model="show" width="500" :persistent="this.is_loading">
    <v-card>
      <v-card-title class="headline"> Enter a comment </v-card-title>

      <v-card-text>
        <v-text-field
          solo
          name="comment"
          :placeholder="'Enter a comment'"
          v-model="comment"
          hide-details
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :loading="this.is_loading"
          @click="PauseJob"
        >
          Pause Job
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PauseJobModal",
  props: {
    value: Boolean,
    job_id: null,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      comment: "",
      is_loading: false,
    };
  },
  methods: {
    PauseJob() {
      var self = this;
      if (!this.job_id) return;
      this.is_loading = true;
      this.$store
        .dispatch("switchJobPausedStatus", {
          id: this.job_id,
          comments: this.comment,
        })
        .then(() => {
          this.$store.commit("SetSnackbarData", {
            message: "The Job has been " + "paused",
          });
        })
        .finally(function () {
          self.is_loading = false;
          self.show = false;
        });
    },
  },
};
</script>

<style>
</style>