<template>
  <v-tab-item class="pa-3">
    <PartsTable :parts="this.parts" />
    <v-btn
      class="ma-3"
      @click="open_modal"
      v-if="this.checkPermission('can_add_job_parts')"
      >Add Part</v-btn
    >
    <AddPartModal
      v-model="show"
      v-if="this.checkPermission('can_add_job_parts')"
    />
  </v-tab-item>
</template>

<script>
import PartsTable from "./PartsTable";
import AddPartModal from "./AddPartModal";
export default {
  name: "PartsTab",
  data() {
    return {
      show: false,
    };
  },
  props: ["parts"],
  components: {
    PartsTable,
    AddPartModal,
  },
  methods: {
    open_modal() {
      this.show = !this.show;
    },
  },
};
</script>

<style>
</style>