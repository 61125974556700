<template>
  <SingleContactInfo
    v-if="CurrentSingleContact != null && CurrentSingleContact.id"
  />
</template>

<script>
import SingleContactInfo from "../../components/management/contacts/SingleContactInfo.vue";
export default {
  name: "SingleContactPage",
  components: {
    SingleContactInfo,
  },
  computed: {
    CurrentSingleContact() {
      return this.$store.getters.GetCurrentSingleContact;
    },
  },
  mounted() {
    if (this.$store.getters.GetCurrentSingleContact == null) {
      this.$store.dispatch("fetchSingleContact", this.$route.params.contact_id);
    }
  },
};
</script>

<style>
</style>