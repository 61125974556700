<template>
  <v-row class="ma-0 pa-6">
    <div class="flex-grow-1 d-flex justify-space-between mb-6">
      <h1>Job Components - Stock Report</h1>
      <!-- <v-btn
        color="success"
        @click="DownloadPDF('awaiting_delivery/pdf')"
        :loading="IsFetchingPDF"
        :disabled="IsFetchingPDF"
        >Download PDF</v-btn
      > -->
    </div>

    <div class="d-flex flex-column">
      <h4 class="mb-2">Search</h4>
      <v-text-field
        v-model="filter"
        solo
        style="max-width: 400px"
        hide-details
        placeholder="Type to search..."
        clearable
        @click:clear="filter = ''"
        :disabled="!this.ComponentList.length > 0"
      ></v-text-field>
    </div>
    <v-data-table
      :headers="headers"
      :items="filtered_component_list"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      class="elevation-1"
      style="width: 100%"
      :loading="this.isLoadingComponents"
      loading-text="Loading... Please wait"
      @page-count="pageCount = $event"
      calculate-widths
      hide-default-footer
    >
      <template v-slot:item.stock_value="{ item }">
        {{ currencyFormatter.format(item.stock_value) }}
      </template>

      <!-- <template v-slot:item.actions="{ item }">
        <v-btn :to="'/job/' + item.id" color="primary">View</v-btn>
      </template> -->

      <template slot="body.append">
        <tr>
          <td><strong>Total</strong></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <!-- <strong>{{ NetTotalTotal | toCurrency }}</strong> -->
          </td>
          <td>
            <strong>{{ TotalStockValue | toCurrency }}</strong>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
      ></v-pagination>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "JobComponentsStockReport",
  data() {
    return {
      currencyFormatter: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      is_loading: false,
      isLoadingComponents: false,
      page: 1,
      pageCount: 0,
      filter: "",
      itemsPerPage: 20,
      IsFetchingPDF: false,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          width: 75,
        },
        { text: "Part Code", value: "part_code" },
        { text: "Description", value: "description", width: 250 },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Supplier", value: "company.name" },
        { text: "Order Code", value: "order_code" },
        { text: "Unit Price", value: "unit_price" },
        { text: "Qty In Stock", value: "qty_in_stock" },
        {
          text: "Stock Value",
          value: "stock_value",
          sort: this.customStockValueSort(),
        },

        // { text: "Actions", value: "actions", sortable: false, width: 100 },
      ],
      jobs: [],
    };
  },
  methods: {
    customStockValueSort(a, b) {
      console.log(a);
      return a > b;
    },
    // calcStockValue(stock_count, unit_price) {
    //   const a = parseFloat(stock_count) || 0;
    //   const b = parseFloat(unit_price) || 0;
    //   const temp = a * b;
    //   const total = Math.round((temp + Number.EPSILON) * 100) / 100;
    //   return total;
    // },
    DownloadPDF(endpoint) {
      this.IsFetchingPDF = true;
      return this.$store.dispatch("DownloadReportPDF", endpoint).finally(() => {
        this.IsFetchingPDF = false;
      });
    },
  },
  mounted() {
    this.isLoadingComponents = true;
    this.$store.dispatch("getComponentsWithValue").finally(() => {
      this.isLoadingComponents = false;
    });
  },
  computed: {
    ComponentList() {
      return this.$store.getters.GetComponentList;
    },
    TotalStockValue() {
      let total = 0;
      if (this.ComponentList.length > 0) {
        for (let i = 0; i < this.ComponentList.length; i++) {
          const temp = parseFloat(this.ComponentList[i].stock_value) || 0;
          // let component_value = this.calcStockValue(
          //   temp.qty_in_stock,
          //   temp.unit_price
          // );
          if (temp > 0) total = total + temp;
        }
      }
      return total;
    },
    filtered_component_list() {
      var filter = "";
      if (this.filter) filter = this.filter.trim();

      if (!filter.length > 0) return this.ComponentList;
      return this.ComponentList.filter((component) => {
        return (
          component["manufacturer"].toString().toLowerCase().includes(filter) ||
          (component["package"] &&
            component["package"].toString().toLowerCase().includes(filter)) ||
          (component["marking_code"] &&
            component["marking_code"]
              .toString()
              .toLowerCase()
              .includes(filter)) ||
          (component["order_code"] &&
            component["order_code"]
              .toString()
              .toLowerCase()
              .includes(filter)) ||
          component["part_code"]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          (component["description"] &&
            component["description"]
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase())) ||
          (component["company"] &&
            component["company"]["name"]
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase()))
        );
        // str.includes("world");
      });
    },
    NetTotalTotal() {
      var total = 0;
      for (let i = 0; i < this.jobs.length; i++) {
        total = total + this.jobs[i]["NetTotal"] || 0;
      }
      return total;
    },
  },
};
</script>

<style>
</style>