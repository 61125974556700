import Axios from 'axios';

const state = {
  mitchell_cables: [],
  servo_connectors: [],
};
const mutations = {
  SetMitchellCables(state, payload) {
    state.mitchell_cables = payload;
  },
  SetServoConnectors(state, payload) {
    state.servo_connectors = payload;
  },

  // RemovePhraseFromList(state, payload) {
  //   state.common_phrases.splice(state.common_phrases.findIndex(function (phrase) {
  //     return phrase.id === payload;
  //   }), 1);
  // },
};
const actions = {
  fetchMitchellCables(context) {
    console.log('getUsersByTag store action with payload');
    var token = context.getters.getCurrentToken;
    console.log('token: ' + token);

    if (token != '')
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/mitchell_cables'
      )
        .then((response) => {
          console.log(response.data);
          context.commit('SetMitchellCables', response.data);
          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },

  fetchServoConnectors(context) {
    console.log('getUsersByTag store action with payload');
    var token = context.getters.getCurrentToken;
    console.log('token: ' + token);

    if (token != '')
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/servo_connectors'
      )
        .then((response) => {
          console.log(response.data);
          context.commit('SetServoConnectors', response.data);
          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },

  deleteServoConnector(context, payload) {
    var token = context.getters.getCurrentToken;
    console.log('token: ' + token);

    if (token != '')
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/servo_connectors/' +
          payload
      )
        .then((response) => {
          console.log(response.data);
          context.commit('SetServoConnectors', response.data);
          context.commit('SetSnackbarData', {
            message: 'Deleted connector successfully',
            timeout: 8000,
            type: 'success',
          });
          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },

  deleteServoMitchellCable(context, payload) {
    var token = context.getters.getCurrentToken;
    console.log('token: ' + token);

    if (token != '')
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/mitchell_cables/' +
          payload
      )
        .then((response) => {
          console.log(response.data);
          context.commit('SetMitchellCables', response.data);
          context.commit('SetSnackbarData', {
            message: 'Deleted cable successfully',
            timeout: 8000,
            type: 'success',
          });
          return response.data;
        })
        .catch(function(error) {
          console.log(error.response.data.message);
          context.commit('SetSnackbarData', {
            message: error.response.data.message,
            type: 'error',
          });

          return Promise.reject(error);
        });
  },
};
const getters = {
  GetMitchellCables(state) {
    return state.mitchell_cables;
  },

  GetServoConnectors(state) {
    return state.servo_connectors;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
