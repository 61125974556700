<template>
  <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12">
    <div class="table-cont">
      <div class="table-header pa-3 d-flex">
        <div class="table-property-label">Time</div>
      </div>
      <div class="table-row">
        <div class="table-property-label">Hourly Rate</div>
        <div class="table-property-value">
          {{ this.job.engTimerate | toCurrency }}
        </div>
      </div>
      <div class="table-row">
        <div class="table-property-label">Hours</div>
        <div class="table-property-value">{{ this.job.engTime }}</div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Quotation</div>
        <div class="table-property-value">
          {{ this.job.engTimeQuote | toCurrency }}
        </div>
      </div>

      <!-- <div class="table-row">
        <div class="table-property-label">Notes</div>
        <div class="table-property-value">
          {{ this.job.engNotes }}
        </div>
      </div> -->
    </div>
  </v-col>
</template>

<script>
export default {
  name: "JobTimeInfo",
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
  },
};
</script>

<style>
</style>