<template>
  <div>
    <apexchart
      type="bar"
      width="100%"
      :options="warranties.chartOptions"
      :series="warranties.series"
      v-if="this.warranties.series[0].data.length > 0"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "WarrantiesChart",

  data() {
    return {
      warranties: {
        series: [
          {
            name: "",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,

            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          legend: {
            onItemClick: {
              toggleDataSeries: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                hideOverflowingLabels: false,

                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              if (opts.seriesIndex == 0) return;
              return val;
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },

          xaxis: {
            categories: [],
            position: "bottom",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              formatter: function (val) {
                return val;
              },
            },
          },
          title: {
            text: "",
            floating: true,
            offsetY: 330,
            align: "center",
            style: {
              color: "#444",
            },
          },
        },
      },
    };
  },
  computed: {
    warranties_data() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats["warranties"]) return Stats["warranties"];
      else return [];
    },
  },

  mounted() {
    var warranties_data = {
      "x-axis": [],
      series: [
        {
          name: "With Warranty",
          data: [],
        },
        {
          name: "Without Warranty",
          data: [],
        },
      ],
    };

    for (let i = 0; i < this.warranties_data.length; i++) {
      warranties_data["x-axis"].push(this.warranties_data[i]["TheMonth"]);
      warranties_data.series[0].data.push(this.warranties_data[i]["Yes"]);
      warranties_data.series[1].data.push(this.warranties_data[i]["No"]);
    }
    this.warranties.chartOptions.xaxis.categories = warranties_data["x-axis"];
    this.warranties.series = warranties_data.series;
  },
};
</script>

<style>
</style>