<template>
  <!-- <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12"> -->
  <div class="table-cont">
    <div class="table-header pa-3 d-flex">
      <div class="table-property-label">Internal Note</div>
    </div>

    <div class="table-row d-flex flex-column">
      <div class="table-property-label mb-3">Internal Note</div>
      <div
        class="table-property-value"
        style="word-break: normal; white-space: pre-line"
      >
        {{ this.job.internalnote }}
        <v-btn
          style="margin-left: auto"
          small
          fab
          icon
          color="cyan"
          v-if="this.checkPermission('can_edit_internal_note')"
          @click.stop="EditNoteDialog = true"
        >
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
      </div>
    </div>
    <EditInternalNote
      v-model="EditNoteDialog"
      :note="this.job.internalnote"
      :job_id="this.job.id"
      v-if="this.checkPermission('can_edit_internal_note')"
    />
  </div>

  <!-- </v-col> -->
</template>

<script>
import EditInternalNote from "./EditInternalNote";
export default {
  name: "JobInternalNote",
  // props: ["job"],
  components: {
    EditInternalNote,
  },
  data() {
    return {
      EditNoteDialog: false,
    };
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
  },
};
</script>

<style>
</style>