<template>
  <div>
    <div class="d-flex justify-space-between align-end mb-8">
      <div class="d-flex flex-column pa-4 mx-0">
        <h4 class="mb-2">Filter</h4>
        <v-text-field
          v-model="filter"
          solo
          style="max-width: 400px"
          hide-details
          placeholder="Type to filter..."
          clearable
          @click:clear="filter = ''"
          :disabled="!this.ContactList.length > 0 && !this.CompanyID"
        ></v-text-field>
      </div>

      <AddContactForm
        v-if="this.checkPermission('can_add_contacts')"
        :company_id="CompanyID"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="filtered_contact_list"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      calculate-widths
      hide-default-footer
      :loading="!this.ContactList.length > 0 && !this.CompanyID"
      loading-text="Loading... Please wait"
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.include_cc_quotation="{ item }">
        <v-simple-checkbox
          v-model="item.include_cc_quotation"
          disabled
        ></v-simple-checkbox>
      </template>

      <template v-slot:item.include_cc_delivery="{ item }">
        <v-simple-checkbox
          v-model="item.include_cc_delivery"
          disabled
        ></v-simple-checkbox>
      </template>

      <template v-slot:item.company_name>
        <span>{{ Company }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="VisitItem(item)"> mdi-eye </v-icon>
        <v-icon
          small
          @click="DeleteModalActive(item)"
          v-if="checkPermission('can_delete_contacts')"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
      ></v-pagination>
    </div>
    <ConfirmationDialog
      v-if="this.checkPermission('can_delete_contacts')"
      v-model="deleteModal"
      @cancelled="
        deleteModal = false;
        contact_to_delete = null;
      "
      @confirmed="deleteContact()"
      :loader="is_deleting"
      :itemName="'contact'"
    />
  </div>
</template>

<script>
import AddContactForm from "../contacts/AddContactForm.vue";
import ConfirmationDialog from "../../global/ConfirmationDialog.vue";

export default {
  name: "CompanyContactsTable",
  components: {
    AddContactForm,
    ConfirmationDialog,
  },
  data() {
    return {
      page: 1,
      deleteModal: false,
      is_deleting: false,
      contact_to_delete: null,
      pageCount: 0,
      filter: "",
      itemsPerPage: 20,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          width: 75,
        },
        { text: "Name", value: "name", width: 200 },
        { text: "Company", value: "company_name", width: 300, sortable: false },
        { text: "Phone", value: "phone", width: 150 },
        { text: "Fax", value: "fax", width: 150 },
        { text: "Mobile", value: "mobile", width: 150 },
        { text: "Email", value: "email", width: 325 },
        { text: "CC Quotation", value: "include_cc_quotation", width: 125 },
        { text: "CC Delivery", value: "include_cc_delivery", width: 125 },
        { text: "Actions", value: "actions", sortable: false, width: 100 },
        { text: "Notes", value: "notes", width: 200 },
      ],
    };
  },
  methods: {
    VisitItem(contact) {
      console.log(contact);
      this.$store.commit("SetCurrentSingleContact", contact);
      this.$router.push("/management/contacts/" + contact.id);
    },
    DeleteModalActive(contact) {
      if (!contact.id) return;
      this.contact_to_delete = contact.id;
      this.deleteModal = true;
    },
    deleteContact(contact) {
      console.log(contact);
      const contact_id = this.contact_to_delete;
      if (!contact_id) return;

      this.is_deleting = true;
      return this.$store
        .dispatch("DeleteSingleContact", contact_id)
        .then(() => {
          this.$store.commit("RemoveContactFromSingleCompany", contact_id);
          this.$store.commit("SetSnackbarData", {
            message: "Contact deleted successfully.",
          });
        })
        .finally(() => {
          this.is_deleting = false;
          this.contact_id = null;
          this.deleteModal = false;
        });
    },
  },

  computed: {
    Company() {
      return this.$store.getters.GetCurrentSingleCompany.name;
    },
    CompanyID() {
      return this.$store.getters.GetCurrentSingleCompany.id;
    },
    ContactList() {
      return this.$store.getters.GetCurrentSingleCompany.contacts || [];
    },
    filtered_contact_list() {
      var filter = "";
      if (this.filter) filter = this.filter.trim();

      if (!filter.length > 0) return this.ContactList;
      return this.ContactList.filter((contact) => {
        return (
          contact["id"].toString().includes(filter) ||
          contact["name"]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          contact["email"]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          (contact["company"] &&
            contact["company"]["name"]
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase()))
        );
        // str.includes("world");
      });
    },
  },
};
</script>

<style>
</style>