import Axios from "axios"

const state = {
}
const mutations = {

}
const actions = {



  email_sales_quotation(context, payload) {
    console.log("email_sales_quotation now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/email/" + payload.id + "/sales_quotation", { cc: payload.cc },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function () {

        context.commit("SetSnackbarData", {
          message: "Email sent successfully.",
        });

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },


  email_job_report(context, payload) {
    console.log("email_job_report now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/email/" + payload.id + "/job_report", { cc: payload.cc },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function () {

        context.commit("SetSnackbarData", {
          message: "Email sent successfully.",
        });

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },

  email_invoice(context, payload) {
    console.log("email_job_report now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/email/" + payload.id + "/invoice", { cc: payload.cc },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function () {

        context.commit("SetSnackbarData", {
          message: "Email sent successfully.",
        });

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },

  email_signed_delivery_note(context, payload) {
    console.log("email_job_report now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/email/" + payload.id + "/signed_delivery_note", { cc: payload.cc },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function () {

        context.commit("SetSnackbarData", {
          message: "Email sent successfully.",
        });

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },

}
const getters = {

}

export default {
  state,
  getters,
  mutations,
  actions
}