<template>
  <v-dialog ref="ServoConnectorsModal" v-model="show" width="800px" :persistent="isSubmitting">
    <v-card class="pa-6">
      <!-- Content -->

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Add a new connector
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form
              ref="AddServoConnectorForm"
              v-model="valid"
              @submit.prevent="AddServoConnector"
              style="width: 100%"
            >
              <h4 class="mb-2">Connector Type</h4>
              <v-select
                :items="connector_types"
                solo
                v-model="formFields.connector_type"
                :rules="[rules.required]"
              ></v-select>
              <h4 class="mb-2">Description</h4>
              <v-textarea
                solo
                v-model="formFields.description"
                :rules="[rules.required]"
              ></v-textarea>
              <div>
                <h4 class="mb-2">Connector Image</h4>
                <VueFileAgent
                  ref="vueFileAgent"
                  :multiple="false"
                  :meta="true"
                  :accept="'image/*'"
                  :maxSize="'10MB'"
                  :maxFiles="1"
                  :helpText="'Choose images'"
                  :errorText="{
                    type: 'Invalid file type. Only images allowed',
                    size: 'Files should not exceed 10MB in size',
                  }"
                  @select="filesSelected($event)"
                  v-model="fileRecords"
                ></VueFileAgent>
              </div>
            </v-form>
            <div class="d-flex align-center justify-center">
              <v-btn
                color="success"
                class="mt-3"
                :disabled="!valid || isSubmitting"
                @click="AddServoConnector"
                :loading="isSubmitting"
                >Add</v-btn
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="connectors-wrapper full-width mt-8">
        <div class="connector" v-for="connector in ServoConnectors" :key="connector.id">
          <div class="connector__inner">
            <div>
              <div>{{ connector.connector_type }}</div>
              <div>{{ connector.description }}</div>
              <v-btn
                color="error"
                @click="DeleteConnector(connector.id)"
                :disabled="connector_deleting && connector_deleting == connector.id"
                :loading="connector_deleting == connector.id"
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
            </div>
            <div>
              <img
                class="connector-image"
                v-if="connector.image_filename"
                :src="'https://api.neutronic.biz/storage/connectors/' + connector.image_filename"
              />
            </div>
          </div>
        </div>
      </div>

      <v-form ref="ServoConnectorsForm" v-model="valid" @submit.prevent="Submit"> </v-form>
      <!-- Content End -->

      <div class="d-flex justify-space-between align-center mt-6">
        <v-btn color="error" text @click="show = false">Close</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Axios from 'axios';
export default {
  name: 'ServoConnectorsModal',
  data() {
    return {
      isSubmitting: false,
      connector_deleting: false,
      valid: false,
      uploadUrl:
        process.env.VUE_APP_API_PROTOCOL +
        '://' +
        process.env.VUE_APP_API_HOST +
        ':' +
        process.env.VUE_APP_API_PORT +
        '/api/jobs/',
      formFields: {
        description: '',
        connector_type: '',
      },
      fileRecords: [],
      fileRecordsForUpload: [], // maintain an upload queue

      connector_types: [
        { value: 'feedback', text: 'Feedback' },
        { value: 'power', text: 'Power' },
      ],
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        isNaN: (v) => this.isNumeric(v) == true || 'Wrong Value',
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  props: {
    value: Boolean,
  },
  methods: {
    upload: function() {
      var formData = new FormData();
      formData.append('description', this.formFields.description);
      formData.append('connector_type', this.formFields.connector_type);

      if (this.fileRecordsForUpload.length > 0) {
        console.log(this.fileRecordsForUpload[0].file);
        formData.append('image_filename', this.fileRecordsForUpload[0].file.name);
        formData.append('file', this.fileRecordsForUpload[0].file);
      }

      this.isSubmitting = true;

      return Axios.post(
        this.uploadUrl + this.job.id + '/images/servo_connector',
        formData,
        this.uploadHeaders
      )
        .then((response) => {
          console.log(response.data);
          // context.commit("setJobList", response.data);
          this.fileRecordsForUpload = [];
          this.fileRecords = [];

          this.$store.commit('SetServoConnectors', response.data);
          this.$store.commit('SetSnackbarData', {
            message: 'created connector successfully',
            timeout: 8000,
            type: 'success',
          });
        })
        .catch((err) => {
          console.log(err);
          this.fileRecordsForUpload = [];
          this.fileRecords = [];
          this.formFields.description = '';
          this.formFields.connector_type = '';

          this.$store.commit('SetSnackbarData', {
            message: 'Error occured while creating connector',
            timeout: 300000,
            type: 'error',
          });
          return Promise.reject(err);
        })
        .finally(() => {
          this.isSubmitting = false;
        });

      // this.$refs.vueFileAgent
      //   .upload(
      //     this.uploadUrl + this.job.id + '/images/servo_connector',
      //     this.uploadHeaders,
      //     this.fileRecordsForUpload,
      //     (fileData) => {
      //       var formData = new FormData();
      //       formData.append('job_id', this.job.id);

      //       var name = fileData.file.name;

      //       if (fileData.customName) name = fileData.customName + '.' + fileData.raw.ext;

      //       formData.append('filename', name);
      //       console.log(fileData);
      //       formData.append('file', fileData.file); // this is important! - the actual file for upload
      //       return formData; // edit: fixed. Thanks to Chauhan-Nitesh for the comment below
      //     }
      //   )
      //   .then(() => {
      //     console.log('done uploading a file');
      //     // var temp = this.fileRecords;

      //     var temp = this.fileRecords.filter((fileRecord) => fileRecord.error);
      //     this.fileRecords = temp;
      //     // temp.forEach((record, index) => {
      //     //   console.log("looping through uploads");

      //     //   if (!record.error) {
      //     //     this.fileRecords.splice(index, 1);
      //     //     console.log("success");
      //     //   }
      //     // });
      //     console.log(temp);
      //     console.log('job id is:' + this.job.id);

      //     if (this.job.id != '') this.$store.dispatch('fetchSingleJob', this.job.id);
      //   });
    },

    AddServoConnector() {
      console.log(this.job.id);
      return this.upload(this.job.id);
    },

    DeleteConnector(connector_id) {
      this.connector_deleting = connector_id;
      return this.$store.dispatch('deleteServoConnector', connector_id).finally(() => {
        this.connector_deleting = false;
      });
    },

    filesSelected: function(fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    validate() {
      return this.$refs.ServoConnectorsForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    SendSMS() {},
  },
  computed: {
    ServoConnectors() {
      return this.$store.getters.GetServoConnectors || [];
    },
    ServoPowerConnectors() {
      return (
        this.ServoConnectors.filter((connector) => {
          return connector.connector_type == 'power';
        }) || []
      );
    },
    ServoFeedbackConnectors() {
      return (
        this.ServoConnectors.filter((connector) => {
          return connector.connector_type == 'feedback';
        }) || []
      );
    },
    ServoPowerConnectorImage() {
      let connector_id = this.formFields.power_connector;
      if (!connector_id) return false;
      let connector = this.ServoConnectors.find((conn) => conn.id == connector_id);

      if (connector && connector.image_filename) return connector.image_filename;
      else return false;
    },
    ServoFeedbackConnectorImage() {
      let connector_id = this.formFields.feedback_connector;
      if (!connector_id) return false;
      let connector = this.ServoConnectors.find((conn) => conn.id == connector_id);

      if (connector && connector.image_filename) return connector.image_filename;
      else return false;
    },

    job() {
      return this.$store.getters.getCurrentSingleJob;
    },

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    token() {
      return this.$store.getters.getCurrentToken;
    },
    uploadHeaders() {
      return {
        'X-Test-Header': 'vue-file-agent',
        Authorization: 'Bearer ' + this.token,
        'Content-Type': 'multipart/form-data',
      };
    },
  },
  mounted() {},
};
</script>

<style scoped>
.connector {
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-bottom: 30px;
}
.connector__inner {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  align-items: center;
}

.connector-image {
  width: 100%;
  aspect-ratio: 1.5;
  object-fit: contain;
  object-position: center;
}
</style>
