<template>
  <v-dialog
    ref="ber_dialog"
    v-model="show"
    width="400px"
    :persistent="is_updating"
  >
    <v-card class="pa-4">
      <h3>BER</h3>

      <p class="mb-3">
        You're marking this job as BER. Please type a note below:
      </p>

      <v-textarea solo v-model="note" name="note" label="Note"></v-textarea>

      <div class="d-flex justify-space-between align-center">
        <v-btn
          @click="$emit('cancelled', true)"
          text
          color="error"
          :disabled="is_updating"
          >Cancel</v-btn
        >
        <v-btn
          @click="SetRNR()"
          color="success"
          :loading="is_updating"
          :disabled="is_updating"
          >Set as BER</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BerDialog",
  data() {
    return {
      note: "",
      is_updating: false,
    };
  },
  props: {
    value: Boolean,
  },
  methods: {
    SetRNR() {
      if (this.job.id) {
        let note = this.note || "";

        note = note + " - set as BER";

        this.is_updating = true;

        this.$store
          .dispatch("updateSingleJob", {
            id: this.job.id,
            bBer: true,
          })
          .then(() => {
            return this.$store
              .dispatch("AddJobNote", {
                id: this.job.id,
                note: note,
              })
              .then(() => {
                this.note = null;
                this.$store.commit("SetSnackbarData", {
                  message: "BER set successfully",
                });
                this.$emit("cancelled", true);
              })
              .finally(() => {
                this.is_updating = false;
              });
          })
          .finally(() => {
            this.$emit("cancelled", true);

            this.is_updating = false;
          });
      }
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>