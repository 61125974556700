<template>
  <div class="d-flex flex-column pa-4 ma-0">
    <CommonPhrasesTable />
  </div>
</template>

<script>
import CommonPhrasesTable from "../../components/management/common_phrases/CommonPhrasesTable.vue";
export default {
  name: "CommonPhrases",
  components: {
    CommonPhrasesTable,
  },
  mounted() {
    // this.$store.dispatch("fetchCommonPhrases");
  },
};
</script>

<style>
</style>