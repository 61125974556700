<template>
  <v-dialog ref="ServoMitchellCablesModal" v-model="show" width="800px" :persistent="isSubmitting">
    <v-card class="pa-6">
      <!-- Content -->

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Add a new cable
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form
              ref="AddServoCableForm"
              v-model="valid"
              @submit.prevent="AddServoCable"
              style="width: 100%"
            >
              <h4 class="mb-2">Cable Reference</h4>
              <v-text-field
                solo
                v-model="formFields.cable_reference"
                :rules="[rules.required]"
              ></v-text-field>
              <h4 class="mb-2">Description</h4>
              <v-textarea
                solo
                v-model="formFields.description"
                :rules="[rules.required]"
              ></v-textarea>
            </v-form>
            <div class="d-flex align-center justify-center">
              <v-btn
                color="success"
                class="mt-3"
                :disabled="!valid || isSubmitting"
                :loading="isSubmitting"
                @click="AddServoCable"
                >Add</v-btn
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="connectors-wrapper full-width mt-8">
        <div class="connector" v-for="cable in MitchellCables" :key="cable.id">
          <div class="connector__inner">
            <div>
              <div>{{ cable.cable_reference }}</div>
              <div>{{ cable.description }}</div>
            </div>
            <div class="d-flex align-center justify-end">
              <v-btn
                color="error"
                @click="DeleteMitchellCable(cable.id)"
                :disabled="connector_deleting && connector_deleting == cable.id"
                :loading="connector_deleting == cable.id"
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Content End -->

      <div class="d-flex justify-space-between align-center mt-6">
        <v-btn color="error" text @click="show = false">Close</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Axios from 'axios';
export default {
  name: 'ServoMitchellCablesModal',
  data() {
    return {
      isSubmitting: false,
      connector_deleting: false,
      valid: false,
      uploadUrl:
        process.env.VUE_APP_API_PROTOCOL +
        '://' +
        process.env.VUE_APP_API_HOST +
        ':' +
        process.env.VUE_APP_API_PORT +
        '/api/mitchell_cables',
      formFields: {
        cable_reference: '',
        description: '',
      },

      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        isNaN: (v) => this.isNumeric(v) == true || 'Wrong Value',
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  props: {
    value: Boolean,
  },
  methods: {
    AddServoCable() {
      console.log(this.job.id);
      this.isSubmitting = true;

      return Axios.post(this.uploadUrl, this.formFields)
        .then((response) => {
          console.log(response.data);
          this.$store.commit('SetMitchellCables', response.data);
          this.$store.commit('SetSnackbarData', {
            message: 'created cable successfully',
            timeout: 8000,
            type: 'success',
          });
        })
        .catch((err) => {
          console.log(err);

          this.$store.commit('SetSnackbarData', {
            message: 'Error occured while creating cable',
            timeout: 300000,
            type: 'error',
          });
          return Promise.reject(err);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    DeleteMitchellCable(connector_id) {
      this.connector_deleting = connector_id;
      return this.$store.dispatch('deleteServoMitchellCable', connector_id).finally(() => {
        this.connector_deleting = false;
      });
    },

    filesSelected: function(fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    validate() {
      return this.$refs.ServoConnectorsForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    SendSMS() {},
  },
  computed: {
    MitchellCables() {
      return this.$store.getters.GetMitchellCables || [];
    },

    job() {
      return this.$store.getters.getCurrentSingleJob;
    },

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {},
};
</script>

<style scoped>
.connector {
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-bottom: 30px;
}
.connector__inner {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  align-items: center;
}

.connector-image {
  width: 100%;
  aspect-ratio: 1.5;
  object-fit: contain;
  object-position: center;
}
</style>
