import Vue from 'vue';



//my currency filter
Vue.filter('toCurrency', function (value) {

  if (value == "" || value == null)
    value = 0;

  if (isNaN(parseFloat(value))) {
    return value;
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
  });

  return formatter.format(value);
});



//convert YYYY-MM-DD to DD-MM-YYYY
Vue.filter('YMD_to_DMY', function (value) {

  if (value == "" || value == null || !value)
    return "";

  const [year, month, day] = value.split("-");

  return `${day}-${month}-${year}`;
});


//convert YYYY-MM-DD to DD-MM-YYYY
Vue.filter('YMDHis_to_DMYHis', function (value) {

  if (value == "" || value == null || !value)
    return "";
  const parts = value.split(" ");
  const [year, month, day] = parts[0].split("-");

  return `${day}-${month}-${year} ${parts[1]}`;
});


