<template>
  <v-dialog
    ref="SMSModal"
    v-model="show"
    width="400px"
    :persistent="isSubmitting"
  >
    <v-form ref="SMSModalForm" v-model="valid" @submit.prevent="Submit">
      <v-card class="pa-6">
        <p>This SMS will be sent to:</p>
        <p class="text-subtitle-2" v-if="this.contact.mobile">
          <span class="text-body-2">{{ this.contact.name }}</span>
          -
          {{ this.contact.mobile }}
        </p>
        <p v-else class="text-subtitle-2 error--text">
          Error: Contact has no phone nnumber, please update the contact's info.
        </p>

        <div class="d-flex justify-space-between align-center mt-6">
          <v-textarea
            v-model="sms_message"
            :rules="[rules.required, rules.min]"
            solo
          ></v-textarea>
        </div>
        <div class="d-flex justify-space-between align-center mt-6">
          <v-btn color="error" text @click="show = false">Cancel</v-btn>
          <v-btn
            color="success"
            @click="SendSMS()"
            :loading="isSubmitting"
            :disabled="isSubmitting || !this.contact.mobile"
            >Send</v-btn
          >
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "SMSModal",
  data() {
    return {
      valid: false,
      sms_message: "",
      isSubmitting: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  props: {
    value: Boolean,
  },
  methods: {
    validate() {
      return this.$refs.SMSModal.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    SendSMS() {
      if (this.job.id) {
        // this.indexClicked = "send_text";
        this.isSubmitting = true;

        this.$store
          .dispatch("SingleJobSendSMS", {
            job_id: this.job.id,
            // mobile: this.job.contact.mobile,
            message: this.sms_message,
          })
          .then(() => {
            this.show = false;

            this.$store.commit("SetSnackbarData", {
              message: "SMS sent successfully",
            });
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_id() {
      return this.$route.params.job_id;
    },
    contact() {
      if (this.$store.getters.getCurrentSingleJob.contact)
        return this.$store.getters.getCurrentSingleJob.contact;
      else return {};
    },

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    let status_name = "Unknown";
    if (this.job && this.job.status && this.job.status.name) status_name = this.job.status.name;

    this.sms_message =
      "Your Job N" + this.job_id + " is now at " + status_name + ".\nThank you.";
  },
};
</script>

<style>
</style>