<template>
  <v-row class="ma-0 pa-6">
    <div class="flex-grow-1 d-flex justify-space-between flex-column">
      <h1 class="mb-10">Engineer Graphs</h1>

      <v-date-picker
        v-model="date"
        type="month"
        class="mt-4"
        style="max-width: 300px"
        min="2019-01"
        :max="max_date"
      ></v-date-picker>

      <div class="full-width" style="height: 300px">
        <apexchart
          type="bar"
          width="100%"
          height="800"
          :options="graph.chartOptions"
          :series="graph.series"
          v-if="
            this.graph.series[0].data.length > 0 &&
            this.graph.series[1].data.length > 0 &&
            this.show_graph &&
            !this.is_loading
          "
        ></apexchart>

        <LoadingScreen v-else-if="this.is_loading" />
      </div>
    </div>
  </v-row>
</template>


<script>
import Axios from "axios";
import LoadingScreen from "../../global/LoadingScreen";

export default {
  name: "EngineerGraphs",
  components: {
    LoadingScreen,
  },
  data() {
    return {
      show_graph: true,
      is_loading: false,
      max_date: null,
      IsFetchingPDF: false,
      engineers_list: [],
      engineerIDs: [],
      temp: null,
      date: null,
      currencyFormatter: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      graph: {
        series: [
          {
            name: "Actual output Month so far",
            data: [],
          },
          {
            name: "Target for month",
            data: [],
          },
          // {
          //   name: "Free Cash Flow",
          //   data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
          // },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
            // stacked: true,

            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          legend: {
            onItemClick: {
              toggleDataSeries: true,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: "rounded",
              columnWidth: "45%",
              borderRadius: 10,
              dataLabels: {
                hideOverflowingLabels: false,
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: (val, opts) => {
              if (opts.seriesIndex == 1) return;
              // return val;
              return this.currencyFormatter.format(val);
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },

          xaxis: {
            // categories: [],
            categories: [],
            position: "bottom",

            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              formatter: (val) => {
                return this.currencyFormatter.format(val);
              },
            },
          },
          title: {
            text: "",
            floating: true,
            offsetY: 330,
            align: "center",
            style: {
              color: "#444",
            },
          },
        },
      },
    };
  },
  methods: {
    loadGraphData() {
      this.is_loading = true;
      var names = [];
      var ids = [];
      var monthly_targets = [];
      this.$store
        .dispatch("fetchEngineersList", { tag_id: null })
        .then((eng_list) => {
          if (eng_list.length == 0 || !eng_list) {
            this.engineers_list = [];
            this.graph.series[0].data = [];
            this.graph.series[1].data = [];
            this.engineerIDs = [];
            return;
          }

          this.engineers_list = eng_list;

          for (let i = 0; i < this.engineers_list.length; i++) {
            let target = this.engineers_list[i].monthly_target || 0;
            if (target > 0) {
              //skip anyone with no monthly target set
              monthly_targets.push(target);
              names.push(this.engineers_list[i].name);
              ids.push(this.engineers_list[i].id);
            }
          }
          this.engineerIDs = ids;
          this.graph.series[1].data = monthly_targets;
          this.graph.chartOptions.xaxis.categories = names;

          //call actual output func with {ids:this.engineerIDs, date:this.date_for_graph}

          return Axios.post(
            process.env.VUE_APP_API_PROTOCOL +
              "://" +
              process.env.VUE_APP_API_HOST +
              ":" +
              process.env.VUE_APP_API_PORT +
              "/api/engineer-graph-actual-output",
            { ids: this.engineerIDs, date: this.date_for_graph }
          )
            .then((response) => {
              console.log(response.data);

              // this.$store.commit("SetSnackbarData", {
              //   message: "fetched engineers",
              // });
              this.temp = response.data;
              return response.data;
              // context.commit("setJobList", response.data);
            })
            .then(() => {
              this.graph.series[0].data = this.engineer_actual_output;
            })
            .catch(function (error) {
              console.log(error.response.data.message);
              // return err;
              this.$store.commit("SetSnackbarData", {
                message: error.response.data.message,
                type: "error",
              });
            });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    // aa() {
    //   // Create our number formatter.
    //   const formatter = new Intl.NumberFormat("en-US", {
    //     style: "currency",
    //     currency: "GBP",
    //     minimumFractionDigits: 0,
    //     maximumFractionDigits: 0,
    //   });

    //   // console.log(formatter.format(2500)); /* $2,500.00 */
    // },
  },
  computed: {
    engineer_actual_output() {
      if (!this.temp) return false;

      let actual_output = [];
      // this will produce the total sales outputs in the same order as "engineerIDs"
      for (let i = 0; i < this.engineerIDs.length; i++) {
        let id = this.engineerIDs[i];
        var sales_quotes_total = 0;
        if (Object.prototype.hasOwnProperty.call(this.temp, id.toString())) {
          let jobs = this.temp[id.toString()];
          for (let x = 0; x < jobs.length; x++) {
            let quote = parseFloat(jobs[x].salQuote) || 0;
            sales_quotes_total =
              parseFloat(sales_quotes_total) + parseFloat(quote);
          }
        }

        if (sales_quotes_total > 0)
          sales_quotes_total =
            Math.round((sales_quotes_total + Number.EPSILON) * 100) / 100;

        actual_output.push(sales_quotes_total);
      }
      return actual_output;
    },
    date_for_graph() {
      if (this.date) return this.date + "-" + "01";

      var today = new Date();
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + "01";
    },
  },
  mounted() {
    this.loadGraphData();
    var today = new Date();
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    this.max_date = yyyy + "-" + mm;
  },
  watch: {
    date() {
      this.loadGraphData();

      //re-init graph with new data
      this.show_graph = false;
      this.is_loading = true;

      setTimeout(() => {
        this.show_graph = true;
        this.is_loading = false;
      }, 1000);
    },
  },
};
</script>

<style>
</style>