<template>
  <v-form ref="JobSearchForm" v-model="valid" @submit.prevent="Submit">
    <h4 class="mb-2">Job ID</h4>

    <v-text-field
      solo
      v-model="searchFields.id"
      single-line
      type="number"
      @wheel="$event.target.blur()"
      name="job_id"
    ></v-text-field>

    <h4 class="mb-2">Description</h4>

    <v-text-field
      solo
      v-model="searchFields.description"
      name="description"
    ></v-text-field>

    <h4 class="mb-2">Priority</h4>

    <v-select
      :items="priorities"
      item-text="name"
      item-value="id"
      label="Priority"
      solo
      clearable
      name="priority_id"
      v-model="searchFields.priority_id"
    ></v-select>

    <h4 class="mb-2">Sales Rep</h4>

    <v-select
      :items="SalesUsers"
      item-text="name"
      item-value="id"
      label="Sales Rep"
      solo
      clearable
      name="sales_rep"
      v-model="searchFields.sales_rep"
    ></v-select>

    <h4 class="mb-2">Company</h4>
    <v-autocomplete
      v-model="searchFields.company_id"
      :disabled="isUpdating"
      :items="companyList"
      solo
      clearable
      color="blue-grey lighten-2"
      label="Select"
      item-text="name"
      item-value="id"
      hide-no-data
      hide-selected
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <!-- <v-list-item-avatar>
                <img :src="data.item.avatar" />
              </v-list-item-avatar> -->
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <!-- <v-list-item-subtitle
                  v-html="data.item.group"
                ></v-list-item-subtitle> -->
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>

    <h4 class="mb-2">Contact</h4>

    <v-select
      v-model="searchFields.contact_id"
      :items="contact"
      label="Contact"
      item-value="id"
      item-text="name"
      solo
      clearable
      :disabled="!CompanyContactList.length > 0"
    ></v-select>

    <h4 class="mb-2">Department</h4>

    <v-select
      v-model="searchFields.tag_id"
      :items="DepartmentList"
      label="Department"
      item-value="id"
      item-text="name"
      solo
      clearable
      :disabled="!DepartmentList.length > 0"
    ></v-select>

    <h4 class="mb-2">Status</h4>

    <v-select
      v-model="searchFields.status_id"
      :items="get_all_status"
      label="Status"
      item-value="id"
      item-text="label"
      solo
      clearable
    ></v-select>

    <h4 class="mb-2">Order Number</h4>

    <v-text-field
      solo
      v-model="searchFields.order_number"
      name="order_number"
    ></v-text-field>

    <h4 class="mb-2">Customer Reference</h4>

    <v-text-field
      solo
      v-model="searchFields.customer_reference"
      name="customer_reference"
    ></v-text-field>

    <h4 class="mb-2">Manufacturer</h4>

    <v-text-field
      solo
      v-model="searchFields.manufacturer"
      name="manufacturer"
    ></v-text-field>

    <h4 class="mb-2">Model</h4>

    <v-text-field
      solo
      v-model="searchFields.machine_model"
      name="machine_model"
    ></v-text-field>

    <h4 class="mb-2">Serial</h4>

    <v-text-field
      solo
      v-model="searchFields.serial"
      name="serial"
    ></v-text-field>

    <h4 class="mb-2">Date From</h4>

    <v-date-picker
      v-model="searchFields.dateFrom"
      show-adjacent-months
      no-title
      scrollable
    ></v-date-picker>

    <h4 class="mb-2">Date To</h4>

    <v-date-picker
      v-model="searchFields.dateTo"
      show-adjacent-months
    ></v-date-picker>

    <v-btn
      :disabled="isSearching"
      class="success mx-0 mt-3"
      type="submit"
      :loading="isSearching"
      >Search</v-btn
    >
  </v-form>
</template>

<script>
export default {
  name: "SearchForm",
  props: ["isSearching"],
  data() {
    return {
      isUpdating: false,
      isSubmitting: false,
      snackbar: false,
      SalesUsers: [],
      priorities: [
        { name: "Normal", id: 2 },
        { name: "High", id: 3 },
        { name: "Emergency", id: 4 },
      ],
      valid: false,

      searchFields: {
        id: "",
        company_id: "",
        contact_id: "",
        customer_reference: "",
        order_number: "",
        description: "",
        serial: "",
        dateFrom: "",
        dateTo: "",
        tag_id: "",
      },

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  methods: {
    checkProperties(obj) {
      var atleastOneFilled = false;
      for (var key in obj) {
        if (obj[key] !== null && obj[key] != "") atleastOneFilled = true;
      }
      return atleastOneFilled;
    },
    Submit() {
      console.log(this.searchFields);
      console.log(this.validate());
      if (!this.checkProperties(this.searchFields)) return null;
      console.log("searching jobs...");
      var payload = this.searchFields;
      if (this.searchFields.id != "")
        payload.id = parseInt(this.searchFields.id);
      this.$emit("submit", payload);
    },
    validate() {
      return this.$refs.JobSearchForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },

  mounted() {
    this.$store.dispatch("fetchCompanyList");
    this.$store
      .dispatch("getUsersByPermission", "show_in_sales_rep_dropdown")
      .then((users) => {
        this.SalesUsers = users;
      });
    this.$store.dispatch("fetchDepartments");
  },

  computed: {
    contact() {
      var array = [];
      if (this.CompanyContactList)
        array = [
          {
            name: "Any",
            id: "",
          },
          ...this.CompanyContactList,
        ];
      return array;
    },
    companyList() {
      return this.$store.getters.getCompanyList;
    },

    CompanyContactList() {
      return this.$store.getters.getCompanyContactList;
    },
    DepartmentList() {
      return this.$store.getters.getDepartmentList;
    },
    get_all_status() {
      var statuses = this.$store.getters.get_all_status;

      var result = [];
      var temp;

      for (var i = 1; i <= Object.keys(statuses).length; i++) {
        if (i > 9) break;
        temp = {
          id: i,
          label: statuses[i.toString()],
        };

        result.push(temp);
        temp = {};
      }

      return result;
    },
  },

  watch: {
    "searchFields.company_id": function (new_selection, old_selection) {
      if (new_selection == null)
        this.$store.commit("setCompanyContactList", []);
      else if (new_selection != old_selection) {
        this.$store.dispatch("fetchCompanyContactList", new_selection);
      }
    },
  },
};
</script>

<style>
</style>