<template>
  <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12">
    <div class="table-cont">
      <div class="table-header pa-3 d-flex">
        <div class="table-property-label">Sales Quotation Total</div>
      </div>
      <div class="table-row">
        <div class="table-property-label">Carriage</div>
        <div class="table-property-value">
          {{ this.job.salCarriage | toCurrency }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Packaging</div>
        <div class="table-property-value">
          {{ this.job.salPackaging | toCurrency }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Quotation</div>
        <div class="table-property-value">
          {{ this.job.salQuote | toCurrency }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Quotation Total</div>
        <div class="table-property-value">
          {{ this.SalesQuotationTotal | toCurrency }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Delivery (days)</div>
        <div class="table-property-value">
          {{ this.job.salDeltime }}
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "SalQuotationTotal",

  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },

    SalesQuotationTotal() {
      var carriage = parseFloat(this.job.salCarriage) || 0;
      var packaging = parseFloat(this.job.salPackaging) || 0;
      var quote = parseFloat(this.job.salQuote) || 0;
      return carriage + packaging + quote;
    },
  },
};
</script>

<style>
</style>