<template>
  <div>
    <v-btn @click="AddContactModal()" color="success">Add Contact</v-btn>

    <v-dialog
      ref="addContactDialog"
      v-model="addContactModal"
      :persistent="isSubmitting"
      width="400px"
    >
      <v-card>
        <v-form ref="AddContactForm" v-model="valid" @submit.prevent="Submit">
          <v-row class="pa-4 ma-0">
            <v-col cols="12">
              <h4 class="mb-2">Company</h4>
              <v-autocomplete
                v-model="formFields.company_id"
                :disabled="isUpdating || this.company_id != null"
                :items="companyList"
                solo
                color="blue-grey lighten-2"
                label="Select"
                item-text="name"
                item-value="id"
                hide-no-data
                :rules="[rules.required, rules.isNaN]"
                hide-selected
              >
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <h4 class="mb-2">Name</h4>

              <v-text-field
                solo
                :rules="[rules.required]"
                v-model="formFields.name"
                name="name"
              ></v-text-field>

              <h4 class="mb-2">Email</h4>

              <v-text-field
                solo
                :rules="[rules.required]"
                v-model="formFields.email"
                name="email"
              ></v-text-field>

              <h4 class="mb-2">Phone</h4>

              <v-text-field
                solo
                v-model="formFields.phone"
                name="phone"
                :rules="[rules.required]"
                type="text"
                v-on:keypress="isNotLetter($event)"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Fax</h4>

              <v-text-field
                solo
                v-model="formFields.fax"
                name="fax"
                type="text"
                v-on:keypress="isNotLetter($event)"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Mobile</h4>

              <v-text-field
                solo
                v-model="formFields.mobile"
                name="mobile"
                type="text"
                v-on:keypress="isNotLetter($event)"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Include CC Quotation</h4>

              <v-simple-checkbox
                v-model="formFields.include_cc_quotation"
                :ripple="false"
              ></v-simple-checkbox>

              <h4 class="mb-2">Include CC Delivery</h4>

              <v-simple-checkbox
                v-model="formFields.include_cc_delivery"
                :ripple="false"
              ></v-simple-checkbox>

              <h4 class="mb-2">Notes</h4>

              <v-textarea
                solo
                v-model.number="formFields.notes"
                name="notes"
              ></v-textarea>
              <div class="mx-0 mt-3 d-flex justify-space-between">
                <v-btn
                  :disabled="isSubmitting"
                  color="error"
                  text
                  @click="addContactModal = false"
                  >Cancel</v-btn
                >

                <!-- <v-btn @click="ResetForm()">Test</v-btn> -->

                <v-btn
                  :disabled="!valid || isSubmitting"
                  class="success"
                  type="submit"
                  :loading="isSubmitting"
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AddContactForm",
  props: {
    company_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      isUpdating: false,
      addContactModal: false,

      isSubmitting: false,
      formFields: {
        name: "",
        company_id: null,
        email: "",
        phone: "",
        fax: "",
        mobile: "",
        include_cc_quotation: false,
        include_cc_delivery: false,
        notes: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  methods: {
    AddContactModal() {
      this.addContactModal = true;
    },
    ResetForm() {
      this.$refs.AddContactForm.reset();
      this.formFields.include_cc_quotation = false;
      this.formFields.include_cc_delivery = false;
    },
    Submit() {
      if (this.validate()) {
        this.isSubmitting = true;
        console.log("Editing Contact");
        console.log(this.formFields);
        console.log(this.validate());
        this.$store
          .dispatch("AddSingleContact", this.formFields)
          .then((new_contact) => {
            if (new_contact && new_contact.id) {
              this.$store.commit("SetSnackbarData", {
                message: "Contact added successfully.",
              });
              this.$store.commit("PushContactToEnd", new_contact);

              if (this.CurrentSingleCompany) {
                let company = { ...this.CurrentSingleCompany };
                company.contacts.push(new_contact);
                this.$store.commit("SetCurrentSingleCompany", company);
              }
              this.addContactModal = false;
              this.ResetForm();
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.AddContactForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isNotLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      var input = e.target;
      var val = input.value;
      var end = input.selectionEnd;

      if (e.keyCode == 32 && (val[end - 1] == " " || val[end] == " ")) {
        e.preventDefault();
        return false;
      } else if (/^[+0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },
  computed: {
    CurrentSingleContact() {
      return this.$store.getters.GetCurrentSingleContact;
    },
    CurrentSingleCompany() {
      return this.$store.getters.GetCurrentSingleCompany;
    },
    companyList() {
      return this.$store.getters.getCompanyList;
    },
  },
  mounted() {
    this.isUpdating = true;
    this.$store.dispatch("fetchCompanyList").finally(() => {
      this.isUpdating = false;
      this.formFields.company_id = this.company_id;
    });
  },
};
</script>

<style>
</style>