<template>
  <v-container fill-height fluid v-if="!this.job.id">
    <LoadingScreen />
  </v-container>

  <v-container fill-height fluid style="align-items: flex-start" v-else>
    <v-row class="mb-4">
      <v-tabs background-color="#E77C1F" center-active dark grow v-model="selected_tab">
        <v-tab>View</v-tab>
        <v-tab>Misc. Costs</v-tab>
        <v-tab>Job Parts</v-tab>
        <v-tab>Engineering Quote</v-tab>
        <v-tab v-if="this.checkPermission('can_view_sales_quotations')">Sales Quote</v-tab>
        <v-tab>Time Log</v-tab>
        <v-tab :style="this.isMechanicalOrServo ? '' : 'display:none;'">Mechanical Data</v-tab>
        <v-tab :style="this.isMechanicalOrServo ? '' : 'display:none;'">Winding Details</v-tab>
        <v-tab :style="this.isServo ? '' : 'display:none;'">Servo Report</v-tab>
        <v-tab :style="!this.isMechanicalOrServo ? '' : 'display:none;'">Job Report</v-tab>
        <v-tab>
          <v-badge dot v-if="this.job.time_signature">Delivery</v-badge>
          <div v-else>Delivery</div>
        </v-tab>
        <v-tab>Files</v-tab>
        <!-- <v-tab>Notes</v-tab> -->

        <v-tabs-slider color="primary"></v-tabs-slider>
      </v-tabs>

      <v-tabs-items v-model="selected_tab" touchless class="flex-grow-1">
        <v-tab-item :eager="true">
          <v-row class="pa-5">
            <!-- first col - job info -->
            <JobBasicInfo />
            <!-- second col - company info -->
            <JobCompanyInfo />
            <!-- <JobMachineInfo /> -->
            <JobStatusInfo />
            <JobHistory />
            <NotesTab />
          </v-row>
        </v-tab-item>

        <!-- parts -->
        <!-- optimize this, only needs parts not whole jobs obj -->
        <!-- <v-tab-item>
          <PartsTable :job="this.job" />
          <v-btn class="ma-3" @click="add_part_modal">Add Part</v-btn>
        </v-tab-item>-->
        <PartsTab :parts="this.job.parts" />

        <!-- Components tab -->

        <v-tab-item class="pa-3">
          <JobComponents />
        </v-tab-item>

        <!-- eng quote -->
        <EngQuotationTab />

        <!-- sales quote -->
        <SalQuotationTab v-if="this.checkPermission('can_view_sales_quotations')" />

        <!-- time log -->
        <v-tab-item>
          <TimeLogTable :logs="this.job.logs" />
        </v-tab-item>
        <v-tab-item class="pa-3" :style="this.isMechanicalOrServo ? '' : 'display:none;'">
          <Mechanical_data v-if="this.isMechanicalOrServo" />
        </v-tab-item>

        <!-- Winding Details -->
        <v-tab-item class="pa-3" :style="this.isMechanicalOrServo ? '' : 'display:none;'">
          <Winding_details v-if="this.isMechanicalOrServo" />
        </v-tab-item>

        <!-- Servo Report -->
        <v-tab-item class="pa-3" :style="this.isServo ? '' : 'display:none;'">
          <ServoReport v-if="this.isServo" />
        </v-tab-item>

        <JobReportTab :style="!this.isMechanicalOrServo ? '' : 'display:none;'" />
        <DeliveryTab />
        <DocumentsTab />
        <!-- <v-tab-item class="pa-3">
          <NotesTab />
        </v-tab-item>-->
      </v-tabs-items>
    </v-row>
    <SingleJobActionButtons @changeTabIndex="changeTabIndex" />
  </v-container>
</template>

<script>
import LoadingScreen from '../../components/global/LoadingScreen';
import JobBasicInfo from '../../components/jobs/singleJob/tab1/JobBasicInfo.vue';
import JobCompanyInfo from '../../components/jobs/singleJob/tab1/JobCompanyInfo.vue';
// import JobMachineInfo from "../../components/jobs/singleJob/tab1/JobMachineInfo";
import JobStatusInfo from '../../components/jobs/singleJob/tab1/JobStatusInfo.vue';
import JobHistory from '../../components/jobs/singleJob/tab1/JobHistory.vue';
import SingleJobActionButtons from '../../components/jobs/singleJob/SingleJobActionButtons';
import PartsTab from '../../components/jobs/singleJob/tab2/PartsTab.vue';
import EngQuotationTab from '../../components/jobs/singleJob/tab3/EngQuotationTab';
import SalQuotationTab from '../../components/jobs/singleJob/tab4/SalQuotationTab';
import TimeLogTable from '../../components/jobs/singleJob/tab5/TimeLogTable.vue';
import JobReportTab from '../../components/jobs/singleJob/tab6/JobReportTab.vue';
import DeliveryTab from '../../components/jobs/singleJob/tab7/DeliveryTab.vue';
import DocumentsTab from '../../components/jobs/singleJob/tab8/DocumentsTab.vue';
import NotesTab from '../../components/jobs/singleJob/tab9/NotesTab.vue';
import Mechanical_data from '../../components/jobs/singleJob/mechanical_data/Mechanical_data.vue';
import Winding_details from '../../components/jobs/singleJob/winding_details/winding_details.vue';
import ServoReport from '../../components/jobs/singleJob/ServoData/ServoReport.vue';
import JobComponents from '../../components/jobs/singleJob/job_components/JobComponents.vue';
import Echo from 'laravel-echo';

export default {
  name: 'SingleJobView',

  data() {
    return {
      selected_tab: 0,
    };
  },
  components: {
    LoadingScreen,
    JobBasicInfo,
    JobCompanyInfo,
    // JobMachineInfo,
    JobStatusInfo,
    JobHistory,
    SingleJobActionButtons,
    // PartsTable,
    TimeLogTable,
    PartsTab,
    EngQuotationTab,
    SalQuotationTab,
    JobReportTab,
    DeliveryTab,
    DocumentsTab,
    NotesTab,
    Mechanical_data,
    JobComponents,
    Winding_details,
    ServoReport,
  },
  methods: {
    add_part_modal() {},
    changeTabIndex(tab_index) {
      if (!tab_index) return;

      this.selected_tab = tab_index;
    },
  },

  computed: {
    job_id() {
      return this.$route.params.job_id;
    },
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    isServo() {
      return (
        this.job.tag && (this.job.tag.nominal_code == 4104 || this.job.tag.nominal_code == 204)
      );
    },
    isMechanicalOrServo() {
      return (
        this.job.tag &&
        (this.job.tag.nominal_code == 4102 ||
          this.job.tag.nominal_code == 4104 ||
          this.job.tag.nominal_code == 202 ||
          this.job.tag.nominal_code == 204)
      );
    },
  },
  mounted() {
    console.log('Single Job View ' + this.job_id);
    if (!window.Echo) {
      var token = this.$store.getters.getCurrentToken;
      window.Echo = new Echo({
        authEndpoint:
          process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/broadcasting/auth',
        broadcaster: 'pusher',
        key: '72b51d7768de32a685c5',
        cluster: 'eu',
        forceTLS: true,
        auth: {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      });
    }

    if (this.job_id != '')
      this.$store.dispatch('fetchSingleJob', this.job_id).then((fetchedJob) => {
        if (fetchedJob.id)
          window.Echo.private('SingleJobView').listen(
            '.job-updated-' + fetchedJob.id,
            (updated_job_id) => {
              console.log(updated_job_id);

              this.$store.commit('AddSnackbar', {
                message:
                  "This job was updated by another user, the data you're viewing is outdated.",
                timeout: 300000,
                type: 'warning',
                updatedJob: updated_job_id.data,
              });
              // this.$store.commit("SingleJobUpdated", e.data); //as it's returned from laravel as {data: {data here}}
            }
          );
      });

    if (this.job_id != '') this.$store.dispatch('FetchJobHistory', this.job_id);
  },
  created() {
    console.log('Single Job View - created');
  },
  beforeDestroy() {
    this.$store.commit('setCurrentSingleJob', {});
    window.Echo.leave(`SingleJobView`);
  },
};
</script>

<style></style>
