<template>
  <div style="width: 100%" class="winding-details-tab-content pb-6">
    <div class="winding-details-cont">
      <v-row class="px-5 ma-0">
        <v-col cols="12" md="12">
          <h2 class="text-center">Servo Report</h2>
        </v-col>

        <v-col cols="12" md="12">
          <v-divider class="my-8"></v-divider>
        </v-col>

        <v-col cols="12" md="12">
          <div class="r-columns-2">
            <div>
              <h4>Job ID</h4>
              <span>{{ this.job.id }}</span>
            </div>
            <div>
              <h4>Customer</h4>
              <span>{{ this.job.company.name || '-' }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="12">
          <v-divider class="my-8"></v-divider>
        </v-col>

        <!-- Titles -->
        <!-- Fields -->
        <v-col cols="12" md="12">
          <div class=" r-columns-2">
            <div class="full-width">
              <!-- Motor Data Column -->
              <h3 class="mb-4">Motor Data</h3>
              <h4 class="mb-2">Manufacturer</h4>
              <v-text-field solo disabled :value="this.job.manufacturer || '-'"></v-text-field>

              <h4 class="mb-2">Model</h4>
              <v-text-field solo disabled :value="this.job.machine_model || '-'"></v-text-field>

              <h4 class="mb-2">Serial Number</h4>
              <v-text-field solo disabled :value="this.job.serial || '-'"></v-text-field>

              <h4 class="mb-2">Poles</h4>
              <v-text-field
                solo
                class="hide-arrows"
                type="number"
                v-model.number="formFields.poles"
                name="poles"
                @wheel.prevent
              ></v-text-field>

              <h4 class="mb-2">FWD Direction</h4>
              <v-select
                solo
                v-model="formFields.fwd_direction"
                name="fwd_direction"
                :items="['CW', 'CCW']"
                clearable
              ></v-select>

              <h4 class="mb-2">Temperature Sensor</h4>
              <v-text-field
                solo
                v-model="formFields.temperature_sensor"
                name="fwd_direction"
              ></v-text-field>

              <h4 class="mb-2">Resistance</h4>
              <v-text-field
                solo
                v-model="formFields.resistance"
                name="fwd_direction"
              ></v-text-field>

              <!-- Left Col -->
            </div>

            <!-- Right Col -->
            <div class="full-width">
              <!-- Feedback Data Column -->
              <h3 class="mb-4">Feedback Data</h3>

              <h4 class="mb-2">Feedback Manufacturer</h4>
              <v-text-field
                solo
                v-model="formFields.feedback_manufacturer"
                name="feedback_manufacturer"
              ></v-text-field>

              <h4 class="mb-2">Feedback Model</h4>
              <v-text-field
                solo
                v-model="formFields.feedback_model"
                name="feedback_model"
              ></v-text-field>

              <h4 class="mb-2">Speeds</h4>

              <v-text-field
                solo
                v-model.number="formFields.speeds"
                type="number"
                class="hide-arrows"
                name="speeds"
              ></v-text-field>

              <h4 class="mb-2">Frequency</h4>
              <v-text-field
                solo
                v-model.number="formFields.frequency"
                type="number"
                class="hide-arrows"
                name="frequency"
                suffix="Hz"
              ></v-text-field>

              <h4 class="mb-2">Voltage</h4>
              <v-text-field
                solo
                v-model.number="formFields.voltage"
                type="number"
                class="hide-arrows"
                name="voltage"
                suffix="V"
              ></v-text-field>

              <h4 class="mb-2">Feedback FWD Direction</h4>
              <v-select
                solo
                v-model="formFields.feedback_fwd_direction"
                name="feedback_fwd_direction"
                :items="['CW', 'CCW']"
                clearable
              ></v-select>

              <h4 class="mb-2">Mitchell Cable</h4>
              <div class="d-flex flex-row align-center mb-7">
                <div class="full-width">
                  <v-autocomplete
                    v-model="formFields.mitchell_cable"
                    :items="MitchellCables"
                    label="Mitchell Cable"
                    hide-details
                    name="mitchell_cable"
                    item-text="cable_reference"
                    item-value="id"
                    :disabled="MitchellCables.length < 1"
                    solo
                    chips
                    clearable
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        {{ data.item.cable_reference }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </div>
              </div>

              <div>
                <h4 class="mb-2">Cable Description</h4>

                <div v-if="this.formFields.mitchell_cable">
                  {{ mitchellCableDescription || '-' }}
                </div>
                <div v-else style="pointer-events: none;">
                  Please select a cable above to show it's description
                </div>
              </div>

              <v-btn color="primary" class="mt-2" @click="show_cables_modal = true"
                >Mitchell Cables</v-btn
              >
            </div>
            <!-- Right Col End -->

            <div
              class="full-width r-columns-2 pt-10 mt-2"
              style="grid-column: 1/-1; border-top: 2px solid #eaeaea"
            >
              <div style="grid-column: 1/-1;"><h3>Alignment</h3></div>
              <div>
                <h4 class="mb-2">+U-V</h4>
                <v-text-field
                  solo
                  v-model.number="formFields.u_v"
                  name="u_v"
                  type="number"
                  class="hide-arrows"
                ></v-text-field>

                <h4 class="mb-2">Power Connector</h4>
                <v-select
                  solo
                  clearable
                  class="connector-select"
                  name="feedback_connector"
                  v-model="formFields.power_connector"
                  :items="ServoPowerConnectors"
                  item-text="description"
                  item-value="id"
                  full-width
                  :disabled="ServoPowerConnectors.length < 1"
                ></v-select>
                <img
                  class="connector-image mb-6"
                  v-if="ServoPowerConnectorImage"
                  :src="'https://api.neutronic.biz/storage/connectors/' + ServoPowerConnectorImage"
                />
              </div>

              <div>
                <h4 class="mb-2">+U-W</h4>
                <v-text-field
                  solo
                  v-model.number="formFields.u_w"
                  name="u_w"
                  type="number"
                  class="hide-arrows"
                ></v-text-field>

                <h4 class="mb-2">Feedback Connector</h4>
                <v-select
                  solo
                  clearable
                  class="connector-select"
                  name="feedback_connector"
                  v-model="formFields.feedback_connector"
                  :items="ServoFeedbackConnectors"
                  item-text="description"
                  item-value="id"
                  full-width
                  :disabled="ServoFeedbackConnectors.length < 1"
                ></v-select>

                <img
                  class="connector-image mb-6"
                  v-if="ServoFeedbackConnectorImage"
                  :src="
                    'https://api.neutronic.biz/storage/connectors/' + ServoFeedbackConnectorImage
                  "
                />
              </div>
            </div>
          </div>

          <div class="d-flex align-center justify-center">
            <v-btn
              color="primary"
              @click="show_connectors_modal = true"
              @input="show_connectors_modal = false"
              >Connectors List</v-btn
            >
          </div>

          <div
            class="full-width r-columns-2 pt-10 mt-10"
            style="grid-column: 1/-1; gap:40px; border-top: 1px solid #eaeaea;"
          >
            <div>
              <h4 class="mb-2">Pin Functions</h4>
              <div class="r-columns-3">
                <span>Pin Number</span>
                <span>Function</span>
                <span>Colour</span>
              </div>

              <div
                class="r-columns-3"
                v-for="(row, index) in formFields.pin_functions"
                :key="index + '-pin-func'"
              >
                <v-text-field solo v-model="row.index" name="index"></v-text-field>
                <v-text-field
                  solo
                  v-model="row.function"
                  name="function"
                  @input="ConditionallyAddRow"
                ></v-text-field>

                <v-text-field
                  solo
                  v-model="row.colour"
                  name="colour"
                  @input="ConditionallyAddRow"
                ></v-text-field>
              </div>
            </div>

            <div>
              <h4 class="mb-2">Feedback Pin Functions</h4>
              <div class="r-columns-3">
                <span>Pin Number</span>
                <span>Function</span>
                <span>Colour</span>
              </div>

              <div
                class="r-columns-3"
                v-for="(row, index) in formFields.feedback_pin_functions"
                :key="index + '-feedback-pin-func'"
              >
                <v-text-field solo v-model="row.index" name="index"></v-text-field>
                <v-text-field
                  solo
                  v-model="row.function"
                  name="function"
                  @input="ConditionallyAddFeedbackRow"
                ></v-text-field>

                <v-text-field
                  solo
                  v-model="row.colour"
                  name="colour"
                  @input="ConditionallyAddFeedbackRow"
                ></v-text-field>
              </div>
            </div>
          </div>

          <span>Notes:</span>
          <v-textarea solo v-model="formFields.notes" name="notes" @change="Save"></v-textarea>
        </v-col>
      </v-row>

      <v-btn
        @click="Save()"
        :loading="isSubmitting"
        color="success"
        :disabled="isSubmitting"
        class=""
        >Save</v-btn
      >

      <div class="r-fab-btn">
        <v-btn fab @click="Save()" :loading="isSubmitting" color="success" :disabled="isSubmitting">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </div>
    </div>
    <ServoConnectorsModal v-model="show_connectors_modal" />
    <ServoMitchellCablesModal v-model="show_cables_modal" />
  </div>
</template>

<script>
// import MechanicalDataJobPhotos from "./MechanicalDataJobPhotos.vue";
// import JobReportEditModal from "../tab6/JobReportEditModal.vue";

import axios from 'axios';
import ServoConnectorsModal from './ServoConnectorsModal';
import ServoMitchellCablesModal from './ServoMitchellCablesModal';

export default {
  name: 'ServoReport',
  components: {
    // MechanicalDataJobPhotos,
    // JobReportEditModal,
    ServoConnectorsModal,
    ServoMitchellCablesModal,
  },
  data() {
    return {
      show_connectors_modal: false,
      show_cables_modal: false,
      servo_data: {
        manufacturers: [],
      },
      formFields: {
        fwd_direction: null,
        power_connector: null,
        temperature_sensor: null,
        resistance: null,

        feedback_manufacturer: null,
        feedback_model: null,
        feedback_fwd_direction: null,
        feedback_connector: null,

        mitchell_cable: null,
        speeds: null,
        voltage: null,
        frequency: null,

        pin_functions: [
          {
            index: 1,
            function: '',
            colour: '',
          },
        ],
        feedback_pin_functions: [
          {
            index: 1,
            function: '',
            colour: '',
          },
        ],

        u_v: null,
        u_w: null,
        notes: '',
        summary: '',
      },
      isSubmitting: false,
      isDeleting: false,
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        Poles: (v) => parseInt(v) <= 40 || 'Max is 40',
        isNaN: (v) => this.isNumeric(v) == true || 'Wrong Value',
      },
    };
  },
  computed: {
    MitchellCables() {
      return this.$store.getters.GetMitchellCables || [];
    },
    mitchellCableDescription() {
      const cable_id = this.formFields.mitchell_cable;
      if (!cable_id) return '';
      let cable = this.MitchellCables.find((cable) => cable.id == cable_id);

      if (cable && cable.description) return cable.description;
      else return '';
    },
    ServoConnectors() {
      return this.$store.getters.GetServoConnectors || [];
    },
    ServoPowerConnectors() {
      return (
        this.ServoConnectors.filter((connector) => {
          return connector.connector_type == 'power';
        }) || []
      );
    },
    ServoFeedbackConnectors() {
      return (
        this.ServoConnectors.filter((connector) => {
          return connector.connector_type == 'feedback';
        }) || []
      );
    },
    ServoPowerConnectorImage() {
      let connector_id = this.formFields.power_connector;
      if (!connector_id) return false;
      let connector = this.ServoConnectors.find((conn) => conn.id == connector_id);

      if (connector && connector.image_filename) return connector.image_filename;
      else return false;
    },
    ServoFeedbackConnectorImage() {
      let connector_id = this.formFields.feedback_connector;
      if (!connector_id) return false;
      let connector = this.ServoConnectors.find((conn) => conn.id == connector_id);

      if (connector && connector.image_filename) return connector.image_filename;
      else return false;
    },

    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    LastPinHasValue() {
      let pins = this.formFields.pin_functions?.length;
      if (
        this.formFields.pin_functions[pins - 1].function ||
        this.formFields.pin_functions[pins - 1].colour
      )
        return true;
      return false;
    },

    SecondLastPinHasValue() {
      let pins = this.formFields.pin_functions?.length;
      if (pins < 2) return false;
      if (
        this.formFields.pin_functions[pins - 2].function ||
        this.formFields.pin_functions[pins - 2].colour
      )
        return true;
      return false;
    },

    LastFeedbackPinHasValue() {
      let pins = this.formFields.feedback_pin_functions?.length;
      if (
        this.formFields.feedback_pin_functions[pins - 1].function ||
        this.formFields.feedback_pin_functions[pins - 1].colour
      )
        return true;
      return false;
    },

    SecondLastFeedbackPinHasValue() {
      let pins = this.formFields.feedback_pin_functions?.length;
      if (pins < 2) return false;
      if (
        this.formFields.feedback_pin_functions[pins - 2].function ||
        this.formFields.feedback_pin_functions[pins - 2].colour
      )
        return true;
      return false;
    },
  },
  methods: {
    ConditionallyAddRow() {
      if (this.LastPinHasValue) {
        this.formFields.pin_functions.push({
          index: this.formFields.pin_functions.length + 1,
          function: '',
          colour: '',
        });
      } else if (!this.SecondLastPinHasValue) {
        this.formFields.pin_functions.pop();
      }
    },

    ConditionallyAddFeedbackRow() {
      if (this.LastFeedbackPinHasValue) {
        this.formFields.feedback_pin_functions.push({
          index: this.formFields.feedback_pin_functions.length,
          function: '',
          colour: '',
        });
      } else if (!this.SecondLastFeedbackPinHasValue) {
        this.formFields.feedback_pin_functions.pop();
      }
    },
    Save() {
      console.log(this.formFields);

      this.isSubmitting = true;

      var payload = { ...this.formFields };

      let temp = [];

      if (payload?.pin_functions?.length > 0) {
        temp = [];
        payload.pin_functions.forEach((func) => {
          if (func.function || func.colour) temp.push(func);
        });
        if (temp.length == 0) payload.pin_functions = null;
        else payload.pin_functions = JSON.stringify(temp);
      }

      if (payload?.feedback_pin_functions?.length > 0) {
        temp = [];
        payload.feedback_pin_functions.forEach((func) => {
          if (func.function || func.colour) temp.push(func);
        });
        if (temp.length == 0) payload.feedback_pin_functions = null;
        else payload.feedback_pin_functions = JSON.stringify(temp);
      }

      return axios
        .post(
          process.env.VUE_APP_API_PROTOCOL +
            '://' +
            process.env.VUE_APP_API_HOST +
            ':' +
            process.env.VUE_APP_API_PORT +
            '/api/jobs/' +
            this.job.id +
            '/servo_data',
          payload
        )
        .then((response) => {
          console.log(response.data);

          var job = response.data;
          if (job && job.id) {
            console.log('Valid Job');

            this.$store.commit('AddSnackbar', {
              message: 'Job updated successfully.',
            });
            this.$router.push('/job/' + this.job.id);
          } else console.log('Invalid Job');
        })
        .then(() => {
          this.$store.dispatch('fetchSingleJob', this.job.id);
        })
        .finally(() => {
          this.isSubmitting = false;
        })
        .catch(function(err) {
          console.log(err);
        });
    },
  },
  mounted() {
    if (this.job.servo_data) {
      this.formFields = this.job.servo_data;
      this.formFields.poles = this.job.winding_details?.poles;

      if (this.job.servo_data.pin_functions) {
        this.formFields.pin_functions = JSON.parse(this.job.servo_data.pin_functions);
        if (this.formFields.pin_functions.length > 0)
          this.formFields.pin_functions.push({
            index: this.formFields.pin_functions.length,
            function: '',
            colour: '',
          });
      }
      if (this.job.servo_data.feedback_pin_functions) {
        this.formFields.feedback_pin_functions = JSON.parse(
          this.job.servo_data.feedback_pin_functions
        );
        if (this.formFields.feedback_pin_functions.length > 0)
          this.formFields.feedback_pin_functions.push({
            index: this.formFields.feedback_pin_functions.length,
            function: '',
            colour: '',
          });
      }
    }
    this.$store.dispatch('fetchMitchellCables');
    this.$store.dispatch('fetchServoConnectors');
  },
};
</script>

<style scoped>
.winding-details-cont {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.mech-data-group-spacing {
  margin-bottom: 60px;
  flex: 0 0 100%;
}

.winding-details-tab-content {
  /* overflow: hidden;
  transform: translate3d(0, 0, 0); */
  position: relative;
}

.r-fab-btn {
  position: absolute;
  inset: 0;
  pointer-events: none;
  clip: rect(0, auto, auto, 0);
  clip-path: inset(0 0 0 0);
}

.r-fab-btn > button {
  position: fixed;
  bottom: 36px;
  right: 36px;
  pointer-events: all;
  pointer-events: auto;
}

.r-columns-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.r-columns-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

>>> .connector-select .v-select__selections .v-select__selection {
  white-space: normal !important;
}

.connector-image {
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
}

@media only screen and (max-width: 1200px) {
  .winding-details-scont {
    margin-top: 36px;
  }
}
</style>
