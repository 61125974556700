import { render, staticRenderFns } from "./AddJobComponent.vue?vue&type=template&id=763d3a1c&scoped=true&"
import script from "./AddJobComponent.vue?vue&type=script&lang=js&"
export * from "./AddJobComponent.vue?vue&type=script&lang=js&"
import style0 from "./AddJobComponent.vue?vue&type=style&index=0&id=763d3a1c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "763d3a1c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VDataTable,VForm,VIcon,VImg,VPagination,VRow,VSimpleCheckbox,VTextField})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
