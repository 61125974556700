var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0 pa-6"},[_c('div',{staticClass:"flex-grow-1 d-flex justify-space-between mb-6"},[_c('h1',{staticClass:"h1"},[_vm._v("Jobs Awaiting Acceptance")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.IsFetchingPDF,"disabled":_vm.IsFetchingPDF},on:{"click":function($event){return _vm.DownloadPDF('awaiting_acceptance/pdf')}}},[_vm._v("Download PDF")])],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.jobs,"mobile-breakpoint":0,"items-per-page":-1,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"group-by":"company.sales_rep.name","loading":_vm.is_loading},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var items = ref.items;
return [_c('tr',{attrs:{"colspan":"7"}},[_c('td',{staticClass:"grey lighten-2",staticStyle:{"font-weight":"500"},attrs:{"colspan":"7"}},[_vm._v(" "+_vm._s(items[0].company && items[0].company.sales_rep ? items[0].company.sales_rep.name : "House Accounts")+" ")])]),_vm._l((items),function(item){return _c('tr',{key:item.id},_vm._l((_vm.headers),function(header){return _c('td',{key:item.id + ' - ' + header.value},[(header.value != 'NetTotal' && header.value != 'date_booked')?_c('div',[_vm._v(" "+_vm._s(header.value == "company" ? item["company"] ? item[header.value]["name"] : "" : item[header.value])+" ")]):_vm._e(),(header.value == 'actions')?_c('v-btn',{attrs:{"to":'/job/' + item.id,"color":"primary"}},[_vm._v("View")]):_vm._e(),(header.value == 'company.name')?_c('div',[_vm._v(" "+_vm._s(item.company.name)+" ")]):_vm._e(),(header.value == 'Gross')?_c('div',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(((item.NetTotal || 0) * 1.2)))+" ")]):_vm._e(),(header.value == 'date_booked')?_c('div',[_vm._v(" "+_vm._s(_vm._f("YMD_to_DMY")(item.date_booked))+" ")]):_vm._e(),(header.value == 'NetTotal')?_c('div',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(((item.NetTotal || 0) * 1)))+" ")]):_vm._e()],1)}),0)}),(items && items.length > 0)?_c('tr',{},[_c('td',[_vm._v(" Total for "+_vm._s(items[0].company && items[0].company.sales_rep ? items[0].company.sales_rep.name : "None Assigned")+" ")]),_c('td'),_c('td'),_c('td'),_c('td',[_c('strong',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.CalculateJobsAssignedTotal(items))))])]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm._f("toCurrency")((_vm.CalculateJobsAssignedTotal(items) * 1.2))))])]),_c('td')]):_vm._e()]}},{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('th',{attrs:{"colspan":"7"}},[_vm._v(" "+_vm._s(items[0].company && items[0].company.sales_rep && items[0].company.sales_rep.name ? items[0].company.sales_rep.name : "No Sales Rep Assigned")+" ")])]}},{key:"item.NetTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")((item.NetTotal || 0)))+" ")]}},{key:"item.Gross",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(((item.NetTotal || 0) * 1.2)))+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',[_c('strong',[_vm._v("Total")])]),_c('td'),_c('td'),_c('td'),_c('td',[_c('strong',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.NetTotalTotal)))])]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm._f("toCurrency")((_vm.NetTotalTotal * 1.2))))])])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }