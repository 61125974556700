<template>
  <div class="d-flex flex-column pa-4 ma-0">
    <ComponentsTable />
  </div>
</template>

<script>
import ComponentsTable from "../../components/stock/components/ComponentsTable.vue";
export default {
  name: "Roles",
  components: {
    ComponentsTable,
  },
  mounted() {
    // this.$store.dispatch("fetchDepartments");
  },
};
</script>

<style>
</style>