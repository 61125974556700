<template>
  <div class="mb-8">
    <VueFileAgent
      ref="vueFileAgent"
      :multiple="true"
      :meta="true"
      :accept="'image/*'"
      :maxSize="'10MB'"
      :maxFiles="4"
      :helpText="'Choose images'"
      :errorText="{
        type: 'Invalid file type. Only images allowed',
        size: 'Files should not exceed 10MB in size',
      }"
      @select="filesSelected($event)"
      v-model="fileRecords"
    ></VueFileAgent>
    <!-- <v-btn
      :disabled="!fileRecordsForUpload.length"
      @click="upload2()"
      class="mt-3"
    >
      Upload {{ fileRecordsForUpload.length }} files
    </v-btn> -->
  </div>
</template>

<script>
export default {
  name: "ImageUploader",
  data() {
    return {
      fileRecords: [],
      uploadUrl:
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/",
      fileRecordsForUpload: [], // maintain an upload queue
    };
  },
  methods: {
    async upload2() {
      // Using the default uploader. You may use another uploader instead.
      let i = 0;
      for (i = 0; i < this.fileRecordsForUpload.length; i++) {
        await this.$refs.vueFileAgent.upload(
          this.uploadUrl + this.job.id + "/images/v2",
          this.uploadHeaders,
          [this.fileRecordsForUpload[i]],
          (fileData) => {
            console.log(fileData);

            // var file_index =
            //   this.fileRecordsForUpload.findIndex(
            //     (obj) => obj.file === fileData.file
            //   ) + 1;

            var formData = new FormData();
            formData.append("job_id", this.job.id);
            var name = this.job.id + "." + fileData.raw.ext;

            formData.append("filename", name);
            // formData.append("imageIndex", file_index);
            // console.log(fileData);
            formData.append("file", fileData.file); // this is important! - the actual file for upload
            return formData; // edit: fixed. Thanks to Chauhan-Nitesh for the comment below
          }
        );
      }

      console.log("done uploading all files");
      var temp = this.fileRecords.filter((fileRecord) => fileRecord.error);
      this.fileRecords = temp;

      console.log(temp);
      console.log("job id is:" + this.job.id);
      // this.$emit("uploaded", true);
      // if (this.job.id != "")
      //   this.$store.dispatch("fetchSingleJob", this.job.id);

      this.fileRecordsForUpload = [];
    },

    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    token() {
      return this.$store.getters.getCurrentToken;
    },
    uploadHeaders() {
      return {
        "X-Test-Header": "vue-file-agent",
        Authorization: "Bearer " + this.token,
      };
    },
  },
};
</script>

<style>
</style>