<template>
  <v-row class="ma-0 px-3">
    <!-- <EditJobComponent
      v-model="show"
      v-if="this.checkPermission('can_add_job_parts')"
    /> -->

    <div class="full-width">
      <h2 class="mb-3">Job Parts</h2>
      <!-- The above used to be "Job Components" -->
      <CurrentJobComponents :refs_prop="this.$refs" />
    </div>

    <div class="component-search-sep"></div>
    <AddJobComponent ref="AddJobComponent" />
  </v-row>
</template>

<script>
// import EditJobComponent from "./EditJobComponent.vue";
import AddJobComponent from './AddJobComponent.vue';
import CurrentJobComponents from './CurrentJobComponents.vue';
export default {
  name: 'PartsTable',
  components: {
    AddJobComponent,
    // EditJobComponent,
    CurrentJobComponents,
  },
  data() {
    return {};
  },
  methods: {},

  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
  },
};
</script>

<style scoped>
.job-component-quantity-field {
  border: 1px solid white;
  transition: 0.3s ease-in-out;
}

.changed-quantity-box .job-component-quantity-field {
  border: 1px solid orange;
}

.component-search-sep {
  height: 6px;
  background: #eaeaea;
  margin: 100px 0px;
  width: 100%;
}

>>> .cursor-pointer {
  cursor: pointer !important;
}
</style>
