<template>
  <div>
    <div
      class="d-flex justify-space-between align-end mb-8"
      ref="top_roles_table"
    >
      <div class="d-flex flex-column">
        <h4 class="mb-2">Search</h4>
        <v-text-field
          v-model="filter"
          solo
          style="max-width: 400px"
          hide-details
          placeholder="Type to search..."
          clearable
          @click:clear="filter = ''"
          :disabled="!this.RoleList.length > 0"
        ></v-text-field>
      </div>

      <v-btn
        color="success"
        @click="AddItem()"
        v-if="this.checkPermission('can_add_roles')"
        >Add Role</v-btn
      >
    </div>

    <v-data-table
      :headers="headers"
      :items="filtered_contact_list"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      calculate-widths
      hide-default-footer
      :loading="!this.RoleList.length > 0"
      loading-text="Loading... Please wait"
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="EditItem(item)"> mdi-pen </v-icon>
        <v-icon
          small
          @click="DeleteItem(item)"
          v-if="checkPermission('can_delete_roles')"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
      ></v-pagination>
    </div>

    <div class="mt-6">
      <transition name="slide-fade">
        <div
          class="edit-form"
          v-if="this.editFormVisible && this.CurrentSingleRole"
        >
          <EditRoleForm @closeModal="CloseModal()" />
        </div>
      </transition>
      <transition name="slide-fade">
        <div class="add-form" v-if="this.addFormVisible">
          <AddRoleForm @closeModal="CloseModal()" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import AddRoleForm from "./AddRoleForm";
import EditRoleForm from "./EditRoleForm";
export default {
  name: "RolesTable",
  components: {
    AddRoleForm,
    EditRoleForm,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      filter: "",
      editFormVisible: false,
      addFormVisible: false,
      editFormVisibleTab: null,
      itemsPerPage: 20,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          width: 75,
        },
        { text: "Name", value: "name", width: 200 },
        { text: "Description", value: "description", width: 300 },
        { text: "Permissions", value: "email", width: 325 },
        { text: "Actions", value: "actions", sortable: false, width: 100 },
      ],
    };
  },
  methods: {
    scrollToElement() {
      const el = this.$refs.top_roles_table.$el;
      if (el) {
        console.log(el);
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    CloseModal() {
      this.editFormVisible = false;
      this.addFormVisible = false;
      this.editFormVisibleTab = null;
      this.$store.commit("SetCurrentSingleRole", null);
      this.scrollToElement();
    },
    EditItem(role) {
      console.log(role);
      this.$store.commit("SetCurrentSingleRole", role);
      this.editFormVisible = true;
      this.addFormVisible = false;

      this.editFormVisibleTab = 0;
    },
    AddItem() {
      this.addFormVisible = true;
      this.editFormVisible = false;

      this.editFormVisibleTab = 0;
    },
    DeleteItem(role) {
      console.log(role);
      // this.$store.dispatch("DeleteSingleUser", user.id);
    },
  },
  mounted() {
    this.$store.dispatch("getAllRoles");
  },
  computed: {
    CurrentSingleRole() {
      return this.$store.getters.GetCurrentSingleRole;
    },
    RoleList() {
      return this.$store.getters.GetRoleList;
    },
    filtered_contact_list() {
      var filter = "";
      if (this.filter) filter = this.filter.trim();

      if (!filter.length > 0) return this.RoleList;
      return this.RoleList.filter((role) => {
        return (
          role["id"].toString().includes(filter) ||
          role["name"]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          role["email"]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          (role["company"] &&
            role["company"]["name"]
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase()))
        );
        // str.includes("world");
      });
    },
  },
};
</script>

<style scoped>
.header-role-chip {
  background: #1976d2;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.9s ease;
}
.slide-fade-leave-active {
  transition: all 0.9s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(30px);
  opacity: 0;
}
</style>