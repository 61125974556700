<template>
  <v-form ref="BookCollectionForm" v-model="valid" @submit.prevent="Submit">
    <v-row class="pa-4 ma-0">
      <v-col cols="12" sm="6" class="py-0 px-4">
        <h4 class="mb-2">Company</h4>
        <v-autocomplete
          v-model="formFields.selected_company"
          :disabled="isUpdating"
          :items="companyList"
          solo
          color="blue-grey lighten-2"
          label="Select"
          item-text="name"
          item-value="id"
          hide-no-data
          :rules="[rules.required, rules.isNaN]"
          hide-selected
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <!-- <v-list-item-avatar>
                <img :src="data.item.avatar" />
              </v-list-item-avatar> -->
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <!-- <v-list-item-subtitle
                  v-html="data.item.group"
                ></v-list-item-subtitle> -->
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

        <h4 class="mb-2">Contact</h4>

        <v-select
          :disabled="!CompanyContactList.length > 0"
          v-model="formFields.selected_contact"
          :items="CompanyContactList"
          :loading="isLoadingContacts"
          loader-height="2"
          label="Contact"
          item-value="id"
          item-text="name"
          :rules="[rules.required, rules.isNaN]"
          solo
        ></v-select>

        <h4 class="mb-2">Priority</h4>

        <v-select
          :items="priorities"
          item-text="name"
          item-value="id"
          label="Priority"
          solo
          name="selected_priority"
          v-model="formFields.selected_priority"
        ></v-select>

        <h4 class="mb-2">Customer Reference</h4>

        <v-text-field
          solo
          v-model="formFields.customer_reference"
          name="customer_reference"
        ></v-text-field>

        <h4 class="mb-2">Order Number</h4>

        <v-text-field
          solo
          v-model="formFields.order_number"
          name="order_number"
        ></v-text-field>
      </v-col>
      <v-col class="px-4 py-0">
        <h4 class="mb-2">Description</h4>

        <v-text-field
          solo
          v-model="formFields.description"
          name="description"
          :rules="[rules.required]"
        ></v-text-field>

        <h4 class="mb-2">Reported Fault</h4>

        <v-textarea
          solo
          v-model="formFields.fault"
          name="fault"
          label="Faults"
        ></v-textarea>

        <h4 class="mb-2">Internal Note</h4>

        <v-textarea
          solo
          v-model="formFields.internal_note"
          name="internal_note"
          label="Internal Note"
        ></v-textarea>

        <v-btn
          :disabled="!valid || isSubmitting"
          class="success mx-0 mt-3"
          type="submit"
          :loading="valid && isSubmitting"
          >Book The Collection</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "BookCollectionForm",
  data() {
    return {
      isUpdating: false,
      isSubmitting: false,
      isLoadingContacts: false,
      priorities: [
        { name: "Normal", id: 2 },
        { name: "High", id: 3 },
        { name: "Emergency", id: 4 },
      ],
      valid: false,

      formFields: {
        selected_company: "",
        selected_contact: "",
        customer_reference: "",
        order_number: "",
        description: "",
        selected_priority: 2,
        fault: "",
        internal_note: "",
      },

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  methods: {
    Submit() {
      console.log("booking new collection");
      console.log(this.formFields);
      console.log(this.validate());
      this.isSubmitting = true;
      var self = this;
      this.$store
        .dispatch("BookCollection", this.formFields)
        .then((job_id) => {
          console.log(job_id);
          if (!isNaN(parseInt(job_id)) && isFinite(job_id)) {
            this.$store.commit("SetSnackbarData", {
              message: "Collection booked successfully",
            });
            this.$router.push("/job/" + job_id);
            // this.$router.push("/dashboard");
          }
        })
        .finally(function () {
          self.isSubmitting = false;
        });
    },
    reset_form() {},
    validate() {
      return this.$refs.BookCollectionForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },

  mounted() {
    this.$store.dispatch("fetchCustomersList");
  },

  computed: {
    companyList() {
      return this.$store.getters.getCompanyList;
    },

    CompanyContactList() {
      return this.$store.getters.getCompanyContactList;
    },
  },

  watch: {
    "formFields.selected_company": function (new_selection, old_selection) {
      if (new_selection == null) {
        this.isLoadingContacts = true;

        this.$store.commit("setCompanyContactList", []).finally(() => {
          this.isLoadingContacts = false;
        });
      } else if (new_selection != old_selection) {
        this.isLoadingContacts = true;
        this.formFields.selected_contact = "";
        this.$store
          .dispatch("fetchCompanyContactList", new_selection)
          .finally(() => {
            this.isLoadingContacts = false;
          });
      }
    },
  },
};
</script>

<style>
</style>