<template>
  <v-row class="ma-0 pa-4">
    <v-col cols="12" sm="5" md="3">
      <div class="dates-container">
        <v-btn
          to="/reports/sales_by_month/"
          exact
          outlined
          class="mb-3"
          style="grid-column: span 2"
          >View All</v-btn
        >
        <v-btn
          v-for="date_obj in datesNav"
          :key="date_obj.date"
          :to="'/reports/sales_by_month/' + date_obj.date"
          outlined
          class="mb-3"
          >{{ date_obj.label }}</v-btn
        >
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="9">
      <div class="container">
        <div
          class="
            d-flex
            justify-space-between
            mb-9
            flex-column flex-sm-row
            align-center
          "
        >
          <!-- <h2 class="page-header">Job Sales By Month</h2> -->
          <h2 class="page-header">Invoiced Jobs by Month</h2>

          <v-btn
            @click="DownloadPDF('sales_by_month/pdf' + urlParamPdfEndpoint)"
            :loading="this.is_loading"
            :disabled="this.is_loading"
            class="mt-sm-0 mt-4"
            >Download as PDF</v-btn
          >
        </div>
        <div>
          <v-data-table
            :headers="headers"
            :items="jobs"
            :items-per-page="-1"
            class="elevation-1"
            style="width: 100%"
            :loading="is_loading"
            @click:row="gotoJob"
            mobile-breakpoint="0"
            :item-class="test"
          >
            <template v-slot:item.invDate="{ item }">
              {{ item.invDate | YMD_to_DMY }}
            </template>

            <template v-slot:item.NetTotal="{ item }">
              {{ item.NetTotal | toCurrency }}
            </template>

            <template v-slot:item.gross="{ item }">
              {{ (item.NetTotal * 1.2) | toCurrency }}
            </template>

            <template slot="body.append">
              <tr>
                <th>Total</th>
                <th></th>
                <th></th>
                <th>{{ NetTotalTotal | toCurrency }}</th>
                <th></th>
                <th></th>
              </tr>
            </template>
          </v-data-table>
          <div></div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Sales_by_Month",
  data() {
    return {
      jobs: [],
      is_loading: false,
      MonthMap: {
        1: "Jan",
        2: "Feb",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      },
      headers: [
        {
          text: "Job",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Customer",
          value: "company.name",
          class: "autofit-column-heading",
        },
        {
          text: "Description",
          value: "description",
          class: "autofit-column-heading",
        },
        {
          text: "Invoiced Date",
          value: "invDate",
          class: "autofit-column-heading",
        },
        { text: "£ Net", value: "NetTotal", class: "autofit-column-heading" },
        { text: "£ Gross", value: "gross", class: "autofit-column-heading" },
        {
          text: "Sales Rep",
          value: "company.sales_rep.name",
          class: "autofit-column-heading",
        },
      ],
    };
  },
  methods: {
    test(item) {
      console.log(item);
      return "cursor-pointer";
    },
    gotoJob(job) {
      const id = job.id;
      if (id) this.$router.push("/job/" + id);
    },
    DownloadPDF(endpoint) {
      this.is_loading = true;
      return this.$store.dispatch("DownloadReportPDF", endpoint).finally(() => {
        this.is_loading = false;
      });
    },
    CalculateJobsAssignedTotal(jobs) {
      var total = 0;
      var i = 0;
      for (i == 0; i < jobs.length; i++) {
        total += jobs[i].salQuote || 0;
      }
      return total;
    },

    toYYYYmmDD(date) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
  },
  computed: {
    NetTotalTotal() {
      var total = 0;
      for (let i = 0; i < this.jobs.length; i++) {
        total = total + this.jobs[i]["NetTotal"] || 0;
      }
      return total;
    },
    datesNav() {
      var today = new Date();
      // today = new Date(today.getFullYear(), today.getMonth(), 1);
      var dates = [];
      // dates.push(this.toYYYYmmDD(today));
      for (let i = 0; i < 12; i++) {
        var new_date = new Date(today.getFullYear(), today.getMonth() - i, 1);
        dates.push({
          date: this.toYYYYmmDD(new_date),
          label:
            this.MonthMap[new_date.getMonth() + 1] +
            " " +
            new_date.getFullYear(),
        });
      }
      return dates;
    },
    urlParam() {
      return this.$route.params.date || "";
    },
    urlParamPdfEndpoint() {
      if (this.urlParam) return "/" + this.urlParam;
      else return "";
    },
  },
  mounted() {
    var date = this.$route.params.date || "";
    if (date != "") date = "/" + date;
    console.log(this.urlParam);
    this.jobs = [];
    this.is_loading = true;
    this.$store
      .dispatch("get_report_data_for_tables", "sales_by_month" + date)
      .then((res) => {
        this.jobs = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
};
</script>

<style scoped>
.dates-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  padding: 10px;
}
</style>