<template>
  <div>
    <v-btn @click="EditCompanyModal()" color="success" large>Edit</v-btn>

    <v-dialog ref="editCompanyDialog" v-model="editModal" :persistent="isSubmitting" width="400px">
      <v-card>
        <v-form ref="EditCompanyForm" v-model="valid" @submit.prevent="Submit">
          <v-row class="pa-4 ma-0">
            <v-col cols="12">
              <h4 class="mb-2">Name</h4>
              <v-text-field
                solo
                :rules="[rules.required]"
                v-model="formFields.name"
                name="name"
              ></v-text-field>

              <h4 class="mb-2">Legal Entity</h4>
              <v-text-field
                solo
                v-model="formFields.legal_entity"
                name="legal_entity"
              ></v-text-field>

              <h4 class="mb-2">Address</h4>

              <v-textarea solo v-model="formFields.address" name="address"></v-textarea>

              <h4 class="mb-2">Region</h4>
              <v-select
                :items="this.regions"
                :disabled="this.isUpdating"
                label="Region"
                solo
                name="region"
                :rules="[rules.required]"
                v-model="formFields.region"
              ></v-select>

              <h4 class="mb-2">Email</h4>

              <v-text-field
                solo
                :rules="[rules.emailPattern]"
                v-model="formFields.email"
                name="email"
              ></v-text-field>

              <h4 class="mb-2">Phone</h4>

              <v-text-field
                v-model="formFields.phone"
                name="phone"
                :rules="[rules.required]"
                color="purple"
                filled
                type="text"
                v-on:keypress="isNotLetter($event)"
                rounded
                label="Phone"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Fax</h4>

              <v-text-field
                solo
                v-model="formFields.fax"
                name="fax"
                type="text"
                v-on:keypress="isNotLetter($event)"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Customer Code</h4>

              <v-text-field
                solo
                v-model="formFields.customer_code"
                name="customer_code"
              ></v-text-field>

              <h4 class="mb-2">Invoice Email</h4>

              <v-text-field
                solo
                v-model="formFields.invoice_email"
                name="invoice_email"
                :rules="[rules.required, rules.emailPattern]"
              ></v-text-field>

              <h4 class="mb-2">Invoice Address</h4>

              <v-textarea
                solo
                v-model="formFields.invoice_address"
                name="invoice_address"
              ></v-textarea>

              <h4 class="mb-2">Company Status</h4>
              <v-select
                :items="this.companyStatuses"
                :loading="this.companyStatuses.length == 0"
                :disabled="
                  this.isUpdating || !this.companyStatuses || !this.companyStatuses.length > 0
                "
                item-text="name"
                item-value="id"
                label="Company Status"
                solo
                name="company_status"
                v-model="formFields.company_status_id"
              ></v-select>

              <h4 class="mb-2">Sales Rep</h4>
              <v-select
                :items="this.SalesUsers"
                :loading="this.isUpdating"
                :disabled="this.isUpdating || !this.SalesUsers || !this.SalesUsers.length > 0"
                item-text="name"
                item-value="id"
                label="Sales Rep"
                solo
                clearable
                name="sales_rep_id"
                v-model="formFields.sales_rep_id"
              ></v-select>

              <h4 class="mb-2">Term</h4>
              <v-select
                :items="this.TermList"
                :loading="this.isUpdating"
                :disabled="this.isUpdating || !this.TermList || !this.TermList.length > 0"
                item-text="name"
                item-value="id"
                label="Term"
                solo
                clearable
                name="Term_id"
                v-model="formFields.term_id"
              ></v-select>

              <!-- add toggles for is customer and is supplier -->

              <!--
  can use
   hint="This label matches most of the examples in the Material Design documentation."
                persistent-hint
 -->
              <v-switch
                class="v-input--reverse v-input--expand"
                v-model="formFields.is_customer"
                color="orange darken-3"
              >
                <template #label> Is Customer? </template>
              </v-switch>

              <v-switch
                class="v-input--reverse v-input--expand"
                color="orange darken-3"
                v-model="formFields.is_supplier"
              >
                <template #label> Is Supplier? </template>
              </v-switch>

              <v-switch
                class="v-input--reverse v-input--expand"
                color="orange darken-3"
                v-model="formFields.is_subcontractor"
              >
                <template #label> Is Subcontractor? </template>
              </v-switch>

              <div class="mx-0 mt-3 d-flex justify-space-between">
                <v-btn :disabled="isSubmitting" color="error" text @click="editModal = false"
                  >Cancel</v-btn
                >

                <v-btn
                  :disabled="!valid || isSubmitting"
                  class="success"
                  type="submit"
                  :loading="isSubmitting"
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'EditCompanyForm',
  data() {
    return {
      valid: false,
      companyStatuses: [],
      isUpdating: false,
      editModal: false,
      SalesUsers: [],
      isSubmitting: false,
      formFields: {
        name: '',
        legal_entity: '',
        email: '',
        phone: '',
        fax: '',
        address: '',
        is_customer: false,
        customer_code: null,
        is_supplier: false,
        is_subcontractor: false,
        company_status_id: null,
        sales_rep_id: null,
        term_id: null,
        invoice_email: null,
        invoice_address: null,
        region: null,
      },
      regions: ['NW', 'NE', 'Mids', 'SE', 'SW', 'Scotland', 'Europe'],

      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        isNaN: (v) => this.isNumeric(v) == true || 'Wrong Value',
        emailMatch: () => `The email and password you entered don't match`,
        emailPattern: (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          'E-mail must be valid',
      },
    };
  },
  methods: {
    EditCompanyModal() {
      this.editModal = true;
    },
    Submit() {
      if (this.validate()) {
        // this.formFields.contact_id = this.CurrentSingleContact.id;
        this.isSubmitting = true;
        console.log('Editing Company');
        console.log(this.formFields);
        console.log(this.validate());
        const payload = {
          company_id: this.CurrentSingleCompany.id,
          data: this.formFields,
        };
        this.$store
          .dispatch('UpdateSingleCompany', payload)
          .then(() => {
            this.$store.commit('SetSnackbarData', {
              message: 'Company updated successfully.',
            });
            this.editModal = false;
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.EditCompanyForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isNotLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      var input = e.target;
      var val = input.value;
      var end = input.selectionEnd;

      if (e.keyCode == 32 && (val[end - 1] == ' ' || val[end] == ' ')) {
        e.preventDefault();
        return false;
      } else if (/^[+0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },
  computed: {
    CurrentSingleCompany() {
      return this.$store.getters.GetCurrentSingleCompany;
    },
    companyList() {
      return this.$store.getters.getCompanyList;
    },
    TermList() {
      return this.$store.getters.GetTerms;
    },
  },
  mounted() {
    // this.isUpdating = true;
    // this.$store.dispatch("fetchCompanyList").finally(() => {
    //   this.isUpdating = false;
    // });

    this.isUpdating = true;
    this.$store
      .dispatch('getUsersByPermission', 'show_in_sales_rep_dropdown')
      .then((users) => {
        this.SalesUsers = users;
      })
      .finally(() => {
        this.isUpdating = false;
      });

    this.$store.dispatch('fetchCompanyStatuses').then((statuses) => {
      this.companyStatuses = statuses;
    });
    if (this.CurrentSingleCompany) {
      this.formFields.name = this.CurrentSingleCompany.name;
      this.formFields.legal_entity = this.CurrentSingleCompany.legal_entity;
      this.formFields.email = this.CurrentSingleCompany.email;
      this.formFields.phone = this.CurrentSingleCompany.phone;
      this.formFields.fax = this.CurrentSingleCompany.fax;
      this.formFields.address = this.CurrentSingleCompany.address;
      this.formFields.is_customer = this.CurrentSingleCompany.is_customer;
      this.formFields.is_supplier = this.CurrentSingleCompany.is_supplier;
      this.formFields.is_subcontractor = this.CurrentSingleCompany.is_subcontractor;
      this.formFields.sales_rep_id = this.CurrentSingleCompany.sales_rep_id;
      this.formFields.term_id = this.CurrentSingleCompany.term_id;
      this.formFields.company_status_id = this.CurrentSingleCompany.company_status_id;

      this.formFields.invoice_email = this.CurrentSingleCompany.invoice_email;
      this.formFields.invoice_address = this.CurrentSingleCompany.invoice_address;
      this.formFields.customer_code = this.CurrentSingleCompany.customer_code;
      this.formFields.region = this.CurrentSingleCompany.region;
    }
  },
};
</script>

<style></style>
