<template>
  <v-dialog ref="editComponentQTYDialog" v-model="show" :persistent="isSubmitting" width="400px">
    <v-card>
      <v-card-title class="text-h5 primary text-white darken-3 text-center">
        Update the stock quantity of:
      </v-card-title>
      <v-form ref="editComponentQTYForm" v-model="valid" @submit.prevent="Submit">
        <v-row class="pa-4 ma-0">
          <v-col cols="12">
            <h4 class="mb-2">Quantity In Stock</h4>
            <v-text-field
              v-model.number="formFields.qty_in_stock"
              name="qty_in_stock"
              :rules="[rules.isNaN]"
              type="number"
              color="purple"
              filled
              rounded
              @keypress="IntegerOnly"
              label="Quantity In Stock"
              class="hide-arrows"
            ></v-text-field>

            <div class="mx-0 mt-3 d-flex justify-space-between">
              <v-btn :disabled="isSubmitting" color="error" text @click="show = false"
                >Cancel</v-btn
              >

              <v-btn
                :disabled="!valid || isSubmitting"
                class="success"
                type="submit"
                :loading="isSubmitting"
                >Update Stock</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditComponentQTY',
  props: {
    value: Boolean,
    currentEditItem: Object,
  },
  data() {
    return {
      valid: false,
      isUpdating: false,
      isSubmitting: false,
      formFields: {
        qty_in_stock: 0,
      },
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        isNaN: (v) => this.isNumeric(v) == true || 'Wrong Value',
      },
    };
  },
  methods: {
    IntegerOnly(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    ResetForm() {
      this.$refs.editComponentQTYForm.reset();
      // this.formFields.include_cc_quotation = false;
      // this.formFields.include_cc_delivery = false;
    },
    Submit() {
      if (this.validate()) {
        this.isSubmitting = true;
        // console.log('Editing Component');
        console.log(this.formFields);
        console.log(this.validate());
        // return;
        this.$store
          .dispatch('UpdateComponentStockOnly', { id: this.currentEditItem.id, ...this.formFields })
          .then((new_component) => {
            if (new_component && new_component.id) {
              this.$store.commit('SetSnackbarData', {
                message: 'Component stock updated successfully.',
              });
              this.show = false;
              this.ResetForm();
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.editComponentQTYForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isNotLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      var input = e.target;
      var val = input.value;
      var end = input.selectionEnd;

      if (e.keyCode == 32 && (val[end - 1] == ' ' || val[end] == ' ')) {
        e.preventDefault();
        return false;
      } else if (/^[+0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    show(newVal, old) {
      if (!this.currentEditItem || !this.currentEditItem.id) return;
      if (old != newVal && newVal == true) {
        this.formFields.qty_in_stock = this.currentEditItem.qty_in_stock;
        // this.formFields = { ...this.CurrentSingleComponent };
      } else if (old != newVal && old === true && newVal == false) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<style>
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.v-application--is-ltr .v-input--reverse .v-input__slot .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}
.v-application--is-rtl .v-input--reverse .v-input__slot .v-input--selection-controls__input {
  margin-left: 0;
  margin-right: 8px;
}

.v-input--expand .v-input__slot .v-label {
  display: block;
  flex: 1;
}
</style>
