var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ConfirmationDialog',{attrs:{"loader":_vm.isDeleting,"itemName":'job part'},on:{"cancelled":function($event){_vm.deleteModal = false},"confirmed":function($event){return _vm.DeleteItem()}},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.JobComponents,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"calculate-widths":"","hide-default-footer":"","loading":this.isLoadingJobComponents,"loading-text":"Loading... Please wait"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2",class:_vm.quantities_intermediate[item.component_id] != item.quantity
            ? 'changed-quantity-box'
            : '',staticStyle:{"max-width":"75px"},attrs:{"id":item.component_id + '-quantity'}},[_c('v-text-field',{staticClass:"job-component-quantity-field",attrs:{"name":"quantity","type":"number","single-line":"","min":"0","max":item.component.qty_in_stock + item.quantity,"dense":"","hide-details":"","solo":""},on:{"change":function($event){return _vm.jobComponentQuantityChanged(
              $event,
              item.quantity,
              item.component_id,
              item
            )}},model:{value:(_vm.quantities_intermediate[item.component_id]),callback:function ($$v) {_vm.$set(_vm.quantities_intermediate, item.component_id, _vm._n($$v))},expression:"quantities_intermediate[item.component_id]"}})],1)]}},{key:"item.component.datasheet",fn:function(ref){
            var item = ref.item;
return [(item.component.datasheet)?_c('a',{attrs:{"target":"_blank","href":item.component.datasheet}},[_c('v-img',{attrs:{"src":require('../../../../assets/pdf.svg'),"contain":"","width":"36"}})],1):_c('div',[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.checkPermission('can_add_job_parts') || true)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.deleteItem = item;
          _vm.deleteModal = true;}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])}),_c('v-btn',{staticClass:"mt-6 ml-auto d-flex",attrs:{"color":"primary","loading":_vm.isUpdatingComponentQuantities,"disabled":!_vm.quantities_changed.length > 0 || _vm.isUpdatingComponentQuantities},on:{"click":_vm.saveQuantities}},[_vm._v("Save Quantities")]),(_vm.pageCount > 1)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }