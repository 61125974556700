<template>
  <div class="dashboard-page-container">
    <div class="dashboard-filters-container d-flex">
      <v-text-field
        solo
        class="single-dashboard-filter mr-5"
        v-model="filter"
        placeholder="Type something to search.."
        label="Search"
      />

      <FilterByUserId v-model="assigned_user" />
    </div>

    <v-row class="ma-0" v-if="Object.keys(jobList).length > 0">
      <JobDashboardColumn
        v-for="(jobs, name) in jobList"
        :key="name"
        :jobs="jobs"
        :filter="filter"
        :assigned_user="assigned_user"
        :col_Id="name"
        :maxIndex="maxIndex"
      />
    </v-row>
    <LoadingScreen message="Fetching Job List..." v-else />
  </div>
</template>

<script>
import JobDashboardColumn from "../../components/jobs/dashboard/JobDashboardColumn.vue";
import FilterByUserId from "../../components/jobs/dashboard/FilterByUserId";
import LoadingScreen from "../../components/global/LoadingScreen";
import Echo from "laravel-echo";

export default {
  components: {
    JobDashboardColumn,
    LoadingScreen,
    FilterByUserId,
  },
  data() {
    return {
      jobs: [],
      filter: "",
      assigned_user: null,
      maxIndex: 20,
    };
  },

  computed: {
    jobList() {
      return this.$store.getters.getJobList;
    },
    UserObj() {
      return this.$store.getters.getCurrentUserObj;
    },
  },
  mounted() {
    this.$store.dispatch("getJobs");
    var token = this.$store.getters.getCurrentToken;

    if (!window.Echo)
      window.Echo = new Echo({
        authEndpoint:
          process.env.VUE_APP_API_PROTOCOL +
          "://" +
          process.env.VUE_APP_API_HOST +
          ":" +
          process.env.VUE_APP_API_PORT +
          "/api/broadcasting/auth",
        broadcaster: "pusher",
        key: "72b51d7768de32a685c5",
        cluster: "eu",
        forceTLS: true,
        auth: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      });

    // var socketId = window.Echo.socketId();
    // if (socketId) this.$store.commit("SetSocketId", socketId);
    /* condition here - if can see col 1*/
    if (this.UserObj != undefined && this.UserObj.permissions["col_1"] == 1)
      window.Echo.private("JobDashboard").listen(".collection-booked", (e) => {
        console.log(e);
        this.$store.commit("CollectionBooked", e.data); //as it's returned from laravel as {data: {data here}}
      });

    /* condition here - if can see col 3*/
    if (this.UserObj != undefined && this.UserObj.permissions["col_3"] == 1)
      window.Echo.private("JobDashboard").listen(".job-created", (e) => {
        console.log(e);
        this.$store.commit("JobCreated", e.data); //as it's returned from laravel as {data: {data here}}
      });

    window.Echo.private("JobDashboard").listen(
      ".job-updated-dashboard",
      (e) => {
        console.log(e.data);
        const updatedJob = e.data;
        const old_card = document.getElementById(updatedJob.id);
        if (!old_card || !old_card.dataset) return;
        const old_status = old_card.dataset.status_id;
        const old_index = old_card.dataset.index;
        if (!old_status || !old_index) return;
        this.$store.commit("JobUpdatedDashboard", {
          updatedJob: updatedJob,
          old_status: old_status,
          old_index: old_index,
        }); //as it's returned from laravel as {data: {data here}}
        //this.$forceUpdate();
      }
    );

    window.Echo.private("user." + this.UserObj.id).listen(
      ".test-event",
      (e) => {
        console.log(e);
      }
    );
  },
  beforeDestroy() {
    window.Echo.leave(`testChannel`);
    this.$store.commit("setJobList", {});
  },
};
</script>

<style>
.dashboard-page-container {
  background: #eee;
  min-height: 100vh;
}

@media only screen and (max-width: 600px) {
  .dashboard-filters-container {
    flex-direction: column;
  }
  .dashboard-page-container {
    padding: 20px;
  }
  .dashboard-page-container
    .dashboard-filters-container
    .single-dashboard-filter {
    margin-right: 0px !important;
  }
}
</style>