import Axios from "axios"

const state = {
  jobList: {},
  companyList: [],
  CompanyContactList: [],
  ManufacturerList: [],
  // departments: {
  //   1: { id: 1, name: "Mechanical" },
  //   2: { id: 2, name: "Suuplies" },
  //   3: { id: 3, name: "Electronic" },
  //   4: { id: 4, name: "Condition Monitoring" },
  //   5: { id: 5, name: "Logistics" },
  //   6: { id: 6, name: "Servo" },
  //   7: { id: 7, name: "Balancing" },
  // }

}
const mutations = {
  setJobList(state, payload) {
    state.jobList = payload;
  },
  setManufacturerList(state, payload) {
    state.ManufacturerList = payload;
  },
  setCompanyList(state, payload) {
    state.companyList = payload;
  },
  setCompanyContactList(state, payload) {
    state.CompanyContactList = payload;
  },
  PushContactToEnd(state, payload) {
    state.CompanyContactList.push(payload)
  },
  PushCompanyToEnd(state, payload) {
    state.companyList.push(payload)
  },
  RemoveCompanyFromList(state, payload) {
    state.companyList.splice(state.companyList.findIndex(function (company) {
      return company.id === payload;
    }), 1);
  },

  RemoveContactFromList(state, payload) {
    state.CompanyContactList.splice(state.CompanyContactList.findIndex(function (contact) {
      return contact.id === payload;
    }), 1);
  }



}
const actions = {

  getJobs(context) {
    var self = this;
    console.log("getting jobs");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs"
      ).then(function (response) {
        console.log(response.data);
        console.log(self.state.UserAuth.currentUserObj);
        var job_list = response.data;
        var user_perms = self.state.UserAuth.currentUserObj.permissions;
        var i = 1;
        for (i = 1; i <= 8; i++) {
          if ((user_perms["col_" + i] == 1 || user_perms["col_" + i] == true) && job_list[i.toString()] == undefined)
            job_list[i.toString()] = [];
        }
        context.commit("setJobList", job_list);
        return response.data
      })
        .catch(function (err) {
          console.log(err);
        });
  },

  BookCollection(context, payload) {
    console.log("booking collection store action with payload");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/book", payload
      ).then(function (response) {
        console.log(response.data);
        // context.commit("setJobList", response.data);
        return response.data

      })
        .catch(function (err) {
          console.log(err);
        });
  },


  CreateJob(context, payload) { //creates a job with form data at status 3
    console.log("create job store action with payload");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/create", payload
      ).then(function (response) {
        console.log(response.data);
        // context.commit("setJobList", response.data);
        return response.data

      })
        .catch(function (err) {
          console.log(err);
        });
  },


  fetchCompanyList(context) {
    console.log("getting company list");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/companies"
      ).then(function (response) {
        console.log(response.data);
        context.commit("setCompanyList", response.data);
      })
        .catch(function (err) {
          console.log(err);
        });
  },

  fetchFullCompanyList(context) {
    console.log("getting company list");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/companies/all",
        {
          // headers: {
          // "Content-Type": "application/json",
          // "Accept": "application/json",
          // },
        }
      ).then(function (response) {
        console.log(response.data);



        context.commit("setCompanyList", response.data);


      })
        .catch(function (err) {
          console.log(err);
        });
  },



  fetchSuppliersList(context) {
    console.log("getting company list");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/companies/suppliers",
        {
          // headers: {
          // "Content-Type": "application/json",
          // "Accept": "application/json",
          // },
        }
      ).then(function (response) {
        console.log(response.data);



        context.commit("setCompanyList", response.data);


      })
        .catch(function (err) {
          console.log(err);
        });
  },

  fetchCustomersShortList(context) {
    console.log("getting company list");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/companies/customers/short"
      ).then(function (response) {
        console.log(response.data);

        context.commit("setCompanyList", response.data);
      })
        .catch(function (err) {
          console.log(err);
        });
  },


  fetchManufacturersList(context) {
    console.log("getting company list");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/manufacturers"
      ).then(function (response) {
        //console.log(response.data);

        context.commit("setManufacturerList", response.data);
      })
        .catch(function (err) {
          console.log(err);
        });
  },

  fetchCustomersList(context) {
    console.log("getting company list");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/companies/customers"
      ).then(function (response) {
        console.log(response.data);

        context.commit("setCompanyList", response.data);
      })
        .catch(function (err) {
          console.log(err);
        });
  },

  fetchCompanyContactList(context, company_id) {

    console.log("getting company list");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);
    var endpoint = "/api/contacts";
    if (token != "") {
      if (company_id && company_id != "" && company_id != null)
        endpoint = "/api/companies/" + company_id + "/contacts";


      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        endpoint
      ).then(function (response) {
        console.log(response.data);



        context.commit("setCompanyContactList", response.data);


      })
        .catch(function (err) {
          console.log(err);
        });
    }



  }
}
const getters = {
  getJobList(state) {
    return state.jobList;
  },
  getCompanyList(state) {
    return state.companyList;
  },

  getCompanyContactList(state) {
    return state.CompanyContactList;
  },


  getManufacturersList(state) {
    return state.ManufacturerList;
  },


}

export default {
  state,
  getters,
  mutations,
  actions
}