<template>
  <div class="ma-0 pa-4 d-flex flex-column">
    <h3>Export CSV</h3>
    <v-btn
      color="success"
      class="ml-auto mb-4"
      @click="ExportCSV()"
      :loading="is_exporting"
      :disabled="is_exporting || selected_jobs.length == 0"
      >Export</v-btn
    >
    <v-data-table
      v-model="selected_jobs"
      :headers="headers"
      :items="jobs"
      :items-per-page="-1"
      class="elevation-1"
      style="width: 100%"
      :loading="is_loading"
      show-select
    >
      <template v-slot:item.delDate="{ item }">
        {{ item.delDate | YMD_to_DMY }}
      </template>

      <template v-slot:item.NetTotal="{ item }">
        {{ item.NetTotal | toCurrency }}
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center">
          <v-btn
            class="mr-5"
            small
            color="warning"
            @click="SendInvoice(item.id)"
            :loading="indexClicked == item.id + '-sendInv'"
            >Send Invoice</v-btn
          >
          <v-btn
            @click="ViewInvoice(item.id)"
            :ref="item.id + '-viewInv'"
            :loading="indexClicked == item.id + '-viewInv'"
            small
            color="primary"
            >View Invoice</v-btn
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SageExport",
  data() {
    return {
      is_loading: false,
      indexClicked: null,
      dialog: false,
      selected_jobs: [],
      is_exporting: false,
      jobs: [],
      headers: [
        {
          text: "Job",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Customer", value: "company.name" },
        { text: "Description", value: "description" },
        { text: "Date Delivered	", value: "delDate" },
        { text: "Order Number", value: "order_number" },
        { text: "Engineer", value: "user.name" },
        { text: "£ Net", value: "NetTotal" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  methods: {
    ExportCSV() {
      this.is_exporting = true;
      this.$store
        .dispatch("fetchSageExportCSV", this.SelectedJobsComputed)
        .then((response) => {
          var downloadLink = document.createElement("a");
          var fileData = ["\ufeff" + response];

          var blobObject = new Blob(fileData, {
            type: "text/csv;charset=utf-8;",
          });

          var url = URL.createObjectURL(blobObject);
          downloadLink.href = url;
          downloadLink.download = "sage_export.csv";

          /*
           * Actually download CSV
           */
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        })
        .finally(() => {
          this.is_exporting = false;
        });
    },
    SendSingleInvoice(jobId) {
      if (!jobId) return;
      this.$store.dispatch("send_invoice", jobId);
    },
    ViewInvoice(jobId) {
      if (!jobId) return;
      // this.is_deleting = true;
      this.indexClicked = jobId + "-viewInv";
      return axios
        .get(
          process.env.VUE_APP_API_PROTOCOL +
            "://" +
            process.env.VUE_APP_API_HOST +
            ":" +
            process.env.VUE_APP_API_PORT +
            "/api/reports/" +
            jobId +
            "/invoice",
          { responseType: "blob" } // important
        )
        .then((response) => {
          var blob = new Blob([response.data], { type: "application/pdf" });
          var blobURL = URL.createObjectURL(blob);
          window.open(blobURL);
        })

        .catch((error) => {
          //cuz its a blob request the error isn't read with a message
          this.$store.commit("SetSnackbarData", {
            message: error,
            type: "error",
          });
        })

        .finally(() => {
          this.indexClicked = null;
        });
    },

    SendInvoice(jobId) {
      if (!jobId) return;
      // this.is_deleting = true;
      this.indexClicked = jobId + "-sendInv";
      this.$store
        .dispatch("send_invoice", jobId)
        .then(() => {
          this.jobs.splice(
            this.jobs.findIndex(function (job) {
              return job.id === jobId;
            }),
            1
          );
        })

        .catch((error) => {
          //cuz its a blob request the error isn't read with a message
          this.$store.commit("SetSnackbarData", {
            message: error,
            type: "error",
          });
        })

        .finally(() => {
          this.indexClicked = null;
        });
    },
  },
  mounted() {
    this.is_loading = true;
    this.$store
      .dispatch("fetchSageExportTableData")
      .then((res) => {
        this.jobs = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
  computed: {
    SelectedJobsComputed() {
      return this.selected_jobs.map((job) => job.id);
    },
  },
};
</script>

<style>
</style>