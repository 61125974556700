<template>
  <div class="doc-uploader-container">
    <label for="doc_admin_toggle" class="doc_admin_toggle">
      <v-switch
        ref="doc_visibility_switch"
        v-model="doc_visible_to_all_switch"
        dense
        @click.prevent
        hide-details
        id="doc_admin_toggle"
        :label="
          `Visible to: ${this.doc_visible_to_all_switch == true ? 'All Users' : 'Admins only'}`
        "
      ></v-switch>
    </label>
    <VueFileAgent
      ref="vueFileAgent"
      :multiple="true"
      :meta="true"
      :editable="true"
      :accept="'.pdf, .doc, .docx, .xls, .xlsx, .csv, .zip, .rar, .7zip, .7z, .tar'"
      :maxSize="'15MB'"
      :maxFiles="14"
      :helpText="'Choose documents'"
      :errorText="{
        type: 'Invalid file type. Only PDF, Zip, word or excel documents are allowed',
        size: 'Files should not exceed 15MB in size',
      }"
      @select="filesSelected($event)"
      v-model="fileRecords"
    ></VueFileAgent>
    <!--      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)" -->

    <v-btn :disabled="!fileRecordsForUpload.length" @click="uploadFiles()" class="mt-3">
      Upload {{ fileRecordsForUpload.length }} files
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'DocumentsUploader',
  data() {
    return {
      fileRecords: [],
      uploadUrl:
        process.env.VUE_APP_API_PROTOCOL +
        '://' +
        process.env.VUE_APP_API_HOST +
        ':' +
        process.env.VUE_APP_API_PORT +
        '/api/upload',
      doc_visible_to_all_switch: true,
      fileRecordsForUpload: [], // maintain an upload queue
    };
  },
  methods: {
    change_switch() {
      this.$refs.doc_visibility_switch.$el.click();
    },
    uploadFiles: function() {
      // var job_id = this.job.id;
      // var self = this;
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent
        .upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload, (fileData) => {
          var formData = new FormData();
          formData.append('job_id', this.job.id);
          formData.append('is_admin', !this.doc_visible_to_all_switch);

          var name = fileData.file.name;

          if (fileData.customName) name = fileData.customName + '.' + fileData.raw.ext;

          formData.append('filename', name);
          console.log(fileData);
          formData.append('file', fileData.file); // this is important! - the actual file for upload
          return formData; // edit: fixed. Thanks to Chauhan-Nitesh for the comment below
        })
        .then(() => {
          console.log('done uploading a file');
          // var temp = this.fileRecords;

          var temp = this.fileRecords.filter((fileRecord) => fileRecord.error);
          this.fileRecords = temp;
          // temp.forEach((record, index) => {
          //   console.log("looping through uploads");

          //   if (!record.error) {
          //     this.fileRecords.splice(index, 1);
          //     console.log("success");
          //   }
          // });
          console.log(temp);
          console.log('job id is:' + this.job.id);

          if (this.job.id != '') this.$store.dispatch('fetchSingleJob', this.job.id);
        });
      this.fileRecordsForUpload = [];
    },

    filesSelected: function(fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },

    // deleteUploadedFile: function (fileRecord) {
    //   // Using the default uploader. You may use another uploader instead.
    //   this.$refs.vueFileAgent.deleteUpload(
    //     this.uploadUrl,
    //     this.uploadHeaders,
    //     fileRecord
    //   );
    // },
    // onBeforeDelete: function (fileRecord) {
    //   var i = this.fileRecordsForUpload.indexOf(fileRecord);
    //   if (i !== -1) {
    //     // queued file, not yet uploaded. Just remove from the arrays
    //     this.fileRecordsForUpload.splice(i, 1);
    //     var k = this.fileRecords.indexOf(fileRecord);
    //     if (k !== -1) this.fileRecords.splice(k, 1);
    //   } else {
    //     if (confirm("Are you sure you want to delete?")) {
    //       this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
    //     }
    //   }
    // },
    // fileDeleted: function (fileRecord) {
    //   var i = this.fileRecordsForUpload.indexOf(fileRecord);
    //   if (i !== -1) {
    //     this.fileRecordsForUpload.splice(i, 1);
    //   } else {
    //     this.deleteUploadedFile(fileRecord);
    //   }
    // },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    token() {
      return this.$store.getters.getCurrentToken;
    },
    uploadHeaders() {
      return {
        'X-Test-Header': 'vue-file-agent',
        Authorization: 'Bearer ' + this.token,
      };
    },
  },
};
</script>

<style>
.doc_admin_toggle {
  position: absolute;
  right: 2px;
  width: 250px;
  top: 2px;
  background: #eee;
  padding: 0px 15px 15px 20px;
  z-index: 11;
  cursor: pointer;
}
.doc-uploader-container {
  position: relative;
}
</style>
