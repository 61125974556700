<template>
  <book-collection-form />
</template>

<script>
import BookCollectionForm from "../../components/jobs/forms/BookCollectionForm.vue";
export default {
  components: { BookCollectionForm },
  name: "BookCollection",
  data() {
    return {};
  },
};
</script>

<style>
</style>