import Axios from "axios"

const state = {


}
const mutations = {

}
const actions = {


  SearchJobs(context, payload) {
    console.log("search jobs store action with payload");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/search", payload
      ).then(function (response) {
        console.log(response.data);
        // context.commit("setJobList", response.data);
      })
        .catch(function (err) {
          console.log(err);

          context.commit("SetSnackbarData", {
            message: "Error occured while searching for jobs",
            timeout: 300000,
            type: "error",
          });
          return Promise.reject(err);
        });
  },

}
const getters = {

}

export default {
  state,
  getters,
  mutations,
  actions
}