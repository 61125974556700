import Axios from "axios"

const state = {
  CurrentSingleUser: null,
  userList: [],
}
const mutations = {
  SetCurrentSingleUser(state, payload) {
    state.CurrentSingleUser = payload
  },
  SetUserList(state, payload) {
    state.userList = payload;
  },
  PushUserToEnd(state, payload) {
    state.userList.push(payload)
  },
  RemoveUserFromList(state, payload) {
    state.userList.splice(state.userList.findIndex(function (user) {
      return user.id === payload;
    }), 1);
  },
}
const actions = {


  getUsersByTag(context, payload) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/users/bytag/" + payload
      ).then(function (response) {
        console.log(response.data);
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },



  getUsersByPermission(context, payload) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/users/bypermission/" + payload
      ).then(function (response) {
        console.log(response.data);
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },



  fetchEngineersList(context, payload) {
    console.log("fetching fetchEngineersListStage6");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/engineers", payload).then(function (response) {
          console.log(response.data);

          // context.commit("SetSnackbarData", {
          //   message: "fetched engineers",
          // });

          return response.data;
          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

        });
  },



  getAllUsers(context) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/users"
      ).then((response) => {
        console.log(response.data);
        context.commit("SetUserList", response.data)
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },


  fetchSingleUser(context, payload) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/users/" + payload
      ).then((response) => {
        console.log(response.data);
        context.commit("SetCurrentSingleUser", response.data)
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },



  DeleteSingleUser(context, payload) {
    console.log("updating single contact");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/users/" + payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        // context.commit("SetCurrentSingleContact", response.data);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },

  // fetchAllRoles(context) {
  //   console.log("getUsersByTag store action with payload");
  //   var token = context.getters.getCurrentToken;
  //   console.log("token: " + token);

  //   if (token != "")
  //     return Axios.get(
  //       process.env.VUE_APP_API_PROTOCOL +
  //       "://" +
  //       process.env.VUE_APP_API_HOST +
  //       ":" +
  //       process.env.VUE_APP_API_PORT +
  //       "/api/roles/"
  //     ).then((response) => {
  //       console.log(response.data);
  //       return response.data;
  //     })
  //       .catch(function (error) {
  //         console.log(error.response.data.message);
  //         context.commit("SetSnackbarData", {
  //           message: error.response.data.message,
  //           type: "error",
  //         });

  //         return Promise.reject(error);
  //       });
  // },



  UpdateSingleUser(context, payload) {
    console.log("updating single user");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/users/" + payload.user_id, payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        context.commit("SetCurrentSingleUser", response.data);
        return response;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },


  AddSingleUser(context, payload) {
    console.log("updating single user");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/users", payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        // context.commit("SetCurrentSingleUser", response.data);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },

}
const getters = {
  GetUserList(state) {
    return state.userList;
  },
  GetCurrentSingleUser(state) {
    return state.CurrentSingleUser;
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}