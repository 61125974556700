<template>
  <div class="ma-0 pa-5">
    <div class="d-flex justify-space-between align-center">
      <h2 class="pa-4 mx-0">Viewing Company: {{ this.CurrentSingleCompany.name }}</h2>
      <EditCompanyForm v-if="conditional_edit_company()" />
    </div>

    <!-- <v-btn @click="EditCompanyModal()">Edit</v-btn> -->
    <v-row class="ma-0">
      <v-col md="4" cols="12">
        <div class="table-cont" v-if="this.CurrentSingleCompany && this.CurrentSingleCompany.id">
          <div class="table-header pa-3">
            <div class="table-property-label">Company Information</div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Name</div>
            <div class="table-property-value" style="text-transform: capitalize">
              {{ this.CurrentSingleCompany.name }}
            </div>
          </div>

          <div class="table-row long-text-row">
            <div class="table-property-label">Address</div>
            <div class="table-property-value">
              {{ this.CurrentSingleCompany.address }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Phone</div>
            <div class="table-property-value">
              {{ this.CurrentSingleCompany.phone }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Fax</div>
            <div class="table-property-value">
              {{ this.CurrentSingleCompany.fax }}
            </div>
          </div>

          <div class="table-row long-text-row">
            <div class="table-property-label">Email</div>
            <div class="table-property-value">
              {{ this.CurrentSingleCompany.email }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Customer Code</div>
            <div class="table-property-value">
              {{ this.CurrentSingleCompany.customer_code }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Sales Rep.</div>
            <div class="table-property-value">
              {{
                this.CurrentSingleCompany.sales_rep_id && this.CurrentSingleCompany.sales_rep
                  ? this.CurrentSingleCompany.sales_rep.name
                  : ''
              }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Is customer?</div>
            <div class="table-property-value">
              <v-icon v-if="this.CurrentSingleCompany.is_customer" color="success">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="error"> mdi-close-circle </v-icon>
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Is supplier?</div>
            <div class="table-property-value">
              <v-icon v-if="this.CurrentSingleCompany.is_supplier" color="success">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="error"> mdi-close-circle </v-icon>
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Is subcontractor?</div>
            <div class="table-property-value">
              <v-icon v-if="this.CurrentSingleCompany.is_subcontractor" color="success">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="error"> mdi-close-circle </v-icon>
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Company Status</div>
            <div class="table-property-value" v-if="this.CurrentSingleCompany.company_status">
              <span>{{ this.CurrentSingleCompany.company_status.name }}</span>
              <span
                class="company-status-desc"
                v-if="this.CurrentSingleCompany.company_status.description"
                >{{ this.CurrentSingleCompany.company_status.description }}</span
              >
            </div>
            <div class="table-property-value" v-else></div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Terms</div>
            <div class="table-property-value" v-if="this.CurrentSingleCompany.term">
              {{ this.CurrentSingleCompany.term.name }}
            </div>
            <div class="table-property-value" v-else>No term selected</div>
          </div>
        </div>
      </v-col>

      <v-col md="4" cols="12">
        <div
          class="table-cont"
          v-if="
            this.CurrentSingleCompany &&
              (this.CurrentSingleCompany.invoice_address || this.CurrentSingleCompany.invoice_email)
          "
        >
          <div class="table-header pa-3">
            <div class="table-property-label">Invoice Details</div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Legal Entity</div>
            <div
              class="table-property-value"
              style="text-transform: capitalize; white-space: break-spaces"
            >
              {{ this.CurrentSingleCompany.legal_entity || '-' }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Invoice Address</div>
            <div
              class="table-property-value"
              style="text-transform: capitalize; white-space: break-spaces"
            >
              {{ this.CurrentSingleCompany.invoice_address }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Invoice Email</div>
            <div class="table-property-value" style="text-transform: capitalize">
              {{ this.CurrentSingleCompany.invoice_email }}
            </div>
          </div>
        </div></v-col
      >

      <v-col md="4" cols="12">
        <div
          class="map-iframe-cont d-flex flex-column align-stretch"
          style="height: 100%; min-height: 350px"
        >
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            v-if="this.CurrentSingleCompany.address"
            style="border: 0"
            :src="
              'https://www.google.com/maps/embed/v1/place?key=AIzaSyAb7dQgSBCZgzF-WGV4H0cUUFIUia3vZiU&q=' +
                this.CurrentSingleCompany.address
            "
            allowfullscreen
          >
          </iframe>
        </div>
      </v-col>
    </v-row>
    <CompanyContactsTable />
  </div>
</template>

<script>
import EditCompanyForm from './EditCompanyForm.vue';
import CompanyContactsTable from './CompanyContactsTable.vue';

export default {
  name: 'SingleCompanyInfo',
  components: {
    EditCompanyForm,
    CompanyContactsTable,
  },
  data() {
    return {};
  },
  methods: {
    conditional_edit_company() {
      const routeName = this.$route.name;
      if (routeName == 'SingleCustomerPage') return this.checkPermission('can_edit_customers');
      else if (routeName == 'SingleSupplierPage') return this.checkPermission('can_edit_suppliers');

      return false;
    },
  },
  computed: {
    CurrentSingleCompany() {
      return this.$store.getters.GetCurrentSingleCompany;
    },
  },
  beforeDestroy() {
    // this.$store.commit("SetCurrentSingleCompany", null);
  },
};
</script>

<style scoped>
.company-status-desc {
  display: flex;
  position: relative;
  margin-top: 12px;
  padding-bottom: 10px;
}

.company-status-desc:before {
  content: '';
  width: 20px;
  height: 20px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgdmlld0JveD0iNSA1IDkwIDkwIj4KIDxwYXRoIGQ9Im01MCA1Yy0yNC44NDggMC00NSAyMC4xNTItNDUgNDVzMjAuMTUyIDQ1IDQ1IDQ1IDQ1LTIwLjE1MiA0NS00NS0yMC4xNTItNDUtNDUtNDV6bTYuMzEyNSA2Ny4zNzVjMCAzLjQ4ODMtMi44MjgxIDYuMzEyNS02LjMxMjUgNi4zMTI1LTMuNDg4MyAwLTYuMzEyNS0yLjgyODEtNi4zMTI1LTYuMzEyNXYtMjMuNDU3YzAtMy40ODgzIDIuODI4MS02LjMxMjUgNi4zMTI1LTYuMzEyNSAxLjc0MjIgMCAzLjMyMDMgMC43MDMxMiA0LjQ3MjcgMS44NTU1IDEuMTQwNiAxLjE0MDYgMS44NDM4IDIuNzE0OCAxLjg0MzggNC40NTd6bS02LjMxMjUtMzguNDM0Yy0zLjQ4ODMgMC02LjMxMjUtMi44MjgxLTYuMzEyNS02LjMxMjUgMC0zLjQ4ODMgMi44MjgxLTYuMzEyNSA2LjMxMjUtNi4zMTI1IDMuNDg4MyAwIDYuMzEyNSAyLjgyODEgNi4zMTI1IDYuMzEyNXMtMi44MjQyIDYuMzEyNS02LjMxMjUgNi4zMTI1eiIgZmlsbD0iIzAwMzA3ZiIvPgo8L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  overflow: hidden;
  transform: translateX(-130%) translateY(0) translateZ(10px);
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
