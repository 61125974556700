<template>
  <v-row class="parts-table ma-0 px-3">
    <EditPartModal
      v-model="show"
      v-if="this.checkPermission('can_add_job_parts')"
    />
    <ConfirmationDialog
      v-model="deleteModal"
      @cancelled="deleteModal = false"
      @confirmed="DeleteItem()"
      :loader="isDeleting"
      :itemName="'job part'"
    />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-left"
              v-for="(value, key) in table_headers"
              :key="key"
            >
              {{ key }}
            </th>

            <th class="text-left">Actions</th>
            <!-- <th class="text-left">Company</th>
          <th class="text-left">Part Code</th>
          <th class="text-left">Part Description</th>
          <th class="text-left">Pack Qty</th>
          <th class="text-left">Packs Ordered</th>
          <th class="text-left">Unit Cost</th>
          <th class="text-left">Packs Cost</th>
          <th class="text-left">Received</th>
          <th class="text-left">Actions</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="part in parts" :key="part.id">
            <!-- <td v-for="cell in table_headers" :key="part.id + '-' + cell">
              {{
                cell == "company" && part[cell] != null
                  ? part[cell]["name"]
                  : part[cell]
              }}
            </td> -->

            <td>{{ part.id }}</td>
            <td>{{ part.company != null ? part.company.name : "" }}</td>
            <td>{{ part.part_code }}</td>
            <td>{{ part.part_description }}</td>
            <td>{{ part.pack_qty }}</td>
            <td>{{ part.packs_ordered }}</td>
            <td>{{ part.unit_cost | toCurrency }}</td>
            <td>{{ part.packs_cost | toCurrency }}</td>
            <td v-if="part.bReceived">
              <v-icon color="success">mdi-check-circle</v-icon>
            </td>
            <td v-else>
              <v-icon color="error">mdi-close-circle</v-icon>
            </td>

            <td>
              <v-btn
                small
                color="primary"
                @click="EditPart(part)"
                v-if="checkPermission('can_add_job_parts')"
                >Edit</v-btn
              >
              <v-btn
                small
                color="success"
                @click="RecievePart(part.id)"
                :loading="isloading_id == part.id"
                :disabled="isloading_id == part.id || part.bReceived == true"
                >Recieve</v-btn
              >
              <v-btn
                small
                color="error"
                @click="
                  deleteModal = true;
                  deleteItem = part;
                "
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-row>
</template>

<script>
import ConfirmationDialog from "../../../global/ConfirmationDialog.vue";

import EditPartModal from "./EditPartModal.vue";
export default {
  name: "PartsTable",
  props: ["parts"],
  components: {
    EditPartModal,
    ConfirmationDialog,
  },
  data() {
    return {
      show: false,
      isloading_id: null, //this is the id of the part that is awaiting ajax
      deleteModal: false,
      isDeleting: false,
      deleteItem: "",
      table_headers: {
        //"key text": "obj position for vlaue"
        ID: "id",
        Company: "company",
        "Part Code": "part_code",
        "Part Description": "part_description",
        "Pack Qty": "pack_qty",
        "Packs Ordered": "packs_ordered",
        "Unit Cost": "unit_cost",
        "Packs Cost": "packs_cost",
        Received: "bReceived",
      },
    };
  },
  methods: {
    EditPart(part) {
      this.$store.commit("setSelectedJobPart", part);
      this.show = true;
    },
    DeleteItem() {
      var part = this.deleteItem;
      if (!part) return;
      console.log(part);
      this.isDeleting = true;
      this.$store
        .dispatch("DeleteJobPart", part.id)
        .then(() => {
          this.$store.commit("RemovePartFromList", part.id);
          this.$store.commit("SetSnackbarData", {
            message: "Job part deleted successfully",
          });
        })
        .finally(() => {
          this.isDeleting = false;
          this.deleteModal = false;
          this.deleteItem = false;
        });
    },
    RecievePart(part_id) {
      //bReceived
      this.isloading_id = part_id;
      this.$store.dispatch("ReceiveJobPart", part_id).finally(() => {
        this.isloading_id = null;
      });
    },
  },
  computed: {
    selectedPart() {
      return this.$store.getters.getSelectedJobPart;
    },
    // jobParts() {
    //   if (this.job && this.job.parts.length > 0) return this.job.parts;
    //   else return {};
    // },
  },
  watch: {
    deleteModal(newValue, oldValue) {
      if (newValue == false && newValue != oldValue) this.deleteItem = "";
    },
  },
};
</script>

<style>
.parts-table table,
.documents-table table {
  table-layout: fixed;
}
@media only screen and (max-width: 1200px) {
  .parts-table table th,
  .documents-table table th {
    width: 100px;
  }
}
</style>