<template>
  <v-form ref="AddJobPartForm" v-model="valid" @submit.prevent="Submit">
    <v-row class="pa-4 ma-0">
      <v-col cols="12">
        <h4 class="mb-2">Supplier</h4>
        <v-autocomplete
          v-model="formFields.company_id"
          :disabled="isUpdating"
          :items="companyList"
          solo
          color="blue-grey lighten-2"
          label="Select"
          item-text="name"
          item-value="id"
          hide-no-data
          :rules="[rules.required, rules.isNaN]"
          hide-selected
        >
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

        <h4 class="mb-2">Part Code</h4>

        <v-text-field
          solo
          v-model="formFields.part_code"
          name="part_code"
        ></v-text-field>

        <h4 class="mb-2">Part Description</h4>

        <v-text-field
          solo
          v-model="formFields.part_description"
          name="order_number"
        ></v-text-field>

        <h4 class="mb-2">Pack Qty</h4>

        <v-text-field
          solo
          v-model.number="formFields.pack_qty"
          name="description"
        ></v-text-field>

        <h4 class="mb-2">Packs Ordered</h4>

        <v-text-field
          solo
          v-model.number="formFields.packs_ordered"
          name="packs_ordered"
          :rules="[rules.required]"
        ></v-text-field>

        <h4 class="mb-2">Unit Cost</h4>

        <v-text-field
          solo
          v-model.number="formFields.unit_cost"
          name="unit_cost"
          prefix="£"
        ></v-text-field>

        <h4 class="mb-2">Pack Cost</h4>

        <v-text-field
          solo
          v-model.number="formFields.packs_cost"
          name="packs_cost"
          disabled
          readonly
          prefix="£"
          :rules="[rules.required]"
        ></v-text-field>

        <v-btn
          :disabled="!valid || isSubmitting"
          class="success mx-0 mt-3"
          type="submit"
          :loading="isSubmitting"
          >Add Part</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "AddJobPartForm",
  data() {
    return {
      isUpdating: false,
      valid: false,
      isSubmitting: false,
      formFields: {
        company_id: "",
        part_code: "",
        part_description: "",
        pack_qty: 1,
        packs_ordered: 1,
        unit_cost: 0,
        packs_cost: 0,
        //        job_id: "",
      },

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  methods: {
    Submit() {
      if (this.validate()) {
        this.formFields.job_id = this.job.id;
        this.isSubmitting = true;
        console.log("Adding Job Part");
        console.log(this.formFields);
        console.log(this.validate());
        const payload = {
          job_id: this.job.id,
          data: this.formFields,
        };
        this.$store
          .dispatch("AddJobPart", payload)
          .then(() => {
            this.$emit("closeModal", true);
            this.$refs.AddJobPartForm.reset();
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
      // let payload = {};
    },
    validate() {
      return this.$refs.AddJobPartForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    calcPartCost() {
      this.formFields.packs_cost =
        this.formFields.unit_cost * this.formFields.packs_ordered || 0;
    },
  },

  mounted() {
    this.$store.dispatch("fetchSuppliersList");
  },

  computed: {
    companyList() {
      return this.$store.getters.getCompanyList;
    },
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    costWatch() {
      return `${this.formFields.unit_cost}|${this.formFields.packs_ordered}`;
    },
  },
  watch: {
    costWatch() {
      this.calcPartCost();
    },
  },
};
</script>

<style>
</style>