<template>
  <div class="d-flex flex-column pa-4 ma-0">
    <div class="pl-6 justify-space-between align-center job-adv-nav">
      <div>
        <v-btn @click="gotoJob()" text>
          <v-icon class="mr-2">mdi-arrow-left</v-icon> Back to Job Page</v-btn
        >
      </div>
      <div class="d-flex justify-center align-center">
        <p>Job: N{{ this.job_id }}</p>
      </div>
      <div class="d-flex justify-end align-center">
        <p>Advancing To: {{ this.Job_next_status }}</p>
      </div>
    </div>
    <toStage2
      :priority_id="this.job.priority_id"
      v-if="this.job.status_id == 1"
    />
    <toStage3 v-else-if="this.job.status_id == 2" />
    <toStage4 v-else-if="this.job.status_id == 3" />
    <toStage5 v-else-if="this.job.status_id == 4" />
    <toStage6 v-else-if="this.job.status_id == 5" />
    <toStage7 v-else-if="this.job.status_id == 6" />
    <toStage8 v-else-if="this.job.status_id == 7" />
    <p v-else>{{ this.job.status_id }}</p>
  </div>
</template>

<script>
import toStage2 from "./toStage2.vue";
import toStage3 from "./toStage3.vue";
import toStage4 from "./toStage4.vue";
import toStage5 from "./toStage5.vue";
import toStage6 from "./toStage6.vue";
import toStage7 from "./toStage7.vue";
import toStage8 from "./toStage8.vue";
export default {
  name: "JobAdvancing",
  components: {
    toStage2,
    toStage3,
    toStage4,
    toStage5,
    toStage6,
    toStage7,
    toStage8,
  },
  methods: {
    gotoJob() {
      if (this.job_id) this.$router.push("/job/" + this.job_id);
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_id() {
      return this.$route.params.job_id;
    },
    Job_next_status() {
      var next_status = this.job.status_id + 1;
      return this.$store.getters.get_status(next_status);
    },
  },
  // mounted() {
  //   console.log("Advancing Job page mounted, fetching job info" + this.job_id);
  //   if (this.job_id != "" && !this.job.id)
  //     this.$store.dispatch("fetchSingleJob", this.job_id);
  // },
  beforeDestroy() {
    this.$store.commit("setCurrentSingleJob", {});
  },
};
</script>

<style>
</style>