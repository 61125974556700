//this file is for all reports related actions on the "Reports" menu items... (so not related to single job reports)
import Axios from "axios"

const state = {
}
const mutations = {

}
const actions = {


  update_loggedin_user_data(context, payload) {
    console.log("email_job_report now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/user", payload
      ).then((res) => {


        console.log(res.data)
        return context.dispatch("getUserObjFromToken")
          .catch(function (error) {
            console.log(error.response.data.message);
            context.commit("SetSnackbarData", {
              message: error.response.data.message,
              type: "error",
            });
            location.reload();

            return Promise.reject(error);

          });
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },


  update_loggedin_user_password(context, payload) {
    console.log("email_job_report now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/user/password", payload
      ).then((res) => {


        console.log(res.data)
        return context.dispatch("getUserObjFromToken")
          .catch(function (error) {
            console.log(error.response.data.message);
            context.commit("SetSnackbarData", {
              message: error.response.data.message,
              type: "error",
            });
            location.reload();

            return Promise.reject(error);

          });
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },

}
const getters = {

}

export default {
  state,
  getters,
  mutations,
  actions
}