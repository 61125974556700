<template>
  <div class="d-flex flex-column pa-4 ma-0">
    <UsersTable />
  </div>
</template>

<script>
import UsersTable from "../../components/management/users/UsersTable.vue";
export default {
  components: {
    UsersTable,
  },
  mounted() {
    this.$store.dispatch("getAllUsers");
  },
  beforeMount() {
    this.$store.commit("SetUserList", []);
  },
};
</script>

<style>
</style>