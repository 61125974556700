<template>
  <div class="d-flex flex-column job-report-col">
    <div class="job-report-images-cont px-4">
      <v-dialog
        v-model="upload_images_form_visible"
        width="500"
        v-if="selected_index_to_upload"
      >
        <v-card color="white" class="pa-4">
          <MechanicalDataImageUploadForm
            :selected_index_to_upload="selected_index_to_upload"
          />
        </v-card>
      </v-dialog>

      <h3 class="mb-4">Motor Photos</h3>

      <div v-for="photo_field in photos_fields" :key="photo_field.photoIndex">
        <h4 class="mb-2">{{ photo_field.title }}</h4>
        <div class="mechanical-data-single-img-upload-row">
          <div v-if="job['photo' + photo_field.photoIndex]">
            <p>current image:</p>
            <img
              :src="
                bases[0] +
                job['photo' + photo_field.photoIndex] +
                '?rand=' +
                rand()
              "
              alt=""
              class="job-report-img"
            />
          </div>

          <p v-else>No image Found</p>

          <v-btn
            :color="
              job['photo' + photo_field.photoIndex] ? 'warning' : 'success'
            "
            @click="
              selected_index_to_upload = photo_field.photoIndex;
              upload_images_form_visible = !upload_images_form_visible;
            "
            >{{
              job["photo" + photo_field.photoIndex] ? "Replace" : "Upload"
            }}
            Image</v-btn
          >
        </div>

        <div
          v-if="photo_field.hasDesc && job['photo' + photo_field.photoIndex]"
        >
          <h4 class="my-3">Description</h4>
          <v-textarea
            solo
            hide-details=""
            v-model="inner_formFields[photo_field.model]"
            @change="InnerFormChanged()"
          ></v-textarea>
        </div>

        <v-divider class="my-8" v-if="photo_field.photoIndex != 5"></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
import MechanicalDataImageUploadForm from "./MechanicalDataImageUploadForm.vue";

export default {
  components: {
    MechanicalDataImageUploadForm,
  },
  data() {
    return {
      upload_images_form_visible: false,
      selected_index_to_upload: null,
      bases: [
        //   "https://www.neutronic.biz/img/jobs/",
        process.env.VUE_APP_API_PROTOCOL +
          "://" +
          process.env.VUE_APP_API_HOST +
          ":" +
          process.env.VUE_APP_API_PORT +
          "/storage/images/",
      ],
      inner_formFields: {
        further_information_desc_1: "",
        further_information_desc_2: "",
      },
      photos_fields: [
        {
          title: "Nameplate Photo",
          photoIndex: 2,
          desc: "Photo of Motor Nameplate",
        },
        {
          title: "Incoming Photo",
          photoIndex: 1,
          desc: "Photo of Motor as it comes into workshop",
        },
        {
          title: "Root Cause Analysis Photo",
          photoIndex: 3,
          desc: "Photo of any element of motor showing the failure",
        },
        {
          title: "Outgoing Photo",
          photoIndex: 6,
          desc: "The outgoing photo of the item once repaired",
        },
        {
          title: "Further Information Photo1",
          photoIndex: 4,
          hasDesc: true,
          model: "further_information_desc_1",
        },
        {
          title: "Further Information Photo2",
          photoIndex: 5,
          hasDesc: true,
          model: "further_information_desc_2",
        },
      ],
    };
  },
  methods: {
    rand() {
      return Date.now();
    },
    InnerFormChanged() {
      this.$emit("InnerFormChanged", this.inner_formFields);
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_photos() {
      var job = this.$store.getters.getCurrentSingleJob;
      var photos = [];
      if (job.photo1) photos.push(job.photo1);
      if (job.photo2) photos.push(job.photo2);
      if (job.photo3) photos.push(job.photo3);
      if (job.photo4) photos.push(job.photo4);
      if (job.photo5) photos.push(job.photo5);
      if (job.photo6) photos.push(job.photo6);
      return photos;
    },
  },
  mounted() {
    if (this.job.mechanical_data) {
      this.inner_formFields.further_information_desc_1 =
        this.job.mechanical_data.further_information_desc_1;
      this.inner_formFields.further_information_desc_2 =
        this.job.mechanical_data.further_information_desc_2;
    } else {
      this.inner_formFields.further_information_desc_1 = "";
      this.inner_formFields.further_information_desc_2 = "";
    }
  },
};
</script>

<style scoped>
.mechanical-data-single-img-upload-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-gap: 12px;

  align-items: flex-end;
  width: 100%;
}

.mechanical-data-single-img-upload-row img {
  max-width: 100%;
}

@media only screen and (max-width: 1600px) {
  .mechanical-data-single-img-upload-row {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 960px) {
  .job-report-images-cont {
    padding: 0px !important;
  }
}
</style>