import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import JobsDashboard from "../pages/jobs/Dashboard.vue"
import BookCollection from "../pages/jobs/BookCollection"
import CreateJob from "../pages/jobs/CreateJob"
import SingleJobView from "../pages/jobs/SingleJobView"
import JobSearch from "../pages/jobs/JobSearch"
import Deliveries from "../pages/jobs/Deliveries"
import EditJob from "../pages/jobs/EditJob"
import JobAdvancingPage from "../pages/jobs/AdvanceJob"
import Contacts from "../pages/management/Contacts"
import SingleContactPage from "../pages/management/SingleContactPage"
import Suppliers from "../pages/management/Suppliers"
import Customers from "../pages/management/Customers"
import Users from "../pages/management/Users"
import UserSettings from "../pages/management/Settings"
import SingleUserPage from "../pages/management/SingleUserPage.vue"
import SingleCustomerPage from "../pages/management/SingleCustomerPage.vue"
import DepartmentsPage from "../pages/management/Departments.vue"
import Roles from "../pages/management/Roles"
import jobsByEngineer from "../pages/reports/jobsByEngineer.vue"
import Awaiting_po from "../pages/reports/Awaiting_po.vue"
import Awaiting_Delivery from "../pages/reports/Awaiting_Delivery.vue"
import Awaiting_Acceptance from "../pages/reports/Awaiting_Acceptance.vue"
import Awaiting_Collection from "../pages/reports/Awaiting_Collection.vue"
import Awaiting_Parts from "../pages/reports/Awaiting_Parts.vue"
import GoAhead from "../pages/reports/Go_Ahead.vue"
import Monthly_Turnaround from "../pages/reports/Monthly_Turnaround.vue"
import Monthly_Sales from "../pages/reports/Monthly_Sales.vue"


import Monthly_Booked_In from "../pages/reports/Jobs_Booked_In_By_Month.vue"
import LowStockComponents from "../pages/reports/LowStockComponents.vue"


import Sales_by_Month from "../pages/reports/Sales_By_Month.vue"
import SageExportReport from "../pages/sage/SageExportReport.vue"
import SageExport from "../pages/sage/SageExport.vue"
import StatsDashboard from "../pages/management/StatsDashboard.vue"

import LabourRates from "../pages/management/LabourRates.vue"
import CommonPhrases from "../pages/management/CommonlyUsedPhrases.vue"
import Components from "../pages/management/Components.vue"

import JobComponentsStockReport from "../pages/reports/Job_Components_Stock_Report.vue"


import EngineerGraphs from "../pages/management/EngineerGraphs.vue"


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   // name: 'JobsDashboard',
  //   component: JobsDashboard
  // },

  {
    path: '/dashboard',
    name: 'JobsDashboard',
    component: JobsDashboard,
    alias: "/",

  },

  {
    path: '/jobs/book',
    name: 'BookCollection',
    component: BookCollection
  },

  {
    path: '/jobs/create',
    name: 'CreateJob',
    component: CreateJob
  },


  {
    path: '/job/:job_id',
    name: 'SingleJobView',
    component: SingleJobView
  },

  {
    path: '/job/:job_id/edit',
    name: 'EditJob',
    component: EditJob
  },
  {
    path: '/job/:job_id/advance',
    name: 'JobAdvancingPage',
    component: JobAdvancingPage
  },


  {
    path: '/search/',
    name: 'JobSearch',
    component: JobSearch
  },
  {
    path: '/deliveries/',
    name: 'Deliveries',
    component: Deliveries
  },

  {
    path: '/finance/export_report/',
    name: 'SageExportReport',
    component: SageExportReport
  },
  {
    path: '/finance/export/',
    name: 'SageExport',
    component: SageExport
  },

  {
    path: '/management/stats',
    name: 'StatsDashboard',
    component: StatsDashboard
  },

  {
    path: '/components_list',
    name: 'Components',
    component: Components
  },


  {
    path: '/management/contacts',
    name: 'Contacts',
    component: Contacts
  },

  {
    path: '/management/contacts/:contact_id',
    name: 'SingleContactPage',
    component: SingleContactPage
  },



  {
    path: '/management/suppliers',
    name: 'Suppliers',
    component: Suppliers
  },

  {
    path: '/management/customers',
    name: 'Customers',
    component: Customers
  },

  {
    path: '/management/customers/:company_id',
    name: 'SingleCustomerPage',
    component: SingleCustomerPage
  },

  {
    path: '/management/suppliers/:company_id',
    name: 'SingleSupplierPage',
    component: SingleCustomerPage
  },


  {
    path: '/management/users',
    name: 'Users',
    component: Users
  },


  {
    path: '/management/users/:user_id',
    name: 'SingleUserPage',
    component: SingleUserPage
  },

  {
    path: '/management/roles',
    name: 'Roles',
    component: Roles
  },

  {
    path: '/reports/jobsByEngineer',
    name: 'jobsByEngineer',
    component: jobsByEngineer
  },

  {
    path: '/reports/awaiting_po',
    name: 'Awaiting_po',
    component: Awaiting_po
  },


  {
    path: '/reports/awaiting_delivery',
    name: 'Awaiting_Delivery',
    component: Awaiting_Delivery
  },


  {
    path: '/reports/awaiting_acceptance',
    name: 'Awaiting_Acceptance',
    component: Awaiting_Acceptance
  },

  {
    path: '/reports/awaiting_collection',
    name: 'Awaiting_Collection',
    component: Awaiting_Collection
  },


  {
    path: '/reports/go_ahead',
    name: 'GoAhead',
    component: GoAhead
  },

  {
    path: '/reports/awaiting_parts',
    name: 'Awaiting_Parts',
    component: Awaiting_Parts
  },
  {
    path: '/reports/monthly_turnaround/:date?',
    name: 'Monthly_Turnaround',
    component: Monthly_Turnaround
  },

  {
    path: '/reports/monthly_sales/:date?',
    name: 'Monthly_Sales',
    component: Monthly_Sales
  },


  {
    path: '/reports/sales_by_month/:date?',
    name: 'Sales_by_Month',
    component: Sales_by_Month
  },

  {
    path: '/reports/booked_in_by_month/:date?',
    name: 'Booked_In_By_Month',
    component: Monthly_Booked_In
  },

  {
    path: '/stock/components_to_order/',
    name: 'LowStockComponents',
    component: LowStockComponents
  },

  {
    path: '/reports/components_stock_report/',
    name: 'JobComponentsStockReport',
    component: JobComponentsStockReport
  },





  {
    path: '/management/departments',
    name: 'DepartmentsPage',
    component: DepartmentsPage
  },

  {
    path: '/management/labour-rates',
    name: 'LabourRates',
    component: LabourRates
  },

  {
    path: '/management/common-phrases',
    name: 'Common Phrases',
    component: CommonPhrases
  },




  {
    path: '/settings',
    name: 'UserSettings',
    component: UserSettings
  },

  {
    path: '/management/graphs/engineer-graphs',
    name: 'EngineerGraphs',
    component: EngineerGraphs
  },





  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]


// Solve the problem that the vue-router in the ElementUI navigation bar repeatedly clicks the menu and reports errors above version 3.0
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
