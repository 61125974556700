
import Axios from "axios"

const state = {


}
const mutations = {

}
const actions = {

  //to stage 2
  AdvanceToAssessment(context, payload) {
    console.log("Advancing to Stage 2- Assessment, with payload:");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + payload.job_id + "/advance/2", payload.data).then(function (response) {
          console.log(response.data);

          context.commit("SetSnackbarData", {
            message: "Collection booked successfully",
          });


          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

        });
  },







  //to stage 3
  AdvanceToCustomerQuotation(context, payload) {
    console.log("Advancing to Stage 3- Customer Quotation, with payload:");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + payload.job_id + "/advance/3", payload.data).then(function (response) {
          console.log(response.data);

          context.commit("SetSnackbarData", {
            message: "Advanced To Customer Quotation",
          });


          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

        });
  },

  //to stage 4
  AdvanceToAwaitingAcceptance(context, payload) {
    console.log("Advancing to Stage 3- Customer Quotation, with payload:");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + payload.job_id + "/advance/4", payload.data).then(function (response) {
          console.log(response.data);

          context.commit("SetSnackbarData", {
            message: "Advanced To Customer Quotation",
          });


          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

        });
  },


  //to stage 5
  AdvanceToGoAhead(context, payload) {
    console.log("Advancing to Stage 5- Go Ahead, with payload:");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + payload.job_id + "/advance/5", payload.data).then(function (response) {
          console.log(response.data);

          context.commit("SetSnackbarData", {
            message: "Advanced To Go Ahead",
          });


          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

        });
  },


  //to stage 6
  AdvanceToInProgress(context, payload) {
    console.log("Advancing to Stage 6 - In Progress, with payload:");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + payload.job_id + "/advance/6", payload.data).then(function (response) {
          console.log(response.data);

          context.commit("SetSnackbarData", {
            message: "Advanced To In Progress",
          });


          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);

        });
  },


  //to stage 7
  AdvanceToForDelivery(context, payload) {
    console.log("Advancing to Stage 7 - For Delivery, with payload:");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + payload.job_id + "/advance/7", payload.data).then(function (response) {
          console.log(response.data);

          context.commit("SetSnackbarData", {
            message: "Advanced To For Delivery",
          });


          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);

        });
  },


  //to stage 8
  AdvanceToForInvoicing(context, payload) {
    console.log("Advancing to Stage 8 - For Invoicing, with payload:");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + payload.job_id + "/advance/8", payload.data).then(function (response) {
          console.log(response.data);

          context.commit("SetSnackbarData", {
            message: "Advanced To For Invoicing",
          });


          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);

        });
  },



  fetchRelevantJobsByModel(context, payload) {
    console.log("fetching relevant jobs for toStage4");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/byModel", payload).then(function (response) {
          console.log(response.data);

          context.commit("SetSnackbarData", {
            message: "fetched jobs by model",
          });

          return response.data;
          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

        });


  },




  fetchEngineersListStage6(context, payload) {
    console.log("fetching fetchEngineersListStage6");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/engineers", payload).then(function (response) {
          console.log(response.data);

          context.commit("SetSnackbarData", {
            message: "fetched engineers by tag",
          });

          return response.data;
          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

        });
  },

  SaveSignature(context, payload) {
    console.log("Saving Signature");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/signature", payload).then(function (response) {
          console.log(response.data);

          context.commit("SetSnackbarData", {
            message: "Saved Signature",
          });

          return response.data;
          // context.commit("setJobList", response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          // return err;
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

        });
  }

}
const getters = {

}

export default {
  state,
  getters,
  mutations,
  actions
}