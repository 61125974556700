<template>
  <div>
    <apexchart
      type="pie"
      width="100%"
      :options="chartOptions"
      :series="pie_series"
      ref="PieChart"
      v-if="pie_series.length > 0"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "JobsInProgress",
  data() {
    return {
      pie_series: [],
      labels: [],

      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: this.labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    const pie = this.pie_chart_store_data;
    var pie_data = [];
    for (let i = 0; i < pie.length; i++) {
      pie_data.push(pie[i]["total"]);
    }
    this.pie_series = pie_data;
  },
  computed: {
    pie_chart_store_data() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats["pie"]) {
        this.update_labels(Stats["pie"]);
        return Stats["pie"];
      } else return [];
    },
  },
  methods: {
    update_labels(data) {
      var statuses = this.$store.getters.get_all_status;

      var result = [];

      for (var i = 0; i < data.length; i++) {
        var status_id = data[i]["status_id"];
        var label = statuses[status_id].toString();
        result.push(label);
      }
      this.chartOptions.labels = result;
    },
  },
};
</script>

<style>
</style>