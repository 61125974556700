<template>
  <div class="fixed-pos">
    <transition-group name="snackbar" tag="div">
      <SingleSnackBar
        v-for="snackbar in this.snackbars"
        :key="snackbar.id"
        :id="snackbar.id"
        :type="snackbar.type"
        :message="snackbar.message"
        :timeout="snackbar.timeout"
        :redirect="snackbar.redirect"
        :buttonText="snackbar.buttonText"
        :updatedJob="snackbar.updatedJob"
      />
    </transition-group>
    <!-- <v-snackbar
      :value="this.Show"
      :timeout="this.Timeout"
      absolute
      bottom
      elevation="24"
      :color="this.Type"
      v-for="snackbar in this.snackbars"
      :key="snackbar.id"
    >
      {{ this.Message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="CloseSnackbar">
          {{ buttonText }}
        </v-btn>
      </template>
    </v-snackbar> -->
  </div>
</template>

<script>
import SingleSnackBar from "./SingleSnackbar.vue";
export default {
  name: "AppSnackBar",
  components: {
    SingleSnackBar,
  },

  data() {
    return {
      type: "success",
      message: "Default message",
      timeout: 3000,
      buttonText: "Close",
      redirect: "",
    };
  },
  methods: {
    CloseSnackbar() {
      if (this.Redirect && this.Redirect.length > 0)
        this.$router.push(this.Redirect);
    },
  },
  computed: {
    // Show() {
    //   return this.$store.getters.getSnackbarVisibility;
    // },
    // DataFromStore() {
    //   return this.$store.getters.getSnackbarData;
    // },
    // ButtonText() {
    //   if (this.DataFromStore && this.DataFromStore.buttonText)
    //     return this.DataFromStore.buttonText;
    //   else return this.buttonText;
    // },
    // Type() {
    //   if (this.DataFromStore && this.DataFromStore.type)
    //     return this.DataFromStore.type;
    //   else return this.type;
    // },
    // Redirect() {
    //   if (this.DataFromStore && this.DataFromStore.redirect)
    //     return this.DataFromStore.redirect;
    //   else return this.redirect;
    // },
    // Message() {
    //   if (this.DataFromStore && this.DataFromStore.message)
    //     return this.DataFromStore.message;
    //   else return this.message;
    // },
    // Timeout() {
    //   if (this.DataFromStore && this.DataFromStore.timeout)
    //     return this.DataFromStore.timeout;
    //   else return this.timeout;
    // },
    snackbars() {
      return this.$store.getters.getSnackbars;
    },
  },
};
</script>

<style scoped>
.fixed-pos {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100vw;
  max-width: 300px;
  z-index: 10;
}

@media only screen and (max-width: 340px) {
  .fixed-pos {
    position: fixed;
    bottom: 20px;
    right: 0px;
    left: 0px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100vw;
  }
}

.snackbar-item {
  display: inline-block;
  margin-right: 10px;
}
.snackbar-enter-active,
.snackbar-leave-active {
  transition: all 1s;
}
.snackbar-enter, .snackbar-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>