// import Axios from "axios"

const state = {

}
const mutations = {

  CollectionBooked(state, payload) {
    var JobList = this.state.JobModules.jobList;
    if (JobList["1"] !== undefined && !isNaN(payload.id)) {

      //check for duplicates
      if (JobList["1"].find(job => job.id == payload.id))
        return;

      console.log("unshifting array....");
      JobList["1"].unshift(payload);
      console.log("new unshifted: ");
      console.log(JobList);
      console.log("old job lists");
      console.log(this.state.JobModules.jobList);

      // this.commit("setJobList", JobList);
    }
  },

  JobCreated(state, payload) {
    var JobList = this.state.JobModules.jobList;
    if (JobList["3"] !== undefined && !isNaN(payload.id)) {

      //check for duplicates
      if (JobList["3"].find(job => job.id == payload.id))
        return;

      console.log("unshifting array....");
      JobList["3"].unshift(payload);
      console.log("new unshifted: ");
      console.log(JobList);
      console.log("old job lists");
      console.log(this.state.JobModules.jobList);

      // this.commit("setJobList", JobList);
    }
  },

  JobUpdatedDashboard(state, payload) {
    var JobList = this.state.JobModules.jobList;

    // if (payload instanceof Array){

    // }
    // else{}
    const old_status = payload.old_status;
    const updated_job = payload.updatedJob;

    if (old_status && JobList[old_status.toString()] !== undefined && !isNaN(updated_job.id)) {
      console.log("job updated - starting func")
      let old_index = JobList[old_status.toString()].findIndex(function (job) {
        return job.id === updated_job.id;
      });
      if (old_status != updated_job.status_id) {

        console.log("job updated - moving to another col")

        //moved to another column
        JobList[old_status.toString()].splice(old_index, 1);

        if (updated_job.status_id <= 8 && JobList[updated_job.status_id].findIndex(function (job) {
          return job.id === updated_job.id;
        }) == -1) //max is invoiced, aka col-8
          JobList[updated_job.status_id].unshift(updated_job);
      }
      else {
        // JobList[old_status.toString()][old_index] = updated_job;
        JobList[old_status.toString()].splice(old_index, 1, updated_job);

        console.log("job updated - updating within same col")
        console.log(JobList[old_status.toString()][old_index])
        // this.commit("setJobList", JobList)


        //still same column
      }

      console.log(JobList);
      //   console.log("old job lists");
      console.log(this.state.JobModules.jobList);

    }
  },




  SingleJobUpdated(state, payload) {
    var JobList = this.state.JobModules.jobList;
    const old_status = payload.old_status;
    const updated_job = payload.updatedJob;
    const old_index = payload.old_index;
    if (old_index && old_status && JobList[old_status.toString()] !== undefined && !isNaN(updated_job.id)) {

      if (old_status != updated_job.status_id) {
        //moved to another column
        JobList[old_status.toString()].splice(old_index, 1);
        if (updated_job.status_id <= 8) //max is invoiced, aka col-8
          JobList[updated_job.status_id].unshift(updated_job);
      }
      else {
        //still same column
        JobList[old_status.toString()][old_index] = updated_job;
      }


      //   console.log("unshifting array....");
      //   JobList["3"].unshift(payload);
      //   console.log("new unshifted: ");
      console.log(JobList);
      //   console.log("old job lists");
      console.log(this.state.JobModules.jobList);

    }
  },
}
const actions = {



}
const getters = {

}

export default {
  state,
  getters,
  mutations,
  actions
}