<template>
  <v-row class="ma-0 pa-6">
    <div class="flex-grow-1 d-flex justify-space-between mb-6">
      <h1>Overdue Order Numbers</h1>
      <v-btn
        color="success"
        @click="DownloadPDF('awaiting_po/pdf')"
        :loading="IsFetchingPDF"
        :disabled="IsFetchingPDF"
        >Download PDF</v-btn
      >
    </div>
    <v-data-table
      :headers="headers"
      :items="jobs"
      :items-per-page="-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="elevation-1"
      style="width: 100%"
      group-by="company.sales_rep.name"
      :loading="is_loading"
    >
      <template v-slot:group.header="{ items }">
        <th :colspan="windowWidth < 600 ? '1' : '8'">
          {{
            items[0].company && items[0].company.sales_rep && items[0].company.sales_rep.name
              ? items[0].company.sales_rep.name
              : 'No Sales Rep Assigned'
          }}
        </th>
      </template>

      <template v-slot:item.delDate="{ item }">
        {{ item.delDate | YMD_to_DMY }}
      </template>

      <template v-slot:item.NetTotal="{ item }">
        {{ item.NetTotal | toCurrency }}
      </template>

      <template v-slot:item.daysOutstanding="{ item }"> {{ item.daysOverdue }} Days </template>
      <template v-slot:item.tag_id="{ item }">{{ Department_name(item) }}</template>

      <template v-slot:item.actions="{ item }">
        <v-btn :to="'/job/' + item.id" color="primary">View</v-btn>
      </template>

      <template slot="body.append">
        <tr>
          <th>
            Total
            <span v-if="windowWidth < 600">: {{ SalesQuotesTotal | toCurrency }}</span>
          </th>
          <th class="hide-600"></th>
          <th class="hide-600"></th>
          <th class="hide-600"></th>
          <th class="hide-600"></th>
          <th class="hide-600"></th>
          <th class="hide-600">{{ SalesQuotesTotal | toCurrency }}</th>
          <th class="hide-600"></th>
        </tr>
      </template>
    </v-data-table>
  </v-row>
</template>

<script>
export default {
  name: 'Awaiting_po',
  data() {
    return {
      is_loading: false,
      sortBy: 'company.name',
      sortDesc: false,
      IsFetchingPDF: false,
      headers: [
        {
          text: 'Job',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Customer', value: 'company.name' },
        { text: 'Description', value: 'description' },
        { text: 'Date Delivered', value: 'delDate' },
        { text: 'Days Outstanding', value: 'daysOutstanding' },
        { text: 'Department', value: 'tag_id' },
        { text: '£ Net', value: 'NetTotal' },
        { text: 'Actions', value: 'actions' },
      ],
      jobs: [],
    };
  },
  methods: {
    DownloadPDF(endpoint) {
      this.IsFetchingPDF = true;
      return this.$store.dispatch('DownloadReportPDF', endpoint).finally(() => {
        this.IsFetchingPDF = false;
      });
    },
    DaysOutstanding(date_delivered) {
      return date_delivered;
    },
    Department_name(job) {
      return this.departments.find((dep) => dep.id === job.tag_id)?.name;
    },
  },
  computed: {
    SalesQuotesTotal() {
      var total = 0;
      for (let i = 0; i < this.jobs.length; i++) {
        total = total + this.jobs[i]['NetTotal'] || 0;
      }
      return total;
    },
    windowWidth() {
      return this.$store.getters.getWindowWidth;
    },
    departments() {
      return this.$store.getters.getDepartmentList;
    },
  },
  mounted() {
    this.is_loading = true;
    this.$store.dispatch('fetchDepartments');

    this.$store
      .dispatch('get_report_data_for_tables', 'awaiting_po')
      .then((res) => {
        this.jobs = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .hide-600 {
    display: none;
  }
}
</style>
