
const state = {
  show: false,
  data: {},
  timeout_func_id: null,
  snackbars: []
}
const mutations = {
  SetSnackbarVisibility(state, payload) {
    state.show = payload;

  },
  SetSnackbarData(state, payload) {
    state.snackbars.push({
      type: payload.type,
      message: payload.message,
      timeout: payload.timeout,
      redirect: payload.redirect,
      id: Date.now(),
      updatedJob: payload.updatedJob
    });
  },
  AddSnackbar(state, payload) {
    state.snackbars.push({
      type: payload.type,
      message: payload.message,
      timeout: payload.timeout,
      redirect: payload.redirect,
      id: Date.now(),
      updatedJob: payload.updatedJob
    });

  },

  CloseSnackbar(state, payload) {
    var index = state.snackbars.findIndex((val) => { return val.id == payload; });
    if (index > -1) {
      state.snackbars.splice(index, 1);
    }
    console.log("new # of snackbars: " + state.snackbars.length)
  }
}
const actions = {
  ShowSnackbar() {
    // context.commit("SetSnackbarVisibility", payload);
    // if (payload == false) {
    //   context.commit("SetSnackbarVisibility", false);
    //   setTimeout(() => {

    //     context.commit("SetSnackbarData", {});
    //     console.log("hide snackbar");

    //     clearTimeout(context.state.timeout_func_id);

    //   }, 150);
    // }
    // else {
    //   var timeout = 3000;

    //   if (context.state.data.timeout)
    //     timeout = context.state.data.timeout


    //   context.state.timeout_func_id = setTimeout(() => {
    //     context.commit("SetSnackbarVisibility", false);
    //     setTimeout(() => {

    //       context.commit("SetSnackbarData", {});
    //       console.log("hide snackbar")
    //     }, 150);
    //   }, timeout);
    // }
    return true;
  }


}
const getters = {
  getSnackbarVisibility(state) {
    return state.show;
  },
  getSnackbarData(state) {
    return state.data;
  },
  getSnackbars(state) {
    return state.snackbars;

  }
}

export default {
  state,
  getters,
  mutations,
  actions
}