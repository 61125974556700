<template>
  <v-col cols="12" sm="12" class="px-5 ma-0 mt-6">
    <div class="table-cont">
      <div class="table-header pa-3">
        <div class="table-property-label">Notes</div>
      </div>
      <!-- <div class="mt-8 mb-8" style="opacity: 0.5">
        <hr />
      </div> -->
      <div class="pa-6">
        <!-- <h2 class="mb-2">Notes</h2> -->

        <div class="mb-8">
          <NotesTable :notes="job_notes" />
        </div>

        <h4 class="mb-2">Note</h4>
        <v-textarea
          solo
          v-model="new_note_to_add"
          name="new_note_to_add"
          label="Add Note"
          class="mb-2"
        ></v-textarea>
        <v-btn
          @click="AddNote()"
          color="success"
          :disabled="!new_note_to_add"
          :loading="this.isSubmitting"
          >Add Note</v-btn
        >
      </div>
    </div>
  </v-col>
</template>

<script>
import NotesTable from "./NotesTable.vue";
export default {
  name: "NotesTab",
  data() {
    return {
      new_note_to_add: null,
      isSubmitting: false,
    };
  },
  components: { NotesTable },
  methods: {
    AddNote() {
      if (this.new_note_to_add) {
        this.isSubmitting = true;
        this.$store
          .dispatch("AddJobNote", {
            id: this.job.id,
            note: this.new_note_to_add,
          })
          .then(() => {
            this.new_note_to_add = null;
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_notes() {
      return this.job.notes || [];
    },
  },
};
</script>

<style>
</style>