<template>
  <v-row class="ma-0 pa-6">
    <div class="flex-grow-1 d-flex justify-space-between mb-6">
      <h1>Jobs Awaiting Delivery</h1>
      <v-btn
        color="success"
        @click="DownloadPDF('awaiting_delivery/pdf')"
        :loading="IsFetchingPDF"
        :disabled="IsFetchingPDF"
        >Download PDF</v-btn
      >
    </div>
    <v-data-table
      :headers="headers"
      :items="jobs"
      :items-per-page="20"
      class="elevation-1"
      style="width: 100%"
      :loading="is_loading"
    >
      <template v-slot:item.comDate="{ item }">
        {{ item.comDate | YMD_to_DMY }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn :to="'/job/' + item.id" color="primary">View</v-btn>
      </template>

      <template slot="body.append">
        <tr>
          <td><strong>Total</strong></td>
          <td></td>
          <td></td>
          <td>
            <strong>{{ NetTotalTotal | toCurrency }}</strong>
          </td>
          <td>
            <strong>{{ (NetTotalTotal * 1.2) | toCurrency }}</strong>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-row>
</template>

<script>
export default {
  name: "Awaiting_Delivery",
  data() {
    return {
      is_loading: false,
      IsFetchingPDF: false,
      headers: [
        {
          text: "Job",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Customer", value: "company.name" },
        { text: "Description", value: "description" },
        { text: "Date Completed", value: "comDate" },
        { text: "Actions", value: "actions" },
      ],
      jobs: [],
    };
  },
  methods: {
    DownloadPDF(endpoint) {
      this.IsFetchingPDF = true;
      return this.$store.dispatch("DownloadReportPDF", endpoint).finally(() => {
        this.IsFetchingPDF = false;
      });
    },
  },
  mounted() {
    this.is_loading = true;
    this.$store
      .dispatch("get_report_data_for_tables", "awaiting_delivery")
      .then((res) => {
        this.jobs = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
  computed: {
    NetTotalTotal() {
      var total = 0;
      for (let i = 0; i < this.jobs.length; i++) {
        total = total + this.jobs[i]["NetTotal"] || 0;
      }
      return total;
    },
  },
};
</script>

<style>
</style>