<template>
  <v-form ref="BookCollectionForm" v-model="valid" @submit.prevent="Submit">
    <v-row class="pa-4 ma-0">
      <v-col cols="12">
        <h4 class="mb-2">Supplier</h4>
        <v-autocomplete
          v-model="formFields.company_id"
          :disabled="isUpdating"
          :items="companyList"
          solo
          color="blue-grey lighten-2"
          label="Select"
          item-text="name"
          item-value="id"
          hide-no-data
          :rules="[rules.required, rules.isNaN]"
          hide-selected
        >
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

        <h4 class="mb-2">Part Code</h4>

        <v-text-field
          solo
          v-model="formFields.part_code"
          name="part_code"
        ></v-text-field>

        <h4 class="mb-2">Part Description</h4>

        <v-text-field
          solo
          v-model="formFields.part_description"
          name="order_number"
        ></v-text-field>

        <h4 class="mb-2">Pack Qty</h4>

        <v-text-field
          solo
          v-model.number="formFields.pack_qty"
          name="description"
        ></v-text-field>

        <h4 class="mb-2">Packs Ordered</h4>

        <v-text-field
          solo
          v-model.number="formFields.packs_ordered"
          name="packs_ordered"
          :rules="[rules.required]"
        ></v-text-field>

        <h4 class="mb-2">Unit Cost</h4>

        <v-text-field
          solo
          v-model.number="formFields.unit_cost"
          name="unit_cost"
          prefix="£"
        ></v-text-field>

        <h4 class="mb-2">Pack Cost</h4>

        <v-text-field
          solo
          v-model.number="formFields.packs_cost"
          name="packs_cost"
          disabled
          readonly
          prefix="£"
          :rules="[rules.required]"
        ></v-text-field>

        <v-btn
          :disabled="!valid || isSubmitting"
          class="success mx-0 mt-3"
          type="submit"
          :loading="isSubmitting"
          >Update Part</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "EditJobPartForm",
  props: ["dialog_shown"],
  data() {
    return {
      isUpdating: false,
      valid: false,
      isSubmitting: false,
      formFields: {
        id: "",
        company_id: "",
        part_code: "",
        part_description: "",
        pack_qty: 1,
        packs_ordered: 1,
        unit_cost: 0,
        packs_cost: 0,
        //        job_id: "",
      },

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  methods: {
    Submit() {
      if (this.validate()) {
        this.formFields.job_id = this.job.id;
        this.isSubmitting = true;
        console.log("Updating Job Part");
        console.log(this.formFields);
        console.log(this.validate());
        const payload = {
          job_id: this.job.id,
          data: this.formFields,
        };
        this.$store
          .dispatch("UpdateJobPart", payload)
          .then(() => {
            this.$emit("closeModal", true);
            this.$store.commit("SetSnackbarData", {
              message: "Job part updated successfully",
            });
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
      // let payload = {};
    },
    validate() {
      return this.$refs.BookCollectionForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    calcPartCost() {
      this.formFields.packs_cost =
        this.formFields.unit_cost * this.formFields.packs_ordered || 0;
    },
    initForm() {
      this.formFields.id = this.part.id;
      this.formFields.part_code = this.part.part_code;
      this.formFields.part_description = this.part.part_description;

      this.formFields.pack_qty = this.part.pack_qty;
      this.formFields.packs_ordered = this.part.packs_ordered;
      this.formFields.unit_cost = this.part.unit_cost;
      this.formFields.packs_cost = this.part.packs_cost;
    },
  },

  computed: {
    companyList() {
      return this.$store.getters.getCompanyList;
    },
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    part() {
      return this.$store.getters.getSelectedJobPart;
    },
    costWatch() {
      return `${this.formFields.unit_cost}|${this.formFields.packs_ordered}`;
    },
    DialogShown() {
      return this.dialog_shown;
    },
  },

  mounted() {
    this.$store.dispatch("fetchCompanyList").then(() => {
      this.formFields.company_id = this.part.company_id;
    });

    this.initForm();
  },

  watch: {
    costWatch() {
      this.calcPartCost();
    },
    DialogShown(newval, oldval) {
      if (newval != oldval && newval == true) {
        this.initForm();
      }
    },
  },
};
</script>

<style>
</style>