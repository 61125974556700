import Axios from "axios"

const state = {
  authToken: "",
  currentUserObj: {},
}
const mutations = {
  setLoggedInUser(state, payload) {

    if (payload.token) {
      localStorage.setItem('neutToken', payload.token);
      state.authToken = payload.token;
      state.currentUserObj = payload.data;
    }
    else {
      localStorage.removeItem('neutToken');
      state.authToken = "";
      state.currentUserObj = {};
    }
  },

  removeUserState(state) {
    localStorage.removeItem('neutToken');
    state.authToken = "";
    state.currentUserObj = {};
  },
}
const actions = {

  logout(context) {

    return Axios.get(
      process.env.VUE_APP_API_PROTOCOL +
      "://" +
      process.env.VUE_APP_API_HOST +
      ":" +
      process.env.VUE_APP_API_PORT +
      "/api/user/logout", {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + context.getters.getCurrentToken,
      }
    })
      .then(function () {
        // window.Echo.leave(`testChannel`);

        return context.commit("removeUserState");

      });
  },

  autoSignIn(context) {
    const token = localStorage.getItem('neutToken');
    if (token) {
      return context.dispatch("getUserObjFromToken");
    }
    else
      return false;
  },

  userLogin({ commit }, payload) {
    console.log("logging in now");

    if (payload.email != null && payload.password != null) {
      console.log("logging in now");
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/tokens/create",
        {
          email: payload.email,
          password: payload.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      ).then(function (response) {
        console.log(response);

        commit("setLoggedInUser", response.data);

        return response.data;
      })
        .catch(function (err) {
          console.log(err);
          return err;
        });
    }
  },

  getUserObjFromToken(context) {
    console.log("logging in now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/user",
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        var payload = {
          token: token,
          data: response.data
        }

        console.log(payload);

        context.commit("setLoggedInUser", payload);


      })
        .catch(function (err) {
          console.log(err);
        });

  },
}


const getters = {
  getCurrentToken(state) {
    return state.authToken;
  },
  isLoggedIn(state) {
    return state.currentUserObj != {} && state.authToken != "" ? true : false;
  },
  getCurrentUserObj(state) {
    return state.currentUserObj;
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}