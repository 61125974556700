
<template>
  <v-row class="parts-table px-5 ma-0">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-left"
              v-for="(value, key) in table_headers"
              :key="key"
            >
              {{ key }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in logs" :key="log.id">
            <td>{{ log.created }}</td>
            <td>{{ get_status_text(log.status_id) }}</td>
            <td>{{ log.user ? log.user.name : "User not found" }}</td>
            <td>{{ log.comments }}</td>
            <td>{{ log.is_working_on ? "No" : "Yes" }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-row>
</template>

<script>
export default {
  name: "TimeLogTable",
  props: ["logs"],
  data() {
    return {
      table_headers: {
        Timestamp: "Timestamp",
        Status: "Status",
        User: "User",
        Comments: "Comments",
        Pause: "Pause",
      },
    };
  },
  methods: {
    get_status_text(status_id) {
      if (!status_id) return 0;
      return this.$store.getters.get_status(status_id);
    },
  },
};
</script>

<style>
</style>