<template>
  <v-row class="ma-0 pa-6">
    <div class="flex-grow-1 d-flex justify-space-between mb-6">
      <h1>Jobs Overdue</h1>
      <v-btn
        color="success"
        @click="DownloadPDF('go_ahead/pdf')"
        :loading="IsFetchingPDF"
        :disabled="IsFetchingPDF"
        >Download PDF</v-btn
      >
    </div>
    <v-data-table
      :headers="headers"
      :items="jobs"
      :items-per-page="-1"
      class="elevation-1"
      style="width: 100%"
      :loading="is_loading"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn :to="'/job/' + item.id" color="primary">View</v-btn>
      </template>

      <template v-slot:item.appDate="{ item }">
        {{ item.appDate | YMD_to_DMY }}
      </template>

      <template v-slot:item.user.name="{ item }">
        {{ item.user_id && item.user.name ? item.user.name : "—" }}
      </template>

      <!-- <template v-slot:body.append>
        <tr>
          <td colspan="5">Total: {{ CalculateJobsAssignedTotal }}</td>
        </tr>
      </template> -->
    </v-data-table></v-row
  >
</template>


<script>
export default {
  name: "Go_Ahead",
  data() {
    return {
      is_loading: false,
      IsFetchingPDF: false,
      headers: [
        {
          text: "Job",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Customer", value: "company.name" },
        { text: "Description", value: "description" },
        { text: "Date Approved", value: "appDate" },
        { text: "Engineer", value: "user.name" },
        { text: "Actions", value: "actions" },
      ],
      jobs: [],
    };
  },
  methods: {
    DownloadPDF(endpoint) {
      this.IsFetchingPDF = true;
      return this.$store.dispatch("DownloadReportPDF", endpoint).finally(() => {
        this.IsFetchingPDF = false;
      });
    },
  },
  computed: {
    // CalculateJobsAssignedTotal() {
    //   var total = 0;
    //   var i = 0;
    //   for (i == 0; i < this.jobs.length; i++) {
    //     total += this.jobs[i].salQuote || 0;
    //     console.log(total)
    //   }
    //   return total;
    // },
  },
  mounted() {
    this.is_loading = true;
    this.$store
      .dispatch("get_report_data_for_tables", "go_ahead")
      .then((res) => {
        this.jobs = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
};
</script>

<style>
</style>