<template>
  <v-tab-item class="pa-3">
    <v-row>
      <JobBasicInfo />
      <v-col cols="12" sm="6" md="9"> <SignatureView /> </v-col>
    </v-row>
  </v-tab-item>
</template>

<script>
import JobBasicInfo from "./JobBasicInfo";
import SignatureView from "./SignatureView";
export default {
  name: "DeliveryTab",
  components: {
    SignatureView,
    JobBasicInfo,
  },
};
</script>

<style>
</style>