<template>
  <v-dialog
    ref="EmailInvoiceModal"
    v-model="show"
    width="400px"
    :persistent="isSubmitting"
  >
    <v-form ref="EmailInvoiceForm" v-model="valid" @submit.prevent="Submit">
      <v-card class="pa-6">
        <p>This quotation will be sent to the company's invoice email:</p>
        <p class="text-subtitle-2" v-if="this.company_invoice_email">
          {{ this.company_invoice_email }}
        </p>
        <div style="white-space: break-spaces" class="text-subtitle-2" v-else>
          <p>No Invoice Email Found.</p>
          <p style="white-space: pre-line">
            Note: The first email in the selected CC list will be sent the
            invoice.
          </p>
        </div>

        <div v-if="OtherContacts && OtherContacts.length > 0">
          <p>Add to CC list:</p>

          <div v-for="contact in this.OtherContacts" :key="contact.id">
            <v-checkbox
              v-model="cc_list"
              :label="contact.name"
              :value="contact.email"
              v-if="contact.email"
              hide-details
            ></v-checkbox>
          </div>
        </div>

        <div class="d-flex justify-space-between align-center mt-6">
          <v-btn color="error" text @click="show = false">Cancel</v-btn>
          <v-btn
            color="success"
            @click="EmailPDF('invoice', cc_list)"
            :loading="isSubmitting"
            :disabled="
              isSubmitting ||
              (!this.company_invoice_email && !cc_list.length > 0)
            "
            >Send</v-btn
          >
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "EmailInvoiceModal",
  data() {
    return {
      valid: false,
      cc_list: [],
      isSubmitting: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  props: {
    value: Boolean,
  },
  methods: {
    validate() {
      return this.$refs.AdvanceToStage8.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    EmailPDF(endpoint, cc_list = []) {
      console.log(cc_list);
      this.isSubmitting = true;

      return this.$store
        .dispatch("email_" + endpoint, {
          id: this.job.id,
          cc: cc_list,
        })
        .then(() => {
          this.isSubmitting = false;
          this.show = false;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_id() {
      return this.$route.params.job_id;
    },
    company_invoice_email() {
      if (this.job.company && this.job.company.invoice_email)
        return this.job.company.invoice_email;
      else return null;
    },
    contact() {
      if (this.$store.getters.getCurrentSingleJob.contact)
        return this.$store.getters.getCurrentSingleJob.contact;
      else return {};
    },
    OtherContacts() {
      if (this.job.company && this.job.company.contacts) {
        var contacts = [...this.job.company.contacts];

        var inv_email = this.company_invoice_email;

        if (inv_email) {
          contacts.splice(
            contacts.findIndex((i) => {
              return i.email === inv_email;
            }),
            1
          );
        }

        return contacts;
      }
      return [];
    },

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    for (let i = 0; i < this.OtherContacts.length; i++) {
      var cc_quotation = this.OtherContacts[i].include_cc_quotation;
      if (cc_quotation) this.cc_list.push(this.OtherContacts[i].email);
    }
  },
};
</script>

<style>
</style>