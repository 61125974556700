<template>
  <v-tab-item class="pa-3">
    <v-row class="pa-5">
      <v-col>
        <DocumentsTable />
      </v-col>
    </v-row>
    <DocumentsUploader />
  </v-tab-item>
</template>

<script>
import DocumentsTable from "./DocumentsTable";
import DocumentsUploader from "./DocumentsUploader";
export default {
  name: "DocumentsTab",
  components: {
    DocumentsTable,
    DocumentsUploader,
  },
};
</script>

<style>
</style>