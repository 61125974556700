<template>
  <v-row class="ma-0 pa-4">
    <v-col cols="12" sm="5" md="3">
      <div class="dates-container">
        <v-btn
          to="/reports/monthly_sales/"
          exact
          outlined
          class="mb-3"
          style="grid-column: span 2"
          >View All</v-btn
        >
        <v-btn
          v-for="date_obj in datesNav"
          :key="date_obj.date"
          :to="'/reports/monthly_sales/' + date_obj.date"
          outlined
          class="mb-3"
          >{{ date_obj.label }}</v-btn
        >
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="9">
      <div class="container">
        <div
          class="
            d-flex
            justify-space-between
            mb-9
            flex-column flex-sm-row
            align-center
          "
        >
          <h2 class="page-header">Monthly Sales By Sales Rep</h2>
          <v-btn
            @click="
              DownloadPDF('monthly_sales_by_rep/pdf' + urlParamPdfEndpoint)
            "
            :loading="this.is_loading"
            :disabled="this.is_loading"
            class="mt-sm-0 mt-4"
            >Download as PDF</v-btn
          >
        </div>
        <div>
          <v-data-table
            :headers="headers"
            :items="jobs"
            :items-per-page="-1"
            class="elevation-1"
            style="width: 100%"
            group-by="sales_rep_name"
            :loading="is_loading"
            mobile-breakpoint="0"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn :to="'/job/' + item.job_id" color="primary">View</v-btn>
            </template>

            <template v-slot:group="{ items }">
              <tr colspan="5">
                <td colspan="5" class="grey lighten-2" style="font-weight: 500">
                  {{
                    items[0] && items[0].sales_rep_name
                      ? items[0].sales_rep_name
                      : "House accounts"
                  }}
                </td>
              </tr>
              <tr
                v-for="item in items"
                :key="item.company_id"
                @click="gotoCompany(item.company_id)"
                style="cursor: pointer"
              >
                <td
                  v-for="header in headers"
                  :key="item.company_id + ' - ' + header.value"
                >
                  <template
                    v-if="
                      header.value != 'Total' &&
                      header.value != 'average_job_value' &&
                      header.value != 'number_of_jobs' &&
                      header.value != 'user'
                    "
                  >
                    {{
                      header.value != "" && item[header.value] != ""
                        ? item[header.value]
                        : "-"
                    }}
                  </template>

                  <template v-if="header.value == 'number_of_jobs'">
                    {{ item[header.value] }}
                  </template>

                  <template v-if="header.value == 'Total'">
                    {{ item[header.value] | toCurrency }}
                  </template>

                  <template v-if="header.value == 'average_job_value'">
                    {{ item["average_job_value"] | toCurrency }}
                  </template>

                  <template v-if="header.value == 'user'">
                    {{ item["user"] != null ? item["user"].name : "" }}
                  </template>
                </td>
              </tr>
              <tr v-if="items && items.length > 0" class="">
                <td></td>
                <td colspan="2">
                  <p class="ma-0">
                    Total Jobs:
                    <strong>{{ CalculateCountTotal(items) }}</strong>
                  </p>
                </td>
                <td colspan="2">
                  <strong>{{
                    CalculateJobsAssignedTotal(items) | toCurrency
                  }}</strong>
                </td>
              </tr>
            </template>

            <template slot="body.append">
              <tr>
                <td>
                  <strong
                    >Total Count:
                    <strong>{{ CalculateCountTotal(jobs) }}</strong></strong
                  >
                </td>
                <td></td>
                <td><strong>Total Spend</strong></td>
                <td>
                  <strong>{{
                    CalculateJobsAssignedTotal(jobs) | toCurrency
                  }}</strong>
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
          <div></div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Monthly_Sales",
  data() {
    return {
      jobs: [],
      is_loading: false,
      MonthMap: {
        1: "Jan",
        2: "Feb",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      },
      headers: [
        {
          text: "Customer ID",
          align: "start",
          sortable: false,
          value: "company_id",
        },
        {
          text: "Customer",
          value: "company_name",
          class: "autofit-column-heading",
        },
        {
          text: "Job Count",
          value: "number_of_jobs",
          class: "autofit-column-heading",
        },
        {
          text: "Total Spend",
          value: "Total",
          class: "autofit-column-heading",
        },
        {
          text: "Average Job Value",
          value: "average_job_value",
          class: "autofit-column-heading",
        },
      ],
    };
  },
  methods: {
    gotoCompany(id) {
      if (id) this.$router.push("/management/customers/" + id);
    },
    DownloadPDF(endpoint) {
      this.is_loading = true;
      return this.$store.dispatch("DownloadReportPDF", endpoint).finally(() => {
        this.is_loading = false;
      });
    },
    CalculateCountTotal(jobs) {
      var total = 0;
      var i = 0;
      for (i == 0; i < jobs.length; i++) {
        total += jobs[i].number_of_jobs || 0;
      }
      return total;
    },
    CalculateJobsAssignedTotal(jobs) {
      var total = 0;
      var i = 0;
      for (i == 0; i < jobs.length; i++) {
        total += jobs[i].Total || 0;
      }
      return total;
    },

    toYYYYmmDD(date) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
  },
  computed: {
    datesNav() {
      var today = new Date();
      // today = new Date(today.getFullYear(), today.getMonth(), 1);
      var dates = [];
      // dates.push(this.toYYYYmmDD(today));
      for (let i = 0; i < 12; i++) {
        var new_date = new Date(today.getFullYear(), today.getMonth() - i, 1);
        dates.push({
          date: this.toYYYYmmDD(new_date),
          label:
            this.MonthMap[new_date.getMonth() + 1] +
            " " +
            new_date.getFullYear(),
        });
      }
      return dates;
    },
    urlParam() {
      return this.$route.params.date || "";
    },
    urlParamPdfEndpoint() {
      if (this.urlParam) return "/" + this.urlParam;
      else return "";
    },
  },
  mounted() {
    var date = this.$route.params.date || "";
    if (date != "") date = "/" + date;

    console.log(this.urlParam);
    this.jobs = [];
    this.is_loading = true;
    this.$store
      .dispatch("get_report_data_for_tables", "monthly_sales_by_rep" + date)
      .then((res) => {
        this.jobs = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
};
</script>

<style scoped>
.dates-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  padding: 10px;
}
</style>