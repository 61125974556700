import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import './Filters.js';
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';

import VueSignaturePad from 'vue-signature-pad';

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false



window.Pusher = require('pusher-js');

window.Pusher.log = function (message) { window.console.log(message); }


axios.interceptors.request.use((config) => {
  const token = store.getters.getCurrentToken;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers["Content-Type"] = "application/json";
  config.headers["Accept"] = "application/json";
  if (window.Echo)
    config.headers["X-Socket-ID"] = window.Echo.socketId();
  return config;
});

Vue.use(VueFileAgent);

Vue.use(VueSignaturePad);

const MyPlugin = {
  install(Vue) {
    Vue.prototype.checkPermission = (permName) => {
      if (!permName)
        return false;

      const user_obj = store.getters.getCurrentUserObj;

      if (user_obj && user_obj.permissions && user_obj.permissions[permName])
        return user_obj.permissions[permName];

      return false;

    },

      Vue.prototype.detectBrowser = () => {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
          return 'Opera';
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
          return 'Chrome';
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
          return 'Safari';
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
          return 'Firefox';
        } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
          return 'IE';//crap
        } else {
          return 'Unknown';

        }
      }




  },
}
Vue.use(MyPlugin)



router.beforeEach((to, from, next) => {
  console.log(from)
  if (from.name == "SingleJobView") {
    console.log("/***** Router Guard Cleared it ***///")
    store.commit("setCurrentSingleJob", {});
  }
  next();

})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),

}).$mount('#app');

