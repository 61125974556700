<template>
  <!-- RIYAD START HERE, THESE ARE COPIED - MAKE THEM ENG. Quotation -->
  <!-- <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12"> -->
  <div class="table-cont" v-if="this.job">
    <div
      class="table-header pa-3"
      v-bind:class="{
        'normal-priority': job.priority_id <= 2,
        'high-priority': job.priority_id == 3,
        'emergency-priority': job.priority_id == 4,
      }"
    >
      <div class="table-property-label">N{{ this.job.id }}</div>
      <div class="table-property-value">Active?</div>
    </div>

    <div class="table-row">
      <div class="table-property-label">Priority</div>
      <div class="table-property-value" style="text-transform: capitalize">
        {{ this.priority }}
      </div>
    </div>

    <div class="table-row">
      <div class="table-property-label">Company</div>
      <div class="table-property-value">
        {{ this.job.company ? this.job.company.name : "" }}
      </div>
    </div>

    <div class="table-row">
      <div class="table-property-label">Quotation Date</div>
      <div class="table-property-value">
        {{ this.job.engDate | YMD_to_DMY }}
      </div>
    </div>
  </div>
  <!-- </v-col> -->
</template>

<script>
export default {
  name: "JobBasicInfo",

  data() {
    return {};
  },

  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    priority() {
      if (!this.job || !this.job.priority_id)
        return "Error, can't fetch priority";
      const priorities = this.$store.getters.get_priority_list;
      return priorities[this.job.priority_id];
    },
  },

  methods: {},
};
</script>

<style>
</style>