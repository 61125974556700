//this file is for all reports related actions on the "Reports" menu items... (so not related to single job reports)
import Axios from "axios"

const state = {
}
const mutations = {

}
const actions = {


  get_report_data_for_tables(context, payload) {
    console.log("email_job_report now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/reports/" + payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (res) {


        console.log(res.data)
        return res.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },



  DownloadReportPDF(context, endpoint) {
    // this.is_deleting = true;
    return Axios
      .get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/reports/" +
        endpoint,
        { responseType: "blob" } // important
      )
      .then((response) => {
        var blob = new Blob([response.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })

      .catch((error) => {
        //cuz its a blob request the error isn't read with a message
        context.commit("SetSnackbarData", {
          message: error,
          type: "error",
        });
      });

  }
}
const getters = {

}

export default {
  state,
  getters,
  mutations,
  actions
}