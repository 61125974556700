<template>
  <!-- <v-snackbar
    elevation="24"
    :color="this.Type"
    :value="true"
    class="snackbar-pos"
  >
    {{ this.Message }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="CloseSnackbar">
        {{ ButtonText }}
      </v-btn>
    </template>
  </v-snackbar> -->

  <div
    class="r-snackbar white--text fade-in"
    :class="[
      this.Type ? this.Type : '',
      this.UpdatedJob != null ? 'd-flex flex-column' : '',
    ]"
    elevation="24"
  >
    {{ this.Message }}
    <div :class="{ 'd-flex mt-2': this.UpdatedJob != null }">
      <v-btn
        color="success"
        depressed
        v-if="UpdatedJob != null && UpdatedJob.id"
        @click="UpdateJobInfo"
      >
        Reload Job Data
      </v-btn>

      <v-btn
        :color="this.UpdatedJob == null ? 'white' : 'white'"
        plain
        text
        @click="CloseSnackbar"
      >
        {{ this.redirect ? "Redirect" : ButtonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleSnackBar",
  props: {
    type: {
      type: String,
      default: "success",
    },
    updatedJob: {
      type: Object,
      default: null,
    },
    message: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "Close",
    },
    redirect: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: 4500,
    },
    id: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      timeout_func_id: null,
      // type: "success",
      // message: "Default message",
      // timeout: 3000,
      // buttonText: "Close",
      // redirect: "",
    };
  },
  methods: {
    UpdateJobInfo() {
      if (this.UpdatedJob != null && this.UpdatedJob.id) {
        this.$store.commit("setCurrentSingleJob", this.UpdatedJob);
        this.$store.commit("AddSnackbar", {
          message: "Job data reloaded successfully.",
        });
      }
      this.CloseSnackbar();
    },
    CloseSnackbar() {
      this.$store.commit("CloseSnackbar", this.id);
      if (this.timeout_func_id) clearTimeout(this.timeout_func_id);

      if (this.Redirect && this.Redirect.length > 0)
        this.$router.push(this.Redirect);
    },
  },
  computed: {
    ButtonText() {
      return this.buttonText;
    },
    Type() {
      return this.type;
    },
    UpdatedJob() {
      return this.updatedJob;
    },
    Redirect() {
      return this.redirect;
    },
    Message() {
      return this.message;
    },
    Timeout() {
      return this.timeout;
    },
  },
  mounted() {
    const timeout = this.Timeout;
    console.log("timeout:" + timeout);
    if (timeout && timeout > 0)
      this.timeout_func_id = setTimeout(() => {
        this.$store.commit("CloseSnackbar", this.id);
      }, timeout);
  },
};
</script>

<style scoped>
.snackbar-pos {
  position: static !important;
}

.r-snackbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.95) 0px 2px 22px -9px;
}
</style>