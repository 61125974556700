<template>
  <v-dialog ref="signature_dialog" v-model="show" width="400px">
    <v-form ref="AdvanceToStage8" v-model="valid" @submit.prevent="Submit">
      <v-card class="pa-6">
        <h4 class="mb-2">Order Number</h4>

        <v-text-field
          solo
          v-model="formFields.order_number"
          name="order_number"
        ></v-text-field>
        <!--
        <h4 class="mb-2">Delivery Date</h4>

        <v-text-field
          solo
          name="delDate"
          @click="show_datepicker()"
          readonly
          :value="formFields.delDate | YMD_to_DMY"
        ></v-text-field>

        <v-dialog
          ref="datepicker_dialog"
          v-model="datepicker_modal"
          persistent
          :return-value.sync="temp_date"
          width="290px"
        >
          <v-date-picker v-model="temp_date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="hide_datepicker()">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="save_datepicker()"> OK </v-btn>
          </v-date-picker>
        </v-dialog> -->

        <h4 class="mb-2">Shipment Ref</h4>

        <v-text-field
          solo
          v-model="formFields.shipment_ref"
          name="shipment_ref"
        ></v-text-field>
        <!--
        <h4 class="mb-2">Print Name</h4>

        <v-text-field
          solo
          v-model="formFields.print_signature"
          name="print_signature"
          :rules="[rules.required]"
        ></v-text-field> -->

        <div class="columns-2">
          <div>
            <h4 class="mb-2">First Name</h4>

            <v-text-field
              solo
              v-model="formFields.deliveryFirstName"
              name="deliveryFirstName"
              :rules="[rules.required, rules.trimWhiteSpace]"
            ></v-text-field>
          </div>

          <div>
            <h4 class="mb-2">Last Name</h4>

            <v-text-field
              solo
              v-model="formFields.deliveryLastName"
              name="deliveryLastName"
              :rules="[rules.required, rules.trimWhiteSpace]"
            ></v-text-field>
          </div>
        </div>

        <!-- <v-btn @click="signature_dialog = true">Signature</v-btn> -->

        <VueSignaturePad
          width="100%"
          height="300px"
          ref="signaturePad"
          class="signaturePad-styles"
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas();
            },
            onEnd: () => {
              this.save();
            },
          }"
        />
        <div class="d-flex justify-space-around mb-6">
          <v-btn
            type="button"
            @click.stop.prevent="clear_sig"
            class="error--text"
            small
            text
            >Clear</v-btn
          >
          <v-btn
            type="button"
            @click.stop.prevent="undo"
            class="warning--text"
            small
            text
            >Undo</v-btn
          >
        </div>

        <v-text-field
          solo
          style="display: none !important"
          readonly
          :value="formFields.svg_signature"
          name="svg_signature"
          :rules="[rules.required]"
        ></v-text-field>

        <v-btn
          :disabled="!valid || isSubmitting"
          class="success mr-0 mt-3 ml-auto d-flex"
          type="submit"
          :loading="valid && isSubmitting"
          >Confirm</v-btn
        >
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "ManualDelivery",
  components: {},
  props: {
    value: Boolean,
  },
  data() {
    return {
      isUpdating: false,
      isSubmitting: false,
      datepicker_modal: false,
      signature_dialog: false,
      temp_date: "",
      valid: false,
      formFields: {
        deliveryFirstName: "",
        deliveryLastName: "",
        order_number: null,
        shipment_ref: null,
        // print_signature: null,
        // delDate: new Date().toISOString().substr(0, 10),
        svg_signature: null,
      },

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
        trimWhiteSpace: (v) => v.toString().trim().length > 0,
      },
    };
  },

  methods: {
    RenderPreviousSig() {
      const sig = this.formFields.svg_signature;
      console.log(sig);
      if (sig) this.$refs.signaturePad.fromDataURL(sig);
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
      this.save();
    },
    clear_sig() {
      this.$refs.signaturePad.clearSignature();
      this.signature_dialog = false;
      this.save();

      // this.formFields.svg_signature = "";
    },
    save() {
      const { isEmpty, data } =
        this.$refs.signaturePad.saveSignature("image/svg+xml");
      this.formFields.svg_signature = data || null;

      console.log(isEmpty);
      console.log(data);
    },

    show_datepicker() {
      this.datepicker_modal = true;
      this.temp_date = "";
    },
    hide_datepicker() {
      this.datepicker_modal = false;
      this.temp_date = "";
    },
    save_datepicker() {
      if (this.temp_date) {
        this.formFields.delDate = this.temp_date;
      }
      this.hide_datepicker();
    },
    Submit() {
      console.log("Manual Delivery started");
      console.log(this.formFields);
      console.log(this.validate());

      // if (
      //   this.formFields.deliveryFirstName.trim().length > 0 &&
      //   this.formFields.deliveryLastName.trim().length > 0
      // )
      //   this.formFields.print_signature =
      //     this.formFields.deliveryFirstName.trim() +
      //     " " +
      //     this.formFields.deliveryLastName.trim();
      // else return;

      this.isSubmitting = true;

      const payload = {
        id: this.job_id,
        ...this.formFields,
      };
      this.$store
        .dispatch("ManualDelivery", payload)
        .then(() => {
          this.show = false;
          this.$store.commit("SetSnackbarData", {
            message: "Manually delivered the job successfully.",
          });
        })
        .finally(() => {
          this.snackbar = true;
          this.isSubmitting = false;

          // this.$router.push("/job/" + this.job_id);
        });
    },
    reset_form() {},
    validate() {
      return this.$refs.AdvanceToStage8.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  updated() {
    this.formFields.order_number = this.job.order_number;
  },

  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_id() {
      return this.$route.params.job_id;
    },

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>