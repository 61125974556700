import Axios from "axios"

const state = {
  currentSingleJob: {},
  selectedJobPart: null,
  jobHistory: []
}
const mutations = {
  setCurrentSingleJob(state, payload) {
    state.currentSingleJob = payload;
  },
  setSpecificKeysOnCurrentSingleJob(state, property) {
    if (property && property["name"]) {
      state.currentSingleJob[property["name"]] = property["value"];

    }
  },
  setSelectedJobPart(state, payload) {
    state.selectedJobPart = payload;
  },
  RemovePartFromList(state, payload) {
    state.currentSingleJob.parts.splice(state.currentSingleJob.parts.findIndex(function (part) {
      return part.id === payload;
    }), 1);
  },


  RemoveJobComponentFromList(state, payload) {

    state.currentSingleJob.jobcomponents.splice(state.currentSingleJob.jobcomponents.findIndex(function (comp) {
      return comp.id === payload;
    }), 1);
  },

  setJobHistory(state, payload) {
    state.jobHistory = payload;
  },

}
const actions = {
  fetchSingleJob(context, job_id) {
    console.log("getting job_id info");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id
      ).then(function (response) {
        console.log(response.data);
        context.commit("setCurrentSingleJob", {});
        context.commit("setCurrentSingleJob", response.data);
        return response.data;
      })
        .catch(function (error) {
          console.log(error);


          context.commit("SetSnackbarData", {
            message: "Error, couldn't find the requested job.",
            timeout: 300000,
            type: "error",
            redirect: "/dashboard"
          });
          return Promise.reject(error);

        });


  },

  FetchJobHistory(context, job_id) {
    var token = context.getters.getCurrentToken;

    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id + "/history"
      ).then(function (response) {
        console.log(response.data);

        context.commit("setJobHistory", response.data);

      })
        .catch(function (error) {
          console.log(error);


          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });
          return Promise.reject(error);



        });
  },

  AddJobPart(context, payload) {
    console.log("saving job info");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);
    const job_id = payload.job_id;

    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id + "/addPart", payload.data
      ).then(function (response) {
        console.log(response.data);

        context.commit("setCurrentSingleJob", response.data);

      })
        .catch(function (error) {
          console.log(error);


          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });
          return Promise.reject(error);



        });
  },



  SingleJobSendSMS(context, payload) {
    console.log("saving job info");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);
    const job_id = payload.job_id;

    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id + "/send_sms", payload
      ).then(function (response) {
        console.log(response.data);

      })
        .catch(function (error) {
          console.log(error);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });
          return Promise.reject(error);
        });
  },


  UpdateJobPart(context, payload) {
    console.log("saving job info");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);
    const job_id = payload.job_id;

    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id + "/updatePart/" + payload.data.id, payload.data
      ).then(function (response) {
        console.log(response.data);

        context.commit("setCurrentSingleJob", response.data);
      })
        .catch(function (error) {
          console.log(error);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });
          return Promise.reject(error);
        });
  },



  ReceiveJobPart(context, payload) {
    console.log("RecieveJobPart");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/parts/" + payload + "/receive", payload
      ).then(function (response) {
        console.log(response.data);

        context.commit("setCurrentSingleJob", response.data);
        return response.data
      })
        .catch(function (error) {
          console.log(error);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });
          return Promise.reject(error);
        });
  },

  DeleteJobPart(context, payload) {
    console.log("RecieveJobPart");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/parts/" + payload).then(function (response) {
          console.log(response.data);

          // context.commit("setCurrentSingleJob", response.data);
          return response.data
        })
        .catch(function (error) {
          console.log(error);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });
          return Promise.reject(error);
        });
  },

  updateSingleJob(context, payload) {
    console.log("saving job info");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    var job_id = payload.id;
    delete payload['id'];


    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id, payload
      ).then(function (response) {
        console.log(response.data);
        context.commit("setCurrentSingleJob", response.data);
      })
        .catch(function (err) {
          console.log(err);
          context.commit("SetSnackbarData", {
            message: err.response.data.message,
            type: "error",
          });
          return Promise.reject(err);
        });
  },


  removeImagesSingleJob(context, payload) {
    console.log("saving job info");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    var job_id = payload.id;
    delete payload['id'];


    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id + "/unlink", payload
      ).then(function (response) {
        console.log(response.data);
        context.commit("setCurrentSingleJob", response.data);
      })
        .catch(function (err) {
          console.log(err);
          context.commit("SetSnackbarData", {
            message: err.response.data.message,
            type: "error",
          });
          return Promise.reject(err);
        });
  },

  switchJobPausedStatus(context, payload) {
    console.log("saving job info");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    var job_id = payload.id;
    delete payload['id'];


    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id + "/pause", payload
      ).then(function (response) {
        console.log(response.data);
        context.commit("setCurrentSingleJob", response.data);
      })
        .catch(function (err) {
          console.log(err);
          context.commit("SetSnackbarData", {
            message: err.response.data.message,
            type: "error",
          });
          return Promise.reject(err);
        });
  },


  ManualDelivery(context, payload) {
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    var job_id = payload.id;


    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id + "/manual-delivery", payload
      ).then(function (response) {
        console.log(response.data);
        context.commit("setCurrentSingleJob", response.data);
      })
        .catch(function (err) {
          console.log(err);
          context.commit("SetSnackbarData", {
            message: err.response.data.message,
            type: "error",
          });
          return Promise.reject(err);
        });
  },

  SwitchRNR(context, payload) {
    console.log("saving job info");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    var job_id = payload.id;
    delete payload['id'];


    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id + "/rnr", payload
      ).then(function (response) {
        console.log(response.data);


        context.commit("setCurrentSingleJob", response.data);

      })
        .catch(function (err) {
          console.log(err);
          context.commit("SetSnackbarData", {
            message: err.response.data.message,
            type: "error",
          });
          return Promise.reject(err);
        });
  },



  deleteJob(context, job_id) {
    console.log("Deleting....");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id
      ).then(function (response) {
        console.log(response.data);

        return response.data;

      })
        .catch(function (error) {
          console.log(error);
          context.commit("SetSnackbarData", {
            message: "Error, couldn't delete job.",
            timeout: 300000,
            type: "error",
            redirect: "/dashboard"
          });
          return Promise.reject(error);
        });
  },


  AddJobNote(context, payload) {
    console.log("Adding Job Note...");
    console.log(payload);
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    var job_id = payload.id;
    delete payload['id'];


    if (token != "" && job_id != "" && job_id != undefined)
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + job_id + "/addNote", payload
      ).then(function (response) {
        console.log(response.data);
        context.commit("setCurrentSingleJob", response.data);
        context.commit("SetSnackbarData", {
          message: "Job note added successfully.",
        });

      })
        .catch(function (err) {
          console.log(err);
          context.commit("SetSnackbarData", {
            message: "Error, couldn't add job note.",
            timeout: 300000,
            type: "error",
          });
          return Promise.reject(err);
        });
    else {
      context.commit("SetSnackbarData", {
        message: "Error, couldn't add job note.",
        timeout: 300000,
        type: "error",
      });
      return Promise.reject();

    }

  },




  deleteJobImage(context, payload) {
    if (!payload || !payload.job_id || !payload.img_id) {
      context.commit("SetSnackbarData", {
        message: "Error with payload, couldn't delete Image.",
        timeout: 10000,
        type: "error",
      });
      return;

    }
    console.log("Deleting....");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "" && payload != "" && payload != undefined)
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/jobs/" + payload.job_id + "/images/v2/" + payload.img_id
      ).then(function (response) {
        console.log(response.data);
        context.commit("setCurrentSingleJob", response.data);
        context.commit("SetSnackbarData", {
          message: "Successfully deleted the job Image.",
          timeout: 5000,
          type: "success",
        });

        return response.data;

      })
        .catch(function (error) {
          console.log(error);
          context.commit("SetSnackbarData", {
            message: "Error, couldn't delete job Image.",
            timeout: 300000,
            type: "error",
          });
          return Promise.reject(error);
        });
  },




}


const getters = {
  getCurrentSingleJob(state) {
    return state.currentSingleJob;
  },
  getSelectedJobPart(state) {
    return state.selectedJobPart
  },
  getJobHistory(state) {
    return state.jobHistory;
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}