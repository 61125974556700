<template>
  <div>
    <v-btn @click="AddDepartmentModal()" color="success"
      >Add a Department</v-btn
    >

    <v-dialog
      ref="addDepartmentDialog"
      v-model="addDepartmentModal"
      :persistent="isSubmitting"
      width="400px"
    >
      <v-card>
        <v-form
          ref="AddDepartmentForm"
          v-model="valid"
          @submit.prevent="Submit"
        >
          <v-row class="pa-4 ma-0">
            <v-col cols="12">
              <h4 class="mb-2">Department Name</h4>
              <v-text-field
                solo
                :rules="[rules.required]"
                v-model="formFields.name"
                name="name"
              ></v-text-field>

              <h4 class="mb-2">Nominal Code</h4>

              <v-text-field
                solo
                type="number"
                      @wheel="$event.target.blur()"

                class="hide-arrows no-arrows"
                v-model.number="formFields.nominal_code"
                name="address"
              ></v-text-field>

              <div class="mx-0 mt-3 d-flex justify-space-between">
                <v-btn
                  :disabled="isSubmitting"
                  color="error"
                  text
                  @click="addDepartmentModal = false"
                  >Cancel</v-btn
                >

                <v-btn
                  :disabled="!valid || isSubmitting"
                  class="success"
                  type="submit"
                  :loading="isSubmitting"
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AddDepartmentForm",
  data() {
    return {
      valid: false,
      isUpdating: false,
      addDepartmentModal: false,
      SalesUsers: [],
      isSubmitting: false,
      formFields: {
        name: "",
        nominal_code: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
        emailPattern: (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          "E-mail must be valid",
      },
    };
  },
  methods: {
    AddDepartmentModal() {
      this.addDepartmentModal = true;
    },
    ResetForm() {
      this.$refs.AddDepartmentForm.reset();
      this.formFields.include_cc_quotation = false;
      this.formFields.include_cc_delivery = false;
    },
    Submit() {
      if (this.validate()) {
        this.isSubmitting = true;
        console.log("Editing Department");
        console.log(this.formFields);
        console.log(this.validate());
        this.$store
          .dispatch("AddSingleDepartment", this.formFields)
          .then((new_department) => {
            if (new_department && new_department.id) {
              this.$store.commit("SetSnackbarData", {
                message: "Department added successfully.",
              });
              this.$store.commit("PushDepartmentToEnd", new_department);
              this.addDepartmentModal = false;
              this.ResetForm();
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.AddDepartmentForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  computed: {},
  mounted() {
    // this.isUpdating = true;
  },
};
</script>

<style>
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.v-application--is-ltr
  .v-input--reverse
  .v-input__slot
  .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}
.v-application--is-rtl
  .v-input--reverse
  .v-input__slot
  .v-input--selection-controls__input {
  margin-left: 0;
  margin-right: 8px;
}

.v-input--expand .v-input__slot .v-label {
  display: block;
  flex: 1;
}
</style>