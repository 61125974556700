<template>
  <v-row class="ma-0 pa-4">
    <v-col cols="3">
      <h3 class="mb-4">Export Report</h3>
      <p>Jobs delivered between dates</p>
      <div class="d-flex flex-column">
        <v-btn
          @click="show_datepicker('dateFrom')"
          outlined
          class="mb-3 text-left"
        >
          Date From {{ dates.dateFrom }}
        </v-btn>
        <v-btn
          @click="show_datepicker('dateTo')"
          outlined
          class="mb-3 text-left"
          >Date To {{ dates.dateTo }}</v-btn
        >
        <v-btn
          @click="search"
          class="ml-auto"
          color="success"
          :disabled="!this.dates.dateFrom || !this.dates.dateTo"
          >Search</v-btn
        >
      </div>

      <v-dialog
        ref="datepicker_dialog"
        v-model="datepicker_modal"
        persistent
        :return-value.sync="temp_date"
        width="290px"
      >
        <v-date-picker v-model="temp_date" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="hide_datepicker()">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="save_datepicker()"> OK </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
    <v-col cols="9">
      <v-data-table
        :headers="headers"
        :items="jobs"
        :items-per-page="-1"
        calculate-widths
        hide-default-footer
        :loading="this.is_loading"
        loading-text="Loading... Please wait"
        class="elevation-1"
        @page-count="pageCount = $event"
      >
        <template v-slot:item.net_amount="{ item }">
          {{ (item.salQuote * 1.2) | toCurrency }}
        </template>

        <template v-slot:item.delDate="{ item }">
          {{ item.delDate | YMD_to_DMY }}
        </template>
      </v-data-table>
    </v-col></v-row
  >
</template>

<script>
export default {
  name: "SageExportReport",
  data() {
    return {
      jobs: [],
      is_loading: false,
      dates: {
        dateFrom: "",
        dateTo: "",
      },
      datepicker_modal: false,
      temp_date: new Date().toISOString().substr(0, 10),
      datepicker_active_field: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Date Delivered", value: "delDate" },
        { text: "Customer", value: "company.name" },
        { text: "£ Net", value: "net_amount" },
      ],
    };
  },
  methods: {
    search() {
      this.$store
        .dispatch("fetchSageExportReport", this.dates)
        .then((res) => {
          this.jobs = res;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    show_datepicker(formfield) {
      this.datepicker_modal = true;
      this.datepicker_active_field = formfield;
      this.temp_date = "";
    },
    hide_datepicker() {
      this.datepicker_modal = false;
      this.datepicker_active_field = "";
      this.temp_date = "";
    },
    save_datepicker() {
      if (this.temp_date && this.datepicker_active_field) {
        this.dates[this.datepicker_active_field] = this.temp_date;
      }
      this.hide_datepicker();
    },
  },
  mounted() {
    this.is_loading = true;
    this.$store
      .dispatch("fetchSageExportReport", null)
      .then((res) => {
        this.jobs = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
};
</script>

<style>
</style>