var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0 pa-6"},[_c('div',{staticClass:"flex-grow-1 d-flex justify-space-between mb-6"},[_c('h1',[_vm._v("Overdue Order Numbers")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.IsFetchingPDF,"disabled":_vm.IsFetchingPDF},on:{"click":function($event){return _vm.DownloadPDF('awaiting_po/pdf')}}},[_vm._v("Download PDF")])],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.jobs,"items-per-page":-1,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"group-by":"company.sales_rep.name","loading":_vm.is_loading},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('th',{attrs:{"colspan":_vm.windowWidth < 600 ? '1' : '8'}},[_vm._v(" "+_vm._s(items[0].company && items[0].company.sales_rep && items[0].company.sales_rep.name ? items[0].company.sales_rep.name : 'No Sales Rep Assigned')+" ")])]}},{key:"item.delDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("YMD_to_DMY")(item.delDate))+" ")]}},{key:"item.NetTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.NetTotal))+" ")]}},{key:"item.daysOutstanding",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.daysOverdue)+" Days ")]}},{key:"item.tag_id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.Department_name(item)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":'/job/' + item.id,"color":"primary"}},[_vm._v("View")])]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v(" Total "),(_vm.windowWidth < 600)?_c('span',[_vm._v(": "+_vm._s(_vm._f("toCurrency")(_vm.SalesQuotesTotal)))]):_vm._e()]),_c('th',{staticClass:"hide-600"}),_c('th',{staticClass:"hide-600"}),_c('th',{staticClass:"hide-600"}),_c('th',{staticClass:"hide-600"}),_c('th',{staticClass:"hide-600"}),_c('th',{staticClass:"hide-600"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.SalesQuotesTotal)))]),_c('th',{staticClass:"hide-600"})])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }