var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{ref:"signature_dialog",attrs:{"width":"400px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-form',{ref:"AdvanceToStage8",on:{"submit":function($event){$event.preventDefault();return _vm.Submit($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"pa-6"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Order Number")]),_c('v-text-field',{attrs:{"solo":"","name":"order_number"},model:{value:(_vm.formFields.order_number),callback:function ($$v) {_vm.$set(_vm.formFields, "order_number", $$v)},expression:"formFields.order_number"}}),_c('h4',{staticClass:"mb-2"},[_vm._v("Shipment Ref")]),_c('v-text-field',{attrs:{"solo":"","name":"shipment_ref"},model:{value:(_vm.formFields.shipment_ref),callback:function ($$v) {_vm.$set(_vm.formFields, "shipment_ref", $$v)},expression:"formFields.shipment_ref"}}),_c('div',{staticClass:"columns-2"},[_c('div',[_c('h4',{staticClass:"mb-2"},[_vm._v("First Name")]),_c('v-text-field',{attrs:{"solo":"","name":"deliveryFirstName","rules":[_vm.rules.required, _vm.rules.trimWhiteSpace]},model:{value:(_vm.formFields.deliveryFirstName),callback:function ($$v) {_vm.$set(_vm.formFields, "deliveryFirstName", $$v)},expression:"formFields.deliveryFirstName"}})],1),_c('div',[_c('h4',{staticClass:"mb-2"},[_vm._v("Last Name")]),_c('v-text-field',{attrs:{"solo":"","name":"deliveryLastName","rules":[_vm.rules.required, _vm.rules.trimWhiteSpace]},model:{value:(_vm.formFields.deliveryLastName),callback:function ($$v) {_vm.$set(_vm.formFields, "deliveryLastName", $$v)},expression:"formFields.deliveryLastName"}})],1)]),_c('VueSignaturePad',{ref:"signaturePad",staticClass:"signaturePad-styles",attrs:{"width":"100%","height":"300px","options":{
          onBegin: function () {
            _vm.$refs.signaturePad.resizeCanvas();
          },
          onEnd: function () {
            this$1.save();
          },
        }}}),_c('div',{staticClass:"d-flex justify-space-around mb-6"},[_c('v-btn',{staticClass:"error--text",attrs:{"type":"button","small":"","text":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.clear_sig($event)}}},[_vm._v("Clear")]),_c('v-btn',{staticClass:"warning--text",attrs:{"type":"button","small":"","text":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.undo($event)}}},[_vm._v("Undo")])],1),_c('v-text-field',{staticStyle:{"display":"none !important"},attrs:{"solo":"","readonly":"","value":_vm.formFields.svg_signature,"name":"svg_signature","rules":[_vm.rules.required]}}),_c('v-btn',{staticClass:"success mr-0 mt-3 ml-auto d-flex",attrs:{"disabled":!_vm.valid || _vm.isSubmitting,"type":"submit","loading":_vm.valid && _vm.isSubmitting}},[_vm._v("Confirm")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }