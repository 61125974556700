<template>
  <div>
    <ConfirmationDialog
      v-model="deleteModal"
      @cancelled="deleteModal = false"
      @confirmed="DeleteItem()"
      :loader="isDeleting"
      :itemName="'job part'"
    />

    <v-data-table
      :headers="headers"
      :items="JobComponents"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      calculate-widths
      hide-default-footer
      :loading="this.isLoadingJobComponents"
      loading-text="Loading... Please wait"
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.quantity="{ item }">
        <div
          style="max-width: 75px"
          :id="item.component_id + '-quantity'"
          class="my-2"
          :class="
            quantities_intermediate[item.component_id] != item.quantity
              ? 'changed-quantity-box'
              : ''
          "
        >
          <!-- {{ item.quantity }}
            {{ quantities_intermediate[item.component_id] }}
           -->
          <!-- {{
              quantities_intermediate[item.component_id] != item.quantity
                ? "Changed"
                : "unchanged"
            }} -->
          <!-- {{ test }} -->
          <v-text-field
            v-model.number="quantities_intermediate[item.component_id]"
            name="quantity"
            type="number"
            class="job-component-quantity-field"
            @change="
              jobComponentQuantityChanged(
                $event,
                item.quantity,
                item.component_id,
                item
              )
            "
            single-line
            min="0"
            :max="item.component.qty_in_stock + item.quantity"
            dense
            hide-details
            solo
          ></v-text-field>
        </div>
      </template>

      <template v-slot:item.component.datasheet="{ item }">
        <a
          target="_blank"
          :href="item.component.datasheet"
          v-if="item.component.datasheet"
        >
          <v-img
            :src="require('../../../../assets/pdf.svg')"
            contain
            width="36"
          />
        </a>
        <div v-else>-</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="
            deleteItem = item;
            deleteModal = true;
          "
          v-if="checkPermission('can_add_job_parts') || true"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-btn
      color="primary"
      class="mt-6 ml-auto d-flex"
      @click="saveQuantities"
      :loading="isUpdatingComponentQuantities"
      :disabled="
        !quantities_changed.length > 0 || isUpdatingComponentQuantities
      "
      >Save Quantities</v-btn
    >
    <div class="text-center pt-2" v-if="pageCount > 1">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ConfirmationDialog from "../../../global/ConfirmationDialog.vue";

export default {
  components: {
    ConfirmationDialog,
  },
  props: ["refs_prop"],
  data() {
    return {
      show: false,
      itemsPerPage: 20,
      page: 1,
      pageCount: 0,
      job_components: [],
      isLoadingJobComponents: null, //this is the id of the part that is awaiting ajax
      deleteModal: false,
      isDeleting: false,
      isUpdating: false,
      deleteItem: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          width: 75,
        },
        { text: "Part Code", value: "component.part_code" },
        { text: "Description", value: "component.description", width: 250 },
        { text: "Manufacturer", value: "component.manufacturer" },
        { text: "Package", value: "component.package" },
        { text: "Marking Code", value: "component.marking_code" },
        { text: "Datasheet", value: "component.datasheet" },
        { text: "Location", value: "component.location" },
        { text: "Supplier", value: "component.company.name" },
        { text: "Order Code", value: "component.order_code" },
        { text: "Unit Price", value: "component.unit_price" },
        { text: "Qty In Stock", value: "component.qty_in_stock" },

        { text: "Quantity", value: "quantity", width: 110 },

        { text: "Actions", value: "actions", sortable: false, width: 100 },
      ],
      quantities_intermediate: {},
      quantities_changed: [],
      isUpdatingComponentQuantities: false,
      exampleRules: [
        (v) => (v && v <= 50000) || "Max should not be above £50,000",
      ],
    };
  },

  mounted() {
    this.isUpdating = true;

    this.$store.dispatch("fetchSuppliersList");
    this.CreateQuantitiesObj();
  },

  methods: {
    upsert(array, element) {
      // (1)
      const i = array.findIndex(
        (_element) => _element.component_id === element.component_id
      );
      if (i > -1) array[i] = element;
      // (2)
      else array.push(element);
    },
    jobComponentQuantityChanged(
      new_quantity,
      old_quantity,
      component_id,
      item
    ) {
      console.log(new_quantity);
      console.log(old_quantity);
      new_quantity = parseInt(new_quantity);
      if (isNaN(new_quantity)) new_quantity = 0;
      else if (
        new_quantity &&
        new_quantity > old_quantity + item.component.qty_in_stock
      )
        new_quantity = old_quantity + item.component.qty_in_stock;
      // this.quantities_intermediate[component_id] = new_quantity;
      let temp = this.quantities_intermediate;
      temp[component_id] = new_quantity;

      this.quantities_intermediate = {
        ...temp,
      };

      // unchanged: quantities_intermediate[item.component_id] == item.quantity,
      console.log(this.$refs[component_id + "-quantity"]);

      if (new_quantity != old_quantity) {
        console.log("changed-quantity-box");
        this.upsert(this.quantities_changed, {
          component_id,
          quantity: new_quantity,
        });
        // let exist = this.quantities_changed.find((obj) => obj.component_id == component_id);
        // if()
        // this.quantities_changed.push({
        //   component_id,
        //   quantity: new_quantity,
        // });
        // document
        //   .getElementById(component_id + "-quantity")
        //   .classList.add("changed-quantity-box");
      } else {
        console.log("unchanged");

        const indexOfObject = this.quantities_changed.findIndex((object) => {
          return object.component_id === component_id;
        });

        if (indexOfObject > -1)
          this.quantities_changed.splice(indexOfObject, 1);

        // document
        //   .getElementById(component_id + "-quantity")
        //   .classList.remove("changed-quantity-box");
      }
      // this.$refs[component_id + "-quantity"].$el.classList.add("changed-quantity-box");
    },

    saveQuantities() {
      let quantities_changed = this.quantities_changed;
      console.log(quantities_changed);

      if (!quantities_changed || !quantities_changed.length > 0 || !this.job.id)
        return;

      this.isUpdatingComponentQuantities = true;
      return axios
        .post(
          process.env.VUE_APP_API_PROTOCOL +
            "://" +
            process.env.VUE_APP_API_HOST +
            ":" +
            process.env.VUE_APP_API_PORT +
            "/api/job_components/update",
          { quantities: this.quantities_changed, job_id: this.job.id }
        )
        .then((response) => {
          console.log(response.data);
          console.log(this.refs_prop);
          let job = response.data;
          this.isUpdatingComponentQuantities = false;
          this.$store.commit("setCurrentSingleJob", {});
          this.$store.commit("setCurrentSingleJob", job);
          this.$store.commit("AddSnackbar", {
            message: "Updated",
          });
          this.quantities_changed = [];
          this.CreateQuantitiesObj();
          if (this.refs_prop && this.refs_prop.AddJobComponent)
            return this.refs_prop.AddJobComponent.SearchComponents().finally(
              () => {
                this.isUpdatingComponentQuantities = false;
              }
            );
          else this.isUpdatingComponentQuantities = false;
        })
        .catch((err) => {
          this.$store.commit("AddSnackbar", {
            type: "error",
            message: err.response.data.message,
          });
          console.log(err);
        })
        .finally(() => {
          this.isUpdatingComponentQuantities = false;
        });

      // this.$emit("addItem")
    },
    EditPart(part) {
      this.$store.commit("setSelectedJobPart", part);
      this.show = true;
    },
    DeleteItem() {
      var jobComponent = this.deleteItem;
      if (!jobComponent) return;
      console.log(this.$refs);

      console.log(jobComponent);
      this.isDeleting = true;
      this.$store
        .dispatch("DeleteJobComponent", jobComponent.id)
        .then(() => {
          this.$store.commit("RemoveJobComponentFromList", jobComponent.id);
          if (this.refs_prop && this.refs_prop.AddJobComponent)
            return this.refs_prop.AddJobComponent.SearchComponents().finally(
              () => {
                this.isDeleting = false;
                this.deleteModal = false;
                this.deleteItem = false;
              }
            );
          else {
            this.isDeleting = false;
            this.deleteModal = false;
            this.deleteItem = false;
          }
        })
        .finally(() => {
          this.isDeleting = false;
          this.deleteModal = false;
          this.deleteItem = false;
        });
    },

    CreateQuantitiesObj() {
      if (this.job.jobcomponents) {
        this.job.jobcomponents.forEach((comp) => {
          this.quantities_intermediate[comp.component_id.toString()] =
            comp.quantity;
        });
      }
    },
  },
  watch: {
    deleteModal(newValue, oldValue) {
      if (newValue == false && newValue != oldValue) this.deleteItem = "";
    },
    JobComponents: {
      handler: function () {
        this.CreateQuantitiesObj();
        this.quantities_changed = [];
      },
      deep: true,
    },
  },

  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    JobComponents() {
      return this.job.jobcomponents || [];
    },
  },
};
</script>

<style>
</style>