<template>
  <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12">
    <div class="table-cont mb-5">
      <div class="table-header pa-3 d-flex">
        <div class="table-property-label">Customer Information</div>
        <div
          style="background: rgba(0, 0, 0, 0.75)"
          class="header-role-chip ml-auto px-4 py-1 css-animate-blink"
          v-if="job.company.company_status_id == 2"
        >
          On stop
        </div>

        <span
          v-if="
            job &&
              job.company &&
              ((job.company.term_id && job.company.term_id == 1) ||
                (job.company.company_status_id && job.company.company_status_id == 5))
          "
          class="is_cash_only_badge py-1 px-2 d-flex"
        >
          PROFORMA
        </span>
        <span
          v-if="
            job &&
              job.company &&
              job.company.company_status_id &&
              job.company.company_status_id == 3
          "
          class="is_cash_only_badge py-1 px-2 d-flex"
          style="background: #e77b20;"
        >
          PO Required
        </span>
        <span
          v-if="
            job &&
              job.company &&
              job.company.company_status_id &&
              job.company.company_status_id == 4
          "
          class="is_cash_only_badge py-1 px-2 d-flex"
          style="background: #e77b20;"
        >
          Authorisation Required
        </span>
      </div>
      <div
        class="table-row long-text-row"
        v-if="job.company"
        :class="job.company && job.company.deleted_at ? 'error lighten-3' : ''"
      >
        <div class="table-property-label">
          Company{{ job.company && job.company.deleted_at ? ' - Deleted' : '' }}
        </div>
        <div class="table-property-value r-link" @click="gotoCompany()">
          {{ this.job.company ? this.job.company.name : '' }}
        </div>
      </div>
      <template v-if="job.contact">
        <div
          class="table-row long-text-row"
          :class="job.contact && job.contact.deleted_at ? 'error lighten-3' : ''"
        >
          <div class="table-property-label">
            Contact{{ job.contact && job.contact.deleted_at ? ' - Deleted' : '' }}
          </div>
          <div class="table-property-value r-link" @click="gotoContact()">
            {{ this.job.contact.name }}
          </div>
        </div>

        <div class="table-row">
          <div class="table-property-label">Phone</div>
          <div class="table-property-value">
            <a :href="'tel:' + this.job.contact.phone">{{ this.job.contact.phone }}</a>
          </div>
        </div>

        <div class="table-row">
          <div class="table-property-label">Mobile</div>
          <div class="table-property-value">
            <a :href="'tel:' + this.job.contact.mobile">{{ this.job.contact.mobile }}</a>
          </div>
        </div>

        <div class="table-row">
          <div class="table-property-label">Fax</div>
          <div class="table-property-value">
            {{ this.job.contact.fax }}
          </div>
        </div>

        <div class="table-row long-text-row">
          <div class="table-property-label">Email</div>
          <div class="table-property-value">
            <a :href="'mailto:' + this.job.contact.email">{{ this.job.contact.email }}</a>
          </div>
        </div>
      </template>
    </div>

    <JobMachineInfo />
  </v-col>
</template>

<script>
import JobMachineInfo from './JobMachineInfo';
export default {
  name: 'JobCompanyInfo',
  components: { JobMachineInfo },
  // props: ["job"],
  methods: {
    gotoContact() {
      if (!this.job || !this.job.contact_id) return;

      this.$router.push('/management/contacts/' + this.job.contact_id);
    },
    gotoCompany() {
      if (!this.job || !this.job.company_id) return;

      var company_type = 'customers';

      if (this.job.company && this.job.company.is_supplier == true) company_type = 'suppliers';
      this.$router.push('/management/' + company_type + '/' + this.job.company_id);
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
  },
};
</script>

<style scoped>
.is_cash_only_badge {
  background: #800382;
  color: white;
  font-size: 12px;
  border-radius: 3px;
  display: inline-flex !important;
  line-height: 1;
}
.css-animate-blink {
  animation: blink 2.25s linear infinite;
  animation-fill-mode: both;
  /* animation-timing-function: ease-in-out; */
}

@keyframes blink {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
</style>
