<template>
  <v-form ref="AdvanceToStage7" v-model="valid" @submit.prevent="Submit">
    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="3" class="mb-4 pa-6">
        <h4 class="mb-2">Order Number</h4>

        <v-text-field
          solo
          v-model="formFields.order_number"
          name="order_number"
        ></v-text-field>

        <h4 class="mb-2">Completed Date</h4>

        <v-text-field
          solo
          name="comDate"
          @click="show_datepicker()"
          readonly
          :value="formFields.comDate | YMD_to_DMY"
        ></v-text-field>

        <v-dialog
          ref="datepicker_dialog"
          v-model="datepicker_modal"
          persistent
          :return-value.sync="temp_date"
          width="290px"
        >
          <v-date-picker v-model="temp_date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="hide_datepicker()">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="save_datepicker()"> OK </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-btn
          :disabled="!valid || isSubmitting"
          class="success mr-0 mt-3 ml-auto d-flex"
          type="submit"
          :loading="valid && isSubmitting"
          >Advance Job</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "toStage7",

  data() {
    return {
      isUpdating: false,
      isSubmitting: false,
      datepicker_modal: false,
      temp_date: "",
      valid: false,
      formFields: {
        order_number: null,
        comDate: new Date().toISOString().substr(0, 10),
      },

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  methods: {
    show_datepicker() {
      this.datepicker_modal = true;
      this.temp_date = "";
    },
    hide_datepicker() {
      this.datepicker_modal = false;
      this.temp_date = "";
    },
    save_datepicker() {
      if (this.temp_date) {
        this.formFields.comDate = this.temp_date;
      }
      this.hide_datepicker();
    },
    Submit() {
      console.log("Advancing to stage 7");
      console.log(this.formFields);
      console.log(this.validate());
      this.isSubmitting = true;

      const payload = {
        job_id: this.job_id,
        data: this.formFields,
      };
      this.$store.dispatch("AdvanceToForDelivery", payload).finally(() => {
        this.snackbar = true;
        this.isSubmitting = false;

        this.$router.push("/job/" + this.job_id);
      });
    },
    reset_form() {},
    validate() {
      return this.$refs.AdvanceToStage7.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  mounted() {
    // this.formFields.order_number = this.job.order_number;
    const keys = Object.keys(this.formFields);
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      console.log(key);
      if (this.job[key] && this.job[key] != "")
        this.formFields[key] = this.job[key];
    }
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_id() {
      return this.$route.params.job_id;
    },
  },
};
</script>

<style>
</style>