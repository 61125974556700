<template>
  <v-form ref="AdvanceToStage4" v-model="valid" @submit.prevent="Submit">
    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="3" class="mb-4 pa-6">
        <h4 class="mb-2">Carriage Charge</h4>

        <v-text-field
          solo
          v-model.number="formFields.salCarriage"
          name="salCarriage"
          class="hide-arrows"
          prefix="£"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Packaging Cost</h4>

        <v-text-field
          solo
          v-model.number="formFields.salPackaging"
          name="salPackaging"
          class="hide-arrows"
          prefix="£"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Delivery Time</h4>

        <v-text-field
          solo
          v-model.number="formFields.salDeltime"
          name="salDeltime"
          class="hide-arrows"
          suffix="Days"
          :rules="[rules.required, rules.min]"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Sales Quote</h4>

        <v-text-field
          solo
          v-model.number="formFields.salQuote"
          name="salQuote"
          class="hide-arrows"
          prefix="£"
          :rules="[rules.required, rules.min]"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Sales Quote Total</h4>

        <v-text-field
          readonly
          disabled
          solo
          :value="this.SalesQuoteTotal"
          :rules="[rules.required, rules.min]"
          name="SalesQuoteTotal"
          class="hide-arrows"
          prefix="£"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Sales Notes</h4>

        <v-textarea
          solo
          v-model="formFields.salNotes"
          :rules="[rules.required]"
          name="salNotes"
        ></v-textarea>

        <!-- <h4 class="mb-2">Common Phrases</h4> -->
        <v-autocomplete
          chips
          rounded
          label="Type to search for a phrase"
          placeholder="Type to search for a phrase"
          solo
          :filter="customPhraseFilter"
          :items="PhraseList"
          small-chips
          item-text="name"
          item-value="phrase"
          :disabled="!PhraseList.length > 0"
          v-model="selected_phrase"
          :loading="isLoadingPhrases"
        >
          <!-- <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected">
              {{ data.item.name }}
            </v-chip>
          </template> -->

          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  TruncateAutocompleteString(data.item.phrase)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

        <h4 class="mb-2">Sales Quote Date</h4>

        <v-text-field
          solo
          name="salDate"
          @click="show_datepicker()"
          readonly
          :value="formFields.salDate | YMD_to_DMY"
        ></v-text-field>

        <v-dialog
          ref="datepicker_dialog"
          v-model="datepicker_modal"
          persistent
          :return-value.sync="temp_date"
          width="290px"
        >
          <v-date-picker v-model="temp_date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="hide_datepicker()"
              >Cancel</v-btn
            >
            <v-btn text color="primary" @click="save_datepicker()">OK</v-btn>
          </v-date-picker>
        </v-dialog>

        <v-checkbox
          v-model="send_quotation_email"
          label="Send a copy of the quotation to the customer's email?"
          color="success"
          class="mb-1"
          hide-details
        ></v-checkbox>

        <div style="display: flex; align-items: center; gap: 20px">
          <v-btn
            class="mb-0"
            color="primary"
            v-if="send_quotation_email"
            @click="show_cc_modal = true"
            >CC List</v-btn
          >

          <span v-if="cc_list && cc_list.length > 0"
            >{{ cc_list.length }} selected</span
          >
        </div>
        <EmailSalesQuotationModal
          v-if="this.checkPermission('can_email_sales_quotations')"
          v-model="show_cc_modal"
          @confirmed="setCCList"
        />

        <v-btn
          :disabled="!valid || isSubmitting || isEmailing"
          class="success mr-0 mt-3 ml-auto d-flex"
          type="submit"
          :loading="valid && (isSubmitting || isEmailing)"
          >Advance Job</v-btn
        >
      </v-col>

      <v-col cols="12" sm="3" class="mb-4 pa-6">
        <div class="table-cont">
          <div
            class="table-header pa-3"
            v-bind:class="{
              'normal-priority': job.priority_id <= 2,
              'high-priority': job.priority_id == 3,
              'emergency-priority': job.priority_id == 4,
            }"
          >
            <div class="table-property-label">N{{ this.job.id }}</div>
            <div class="table-property-value">Engineer Quote</div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Engineer Time Quote</div>
            <div
              class="table-property-value"
              style="text-transform: capitalize"
            >
              {{ this.job.engTimeQuote | toCurrency }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Parts Quote</div>
            <div
              class="table-property-value"
              style="text-transform: capitalize"
            >
              {{ this.job.engPartsQuote | toCurrency }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Components Quote</div>
            <div
              class="table-property-value"
              style="text-transform: capitalize"
            >
              {{ this.job.componentsTotalQuote | toCurrency }}
            </div>
          </div>

          <div class="table-row">
            <div class="table-property-label">Total</div>
            <div
              class="table-property-value"
              style="text-transform: capitalize"
            >
              {{
                ((this.job.engTimeQuote || 0) +
                  (this.job.engPartsQuote || 0) +
                  (this.job.componentsTotalQuote || 0))
                  | toCurrency
              }}
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="6" class="mb-4 pa-6">
        <div class="table-cont mb-8">
          <div class="table-header pa-3 primary darken-3">
            <div class="table-property-value">Job Information</div>
          </div>

          <div class="table-row d-flex flex-column">
            <div class="table-property-value mb-1">
              Engineer's Costing Notes
            </div>
            <div class="table-property-label">
              {{ this.job.engNotes || "Notes are empty..." }}
            </div>
          </div>
        </div>

        <v-simple-table
          v-if="
            previousSalesQuotes &&
            previousSalesQuotes.length > 0 &&
            !isFetchingPreviousSalesQuotes
          "
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Date</th>
                <th class="text-left">Job Number</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Model Number</th>
                <th class="text-left">Sales Quote</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="previousJob in previousSalesQuotes"
                :key="previousJob.id"
              >
                <td>{{ previousJob.date_booked | YMD_to_DMY }}</td>
                <td>{{ previousJob.id }}</td>
                <td>
                  {{ previousJob.company ? previousJob.company.name : "" }}
                </td>
                <td>{{ previousJob.machine_model }}</td>
                <td>{{ previousJob.salQuote | toCurrency }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div
          class="no-parts d-flex justify-center"
          v-else-if="isFetchingPreviousSalesQuotes"
        >
          Loading previous sales quotations for this machine model, please
          wait...
        </div>
        <div class="no-parts d-flex justify-center" v-else>
          No previous sales quotations found for this model.
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import EmailSalesQuotationModal from "../../modals/EmailSalesQuotationAdvanceStage4.vue";

export default {
  name: "toStage4",
  components: {
    EmailSalesQuotationModal,
  },

  data() {
    return {
      SalesQuoteTotal: 0,
      isUpdating: false,
      isFetchingPreviousSalesQuotes: true,
      isSubmitting: false,
      isLoadingContacts: false,
      isLoadingPhrases: false,
      datepicker_modal: false,
      send_quotation_email: true,
      show_cc_modal: false,
      isEmailing: false,
      cc_list: [],
      temp_date: "",
      valid: false,
      selected_phrase: null,
      formFields: {
        salCarriage: 10,
        salPackaging: null,
        salDeltime: 20, //delivery time in days
        salNotes: "",
        salQuote: 0,
        salDate: new Date().toISOString().substr(0, 10),
      },
      previousSalesQuotes: [],

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v >= 1 || "Min 1 day",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  methods: {
    setCCList(ccs) {
      console.log("cc list is:");
      console.log(ccs);
      this.cc_list = ccs;
      this.show_cc_modal = false;
    },
    show_datepicker() {
      this.datepicker_modal = true;
      this.temp_date = "";
    },
    hide_datepicker() {
      this.datepicker_modal = false;
      this.temp_date = "";
    },
    save_datepicker() {
      if (this.temp_date) {
        this.formFields.salDate = this.temp_date;
      }
      this.hide_datepicker();
    },
    Submit() {
      console.log("Advancing to stage 4");
      console.log(this.formFields);
      console.log(this.validate());
      this.isSubmitting = true;

      const payload = {
        job_id: this.job_id,
        data: this.formFields,
      };
      this.$store
        .dispatch("AdvanceToAwaitingAcceptance", payload)
        .then(() => {
          if (this.send_quotation_email) {
            this.isEmailing = true;
            return this.$store
              .dispatch("email_sales_quotation", {
                id: this.job_id,
                cc: this.cc_list,
              })
              .finally(() => {
                this.isEmailing = false;
              });
          } else return false;
        })
        .finally(() => {
          // this.snackbar = true;
          this.isSubmitting = false;

          this.$router.push("/job/" + this.job_id);
        });
    },
    reset_form() {},
    validate() {
      return this.$refs.AdvanceToStage4.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    CalcSalesQuoteTotal() {
      this.SalesQuoteTotal =
        (parseFloat(this.formFields.salCarriage) || 0) +
          (parseFloat(this.formFields.salPackaging) || 0) +
          (parseFloat(this.formFields.salQuote) || 0) || 0;
    },

    AppendTextToString(main_string, text_to_append) {
      let to_return = "";
      if (main_string && main_string.endsWith(" "))
        to_return = main_string + text_to_append;
      else to_return = main_string + " " + text_to_append;

      return to_return;
    },

    customPhraseFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.phrase.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    TruncateAutocompleteString(string) {
      if (string.length < 100) return string;
      var length = 100;
      let truncated_string = string.substring(0, length);
      if (truncated_string && truncated_string.endsWith(" "))
        truncated_string = truncated_string.substring(0, 99);

      truncated_string = truncated_string + "...";
      return truncated_string;
    },
  },
  mounted() {
    //fetch relevant previous sales quotes
    if (this.job.id) {
      this.isFetchingPreviousSalesQuotes = true;
      this.$store
        .dispatch("fetchRelevantJobsByModel", {
          machine_model: this.job.machine_model,
          current_job_id: this.job.id,
        })
        .then((responeData) => {
          this.previousSalesQuotes = responeData;
        })
        .finally(() => {
          this.isFetchingPreviousSalesQuotes = false;
        });
      this.isLoadingPhrases = true;
      this.$store.dispatch("fetchCommonPhrases").finally(() => {
        this.isLoadingPhrases = false;
      });
      this.CalcSalesQuoteTotal();
    }

    const keys = Object.keys(this.formFields);
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      console.log(key);
      if (this.job[key] && this.job[key] != "")
        this.formFields[key] = this.job[key];
    }
    this.formFields.salDeltime = this.JobDeliveryTime;

    for (let i = 0; i < this.OtherContacts.length; i++) {
      var cc_quotation = this.OtherContacts[i].include_cc_quotation;
      if (cc_quotation) this.cc_list.push(this.OtherContacts[i].email);
    }
    if (this.cc_list && this.cc_list.length > 0) console.log(this.cc_list);
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_id() {
      return this.$route.params.job_id;
    },
    SalesQuoteWacth() {
      return `${this.formFields.salCarriage}|${this.formFields.salPackaging}|${this.formFields.salQuote}`;
    },
    JobDeliveryTime() {
      var prio = this.$store.getters.getCurrentSingleJob.priority_id;
      if (prio <= 2) return 20;
      else if (prio == 3) return 5;
      else if (prio == 4) return 1;
      else return 20;
    },
    PhraseList() {
      return this.$store.getters.GetCommonPhrases || [];
    },
    OtherContacts() {
      if (this.job.company && this.job.company.contacts) {
        var contacts = [...this.job.company.contacts];

        var job_contact = null;

        if (this.job.contact && this.job.contact.id) {
          job_contact = this.job.contact.id;
          contacts.splice(
            contacts.findIndex((i) => {
              return i.id === job_contact;
            }),
            1
          );
        }

        return contacts;
      }
      return [];
    },
    jobComponentsCost() {
      if (!this.job.jobcomponents || !this.job.jobcomponents.length > 0)
        return 0;
      let cost = 0;
      for (let i = 0; i < this.job.jobcomponents.length; i++) {
        console.log(this.job.jobcomponents[i]);
        cost +=
          parseInt(this.job.jobcomponents[i].quantity) *
          parseFloat(this.job.jobcomponents[i].component.unit_price);
      }
      return Math.round((cost + Number.EPSILON) * 100) / 100;
    },
    jobComponentsTotal() {
      return (
        Math.round(
          (this.jobComponentsCost *
            (1 + parseFloat(this.job.componentsMarkup) / 100) +
            Number.EPSILON) *
            100
        ) / 100 || 0
      );
    },
  },
  watch: {
    SalesQuoteWacth() {
      this.CalcSalesQuoteTotal();
    },
    selected_phrase(new_val) {
      if (!new_val) return;
      // console.log(new_val);

      this.formFields.salNotes = this.AppendTextToString(
        this.formFields.salNotes,
        new_val
      );

      setTimeout(() => {
        this.selected_phrase = null;
      }, 500);
    },
  },
};
</script>

<style>
</style>