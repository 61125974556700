<template>
  <div class="d-flex flex-column job-report-col">
    <div class="job-report-images-header px-5 py-2">Signature</div>

    <div
      class="job-report-images-cont pa-6"
      v-if="this.signature"
      v-html="this.signature"
    ></div>

    <div class="job-report-images-cont pa-6" v-else>Not Signed</div>
  </div>
</template>

<script>
export default {
  name: "SignatureView",
  computed: {
    signature() {
      return this.$store.getters.getCurrentSingleJob.svg_signature;
    },
  },
};
</script>

<style scoped>
.job-report-images-header {
  background: #eee;
  color: #0a0a0a;
  font-weight: 300;
  letter-spacing: 0.5px;
  font-size: 18px;
}
.job-report-col {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ccc;
}
/* .job-report-images-cont {
} */
.job-report-img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 22px -12px;
}
</style>