<template>
  <CreateJobForm />
</template>

<script>
import CreateJobForm from "../../components/jobs/forms/CreateJobForm.vue";

export default {
  name: "CreateJob",
  components: {
    CreateJobForm,
  },
};
</script>

<style>
</style>