<template>
  <div class="d-flex flex-column job-report-col">
    <div class="job-report-images-header px-5 py-2">Photographs</div>

    <div class="job-report-images-cont pa-6">
      <div v-if="this.job_photos.length > 0">
        <img
          v-for="photo in this.job_photos"
          :key="'base-0-' + photo"
          :src="bases[0] + photo + '?rand=' + rand()"
          alt=""
          class="job-report-img"
        />

        <img
          v-for="photo in this.job_photos"
          :key="'base-1-' + photo"
          :src="bases[1] + photo + '?rand=' + rand()"
          alt=""
          class="job-report-img"
        />
      </div>

      <p v-if="this.job_photos && this.job_photos.length === 0">
        No images Found
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobReportImages",
  data() {
    return {
      bases: [
        //   "https://www.neutronic.biz/img/jobs/",
        process.env.VUE_APP_API_PROTOCOL +
          "://" +
          process.env.VUE_APP_API_HOST +
          ":" +
          process.env.VUE_APP_API_PORT +
          "/storage/images/",
      ],
    };
  },
  methods: {
    rand() {
      return Date.now();
    },
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_photos() {
      var job = this.$store.getters.getCurrentSingleJob;
      var photos = [];
      if (job.photo1) photos.push(job.photo1);
      if (job.photo2) photos.push(job.photo2);
      if (job.photo3) photos.push(job.photo3);
      if (job.photo4) photos.push(job.photo4);
      return photos;
    },
  },
};
</script>

<style scoped>
.job-report-images-header {
  background: #eee;
  color: #0a0a0a;
  font-weight: 300;
  letter-spacing: 0.5px;
  font-size: 18px;
}
.job-report-col {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ccc;
}
/* .job-report-images-cont {
} */
.job-report-img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 22px -12px;
}
</style>