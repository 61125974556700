<template>
  <div>
    <v-btn @click="EditUserModal()" color="warning"
      ><v-icon small class="mr-3">mdi-pen</v-icon> Edit</v-btn
    >

    <v-dialog
      ref="editUserDialog"
      v-model="editModal"
      :persistent="isSubmitting"
      width="400px"
    >
      <v-card>
        <v-form ref="EditUserForm" v-model="valid" @submit.prevent="Submit">
          <v-row class="pa-4 ma-0">
            <v-col cols="12">
              <h4 class="mb-2">Name</h4>

              <v-text-field
                solo
                v-model="formFields.name"
                name="name"
              ></v-text-field>

              <h4 class="mb-2">Username</h4>

              <v-text-field
                solo
                v-model="formFields.username"
                name="name"
              ></v-text-field>

              <h4 class="mb-2">Monthly Target</h4>

              <v-text-field
                solo
                v-model="formFields.monthly_target"
                name="monthly_target"
                type="number"
                prefix="£"
                @wheel="$event.target.blur()"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Email</h4>

              <v-text-field
                solo
                v-model="formFields.email"
                name="email"
              ></v-text-field>

              <h4 class="mb-2">Sage Ref</h4>

              <v-text-field
                solo
                v-model="formFields.sage_ref"
                name="sage_ref"
                type="number"
                @wheel="$event.target.blur()"
                class="hide-arrows"
              ></v-text-field>

              <v-autocomplete
                v-model="formFields.selectedRoles"
                :disabled="isUpdating"
                :items="rolesList"
                solo
                chips
                multiple
                color="blue-grey lighten-2"
                label="Select"
                item-text="name"
                item-value="id"
                hide-no-data
                :rules="[rules.required, rules.isArr, rules.ArrLength]"
                hide-selected
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>

                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <h4 class="mb-2">Password</h4>
              <v-text-field
                v-model="password"
                :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.passwordMin]"
                :type="showPass1 ? 'text' : 'password'"
                name="password"
                solo
                hint="At least 8 characters"
                counter
                @click:append="showPass1 = !showPass1"
              ></v-text-field>

              <h4 class="mb-2">Confirm Password</h4>
              <v-text-field
                v-model="confirm_password"
                :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.passwordMin, rules.confirmPasswordRules]"
                :type="showPass2 ? 'text' : 'password'"
                name="confim_password"
                filled
                hint="At least 8 characters"
                counter
                @click:append="showPass2 = !showPass2"
              ></v-text-field>

              <div class="mx-0 mt-3 d-flex justify-space-between">
                <v-btn
                  :disabled="isSubmitting"
                  color="error"
                  text
                  @click="editModal = false"
                  >Cancel</v-btn
                >

                <v-btn
                  :disabled="!valid || isSubmitting"
                  class="success"
                  type="submit"
                  :loading="isSubmitting"
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EditUserForm",
  data() {
    return {
      valid: false,
      isUpdating: false,
      editModal: false,
      rolesList: [],
      isSubmitting: false,
      confirm_password: "",
      showPass1: false,
      showPass2: false,
      password: "",
      formFields: {
        name: "",
        username: "",
        email: "",
        sage_ref: "",
        selectedRoles: [],
        monthly_target: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        isArr: (v) => Array.isArray(v) == true || "Wrong Value",
        ArrLength: (v) =>
          (Array.isArray(v) == true && v.length > 0) || "This can't be empty",
        emailMatch: () => `The email and password you entered don't match`,
        confirmPasswordRules: (v) =>
          v == this.password || "Passwords don't match",
        passwordMin: (v) =>
          v.length >= 8 || v.trim().length == 0 || "Min 8 characters",
      },
    };
  },
  methods: {
    CopyObject(object) {
      let copiedObj = {};
      for (let key in object) {
        copiedObj[key] = object[key];
      }
      return copiedObj;
    },
    CopyArray(array) {
      /* let copiedArr = [];
      for (let entry in array) {
        copiedArr.push(entry);
      }*/
      const copiedArr = [];
      array.forEach((item) => copiedArr.push(item.id));

      return copiedArr;
    },
    remove(item) {
      const index = this.formFields.selectedRoles.findIndex(
        (role_id) => role_id == item.id
      );
      if (index >= 0) this.formFields.selectedRoles.splice(index, 1);
    },
    EditUserModal() {
      this.editModal = true;
    },
    Submit() {
      if (this.validate()) {
        //this.formFields.user_id = this.CurrentSingleUser.id;
        this.isSubmitting = true;
        console.log("Editing User");
        let formsfields = { ...this.formFields };
        if (
          this.password.trim().length >= 8 &&
          this.password == this.confirm_password
        )
          formsfields["password"] = this.password;
        console.log(formsfields);
        console.log(this.validate());
        const payload = {
          user_id: this.CurrentSingleUser.id,
          data: formsfields,
        };
        this.$store
          .dispatch("UpdateSingleUser", payload)
          .then(() => {
            this.$store.commit("SetSnackbarData", {
              message: "User updated successfully.",
            });
            this.editModal = false;
          })
          .finally(() => {
            this.isSubmitting = false;
            this.password = "";
          });
      }
    },
    validate() {
      return this.$refs.EditUserForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  computed: {
    CurrentSingleUser() {
      return this.$store.getters.GetCurrentSingleUser;
    },
    companyList() {
      return this.$store.getters.getCompanyList;
    },
  },
  mounted() {
    this.isUpdating = true;
    this.$store
      .dispatch("fetchAllRoles")
      .then((roles) => {
        this.rolesList = roles;
      })
      .finally(() => {
        this.isUpdating = false;
      });

    this.formFields.name = this.CurrentSingleUser.name;
    this.formFields.email = this.CurrentSingleUser.email;
    this.formFields.username = this.CurrentSingleUser.username;
    this.formFields.sage_ref = this.CurrentSingleUser.sage_ref;
    this.formFields.monthly_target = this.CurrentSingleUser.monthly_target;

    this.formFields.selectedRoles = this.CopyArray(
      this.CurrentSingleUser.permissions
    );
  },
  watch: {
    password() {
      if (this.password.length >= 8 && this.confirm_password.length >= 8)
        this.validate();
    },
  },
};
</script>

<style>
</style>