<template>
  <v-row class="ma-0 pa-6">
    <div class="flex-grow-1 d-flex justify-space-between mb-6">
      <h1>Jobs Awaiting Parts</h1>
    </div>
    <v-data-table
      :headers="headers"
      :items="jobs"
      :items-per-page="-1"
      class="elevation-1"
      style="width: 100%"
      :loading="is_loading"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn :to="'/job/' + item.id" color="primary">View</v-btn>
      </template>

      <template v-slot:item.booked_date="{ item }">
        {{ item.appDate | YMD_to_DMY }}
      </template>

      <template v-slot:item.parts="{ item }">
        <div class="pa-4">
          <v-data-table
            :items="item.parts"
            class="elevation-1"
            :headers="Partheaders"
            hide-default-footer
          >
          </v-data-table>
        </div>
      </template>

      <!-- <template v-slot:body.append>
        <tr>
          <td colspan="5">Total: {{ CalculateJobsAssignedTotal }}</td>
        </tr>
      </template> -->
    </v-data-table></v-row
  >
</template>


<script>
export default {
  name: "Awaiting_Parts",
  data() {
    return {
      is_loading: false,
      IsFetchingPDF: false,
      headers: [
        {
          text: "Job",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Customer", value: "company.name" },
        { text: "Description", value: "description" },
        { text: "Actions", value: "actions" },
        { text: "Parts", value: "parts" },
      ],

      Partheaders: [
        {
          text: "Supplier",
          value: "company.name",
          sortable: false,
          width: "25%",
        },
        { text: "Part", value: "part_code", sortable: false, width: "25%" },
        { text: "Description", value: "part_description", sortable: false },
        { text: "Unit Cost", value: "unit_cost", sortable: false },
        { text: "Packs Cost", value: "packs_cost", sortable: false },
      ],
      jobs: [],
    };
  },

  computed: {},
  mounted() {
    this.is_loading = true;
    this.$store
      .dispatch("get_report_data_for_tables", "awaiting_parts")
      .then((res) => {
        this.jobs = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
};
</script>

<style>
</style>