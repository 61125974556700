<template>
  <v-tab-item class="pa-3">
    <v-row>
      <JobReportSummary />
      <v-col cols="12" md="9">
        <JobReportImages />
      </v-col>
    </v-row>
  </v-tab-item>
</template>

<script>
import JobReportSummary from "./JobReportSummary.vue";
import JobReportImages from "./JobReportImages.vue";
export default {
  name: "JobReportTab",
  components: {
    JobReportSummary,
    JobReportImages,
  },
};
</script>

<style>
</style>