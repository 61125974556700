<template>
  <v-app>
    <Menu v-model="drawer" @logout="logout()"></Menu>

    <v-app-bar
      app
      color="primary darken-3"
      dark
      :shrink-on-scroll="windowWidth < 600"
      :prominent="windowWidth < 600"
      :height="windowWidth < 600 ? '110' : '64'"
    >
      <div class="app-bar-cont" style="overflow: hidden; max-height: 100%">
        <div class="r-app-bar-row">
          <div class="d-flex align-center px-3">
            <v-btn @click.stop="drawer = !drawer" text>
              <v-icon>mdi-menu</v-icon> Menu
            </v-btn>
          </div>
          <!-- <Menu :drawer="drawer" @closed="drawer = false"></Menu> -->
          <v-spacer class="d-none d-sm-flex"></v-spacer>

          <div
            v-if="UserData && UserData.name"
            class="username d-none d-lg-flex"
          >
            {{ UserData.name }}
          </div>
          <div
            class="header-roles-container d-none d-lg-flex mr-8"
            v-if="UserData && UserData.roles"
          >
            <div
              class="header-role-chip mx-1 px-4 py-1 primary"
              v-for="role in UserData.roles"
              :key="role"
            >
              {{ role }}
            </div>
          </div>

          <v-spacer class="d-none d-sm-flex"></v-spacer>
          <MenuJobNumberNav />
          <v-spacer class="d-none d-sm-flex"></v-spacer>

          <v-spacer class="d-flex d-sm-none"></v-spacer>

          <v-btn
            class="d-flex d-sm-none"
            dark
            small
            color="success"
            to="/deliveries/"
            ><v-icon dark> mdi-truck </v-icon></v-btn
          >
          <v-spacer class="d-flex d-sm-none"></v-spacer>

          <!-- <v-img
            alt="Vuetify Logo"
            class="Logo shrink mr-2"
            contain
            src="@/assets/logo70h.jpg"
            transition="scale-transition"
            @click="gotoDashboard"
            style="cursor: pointer"
          /> -->
          <img
            src="@/assets/logo70h.jpg"
            transition="scale-transition"
            style="cursor: pointer"
            alt="Vuetify Logo"
            @click="gotoDashboard"
            class="Logo shrink mr-2"
          />
          <!-- <v-btn @click="logout" text class="d-none d-sm-flex">
        <v-icon>mdi-lock-open-outline</v-icon>
        <span class="ml-2">Logout</span>
      </v-btn> -->
        </div>
        <div class="r-app-bar-row mt-4" v-if="windowWidth < 600">
          <MenuJobNumberNav :extraClasses="'d-flex on-row-2 mx-auto px-4'" />
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <router-view v-if="isLoggedIn" :key="$route.fullPath"></router-view>

      <LoadingScreen v-else-if="isLoggingIn" />
      <login-form v-else> </login-form>
    </v-main>
    <AppSnackBar />
  </v-app>
</template>

<script>
// import HelloWorld from "./components/HelloWorld";
import LoginForm from "./components/LoginForm.vue";
import Menu from "./components/global/menu.vue";
import LoadingScreen from "./components/global/LoadingScreen";
import AppSnackBar from "./components/global/AppSnackBar";
import MenuJobNumberNav from "./components/global/MenuJobNumberNav.vue";

export default {
  name: "App",

  components: {
    // HelloWorld,
    LoginForm,
    Menu,
    LoadingScreen,
    AppSnackBar,
    MenuJobNumberNav,
  },

  data() {
    return {
      isLoggingIn: false,
      drawer: false,
    };
  },
  methods: {
    logout() {
      this.isLoggingIn = true;
      this.$store.dispatch("logout").then(() => {
        this.isLoggingIn = false;
      });
    },
    gotoDashboard() {
      this.$router.push("/dashboard");
    },
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    UserData() {
      return this.$store.getters.getCurrentUserObj;
    },
    windowWidth() {
      return this.$store.getters.getWindowWidth;
    },
  },
  mounted() {
    let $vm = this;
    $vm.isLoggingIn = true;
    $vm.$store
      .dispatch("autoSignIn")
      .then(() => {
        $vm.isLoggingIn = false;
      })
      .catch(function (err) {
        $vm.isLoggingIn = false;
        console.log(err);
      });

    window.onresize = () => {
      this.$store.commit("setWindowWidth", window.innerWidth);
    };
  },
};
</script>

<style>
@import "./assets/styles.css";

.app-bar-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.r-app-bar-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.Logo {
  height: 50px;
}
@media only screen and (max-width: 600px) {
  .Logo {
    height: auto;
    width: 100px;
    margin-left: auto;
  }
}
</style>
