<template>
  <v-dialog v-model="show" width="1600" :persistent="this.is_loading">
    <v-container class="pa-0">
      <v-card class="pa-6">
        <AddJobComponent ref="AddJobComponent" v-if="this.show" />
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import AddJobComponent from "../job_components/AddJobComponent";
export default {
  name: "AddComponentModal",
  components: {
    AddJobComponent,
  },
  props: {
    value: Boolean,
    bin: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      new_bin: "",
      is_loading: false,
    };
  },
  methods: {
    save_bin() {
      this.is_loading = true;
    },
    closeModal() {
      this.show = false;
    },
  },
};
</script>

<style>
</style>