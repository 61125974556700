<template>
  <div>
    <div
      class="d-flex justify-space-between align-end mb-8"
      ref="top_roles_table"
    >
      <div class="d-flex flex-column">
        <h4 class="mb-2">Search</h4>
        <v-text-field
          v-model="filter"
          solo
          style="max-width: 400px"
          hide-details
          placeholder="Type to search..."
          clearable
          @click:clear="filter = ''"
          :disabled="!this.PhraseList.length > 0"
        ></v-text-field>
      </div>

      <!-- <v-btn
        color="success"
        @click="AddItem()"
        v-if="this.checkPermission('can_add_roles')"
        >Add Role</v-btn
      > -->
      <AddPhraseForm @closeModal="CloseModal()" />
    </div>

    <v-data-table
      :headers="headers"
      :items="filtered_contact_list"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      calculate-widths
      hide-default-footer
      :loading="this.isFetching"
      loading-text="Loading... Please wait"
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="EditItem(item)"> mdi-pen </v-icon>
        <v-icon
          small
          @click="
            deleteModal = true;
            deleteItem = item;
          "
          v-if="checkPermission('can_delete_roles')"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
      ></v-pagination>
    </div>
    <EditPhraseForm
      v-model="editFormVisible"
      :currentEditItem="CurrentCommonPhrase"
      @closeModal="CloseModal()"
    />

    <ConfirmationDialog
      v-model="deleteModal"
      itemName="phrase"
      @cancelled="
        deleteModal = false;
        deleteItem = null;
      "
      @confirmed="DeleteItem()"
      :loader="isDeleting"
    />
  </div>
</template>

<script>
import AddPhraseForm from "./AddPhraseForm";
import EditPhraseForm from "./EditPhraseForm";
import ConfirmationDialog from "../../global/ConfirmationDialog.vue";

export default {
  name: "RolesTable",
  components: {
    AddPhraseForm,
    EditPhraseForm,
    ConfirmationDialog,
  },
  data() {
    return {
      isFetching: false,
      page: 1,
      pageCount: 0,
      filter: "",
      editFormVisible: false,
      addFormVisible: false,
      isDeleting: false,
      deleteItem: null,
      deleteModal: false,
      editFormVisibleTab: null,
      itemsPerPage: 20,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          width: 75,
        },
        { text: "Name", value: "name", width: 150 },
        { text: "Phrase", value: "phrase", width: 300 },
        { text: "Actions", value: "actions", sortable: false, width: 100 },
      ],
    };
  },
  methods: {
    scrollToElement() {
      const el = this.$refs.top_roles_table.$el;
      if (el) {
        console.log(el);
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    CloseModal() {
      this.editFormVisible = false;
      this.addFormVisible = false;
      this.editFormVisibleTab = null;
      this.$store.commit("SetCurrentPhrase", null);
      this.scrollToElement();
    },
    EditItem(role) {
      console.log(role);
      this.$store.commit("SetCurrentPhrase", role);
      this.editFormVisible = true;
      this.addFormVisible = false;

      this.editFormVisibleTab = 0;
    },
    AddItem() {
      this.addFormVisible = true;
      this.editFormVisible = false;

      this.editFormVisibleTab = 0;
    },
    DeleteItem() {
      // this.$store.dispatch("DeleteSingleUser", user.id);
      var phrase = this.deleteItem;
      if (!phrase) return;
      console.log(phrase);
      this.isDeleting = true;
      this.deleteItem = phrase;

      this.$store
        .dispatch("DeleteCommonPhrase", phrase.id)
        .then(() => {
          // this.$store.commit("RemoveDepartmentFromList", phrase.id);
          this.$store.commit("SetSnackbarData", {
            message: "Phrase removed successfully.",
          });
        })
        .finally(() => {
          this.isDeleting = false;
          this.deleteModal = false;
          this.deleteItem = false;
        });
    },
  },
  mounted() {
    this.isFetching = true;
    this.$store.dispatch("fetchCommonPhrases").then(() => {
      this.isFetching = false;
    });
  },
  computed: {
    CurrentCommonPhrase() {
      return this.$store.getters.GetCurrentCommonPhrase;
    },
    PhraseList() {
      return this.$store.getters.GetCommonPhrases;
    },
    filtered_contact_list() {
      var filter = "";
      if (this.filter) filter = this.filter.trim();

      if (!filter.length > 0) return this.PhraseList;
      return this.PhraseList.filter((role) => {
        return (
          role["id"].toString().includes(filter) ||
          role["name"].toString().toLowerCase().includes(filter.toLowerCase())
        );
      });
    },
  },
};
</script>

<style scoped>
.header-role-chip {
  background: #1976d2;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.9s ease;
}
.slide-fade-leave-active {
  transition: all 0.9s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(30px);
  opacity: 0;
}
</style>