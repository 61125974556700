<template>
  <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12">
    <div class="table-cont">
      <div
        class="table-header pa-3"
        v-bind:class="{
          'normal-priority': job.priority_id <= 2,
          'high-priority': job.priority_id == 3,
          'emergency-priority': job.priority_id == 4,
        }"
      >
        <div class="table-property-label">N{{ this.job.id }}</div>
        <div class="table-property-value d-flex justify-end">
          <!-- Active? -->

          <div
            class="header-roles-container d-flex"
            v-if="this.job.bBer || this.job.bRnr"
          >
            <div
              class="header-role-chip mx-1 px-4 py-1"
              style="background: rgba(0, 0, 0, 0.5)"
              v-if="this.job.bBer"
            >
              BER
            </div>
            <div class="header-role-chip mx-1 px-4 py-1" v-if="this.job.bRnr">
              RNR
            </div>
          </div>
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Priority</div>
        <div class="table-property-value" style="text-transform: capitalize">
          {{ this.priority }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Order Number</div>
        <div class="table-property-value">{{ this.job.order_number }}</div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Customer Reference</div>
        <div class="table-property-value">
          {{ this.job.customer_reference }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Engineer</div>
        <div class="table-property-value">
          {{ this.engineer_name }}
          <!-- {{ this.job.user_id || "-None Assigned-" }} -->
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Projected Hours</div>
        <div class="table-property-value">
          {{ this.job.engTime ? this.job.engTime + " hours" : "-" }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Department</div>
        <div class="table-property-value">
          {{
            this.job.tag != null && this.job.tag.name != ""
              ? this.job.tag.name
              : ""
          }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Bin</div>
        <div class="table-property-value d-flex">
          {{ this.job.bin }}
          <v-btn
            style="margin-left: auto"
            small
            fab
            v-if="this.checkPermission('can_edit_bin')"
            icon
            color="green"
            @click.stop="EditBinDialog = true"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
        </div>
      </div>

      <div class="table-row" v-if="this.job.date_booked">
        <div class="table-property-label">Date Booked</div>
        <div class="table-property-value">
          {{ this.job.date_booked | YMD_to_DMY }}
        </div>
      </div>

      <div class="table-row" v-if="this.job.engDate">
        <div class="table-property-label">Engineers Quote Date</div>
        <div class="table-property-value">
          {{ this.job.engDate | YMD_to_DMY }}
        </div>
      </div>

      <div class="table-row" v-if="this.job.salDate">
        <div class="table-property-label">Sales Quote Date</div>
        <div class="table-property-value">
          {{ this.job.salDate | YMD_to_DMY }}
        </div>
      </div>

      <div class="table-row" v-if="this.job.appDate">
        <div class="table-property-label">Approval Date</div>
        <div class="table-property-value">
          {{ this.job.appDate | YMD_to_DMY }}
        </div>
      </div>

      <div class="table-row" v-if="this.job.staDate">
        <div class="table-property-label">Start Date</div>
        <div class="table-property-value">
          {{ this.job.staDate | YMD_to_DMY }}
        </div>
      </div>

      <div class="table-row" v-if="this.job.comDate">
        <div class="table-property-label">Completed Date</div>
        <div class="table-property-value">
          {{ this.job.comDate | YMD_to_DMY }}
        </div>
      </div>

      <div class="table-row" v-if="this.job.delDate">
        <div class="table-property-label">Delivery Date</div>
        <div class="table-property-value">
          {{ this.job.delDate | YMD_to_DMY }}
        </div>
      </div>

      <div class="table-row" v-if="this.job.invDate">
        <div class="table-property-label">Invoiced Date</div>
        <div class="table-property-value">
          {{ this.job.invDate | YMD_to_DMY }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Shipment Ref</div>
        <div class="table-property-value">{{ this.job.shipment_ref }}</div>
      </div>
    </div>
    <EditBinModal
      v-model="EditBinDialog"
      v-if="this.checkPermission('can_edit_bin')"
      :bin="this.job.bin"
      :job_id="this.job.id"
    />
  </v-col>
</template>

<script>
import EditBinModal from "./EditBinModal";
export default {
  name: "JobBasicInfo",
  // props: ["job"],
  data() {
    return {
      EditBinDialog: false,
    };
  },
  components: {
    EditBinModal,
  },
  methods: {
    editJobBin() {},
  },
  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    priority() {
      if (this.job.priority_id)
        return this.$store.getters.get_priority_list[
          this.job.priority_id.toString()
        ];

      return "";
    },
    engineer_name() {
      const id = this.job.user_id;
      if (!id) return "-None Assigned-";
      else {
        var name = null;
        if (this.job.user) name = this.job.user.name;

        if (!name) return id;
        else return name;
      }
    },
  },
};
</script>

<style>
</style>