import Axios from "axios"

const state = {
  CurrentSingleRole: null,
  roleList: [],
}
const mutations = {
  SetCurrentSingleRole(state, payload) {
    state.CurrentSingleRole = payload
  },
  SetRoleList(state, payload) {
    state.roleList = payload;
  },

  UpdateRoleItem(state, payload) {
    const index = state.roleList.findIndex(role => role.id == payload.id);
    if (index >= 0)
      state.roleList.splice(index, 1, payload);
  }

}
const actions = {




  getAllRoles(context) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/roles"
      ).then((response) => {
        console.log(response.data);
        context.commit("SetRoleList", response.data)
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },


  fetchSingleUser(context, payload) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/users/" + payload
      ).then((response) => {
        console.log(response.data);
        context.commit("SetCurrentSingleRole", response.data)
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },


  fetchAllRoles(context) {
    console.log("getUsersByTag store action with payload");
    var token = context.getters.getCurrentToken;
    console.log("token: " + token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT +
        "/api/roles"
      ).then((response) => {
        console.log(response.data);
        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },



  UpdateSingleRole(context, payload) {
    console.log("updating single user");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/roles/" + payload.role_id, payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        context.commit("UpdateRoleItem", response.data);
        // context.commit("SetCurrentSingleRole", response.data);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },


  AddSingleRole(context, payload) {
    console.log("updating single user");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/roles", payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        // context.commit("SetCurrentSingleUser", response.data);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },

}
const getters = {
  GetRoleList(state) {
    return state.roleList;
  },
  GetCurrentSingleRole(state) {
    return state.CurrentSingleRole;
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}