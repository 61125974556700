import Axios from "axios"

const state = {
  CurrentSingleCompany: null,
  Terms: []
}
const mutations = {
  SetCurrentSingleCompany(state, payload) {
    state.CurrentSingleCompany = payload;
  },
  RemoveContactFromSingleCompany(state, payload) {
    //used for contacts table on single company pages.
    if (state.CurrentSingleCompany && state.CurrentSingleCompany.contacts && state.CurrentSingleCompany.contacts.length > 0)
      state.CurrentSingleCompany.contacts.splice(state.CurrentSingleCompany.contacts.findIndex(function (contact) {
        return contact.id === payload;
      }), 1);
  },
  SetTerms(state, payload) {
    state.Terms = payload;
  }
}
const actions = {


  fetchTerms(context) {

    console.log("fetching single contact now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/terms",
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        var payload = {
          token: token,
          data: response.data
        }

        console.log(payload);

        context.commit("SetTerms", response.data);
        return response;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });
  },



  fetchCompanyStatuses(context) {
    console.log("fetching single contact now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/company_statuses",
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        return response.data;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },

  fetchSingleCompany(context, payload) {
    console.log("fetching single contact now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/companies/" + payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        var payload = {
          token: token,
          data: response.data
        }

        console.log(payload);

        context.commit("SetCurrentSingleCompany", response.data);
        context.dispatch("fetchTerms");
        return response;
      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },


  UpdateSingleCompany(context, payload) {
    console.log("updating single Company");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/companies/" + payload.company_id, payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        context.commit("SetCurrentSingleCompany", response.data);
        return response;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },



  AddSingleCompany(context, payload) {
    console.log("updating single Company");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/companies", payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        // context.commit("SetCurrentSingleContact", response.data);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },



  DeleteSingleCompany(context, payload) {
    console.log("updating single contact");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/companies/" + payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        // context.commit("SetCurrentSingleContact", response.data);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },
}


const getters = {
  GetCurrentSingleCompany(state) {
    return state.CurrentSingleCompany
  },
  GetTerms(state) {
    return state.Terms;
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}