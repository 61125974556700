<template>
  <div class="d-flex flex-column pa-4 ma-0">
    <div class="d-flex justify-space-between mb-6">
      <h3>Departments</h3>
      <AddDepartmentForm v-if="this.checkPermission('can_add_departments')" />
      <EditDepartmentForm
        v-if="this.checkPermission('can_edit_departments')"
        v-model="editDepModal"
        :currentEditItem="currentEditItem"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="Departments"
      :items-per-page="-1"
      calculate-widths
      hide-default-footer
      :loading="!this.Departments.length > 0"
      loading-text="Loading... Please wait"
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="EditItem(item)"> mdi-pencil </v-icon>
        <v-icon
          small
          v-if="checkPermission('can_delete_departments')"
          @click="
            deleteModal = true;
            deleteItem = item;
          "
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <!-- <RolesTable /> -->

    <ConfirmationDialog
      v-model="deleteModal"
      itemName="department"
      @cancelled="deleteModal = false"
      @confirmed="DeleteItem()"
      :loader="isDeleting"
    />
  </div>
</template>

<script>
import AddDepartmentForm from "../../components/management/departments/AddDepartmentForm.vue";
import EditDepartmentForm from "../../components/management/departments/EditDepartmentForm.vue";
import ConfirmationDialog from "../../components/global/ConfirmationDialog.vue";

export default {
  name: "Departments",
  components: {
    AddDepartmentForm,
    EditDepartmentForm,
    ConfirmationDialog,
  },
  data() {
    return {
      editDepModal: false,
      deleteModal: false,
      isDeleting: false,
      currentEditItem: null,
      deleteItem: null,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          width: 75,
        },
        { text: "Name", value: "name", width: 200 },
        { text: "Nominal Code", value: "nominal_code", width: 150 },
        { text: "Actions", value: "actions", width: 150 },
      ],
    };
  },
  methods: {
    EditItem(item) {
      console.log(item);
      this.currentEditItem = item;
      this.editDepModal = true;
    },
    DeleteItem() {
      var department = this.deleteItem;
      if (!department) return;
      console.log(department);
      this.isDeleting = true;
      this.$store
        .dispatch("DeleteSingleDepartment", department.id)
        .then(() => {
          this.$store.commit("RemoveDepartmentFromList", department.id);
          this.$store.commit("SetSnackbarData", {
            message: "Department removed successfully.",
          });
        })
        .finally(() => {
          this.isDeleting = false;
          this.deleteModal = false;
          this.deleteItem = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch("fetchDepartments");
  },
  computed: {
    Departments() {
      return this.$store.getters.getDepartmentList;
    },
  },
};
</script>

<style>
</style>