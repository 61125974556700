<template>
  <div>
    <v-btn color="success" @click="ChanePassModal = true"
      >Change Password</v-btn
    >
    <v-dialog
      ref="settingsChangePasswordDialog"
      v-model="ChanePassModal"
      :persistent="isSubmitting"
      width="400px"
    >
      <v-card>
        <v-form
          ref="settingsChangePasswordForm"
          class="pa-4"
          v-model="valid"
          @submit.prevent="Submit"
        >
          <h4 class="mb-2">Current Password</h4>
          <v-text-field
            solo
            v-model="formFields.current_password"
            type="password"
            :rules="[rules.required, rules.min]"
          ></v-text-field>

          <h4 class="mb-2">New Password</h4>
          <v-text-field
            solo
            v-model="formFields.new_password"
            :rules="[rules.required, rules.min]"
            type="password"
          ></v-text-field>

          <h4 class="mb-2">Confirm New Password</h4>
          <v-text-field
            solo
            v-model="formFields.confirm_new_password"
            type="password"
            :rules="[rules.confirmPasswordRules, rules.required, rules.min]"
          ></v-text-field>

          <v-btn
            :disabled="!valid || isSubmitting"
            class="success"
            type="submit"
            :loading="isSubmitting"
            >Save</v-btn
          >
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "SettingsChangePassword",
  data() {
    return {
      ChanePassModal: false,
      isSubmitting: false,
      formFields: {
        current_password: "",
        new_password: "",
        confirm_new_password: "",
      },
      valid: false,
      rules: {
        confirmPasswordRules: (v) =>
          v == this.formFields.new_password || "Passwords don't match",
        newPasswordRules: (v) =>
          v == this.formFields.confirm_new_password || "Passwords don't match",
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    Submit() {
      if (this.validate()) {
        this.isSubmitting = true;
        console.log("Editing Contact");
        console.log(this.formFields);
        console.log(this.validate());

        this.$store
          .dispatch("update_loggedin_user_password", this.formFields)
          .then(() => {
            this.$store.commit("SetSnackbarData", {
              message: "User details updated successfully.",
            });
          })

          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.settingsChangePasswordForm.validate();
    },
  },
  watch: {
    formFields: {
      handler: function () {
        console.log("z");
        this.validate();
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>