const state = {
  windowWidth: window.innerWidth
}
const mutations = {
  setWindowWidth(state, payload) {
    state.windowWidth = payload;
  }
}
const actions = {

}
const getters = {
  getWindowWidth(state) {
    return state.windowWidth;
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}