<template>
  <div>
    <v-btn @click="EditContactModal()" color="success" large>Edit</v-btn>

    <v-dialog
      ref="editContactDialog"
      v-model="editModal"
      :persistent="isSubmitting"
      width="400px"
    >
      <v-card>
        <v-form ref="EditContactForm" v-model="valid" @submit.prevent="Submit">
          <v-row class="pa-4 ma-0">
            <v-col cols="12">
              <h4 class="mb-2">Company</h4>
              <v-autocomplete
                v-model="formFields.company_id"
                :disabled="isUpdating"
                :items="companyList"
                solo
                color="blue-grey lighten-2"
                label="Select"
                item-text="name"
                item-value="id"
                hide-no-data
                :rules="[rules.required, rules.isNaN]"
                hide-selected
              >
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <h4 class="mb-2">Name</h4>

              <v-text-field
                solo
                v-model="formFields.name"
                name="name"
              ></v-text-field>

              <h4 class="mb-2">Email</h4>

              <v-text-field
                solo
                v-model="formFields.email"
                name="email"
              ></v-text-field>

              <h4 class="mb-2">Phone</h4>

              <v-text-field
                solo
                v-model="formFields.phone"
                name="phone"
                type="text"
                v-on:keypress="isNotLetter($event)"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Fax</h4>

              <v-text-field
                solo
                v-model="formFields.fax"
                name="fax"
                type="text"
                v-on:keypress="isNotLetter($event)"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Mobile</h4>

              <v-text-field
                solo
                v-model="formFields.mobile"
                name="mobile"
                type="text"
                v-on:keypress="isNotLetter($event)"
                class="hide-arrows"
              ></v-text-field>

              <h4 class="mb-2">Include CC Quotation</h4>

              <v-simple-checkbox
                v-model="formFields.include_cc_quotation"
                :ripple="false"
              ></v-simple-checkbox>

              <h4 class="mb-2">Include CC Delivery</h4>

              <v-simple-checkbox
                v-model="formFields.include_cc_delivery"
                :ripple="false"
              ></v-simple-checkbox>

              <h4 class="mb-2">Notes</h4>

              <v-textarea
                solo
                v-model="formFields.notes"
                name="notes"
              ></v-textarea>
              <div class="mx-0 mt-3 d-flex justify-space-between">
                <v-btn
                  :disabled="isSubmitting"
                  color="error"
                  text
                  @click="editModal = false"
                  >Cancel</v-btn
                >

                <v-btn
                  :disabled="!valid || isSubmitting"
                  class="success"
                  type="submit"
                  :loading="isSubmitting"
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EditContactForm",
  data() {
    return {
      valid: false,
      isUpdating: false,
      editModal: false,

      isSubmitting: false,
      formFields: {
        name: "",
        company_id: "",
        email: "",
        phone: "",
        fax: "",
        mobile: "",
        include_cc_quotation: null,
        include_cc_delivery: null,
        notes: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        isNaN: (v) => this.isNumeric(v) == true || "Wrong Value",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  methods: {
    EditContactModal() {
      this.editModal = true;
    },
    Submit() {
      if (this.validate()) {
        this.formFields.contact_id = this.CurrentSingleContact.id;
        this.isSubmitting = true;
        console.log("Editing Contact");
        console.log(this.formFields);
        console.log(this.validate());
        const payload = {
          contact_id: this.CurrentSingleContact.id,
          data: this.formFields,
        };
        this.$store
          .dispatch("UpdateSingleContact", payload)
          .then(() => {
            this.$store.commit("SetSnackbarData", {
              message: "Contact updated successfully.",
            });
            this.editModal = false;
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    validate() {
      return this.$refs.EditContactForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isNotLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      var input = e.target;
      var val = input.value;
      var end = input.selectionEnd;

      if (e.keyCode == 32 && (val[end - 1] == " " || val[end] == " ")) {
        e.preventDefault();
        return false;
      } else if (/^[+0-9 ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },
  computed: {
    CurrentSingleContact() {
      return this.$store.getters.GetCurrentSingleContact;
    },
    companyList() {
      return this.$store.getters.getCompanyList;
    },
  },
  mounted() {
    this.isUpdating = true;
    this.$store.dispatch("fetchCompanyList").finally(() => {
      this.isUpdating = false;
    });

    this.formFields.name = this.CurrentSingleContact.name;
    this.formFields.email = this.CurrentSingleContact.email;
    this.formFields.phone = this.CurrentSingleContact.phone;
    this.formFields.fax = this.CurrentSingleContact.fax;
    this.formFields.mobile = this.CurrentSingleContact.mobile;
    this.formFields.include_cc_quotation =
      this.CurrentSingleContact.include_cc_quotation || false;
    this.formFields.include_cc_delivery =
      this.CurrentSingleContact.include_cc_delivery || false;
    this.formFields.notes = this.CurrentSingleContact.notes;
    this.formFields.company_id = this.CurrentSingleContact.company_id;
  },
};
</script>

<style>
</style>