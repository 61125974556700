import Axios from "axios"

const state = {
  CurrentSingleContact: null,
}
const mutations = {
  SetCurrentSingleContact(state, payload) {
    state.CurrentSingleContact = payload;
  }
}
const actions = {


  fetchSingleContact(context, payload) {
    console.log("fetching single contact now");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/contacts/" + payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);
        var payload = {
          token: token,
          data: response.data
        }

        console.log(payload);

        context.commit("SetCurrentSingleContact", response.data);


      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },


  UpdateSingleContact(context, payload) {
    console.log("updating single contact");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/contacts/" + payload.contact_id, payload.data,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        context.commit("SetCurrentSingleContact", response.data);
        return response;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },



  AddSingleContact(context, payload) {
    console.log("updating single contact");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.post(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/contacts", payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        // context.commit("SetCurrentSingleContact", response.data);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },



  DeleteSingleContact(context, payload) {
    console.log("updating single contact");
    var token = localStorage.getItem('neutToken');
    console.log(token);

    if (token != "")
      return Axios.delete(
        process.env.VUE_APP_API_PROTOCOL +
        "://" +
        process.env.VUE_APP_API_HOST +
        ":" +
        process.env.VUE_APP_API_PORT + "/api/contacts/" + payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      ).then(function (response) {
        console.log(response);

        // context.commit("SetCurrentSingleContact", response.data);
        return response.data;

      })
        .catch(function (error) {
          console.log(error.response.data.message);
          context.commit("SetSnackbarData", {
            message: error.response.data.message,
            type: "error",
          });

          return Promise.reject(error);
        });

  },
}


const getters = {
  GetCurrentSingleContact(state) {
    return state.CurrentSingleContact
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}