<template>
  <div>
    <ConfirmationDialog
      v-model="deleteModal"
      @cancelled="deleteModal = false"
      @confirmed="DeleteItem()"
      :loader="isDeleting"
      :itemName="'user'"
    />

    <div class="d-flex justify-space-between align-end mb-8">
      <div class="d-flex flex-column">
        <h4 class="mb-2">Search</h4>
        <v-text-field
          v-model="filter"
          solo
          style="max-width: 400px"
          hide-details
          placeholder="Type to search..."
          clearable
          @click:clear="filter = ''"
          :disabled="!this.UserList.length > 0"
        ></v-text-field>
      </div>

      <AddUserForm v-if="this.checkPermission('can_add_users')" />
    </div>

    <v-data-table
      :headers="headers"
      :items="filtered_contact_list"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      calculate-widths
      hide-default-footer
      :loading="!this.UserList.length > 0"
      loading-text="Loading... Please wait"
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <!-- <template v-slot:item.include_cc_quotation="{ item }">
        <v-simple-checkbox
          v-model="item.include_cc_quotation"
          disabled
        ></v-simple-checkbox>
      </template>

      <template v-slot:item.include_cc_delivery="{ item }">
        <v-simple-checkbox
          v-model="item.include_cc_delivery"
          disabled
        ></v-simple-checkbox>
      </template> -->

      <template v-slot:item.permissions="{ item }">
        <div
          class="d-flex align-center"
          v-if="item.permissions && item.permissions.length > 0"
        >
          <span
            v-for="(permission, index) in item.permissions"
            :key="item.id - index"
            class="header-role-chip mx-1 px-4 py-1"
          >
            {{ permission.name }}
          </span>
        </div>
        <span v-else> No Roles Assigned</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="VisitItem(item)"> mdi-eye </v-icon>
        <v-icon
          small
          @click="
            deleteModal = true;
            deleteItem = item;
          "
          v-if="checkPermission('can_delete_users')"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import AddUserForm from "./AddUserForm.vue";
import ConfirmationDialog from "../../global/ConfirmationDialog.vue";

export default {
  name: "ContactsTable",
  components: {
    AddUserForm,
    ConfirmationDialog,
  },
  data() {
    return {
      deleteModal: false,
      deleteItem: "",
      isDeleting: false,
      page: 1,
      pageCount: 0,
      filter: "",
      itemsPerPage: 20,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          width: 75,
        },
        { text: "Name", value: "name", width: 200 },
        { text: "Username", value: "username", width: 300 },
        // { text: "Phone", value: "phone", width: 150 },
        // { text: "Fax", value: "fax", width: 150 },
        // { text: "Mobile", value: "mobile", width: 150 },
        { text: "Email", value: "email", width: 325 },
        { text: "Roles", value: "permissions", width: 325 },

        // { text: "CC Quotation", value: "include_cc_quotation", width: 125 },
        // { text: "CC Delivery", value: "include_cc_delivery", width: 125 },
        { text: "Actions", value: "actions", sortable: false, width: 100 },
        // { text: "Notes", value: "notes", width: 200 },
      ],
    };
  },
  methods: {
    VisitItem(user) {
      console.log(user);
      this.$store.commit("SetCurrentSingleUser", user);
      this.$router.push("/management/users/" + user.id);
    },
    DeleteItem() {
      var user = this.deleteItem;
      if (!user) return;
      console.log(user);
      this.isDeleting = true;

      this.$store
        .dispatch("DeleteSingleUser", user.id)
        .then(() => {
          this.$store.commit("RemoveUserFromList", user.id);
        })
        .finally(() => {
          this.isDeleting = false;
          this.deleteModal = false;
          this.deleteItem = false;
        });
    },
  },

  computed: {
    UserList() {
      return this.$store.getters.GetUserList;
    },
    filtered_contact_list() {
      var filter = "";
      if (this.filter) filter = this.filter.trim();

      if (!filter.length > 0) return this.UserList;
      return this.UserList.filter((contact) => {
        return (
          contact["id"].toString().includes(filter) ||
          contact["name"]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          contact["email"]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          (contact["company"] &&
            contact["company"]["name"]
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase()))
        );
        // str.includes("world");
      });
    },
  },
  watch: {
    deleteModal(newValue, oldValue) {
      if (newValue == false && newValue != oldValue) this.deleteItem = "";
    },
  },
};
</script>

<style scoped>
.header-role-chip {
  background: #1976d2;
}
</style>