<template>
  <v-row>
    <v-col cols="12" md="4" sm="4" lg="2" class="d-flex flex-column">
      <p>Job Label</p>
      <v-btn
        @click="GetPDF('job_label')"
        class="pa-5"
        :loading="this.indexClicked == 'job_label'"
        :disabled="this.indexClicked != null"
        v-if="this.checkPermission('can_view_job_label_pdf')"
        >Job Label</v-btn
      >
    </v-col>

    <v-col cols="12" md="4" sm="4" lg="2" class="d-flex flex-column">
      <p>Function</p>
      <v-btn
        @click="smsModal = true"
        class="mb-4 pa-5"
        v-if="this.checkPermission('can_send_sms')"
        :loading="this.indexClicked == 'send_text'"
        :disabled="this.indexClicked != null"
        >Send SMS</v-btn
      >

      <v-btn
        @click="SetBER()"
        class="mb-4 pa-5"
        v-if="this.checkPermission('can_ber')"
        :loading="this.indexClicked == 'ber'"
        :disabled="this.indexClicked != null"
        >{{ !this.job.bBer ? "Set BER" : "Unset BER" }}</v-btn
      >
      <v-btn
        @click="SetRNR()"
        class="mb-4 pa-5"
        v-if="this.checkPermission('can_rnr')"
        :loading="
          (this.indexClicked == 'rnr' && this.rnr_modal == true) ||
          (this.job.bRnr && this.indexClicked == 'rnr')
        "
        :disabled="
          (this.indexClicked != null && this.rnr_modal == true) ||
          (this.job.bRnr && this.indexClicked == 'rnr')
        "
        >{{ !this.job.bRnr ? "Set RNR" : "Unset RNR" }}</v-btn
      >
      <v-btn
        @click="gotoEditJob()"
        class="mb-4 pa-5"
        v-if="this.checkPermission('can_edit_job')"
        >Edit</v-btn
      >
      <v-btn
        class="pa-5"
        color="error"
        @click="deleteModal = true"
        v-if="this.checkPermission('can_delete_job')"
        >Delete</v-btn
      >
      <ConfirmationDialog
        v-if="this.checkPermission('can_delete_job')"
        v-model="deleteModal"
        @cancelled="deleteModal = false"
        @confirmed="deleteJob()"
        :loader="is_deleting"
        :itemName="'job'"
      />

      <RnrDialog
        v-if="this.checkPermission('can_rnr')"
        v-model="rnr_modal"
        @cancelled="RNRcancelled()"
      />
      <BerDialog
        v-if="this.checkPermission('can_ber')"
        v-model="ber_modal"
        @cancelled="BERcancelled()"
      />
    </v-col>

    <v-col
      cols="12"
      md="4"
      sm="4"
      lg="2"
      class="d-flex flex-column"
      v-if="
        this.job.status_id >= 4 &&
        (this.checkPermission('can_view_sales_quotations') ||
          this.checkPermission('can_email_sales_quotations'))
      "
    >
      <p>Quotation</p>
      <v-btn
        @click="GetPDF('sales_quotation')"
        class="mb-4 pa-5"
        :loading="this.indexClicked == 'sales_quotation'"
        v-if="this.checkPermission('can_view_sales_quotations')"
        :disabled="this.indexClicked != null"
        >View Sales Quotation</v-btn
      >
      <v-btn
        @click="EmailSalesQVisible = true"
        class="pa-5"
        :loading="this.indexClicked == 'email'"
        v-if="this.checkPermission('can_email_sales_quotations')"
        >Email Sales Quotation</v-btn
      >
      <EmailSalesQuotationModal
        v-if="this.checkPermission('can_email_sales_quotations')"
        v-model="EmailSalesQVisible"
        @confirmed="EmailPDF"
      />
    </v-col>

    <v-col cols="12" md="4" sm="4" lg="2" class="d-flex flex-column">
      <p>Job Report</p>
      <v-btn
        @click="ShowUploadImagesForm()"
        class="mb-4 pa-5"
        v-if="this.checkPermission('can_add_photos')"
        >Add Photos</v-btn
      >

      <v-btn
        @click="unlink_images_form_visible = true"
        class="mb-4 pa-5"
        v-if="
          this.checkPermission('can_add_photos') &&
          (this.job.photo1 ||
            this.job.photo2 ||
            this.job.photo3 ||
            this.job.photo4 ||
            this.job.photo5 ||
            this.job.photo6)
        "
        >Remove Photos</v-btn
      >

      <v-btn
        @click="GetPDF('job_report')"
        class="mb-4 pa-5"
        :loading="this.indexClicked == 'job_report'"
        :disabled="this.indexClicked != null"
        v-if="this.checkPermission('can_view_job_report_pdf')"
        >View Job Report</v-btn
      >

      <v-btn
        @click="EmailJobReportVisible = true"
        class="pa-5"
        :loading="this.indexClicked == 'email'"
        :disabled="this.indexClicked != null"
        v-if="this.checkPermission('can_email_job_report')"
        >Email Job Report</v-btn
      >
      <EmailJobReportModal
        v-model="EmailJobReportVisible"
        @confirmed="EmailPDF"
      />
    </v-col>

    <v-col cols="12" md="4" sm="4" lg="2" class="d-flex flex-column">
      <p>Delivery</p>
      <v-btn
        class="mb-4 pa-5"
        @click="ManualDelivery()"
        v-if="this.checkPermission('can_manually_deliver')"
        >Manual Delivery</v-btn
      >
      <v-btn
        @click="GetPDF('delivery_note')"
        class="mb-4 pa-5"
        :loading="this.indexClicked == 'delivery_note'"
        :disabled="this.indexClicked != null"
        >View Delivery Note</v-btn
      >
      <v-btn
        @click="GetPDF('signed_delivery_note')"
        class="mb-4 pa-5"
        :loading="this.indexClicked == 'signed_delivery_note'"
        :disabled="this.indexClicked != null"
        v-if="
          this.job.time_signature &&
          this.job.svg_signature &&
          this.checkPermission('can_view_signed_delivery_note_pdf')
        "
        >View Signed Delivery Note</v-btn
      >
      <v-btn
        @click="EmailSignedDNVisible = true"
        class="mb-4 pa-5"
        :loading="this.indexClicked == 'signed_delivery_note'"
        v-if="
          this.job.status_id >= 8 &&
          this.checkPermission('can_email_signed_delivery_note_pdf')
        "
        :disabled="this.indexClicked != null"
        >Email Signed Delivery Note</v-btn
      >
      <EmailSignedDeliveryNoteModal
        v-model="EmailSignedDNVisible"
        @confirmed="EmailPDF"
      />

      <v-btn
        @click="GetPDF('invoice')"
        class="mb-4 pa-5"
        :loading="this.indexClicked == 'invoice'"
        :disabled="this.indexClicked != null"
        v-if="this.job.status_id >= 9"
        >View Invoice</v-btn
      >
      <v-btn
        @click="EmailInvoiceVisible = true"
        class="pa-5"
        :loading="this.indexClicked == 'email'"
        v-if="
          this.job.status_id >= 9 && this.checkPermission('can_email_invoice')
        "
        :disabled="this.indexClicked != null"
        >Email Invoice</v-btn
      >

      <EmailInvoiceModal v-model="EmailInvoiceVisible" @confirmed="EmailPDF" />
    </v-col>

    <v-col
      cols="12"
      md="4"
      sm="4"
      lg="2"
      class="d-flex flex-column"
      v-if="this.job.status_id < 8 && this.checkPermission('can_advance_job')"
    >
      <p>Advance Job</p>
      <v-btn @click="gotoAdvanceJob()" class="pa-5" color="success">{{
        this.advance_button_text
      }}</v-btn>
    </v-col>

    <v-dialog v-model="upload_images_form_visible" width="500">
      <v-card color="white" class="pa-4">
        <UploadJobImagesForm />
      </v-card>
    </v-dialog>

    <UnlinkJobImagesForm
      v-model="unlink_images_form_visible"
      v-if="
        this.job.photo1 ||
        this.job.photo2 ||
        this.job.photo3 ||
        this.job.photo4 ||
        this.job.photo5 ||
        this.job.photo6
      "
    />
    <ManualDeliver v-model="manualDeliveryModal" />
    <SMSModal v-model="smsModal" v-if="this.job" />
  </v-row>
</template>

<script>
import axios from "axios";
import UploadJobImagesForm from "../forms/UploadJobImagesForm.vue";
import UnlinkJobImagesForm from "../forms/UnlinkJobImagesForm.vue";
import ManualDeliver from "./ManualDelivery.vue";
import EmailSalesQuotationModal from "../modals/EmailSalesQuotation.vue";
import EmailInvoiceModal from "../modals/EmailInvoiceModal.vue";
import EmailJobReportModal from "../modals/EmailJobReportModal.vue";
import EmailSignedDeliveryNoteModal from "../modals/EmailSignedDeliveryNoteModal";
import ConfirmationDialog from "../../global/ConfirmationDialog.vue";
import SMSModal from "../modals/SMSModal.vue";
import RnrDialog from "./RnrDialog.vue";
import BerDialog from "./BerDialog.vue";

export default {
  name: "SingleJobActionButtons",
  components: {
    UploadJobImagesForm,
    ManualDeliver,
    EmailSalesQuotationModal,
    EmailInvoiceModal,
    EmailJobReportModal,
    EmailSignedDeliveryNoteModal,
    ConfirmationDialog,
    UnlinkJobImagesForm,
    SMSModal,
    RnrDialog,
    BerDialog,
  },
  data() {
    return {
      upload_images_form_visible: false,
      unlink_images_form_visible: false,
      EmailInvoiceVisible: false,
      EmailJobReportVisible: false,
      EmailSignedDNVisible: false,
      manualDeliveryModal: false,
      indexClicked: undefined, // Some predefined value
      EmailSalesQVisible: false,
      deleteModal: false,
      smsModal: false,
      is_deleting: false,

      rnr_modal: false,
      ber_modal: false,
    };
  },
  methods: {
    BERcancelled() {
      this.ber_modal = false;
    },
    RNRcancelled() {
      this.rnr_modal = false;
    },
    ManualDelivery() {
      this.manualDeliveryModal = true;
    },
    ShowUploadImagesForm() {
      console.log("upload image modal trigger click");
      if (this.isMechanicalOrServo) this.$emit("changeTabIndex", 5);
      else this.upload_images_form_visible = true;
    },

    SetBER() {
      var value = true;
      if (this.job.id) {
        if (!this.job.bBer) {
          //if button clicked and BER was false to begin with
          this.ber_modal = true;
          return;
        }

        if (this.job.bBer == true || this.job.bBer == 1) value = false;
        this.indexClicked = "ber";

        this.$store
          .dispatch("updateSingleJob", {
            id: this.job.id,
            bBer: value,
          })
          .then(() => {
            this.$store.commit("SetSnackbarData", {
              message: "BER set successfully",
            });
          })
          .finally(() => {
            this.indexClicked = null;
          });
      }
    },
    SetRNR() {
      var value = true;
      if (this.job.id) {
        // this.indexClicked = "rnr";

        if (!this.job.bRnr) {
          //if button clicked and RNR was false to begin with
          this.rnr_modal = true;
          return;
        }

        if (this.job.bRnr == true || this.job.bRnr == 1) {
          value = false;
          this.indexClicked = "rnr";
        }

        this.$store
          .dispatch("SwitchRNR", {
            id: this.job.id,
            bRnr: value,
          })
          .then(() => {
            this.$store.commit("SetSnackbarData", {
              message: "RNR set successfully",
            });
          })
          .finally(() => {
            this.indexClicked = null;
          });
      }
    },

    GetPDF(endpoint) {
      // this.is_deleting = true;
      this.indexClicked = endpoint;
      return axios
        .get(
          process.env.VUE_APP_API_PROTOCOL +
            "://" +
            process.env.VUE_APP_API_HOST +
            ":" +
            process.env.VUE_APP_API_PORT +
            "/api/reports/" +
            this.job.id +
            "/" +
            endpoint,
          { responseType: "blob" } // important
        )
        .then((response) => {
          let browser = this.detectBrowser();
          if (browser == "Safari" || browser == "Unknown")
            // is safari
            this.Download_Instead_of_Open_PDF(response.data, endpoint + ".pdf");
          else {
            var blob = new Blob([response.data], { type: "application/pdf" });
            var blobURL = URL.createObjectURL(blob);
            window.open(blobURL, "_blank");
          }

          // window.open(blobURL);
        })

        .catch((error) => {
          //cuz its a blob request the error isn't read with a message
          this.$store.commit("SetSnackbarData", {
            message: error,
            type: "error",
          });
        })

        .finally(() => {
          this.is_deleting = false;
          this.indexClicked = null;
        });
    },

    EmailPDF(endpoint, cc_list = []) {
      this.indexClicked = "email";
      console.log(cc_list);
      return this.$store
        .dispatch("email_" + endpoint, { id: this.job.id, cc: cc_list })
        .finally(() => {
          this.indexClicked = null;
        });
    },

    Download_Instead_of_Open_PDF(data, filename) {
      var downloadLink = document.createElement("a");
      var fileData = [data];

      var blobObject = new Blob(fileData, {
        type: "application/pdf",
      });

      var url = URL.createObjectURL(blobObject);
      downloadLink.href = url;
      downloadLink.download = filename;

      /*
       * Actually download CSV
       */
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    gotoEditJob() {
      if (this.job.id) this.$router.push("/job/" + this.job.id + "/edit");
    },
    gotoAdvanceJob() {
      if (this.job.id) this.$router.push("/job/" + this.job.id + "/advance");
    },
    deleteJob() {
      if (this.job.id) {
        this.is_deleting = true;
        this.$store.dispatch("deleteJob", this.job.id).then(() => {
          this.is_deleting = false;
          this.$router.push("/dashboard");
        });
        // context.commit("setCurrentSingleJob", response.data);
      }
    },
  },
  computed: {
    isMechanicalOrServo() {
      return (
        this.job.tag &&
        (this.job.tag.nominal_code == 4102 ||
          this.job.tag.nominal_code == 4104 ||
          this.job.tag.nominal_code == 202 ||
          this.job.tag.nominal_code == 204)
      );
    },
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    advance_button_text() {
      if (this.job.status_id < 5) return "Advance Job";
      else if (this.job.status_id == 6) return "Complete Job";
      else if (this.job.status_id == 7) return "Deliver Job";
      else return "Advance Job";
    },
  },
};
</script>

<style>
</style>